import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Backdrop, Typography, Snackbar } from '@material-ui/core'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: '#0B6AB0',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}))

const DeleteAdminDialog = ({ open, close, selectedAdmins, handleChangeAdmins }) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [openDeleteSuccessSnackbar, setOpenDeleteSuccessSnackbar] = useState(false)
    const [openDeleteFailedSnackbar, setOpenDeleteFailedSnackbar] = useState(false)

    const deleteAdmins = async (selectedAdmins) => {
        setIsLoading(true)
        await axios
            .delete('admins/admin-list/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    // 'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    selected_admins: selectedAdmins
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Admin Delete Success ---- ##', response.status);
                    close()
                    handleChangeAdmins(response.data)
                    setIsLoading(false)
                    setOpenDeleteSuccessSnackbar(true)
                } else {
                    console.log('## ---- Admin Delete Failed ---- ##', response.status);
                    setOpenDeleteFailedSnackbar(true)
                }
            }).catch((error) => {
                console.log('## ---- Admin Delete Error ---- ##', error);
                setOpenDeleteFailedSnackbar(true)
            });
    }

    const handleCloseDeleteSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSuccessSnackbar(false)
    }

    const handleCloseDeleteFailedSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteFailedSnackbar(false)
    }

    return (
        <div>
            <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth='xs'>
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    <Typography variant='h6'>Delete Admin</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure to delete this admin?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} color="primary" onClick={close}>Cancel</Button>
                    <Button className={classes.deleteConfirmButton} onClick={() => { deleteAdmins(selectedAdmins) }}>Confirm</Button>
                </DialogActions>
                <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
            </Dialog>

            <Snackbar open={openDeleteSuccessSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteSuccessSnackbar}>
                <Alert onClose={handleCloseDeleteSuccessSnackbar} severity='success'>
                    You have deleted selected admin
                </Alert>
            </Snackbar>

            <Snackbar open={openDeleteFailedSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteFailedSnackbar}>
                <Alert onClose={handleCloseDeleteFailedSnackbar} severity='error'>
                    Operation failed!
                </Alert>
            </Snackbar>

            
        </div>
    )
}

export default DeleteAdminDialog
