import { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Paper } from "@material-ui/core";
import {Link} from 'react-router-dom'
import {userTime, getLocalDateFormat} from '../../custom_util'
import { FiAlertTriangle, FiChevronRight, FiCalendar, FiClock, FiTrendingUp } from "react-icons/fi";
import { ImSigma } from "react-icons/im";
import { IoHourglassOutline } from "react-icons/io5";


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    examEnterButton:{
        color: '#0B6AB0',
        textDecoration: 'none',
        marginRight: 3,
        "&:hover > $examEnterArrow": {
            marginLeft: theme.spacing(1),
            textDecoration: 'none',
        }
    },
    examEnterArrow: {
        color: '#0B6AB0',
        position: 'relative',
        top: '3px',
        "&:hover": {
            marginLeft: theme.spacing(1),
            textDecoration: 'none',
        }
    },
    upcomingContainer: {
        padding: 16,
        marginTop: 16,
    },
    iconStyle:{
        position: 'relative',
        top: 2,
        marginRight: 4 
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    customWidth: {
        maxWidth: 700,
    },
}));

const UpcomingExam = ({ upcoming }) => {
    const classes = useStyles();

    const isRunning = (exam) => (
        getLocalDateFormat(exam.start_time) < new Date() 
                        && new Date() < getLocalDateFormat(exam.end_time)
                        ? true : false
    )

    return (
        <>{upcoming && upcoming.length ?
            <>
            <Box>
                <Typography variant="h6" className={classes.header} display="inline">Upcoming Exams</Typography>
            </Box>
            <Box mb={4}>
                {upcoming.map((upComingCourseDetails, key) => (
                    <Paper
                        variant="outlined"
                        className={classes.upcomingContainer}>
                        <Grid container>
                            <Grid item sm={8} xs={12}>
                                <Typography 
                                    variant="h6"
                                    style={{width:"60vw", marginBottom:"2px"}}
                                    >
                                        {upComingCourseDetails.title}
                                </Typography>
                                <Typography variant="body2" style={{ marginBottom: 4 }}>
                                    Course: {upComingCourseDetails.course}
                                </Typography>
                                <Box>
                                    <Typography variant="body2" display="inline">
                                        <FiCalendar color="#0B6AB0" className={classes.iconStyle}/> 
                                        {userTime(upComingCourseDetails.start_time,'DD MMM YYYY')}
                                    </Typography>
                                    <Typography variant="body2" display="inline" style={{ marginLeft: 16 }}>
                                        <FiClock color="red" className={classes.iconStyle}/> 
                                        {userTime(upComingCourseDetails.start_time, 'hh:mm A')}
                                    </Typography>
                                    <Typography variant="body2" display="inline" style={{ marginLeft: 16 }}>
                                        <FiTrendingUp color="#00BC9E" className={classes.iconStyle}/> 
                                        {upComingCourseDetails.duration} min {isRunning(upComingCourseDetails) 
                                        && <span style={{color: "red"}}>(Running)</span>}
                                    </Typography>
                                    <Typography variant="body2" display="inline" style={{ marginLeft: 16 }}>
                                        <ImSigma color="#0B6AB0" className={classes.iconStyle}/>
                                        {upComingCourseDetails.total_marks} marks
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item sm={4} xs={12}>
                                <Box display="flex" alignItems="center" justifyContent="flex-end" height='100%'>
                                    <Link className={classes.examEnterButton} to={`/exam-instructions/${upComingCourseDetails.id}`}>Enter 
                                    <FiChevronRight className={classes.examEnterArrow}/> </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                ))} 
            </Box> 
            </>
        : <></>}
        </>
    );
};

export default UpcomingExam;
