import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography, Button, Chip, Switch, FormControlLabel } from '@material-ui/core'
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'
import { useConfirm } from "material-ui-confirm"
import axios from 'axios'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        padding: theme.spacing(1),
    },
    card: {
        //backgroundColor: '#fcfdfe',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    cardMedia: {
        paddingTop: '56.25%'  // aspect ratio 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
    },
    formControlLabel: {
        // marginTop: theme.spacing(2),
    },
}));

const Course = ({ course }) => {
    const classes = useStyles();
    const confirm = useConfirm();
    const [isActive, setIsActive] = useState(course.is_active);

    const handleStatusChange = (e) => {
        confirm({ description: `Changing status to ${e.target.checked ? 'active' : 'inactive'}.` })
            .then(() => changeStatus(!e.target.checked))
            .catch(() => setIsActive(e.target.checked));
    }

    const changeStatus = (status) => {
        axios({
            method: 'PUT',
            url: `courses/status/${course.id}/`,
            data: {
                status: status
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                console.log('Status changed!');
                setIsActive(status);
            }
        }).catch((error) => {
            console.log(error);
            setIsActive(!status);
        });
    }
    
    return (
        <Grid item xs={12} md={6} className={classes.cardGrid}>
            <Card className={classes.card} variant='outlined'>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="body1" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                            {course.title}
                        </Typography>
                        {/* <Typography gutterBottom variant="overline">
                            {course.instructor.map((instructor, index) => (
                                <>{index == 0 ? instructor.name : `, ${instructor.name}`}</>
                            ))}
                        </Typography> */}
                        <Typography variant="overline">Enrolled Students: {course.student_count}</Typography>
                        <Typography display='block' variant="caption" color="textSecondary" style={{ marginTop: 0, fontWeight: 'bold' }}>
                            {course.academic_session ? course.academic_session.title : "No Session"}
                        </Typography>
                        {/* <div style={{ display: 'flex', direction: 'row' }}>
                            <Rating defaultValue={course.rating} precision={0.1} size="small" readOnly />
                        </div> */}
                        <Box mt={2}>
                            {course.is_archived ?
                                <Chip label="Archived" />
                                :
                                // <FormControlLabel
                                //     className={classes.formControlLabel}
                                //     control={<Switch checked={isActive} onChange={handleStatusChange} color="primary" />}
                                //     label={isActive ? 'Active' : 'Inactive'}
                                // />
                                <></>
                            }
                        </Box>
                    </CardContent>
                    {!course.is_archived && <Box display='flex' alignItems='center'>
                        <Link to={`/instructor-course/${course.id}`} className={classes.link}><Button size='large' variant='outlined' color="primary" style={{ textTransform: 'none' }}>View</Button></Link>
                    </Box>}
                </div>
            </Card>
        </Grid>
    )
}

export default Course
