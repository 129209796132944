import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box, Typography } from '@material-ui/core';
import FileViewer from 'react-file-viewer';

import './index.css';

//const corsProxy = 'https://cors.bridged.cc/';

const useStyles = makeStyles((theme) => ({
}));

// Supports pdf csv xslx docx mp4 webm mp3
function FViewer({ file, type }) {
    const classes = useStyles();

    return (
        <div className="pdf-container">
            <FileViewer
                fileType={type}
                filePath={file}
            />
        </div>
    );
}

export default FViewer
