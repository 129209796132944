import { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container,Grid, Box, Typography, Backdrop, Paper, useTheme, Button} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { FiTrendingUp, FiCalendar, FiClock,  FiChevronLeft, FiPlay } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {userTime, getLocalDateFormat, fromNowMoment} from '../../custom_util'
import moment from 'moment';
import axios from 'axios';
import ReactQuill from 'react-quill';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import { ImSigma } from "react-icons/im";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 17,
        padding: theme.spacing(1),
    },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    iconStyle:{
        position: 'relative',
        top: 2,
        marginRight: 4
    },
    titleHandle:{
        position: 'relative',
        left: -15
    },
    button: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    paper: {
        padding: 0,
        boxShadow: 'none',
        border: '1px solid gray'
    },
    popover: {
        pointerEvents: 'none',
    },
}));

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function ExamInstruction() {
    const { examId } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [isCourseLoading, setIsCourseLoading] = useState(false);
    const [day, setDay] = useState('_ _')
    const [hour, setHour] = useState('_ _')
    const [minute, setMinute] = useState('_ _')
    const [second, setSecond] = useState('_ _')

    const [examDisabled, setExamDisabled] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const examScheduleChecker = () => {
        //const start_time = new Date(exam.start_time) 
        const start_time = getLocalDateFormat(exam.start_time)
        //const end_time = new Date(exam.end_time)
        const end_time = getLocalDateFormat(exam.end_time)
        if(new Date() < end_time && start_time < new Date()){
            return false
        } else if( start_time > new Date()){
            if(day === 0 && (hour < 6 && hour >= 0))
                return show_duration_timer()
            else return "Exam will start " + fromNowMoment(start_time)
        } else{
            return false
        }
    }
    
    const [exam, setExam] = useState({})

    useEffect(() =>{
        setIsCourseLoading(true)
        const getExamsDetails = () => {
            axios({
                method: 'GET',
                url: `exams/student-exam-details/${examId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Exams fetch success!', response.status);
                    setExam(response.data);
                    setIsCourseLoading(false)
                } else {
                    setIsCourseLoading(false)
                    console.log('Exams fetch failed!', response.status);
                }
            }).catch((error) => {
                    setIsCourseLoading(false)
                    console.log('Exams fetch failed!', error);
            })
        }
        getExamsDetails()
    }, [])
    
    const get_duaration = () => {
        
        const now = new Date()
        //const end = new Date(exam.start_time)
        const end = getLocalDateFormat(exam.start_time)
        if(now < end){
            var delta = Math.abs(end - now) / 1000;
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = parseInt(delta % 60)

            setDay(days)
            setHour(hours)
            setMinute(minutes)
            setSecond(seconds)
            //setExamDisabled(true)
        } else{
            setDay(0)
            setHour(0)
            setMinute(0)
            setSecond(0)
            setExamDisabled(false)
        }
    }
    
    useEffect(() => {
        let timer = null
        if(day === 0 && hour < 6)
            timer = setInterval(() => exam && get_duaration(), 1000)
        return () => clearInterval(timer)
    }, [second])

    useEffect(() => {Object.keys(exam).length !== 0 && get_duaration()}, [exam])

    const show_duration_timer = () => (
        <>{day ? `${day}d : ` : ''}{hour ? `${hour}h : `: ''}{minute ? `${minute}m : `: ''}{second && `${second}s`}</>
    )

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Container maxWidth={false} className={classes.root}>
            {isCourseLoading ? <Backdrop className={classes.backdrop} open={isCourseLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop> :
            <div style={{marginLeft: '24px', marginRight: '24px'}}>
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start"  className={classes.titleHandle}>
                            <FiChevronLeft
                                style={{ cursor: 'pointer', fontSize: 24, color: '#0B6AB0' }}
                                onClick={() => history.goBack()}
                            />
                            <Typography 
                                noWrap 
                                variant="h5" 
                                style={{ color: '#0B6AB0'}}
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                >
                                    {exam.title}
                                </Typography>
                            <Popover
                                id="mouse-over-popover"
                                className={classes.popover}
                                classes={{
                                    paper: classes.paper,
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'start',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Typography>{exam.title}</Typography>
                            </Popover>
                        </Box>
                        <Typography variant="h6">Course: {exam.course}</Typography>
                        <Typography variant="subtitle2">Session: {exam.session}</Typography>

                        <Box>
                            <Typography display="inline">
                                <FiCalendar color="#0B6AB0" className={classes.iconStyle}/> 
                                {userTime(exam.start_time, 'DD MMM YYYY')}
                            </Typography>
                            <Typography display="inline" style={{ marginLeft: 16 }}>
                                <FiClock color="red" className={classes.iconStyle}/> 
                                {userTime(exam.start_time, 'hh:mm A')}
                            </Typography>
                            <Typography display="inline" style={{ marginLeft: 16 }}>
                                <FiTrendingUp color="#00BC9E" className={classes.iconStyle}/> 
                                {exam.duration} min
                            </Typography>
                            <Typography display="inline" style={{ marginLeft: 16 }}>
                                <ImSigma color="#0B6AB0" className={classes.iconStyle}/>
                                {exam.total_marks} marks
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box display="flex" alignItems={matches ? "flex-end" : "center"} justifyContent="center"  flexDirection="column" height="100%">
                            <Button
                                startIcon={<FiPlay/>}
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={`/exam/${examId}/`}
                                disabled={examDisabled}
                                disableElevation
                                className={classes.button}
                            >
                                <Link to={`/exam/${examId}/`} style={{ textDecoration: 'none', color: 'white' }}>Enter Exam</Link>
                            </Button>
                            <Typography variant="subtitle2" align={matches ? "right" : "center"} style={{color: "gray", padding: 3}} >
                                {examScheduleChecker() || "Exam Ongoing"}
                                {examScheduleChecker() && <span style={{color:'red'}}><br/>Please wait until exam starts.</span>}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={3}>
                    <Typography variant="subtitle1">
                        Instruction
                    </Typography>

                    <Paper variant="outlined">
                        {/* <Paper elevation={0} style={{color: 'gray'}}
                        dangerouslySetInnerHTML={{__html: exam.instruction}} /> */}
                        <ReactQuill
                            value={exam.instruction || ''}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Paper>
                </Box>
            </div>}
        </Container>
    )
}

export default ExamInstruction
