import { DataGrid } from "@material-ui/data-grid";
import React from "react";

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "studentName", headerName: "Student Name", width: 300 },
  { field: "academicSessionTitle", headerName: "Session", width: 300 },
  { field: "courseTitle", headerName: "Course Title", width: 450 },
  { field: "email", headerName: "Email", width: 310 },
];

function rows(enrollments) {
  const rows = [];
  enrollments.map((enrollment, index) =>
    rows.push({
      id: enrollment.id,
      studentName: enrollment.name,
      academicSessionTitle: enrollment.academic_session_title,
      courseTitle: enrollment.course_title,
      email: enrollment.email,
    })
  );
  return rows;
}

const EnrollmentListTable = ({
  enrollments,
  handleChecked,
  handleSelectedEnrollments,
}) => {
  return (
    <div>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(enrollments)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          handleChecked(newSelection.length);
          handleSelectedEnrollments(newSelection);
        }}
        pageSize={10}
      />
    </div>
  );
};

export default EnrollmentListTable;
