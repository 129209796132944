import { Box, IconButton, makeStyles, Paper, TextField, Tooltip, Typography, Slide, Fade, Slider, Grow, Backdrop, Snackbar, Popover } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FiCalendar, FiCheck, FiClock, FiPlusCircle, FiTrendingUp, FiX, FiXCircle } from 'react-icons/fi';
import { IoHourglassOutline } from 'react-icons/io5';
import { userTime, webSocketBase } from '../../../custom_util';
import AttendeeListTable from './AttendeeListTable';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

const useStyles = makeStyles((theme) => ({
    calendarIcon: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    clockIcon: {
        color: '#AF2323',
        marginRight: 4,
    },
    durationIcon: {
        color: '#00BC9E',
        marginLeft: 8,
        marginRight: 4,
    },
    addIcon: {
        color: '#00BC9E',
        fontSize: 20,
        cursor: 'pointer'
    },
    saveIcon: {
        color: '#00BC9E',
        fontSize: 20,
        cursor: 'pointer'
    },
    cancelIcon: {
        color: '#AF2323',
        fontSize: 20,
        cursor: 'pointer'
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

const examStatusColor = {
    'Unpublished': 'grey',
    'Published': '#0B6AB0',
    'Finished': '#00BC9E',
}

const ExamDurationAddition = ({ examId, open }) => {
    const classes = useStyles();
    const [examDate, setExamDate] = useState('');
    const [examStartTime, setExamStartTime] = useState('');
    const [examDuration, setExamDuration] = useState('');
    const [examStatus, setExamStatus] = useState(''); 
    const [participants, setParticipants] = useState('');
    const [attendees, setAttendees] = useState([]);
    const [examDurationInput, setExamDurationInput] = useState('');
    const [selectedSubmission, setSelectedSubmission] = useState('');
    const [individualExamDurationInput, setIndividualExamDurationInput] = useState('');
    const [selectedSubmissionIndex, setSelectedSubmissionIndex] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAttendeeList, setIsLoadingAttendeeList] = useState(false);

    const [showAdditionalMinuteBox, setShowAddionalMinuteBox] = useState(false);
    const [showAddIcon, setShowAddIcon] = useState(true);

    const [showExamAdditionSnack, setShowExamAdditionSnack] = useState(false);

    const [showPopover, setShowPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // var examEndPoint = webSocketBase() + '/exam-socket/'
    // var examSocket = new WebSocket(examEndPoint);
    
    // examSocket.onmessage = (message) => {
    //     console.log(message);
    // };
    // examSocket.onerror = function() {
    //     console.log('Connection Error');
    // };
    

    // useEffect(() => {
    //     var examEndPoint = webSocketBase() + '/exam-socket/'
    //     var examSocket = new WebSocket(examEndPoint);
        
    //     examSocket.onmessage = (message) => {
    //         console.log(message.data);
    //     };
    //     examSocket.onerror = function() {
    //         console.log('Connection Error');
    //     };
    //     return () => examSocket.onclose;
    // }, [])

    useEffect(() => {
        const getExamDetails = async () => {
            setIsLoading(true);
            await axios({
                method: 'GET',
                url:  `exams/exam-details/${examId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                setIsLoading(false);
                console.log("# ---- Exam details success ---- #", response.status);
                setExamDate(response.data.start_time);
                setExamStartTime(response.data.start_time);
                setExamDuration(response.data.duration);
                setExamStatus(response.data.status);
            }).catch((error) => {
                console.log("# ---- Exam details error ---- #", error);
                setIsLoading(false);
            })
        }
        if (open) {
            getExamDetails(); 
        }
    }, [open])

    useEffect(() => {
        const getExamAttendeeList = async () => {
            setIsLoadingAttendeeList(true);
            await axios({
                method: 'GET',
                url: 'exams/exam-submission-list/',
                params: {
                    exam_id: examId
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                console.log("# ---- Exam attendee list ---- #");
                setIsLoadingAttendeeList(false);
                setParticipants(response.data.length);
                setAttendees(response.data);
            }).catch((error) => {
                console.log("# ---- Exam attendee list error ---- #", error.response.status);
            })
        }
        if (open) {
            getExamAttendeeList();
        }
    }, [open])

    const additionTimeHtml = () => (
        <Box display='flex'alignItems='center'>
            <TextField 
                value={examDurationInput}
                fullWidth
                type='number'
                size='small'
                label='Additional Minute'
                variant='outlined'
                onChange={(event) => setExamDurationInput(event.target.value)}
                style={{width: 300, marginTop: 5}}    
            />
            <FiCheck 
                className={classes.saveIcon}
                onClick={() => saveIconClick()}
                style={{marginLeft: 16}}
            />
            <FiX 
                className={classes.cancelIcon}
                onClick={() => cancelIconClick()}
                style={{marginLeft: 16}}
            />
        </Box>
    );

    const changeExamDuration = async () => {
        setIsLoading(true);
        await axios({
            method: 'PUT',
            url: 'exams/exam-duration-all/',
            data: {
                exam_id: examId,
                duration: examDurationInput
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Exam duration extend success ---- #");
            setExamDuration(response.data.duration);
            setShowExamAdditionSnack(true);
            setIsLoading(false);
        }).catch((error) => {
            console.log("# ---- Exam duration extend error ---- #", error);
            setIsLoading(false);
        })
    }

    const changeExamDurationIndividual = async () => {
        await axios({
            method: 'PUT',
            url: 'exams/exam-duration-individual/',
            data: {
                submission_id: selectedSubmission,
                duration: individualExamDurationInput
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Exam duration individual extend success ---- #");
            setShowExamAdditionSnack(true);
            var values = [...attendees];
            values[selectedSubmissionIndex].additonal_duration = response.data.additonal_duration;
            setAttendees(values);

        }).catch((error) => {
            console.log("# ---- Exam duration individual extend error ---- #", error);
        })
    }

    const addIconClick = () => {
        setShowAddionalMinuteBox(true);
        setShowAddIcon(false);
    }

    const saveIconClick = () => {
        setShowAddIcon(true);
        setShowAddionalMinuteBox(false);
        changeExamDuration();
        setExamDurationInput('');
    }

    const cancelIconClick = () => {
        setShowAddIcon(true);
        setShowAddionalMinuteBox(false);
        setExamDurationInput('');
    }

    const handleCloseAdditonSuccessSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setShowExamAdditionSnack(false);
    }
    
    const handleClickAdditon = (event, index, submissionId) => {
        setShowPopover(true);
        setAnchorEl(event.currentTarget);
        setSelectedSubmission(submissionId);
        setSelectedSubmissionIndex(index);
    }

    const saveIndividualDurationChange = () => {
        setShowPopover(false);
        setAnchorEl(null);
        setIndividualExamDurationInput('');
        changeExamDurationIndividual();
    }

    const handleClose = () => {
        setShowPopover(false);
        setAnchorEl(null);
        setIndividualExamDurationInput('');
    };

    return (
        <div>
            {!isLoadingAttendeeList &&
                <Paper elevation={0} variant='outlined'>
                    <Box display='flex' p={1} justifyContent='space-between'>
                        <Box>
                            <Typography variant='body2'>Primary Scheduled Timings</Typography>
                            <Box display='flex' flexDirection='row' alignItems='center' mt={1}>
                                <FiCalendar className={classes.calendarIcon} />
                                <Typography variant='body2'>{userTime(examDate, 'DD MMM YYYY')}</Typography>
                            </Box>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <FiClock className={classes.clockIcon} />
                                <Typography variant='body2'>{userTime(examStartTime, 'hh:mm A')}</Typography>
                                <FiTrendingUp className={classes.durationIcon} />
                                <Typography variant='body2' style={{marginRight: 16}}>{examDuration} min</Typography>
                                <Grow in={showAddIcon} timeout={500}>
                                    <FiPlusCircle 
                                        className={classes.addIcon}
                                        onClick={() => addIconClick()}
                                    />
                                </Grow>
                                <Box style={{overflow: 'hidden'}}>
                                    <Slide direction='right' in={showAdditionalMinuteBox} timeout={250}>
                                        {additionTimeHtml()}
                                    </Slide>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="body2">Participants: {participants ? participants : 'N/A'}</Typography>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <Typography variant="body2">Status: </Typography>
                                <Typography variant='caption' style={{ marginLeft: 4, marginTop:4, fontWeight: 'bold', color: examStatusColor[examStatus] }}>{examStatus}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            }
            {!isLoadingAttendeeList &&
                <>
                    <Box display='flex' m={1}>
                        <Typography variant='h6' className={classes.header}>Exam Attendee List</Typography>
                    </Box>
                    <AttendeeListTable 
                        attendees={attendees}
                        handleClickAdditon={handleClickAdditon}
                    />
                </>
            }

            <Popover
                id='simple-popover'
                open={showPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box display='flex' flexDirection='column' m={2}>
                    <TextField 
                        value={individualExamDurationInput}
                        type='number'
                        label='Additional Minute'
                        variant='outlined'
                        size='small'
                        onChange={(event) => setIndividualExamDurationInput(event.target.value)}
                    />
                    <Box display='flex' justifyContent='space-between' mt={1} mr={2}>
                    <FiCheck 
                        className={classes.saveIcon}
                        onClick={() => saveIndividualDurationChange()}
                        style={{marginLeft: 16}}
                    />
                    <FiX 
                        className={classes.cancelIcon}
                        onClick={() => handleClose()}
                        style={{marginLeft: 16}}
                    />
                    </Box>
                </Box>
            </Popover>

            <Backdrop className={classes.backdrop} open={isLoadingAttendeeList || isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }}/>
            </Backdrop>

            <Snackbar open={showExamAdditionSnack} autoHideDuration={4000} onClose={handleCloseAdditonSuccessSnack}>
                <Alert onClose={handleCloseAdditonSuccessSnack} severity='success' >
                    You have added addiotinal exam duration.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ExamDurationAddition;
