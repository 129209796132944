import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Container,Grid, Box, Typography, Paper, useTheme,
    Button, Snackbar } from "@material-ui/core";
import { FiArrowRightCircle,  FiArrowLeftCircle, FiTrendingDown, FiUpload} from "react-icons/fi";
import {getLocalDateFormat, webSocketBase} from '../../custom_util'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    iconStyle:{
        position: 'relative',
        marginRight: 3,
    },
    bottomNavigation:{
        position: 'fixed',
        bottom: theme.spacing(6.5),
        right: 0,
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
    },
    finishBtn: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none',
        marginLeft: 8,
        marginRight: 8,
    },
}));


function ExamTimer({confirmExamFinish, value, exam, handleValue, makeExamFinish, user }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [minute, setMinute] = useState('_ _')
    const [second, setSecond] = useState('_ _')
    const [examEndTime, setExamEndTime] = useState(exam.end_time);

    const get_duaration = () => {
        const now = new Date()
        //const end = new Date(exam.end_time)
        const end = getLocalDateFormat(examEndTime);

        if(now < end){
            var delta = Math.abs(end - now) / 1000;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60);
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = parseInt(delta % 60)
        
            setMinute(minutes)
            setSecond(seconds)
        } else{
            makeExamFinish(true)
            setMinute(0)
            setSecond(0)
        }
    }

    useEffect(() => {
        let timer = null
        if(minute === 0 && second === 0) confirmExamFinish("Your time is over. Please submit your answer.")
        timer = setInterval(() => exam && get_duaration(), 1000)
        return () => clearInterval(timer)
    }, [second, exam])

    useEffect(() => {
        var examEndPoint = webSocketBase() + '/exam-socket/'
        var examSocket = new WebSocket(examEndPoint);
        
        examSocket.onmessage = (message) => {
            var data = JSON.parse(message.data);
            
            if (data) {
                if (data.event == 'change_exam_duration_all') {
                    if (data.user_id === user.id && data.exam_id === parseInt(exam.id)) {
                        setExamEndTime(data.end_time);
                        // setSnackBar({isOpen:true, message: 'You have given additional exam duration.', status: 'info'});
                    }
                }
            }
        };
        examSocket.onerror = function() {
            console.log('Connection Error');
        };
        return () => examSocket.onclose;
    }, [])

    useEffect(() => {
        var examEndPoint = webSocketBase() + '/exam-socket/'
        var examSocket = new WebSocket(examEndPoint);
        
        examSocket.onmessage = (message) => {
            var data = JSON.parse(message.data);
            if (data && user) {
                if (data.event == 'change_exam_duration_individual') {
                    if (data.user_id === user.id && data.exam_id === parseInt(exam.id)) {
                        setExamEndTime(data.end_time);
                        // setSnackBar({isOpen:true, message: 'You have given additional exam duration.', status: 'info'});
                    }
                }
            }
        };
        examSocket.onerror = function() {
            console.log('Connection Error');
        };
        return () => examSocket.onclose;
    }, [user])

    return <Paper className={classes.bottomNavigation} variant='outlined'>
        <Grid container>
            <Grid item sm={6} xs={12}>
                <Box display='flex' alignItems='center' justifyContent={matches ? "flex-start" : "center"}>
                    <Button onClick={() => value-1 >= 0 ? handleValue(value-1) : handleValue(0)}><FiArrowLeftCircle size="1.4rem" color="#0B6AB0" className={classes.iconStyle}/></Button> 
                    <Typography>  Questions {value+1} of {exam.question.length} </Typography>
                    <Button onClick={() => value + 1 < exam.question.length ? handleValue(value+1) : handleValue(exam.question.length-1)}><FiArrowRightCircle size="1.4rem" color="#0B6AB0" className={classes.iconStyle}/></Button>
                </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
                <Box display="flex" justifyContent={matches ? "flex-end" : "center"} alignItems="center">
                    <FiTrendingDown color="red" className={classes.iconStyle}/><Typography style={{marginRight: '16px'}}> {minute}:{second < 10 ? '0'+second : second}</Typography>
                    <Button
                        startIcon={<FiUpload />}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => confirmExamFinish()}
                        className={classes.finishBtn}
                        disableElevation
                    >
                        Finish Exam
                    </Button>
                </Box>
            </Grid>
        </Grid>      
</Paper>;
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps) (ExamTimer);
