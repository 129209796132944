import { useState, useEffect, forwardRef, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Slide, Dialog, Toolbar, IconButton, Typography, Grid, Box, Tooltip, Backdrop } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { Scrollbars } from 'react-custom-scrollbars';
import { IoHourglassOutline } from "react-icons/io5";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import axios from 'axios';
import { useConfirm } from "material-ui-confirm"

import GViewer from "../GViewer";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "#0B6AB0",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: "black",
        maxWidth: '30vw',
    },
    textArea: {
        width: "100%",
        border: "1px solid #A9A9A9",
        borderRadius: 20,
        padding: 10,
        resize: "none",
        "&:focus": {
            border: "1px solid #0B6AB0",
            outline: "none !important",
        },
        overflow: 'hidden',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    deleteIcon: {
        color: "#C0392B",
        cursor: "pointer",
        fontSize: "20px",
        backgroundColor: "#EDF0F5",
        padding: "2px",
        borderRadius: "5px",
        marginLeft: theme.spacing(1),
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function Comment({index, comment, comments, setComments}) {
    const classes = useStyles();
    const confirm = useConfirm();
    const [showDelete, setShowDelete] = useState(false);

    const deleteConfirmComment = () => {
        confirm({
            title: <Typography variant='h6' className={classes.dialogTitle}>Deleting the Comment</Typography>, 
            description: <Typography varint='body1'>You cannot undo the process. Are you sure to delete?</Typography>,
            confirmationText: 'Delete',
            confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
            cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
        })
            .then(handleDeleteComment)
            .catch(() => console.log('Cancelled!'));
    }

    const handleDeleteComment = () => {
        axios({
            method: 'DELETE',
            url: `courses/homework-sub-comment-details/`,
            data: {
                id: comment.id,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 202) {
                var values = [...comments];
                values.splice(index, 1);
                setComments(values);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Box
            p={2}
            //pb={1}
            //pt={1}
            mb={1}
            onMouseEnter={() => setShowDelete(true)}
            onMouseLeave={() => setShowDelete(false)}
            style={{ backgroundColor: "white", borderRadius: 10, border: '1px solid #EAECEE' }}
        >
            <Typography variant="caption" display='block' style={{ fontWeight: 'bold', color: comment.is_owner ? 'grey' : 'black' }}>{comment.is_owner ? "You" : comment.name}</Typography>
            <Typography variant="body2" display="block">{comment.text}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="caption" display="block" style={{ color: "grey" }}>{comment.time}</Typography>
                {comment.is_owner &&
                    showDelete && <Tooltip title='Delete'><DeleteOutlineIcon className={classes.deleteIcon} onClick={deleteConfirmComment} /></Tooltip>
                }
            </Box>
        </Box>
    )
}

export function ManageComments({id}) {
    const classes = useStyles();
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [commentTxt, setCommentTxt] = useState("");

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            await axios({
                method: 'GET',
                url: `courses/homework-sub-comment-list/${id}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                setIsLoading(false);
                if (res.status == 200) {
                    setComments(res.data);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        }

        getData();
    }, []);

    const handleSubmitComment = () => {
        if (commentTxt) {
            setCommentTxt("");
            axios({
                method: 'POST',
                url: `courses/homework-sub-comment-details/`,
                data: {
                    id: id,
                    text: commentTxt,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status == 201) {
                    setComments([...comments, res.data]);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    
    return (
        <div style={{ backgroundColor: "#fcfdfe" }}>
            <Scrollbars
                style={{ height: window.innerHeight*0.7 }}
                autoHide
            >
                {isLoading ?
                    <Box mt={6}>
                        <Typography variant="body2" color="initial" style={{ color: 'grey' }} align="center">Loading...</Typography>
                    </Box>
                    :
                    <Box p={3}>
                        {comments.length > 0 ?
                            comments.map((comment, index) => (
                                <Comment index={index} comment={comment} comments={comments} setComments={setComments} />
                            ))
                            :
                            <Box display="flex" flexDirection="column" justifyContent="center" pt={6}>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <CommentIcon style={{ color: "grey" }} />
                                </Box>
                                <Typography variant="body2" style={{ color: 'grey' }} align="center">No comment yet.</Typography>
                            </Box>
                        }
                    </Box>
                }
            </Scrollbars>
            <div style={{ height: window.innerHeight*0.2 }}>
                <Box p={3} pb={0}>
                    <textarea
                        className={classes.textArea}
                        rows="1"
                        value={commentTxt}
                        placeholder="Type your comment here"
                        onChange={(e) => setCommentTxt(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                e.preventDefault();
                                handleSubmitComment();
                            }
                        }}
                    />
                </Box>
                <Box m={0} ml={5}>
                    <Typography
                        variant="caption"
                        style={{ color: "#0B6AB0" }}
                        display="inline"
                    >
                        Press enter to add the comment.
                    </Typography>
                </Box>
            </div>
            
        </div>
    )
}

function HomeworkViewer({open, setOpen, title, id, file}) {
    const classes = useStyles();
    const [showComments, setShowComments] = useState(true);
    
    return (
        <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar variant='dense'>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <IconButton edge="start" size="small" color="textSecondary" onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                            <Typography noWrap variant="body1" display="inline" className={classes.title}>
                                {title}
                            </Typography>
                        </Box>
                        <Tooltip title='Download'>
                            <a target="_blank" href={`${contentBaseURL}${file}`} download>
                                <GetAppOutlinedIcon className={classes.downloadIcon} />
                            </a>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            {}
            <Grid container spacing={0}>
                {showComments &&
                    <Grid item xs={4} md={3}>
                        <ManageComments id={id} />
                    </Grid>
                }
                <Grid item xs={showComments ? 8 : 12} md={showComments ? 9 : 12}>
                    <GViewer url={`${contentBaseURL}${file}`} />
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default HomeworkViewer;