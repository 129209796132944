import { makeStyles, Paper, Typography, withStyles, Backdrop, Box } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import React, { useEffect } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useState } from 'react';
import { IoHourglassOutline } from "react-icons/io5";
import axios from 'axios'
import ResultListTable from './ResultListTable';

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
const AccordionSummary = withStyles({
  root: {
  //   backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      backgroundColor: "#fcfdfe",
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);
  
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    heading: {
        //fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}))

const PerExamProgress = ({ courseId }) => {
    const classes = useStyles();
    
    const [exams, setExams] = useState([]);
    const [selectedExam, setSelectedExam] = useState('');
    const [expanded, setExpanded] = useState('');
    const [results, setResults] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        const getExams = () => {
            axios({
                method: 'GET',
                url: `exams/exam-list/${courseId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setExams(response.data);
                } else {
                    console.log('Exams fetch failed!', response.status);
                }
            }).catch((error) => {
                setIsLoading(false);
                console.log('Exams fetch error!', error);
            })
        }
        getExams();
    }, []);

    const getResults = async (examId) => {
      setIsLoading(true);
      await axios({
        method: 'GET',
        url: 'exams/exam-result-list/',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },
        params: {
          exam_id: examId
        }
      }).then((response) => {
        console.log("# ---- Exam result fetch success ---- #", response.status);
        setIsLoading(false);
        if (response.status) {
          setResults(response.data);
        }

      }).catch((error) => {
        console.log("# ---- Exam result fetch error ---- #", error);
        setIsLoading(false);
      })
    }

    const handleChange = (panel) => (event, newExpanded) => {
      setSelectedExam(panel);
      setExpanded(newExpanded ? panel : false);
      if (newExpanded) {
        getResults(panel);
      } else {
        setResults([])
      }
    }

  return (
    
    <Paper elavation={0} variant="outlined" square>
        {exams.length ? 
            <>
            {exams.map((exam, index) => (
                <Accordion square expanded={expanded === exam.id} onChange={handleChange(exam.id)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{exam.title}</Typography>
                    <Box textAlign="right">
                      <Typography variant="caption" color="textSecondary">Total Marks: {exam.total_marks}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {!isLoading && results.length ? <ResultListTable results={results} /> : <Typography variant="subtitle1">No results found</Typography> }
                    
                </AccordionDetails>
            </Accordion>
            ))}</>
            : !isLoading && 
            <Box height='50vh' display="flex" flexDirection='row' justifyContent='center'>
                <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                    <Typography variant="h5" color="initial" align="center" style={{ color: '#0B6AB0' }}>No Exam Found</Typography>
                </Box>
            </Box>
        }

        <Backdrop className={classes.backdrop} open={isLoading}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
        </Backdrop>
    </Paper>
  )
}

export default PerExamProgress