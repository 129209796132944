import { Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography, Button, Chip } from '@material-ui/core'
import React from 'react'
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'
import SoftwareTestingImage from '../../images/software_testing.jpg'
import { Link } from 'react-router-dom'

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6)
    },
    cardGrid: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    card: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: '#fcfdfe',
        height: 'auto',
    },
    cardMedia: {
        paddingTop: '56.25%'  // aspect ratio 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
    }
}));

const Course = ({ course }) => {
    const classes = useStyles()
    
    return (
            <Grid item xs={12} md={4} className={classes.cardGrid}>
                <Link to={`/course-details/${course.id}`} className={classes.link}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia 
                                className={classes.cardMedia}
                                image={`${contentBaseURL}${course.image}`}
                                title={course.title} 
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="body1" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                                    {course.title}
                                    {/* Software Testing with Selenium */}
                                </Typography>
                                <Typography gutterBottom variant="overline">
                                    {course.instructor.map((instructor, index) => (
                                        <>{index == 0 ? instructor.name : `, ${instructor.name}`}</>
                                    ))}
                                    {/* Rifat Halim */}
                                </Typography>
                                {/* <div style={{ display: 'flex', direction: 'row' }}>
                                    <Rating defaultValue={course.rating} precision={0.1} size="small" readOnly />
                                </div> */}
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            {course.is_archived ?
                                <Chip label='Archived' />
                                :
                                <Link to={`/course-details/${course.id}`} className={classes.link}><Button color="primary">Details</Button></Link>
                            }
                            
                        </CardActions>
                    </Card>
                </Link>
            </Grid>
    )
}

export default Course
