import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import logo from '../images/logo.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Checkbox, InputAdornment, Container, Chip, Typography, Button, TextField, Divider, Link, 
    Backdrop, CircularProgress, Select, FormControl, FormHelperText, MenuItem,
    InputLabel, Grid, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import APPLICATION_CONSTANTS from '../strings'
import axios from 'axios'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { IoHourglassOutline } from "react-icons/io5"


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(6, 0, 6),
        textAlign: 'center',
    },
    inputField: {
        margin: theme.spacing(1),
    },
    title: {
        color: '#454545',
        marginLeft: theme.spacing(0),
    },
    form: {
        textAlign: 'center',
        margin: theme.spacing(2)
    },
    logoStyle: {
        width: '20%',
        height: '20%',
    },
    button: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
    },
    link: {
        color: 'steelblue',
        textDecoration: 'none',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    inputSelectField: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(0.5),
        //width: '20vw',
    },
    cancelButton: {
        textTransform: 'none',
    },
    visibilityIcon: {
        margin: 0,
        padding: 0
    },
    menuItemRoot: {
        "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
            backgroundColor: "#0B6AB0",

        }
    },
    menuItemSelected: {
        color: '#FFFFFF'
    },
    addButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};


const Signup = (props) => {
    const classes = useStyles()
    const [accountCreated, setAccountCreated] = useState(false)

    // Form input fields useState
    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [courses, setCourses] = useState([])
    const [sessions, setSessions] = useState([])

    // Form validation useState
    const [usernameHelperText, setusernameHelperText] = useState('')
    const [firstNameHelperText, setfirstNameHelperText] = useState('')
    const [lastNameHelperText, setlastNameHelperText] = useState('')
    const [emailHelperText, setemailHelperText] = useState('')
    const [passwordHelperText, setpasswordHelperText] = useState('')
    const [selectedCourseHelperText, setSelectedCourseHelperText] = useState('')
    const [commonPasswordHelperText, setCommonPasswordHelperText] = useState('')
    const [passwordMismatchHelpText, setPasswordMismatchhelpText] = useState('')
    const [instructorHelpText, setInstructorHelpText] = useState('')

    const [usernameError, setUsernameError] = useState(false)
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [commonPasswordError, setCommonPasswordError] = useState(false)
    const [selectedSessionError, setSelectedSessionError] = useState(false)
    const [selectedCourseError, setSelectedCourseError] = useState(false)
    const [passwordMismatchError, setPasswordMismatchError] = useState(false)
    const [instructorError, setInstructorError] = useState(false)

    const [backDropOpen, setBackDropOpen] = useState(false)

    const [networkError, setNetworkError] = useState(false)
    const [courseTitle, setCourseTitle] = useState("");
    const [courseId, setCourseId] = useState("");
    const [sessionTitle, setSessionTitle] = useState("");

    // Show password and confirm password useState
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showTC, setTCshow] = useState(false)

    // Location
    const [selectedLocation, setSelectedLocation] = useState("")
    const [selectedLocationError, setSelectedLocationError] = useState(false)
    const [selectedLocationHelpText, setSelectedLocationHelpText] = useState('')

    const handleClickShowPassword = () => {
        if (password !== '') {
            setShowPassword(!showPassword)
        } else {
            setShowPassword(false)
        }
    }

    const handleClickShowConfirmPassword = () => {
        if (confirmPassword !== '') {
            setShowConfirmPassword(!showConfirmPassword)
        } else {
            setShowConfirmPassword(false)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeLocation = (loc) => {
        setSelectedLocation(loc);
    }

    const handleChangeSession = (id, title) => {
        setSessionTitle(title);
        setCourseTitle("");
        getCourses(id);
    };

    const handleChangeCourse = (event) => {
        setCourseTitle(event.target.value);
        
        var id = event.currentTarget.getAttribute("id");
        setCourseId(id);
    };

    let role = ''
    if (props.location.signupProps === 'Student') {
        role = 'Student'
    } else if (props.location.signupProps === 'Instructor') {
        role = 'Instructor'
    } else if (props.location.signupProps === 'Admin') {
        role = 'Admin'
    }

    let headerTitle = ''
    if (role === 'Student') {
        headerTitle = 'Create account: Student'
    } else if (role === 'Instructor') {
        headerTitle = 'Create account: Instructor'
    } else if (role === 'Admin') {
        headerTitle = 'Create account: Admin'
    }

    let selectLocationInput = <> </>
    let selectSessionInput = <> </>
    let selectCourseInput = <> </>
    if (role === 'Student') {
        selectLocationInput =
            <FormControl
                fullWidth
                error={selectedLocationError}
                variant="outlined"
                size='small'
                className={classes.inputSelectField}
                style={{ marginBottom: 5 }}
            >
                <InputLabel style={{ marginLeft: 10 }}>Select Location</InputLabel>
                <Select
                    value={selectedLocation}
                    label="Select Location"
                    variant="outlined"
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            <Chip key={selected} label={selected} className={classes.chip} />
                        </div>
                    )}
                >
                    <MenuItem
                        key={0}
                        onClick={() => handleChangeLocation("New York")}
                        classes={{
                            root: classes.menuItemRoot,
                            selected: classes.menuItemSelected
                        }}
                    >
                        New York
                    </MenuItem>
                    <MenuItem
                        key={1}
                        onClick={() => handleChangeLocation("Virginia")}
                        classes={{
                            root: classes.menuItemRoot,
                            selected: classes.menuItemSelected
                        }}
                    >
                        Virginia
                    </MenuItem>
                    <MenuItem
                        key={2}
                        onClick={() => handleChangeLocation("Online")}
                        classes={{
                            root: classes.menuItemRoot,
                            selected: classes.menuItemSelected
                        }}
                    >
                        Online
                    </MenuItem>
                </Select>
                <FormHelperText>{selectedLocationHelpText}</FormHelperText>
            </FormControl>
        selectSessionInput = 
            <FormControl
                fullWidth
                error={selectedSessionError}
                variant="outlined"
                size='small'
                className={classes.inputSelectField}
                style={{ marginBottom: 8 }}
            >
                <InputLabel style={{ marginLeft: 10 }}>Select Session</InputLabel>
                <Select
                    value={sessionTitle}
                    label="Select Session"
                    variant="outlined"
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            <Chip key={selected} label={selected} className={classes.chip} />
                        </div>
                    )}
                >
                    {sessions.map((session, index) => (
                        <MenuItem
                            key={index}
                            value={session.title}
                            onClick={() => handleChangeSession(session.id, session.title)}
                            classes={{
                                root: classes.menuItemRoot,
                                selected: classes.menuItemSelected
                            }}
                        >
                            {session.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        selectCourseInput =
            <FormControl
                fullWidth
                error={selectedCourseError}
                variant="outlined"
                size='small'
                className={classes.inputSelectField}
            >
                <InputLabel style={{ marginLeft: 10 }}>Select Course</InputLabel>
                <Select
                    value={courseTitle}
                    onChange={handleChangeCourse}
                    label="Select Course"
                    variant="outlined"
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            <Chip key={selected} label={selected} className={classes.chip} />
                        </div>
                    )}
                >
                    {courses.map((course, index) => (
                        <MenuItem
                            key={index}
                            value={course.title}
                            id={course.id}
                            classes={{
                                root: classes.menuItemRoot,
                                selected: classes.menuItemSelected
                            }}
                        >{course.title}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>{selectedCourseHelperText}</FormHelperText>
            </FormControl>
    } else {
        selectSessionInput = <> </>
        selectCourseInput = <> </>
    }

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }

    useEffect(() => {
        const getSessions = async () => {
            await axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    setSessions(response.data.slice(0, 2));
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }
        getSessions();
    }, [])

    const getCourses = (sessionId) => {
        axios
            .get(`courses/list/${sessionId}/`, config)
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Course Fetched Success ---- ##', response.status);
                    if (response.data.length > 0) {
                        setCourses(response.data);
                    } else {
                        setCourses('No course available');
                    }
                    
                } else {
                    console.log('## ---- Course Fetched Failed ---- ##', response.status);
                }
            })
            .catch((error) => {
                console.log(error);
                console.log('## ---- Course Fetched Error ---- ##', error.status);
            })
    }

    const postSignupData = async (username, firstName, lastName, email, password, confirmPassword, role, selectedCourseId, selectedLocation) => {
        setUsernameError(false)
        setFirstNameError(false)
        setLastNameError(false)
        setEmailError(false)
        setPasswordError(false)
        setSelectedLocationError(false)
        setSelectedCourseError(false)
        setPasswordMismatchError(false)
        setInstructorError(false)
        setCommonPasswordError(false)
        setNetworkError(false)

        await axios
            .post('/auth/signup/',
                {
                    username: username,
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    password: password,
                    confirm_password: confirmPassword,
                    role: role,
                    selected_course_id: selectedCourseId,
                    selected_location: selectedLocation,
                }
            )
            .then((response) => {
                console.log("## ---- Signup API success ---- ##", response.status);
                setAccountCreated(true)
                setBackDropOpen(false)
            })
            .catch((error) => {
                if (error.response) {
                    console.log("## ---- Signup API error ---- ##", error.response.status);

                    setBackDropOpen(false)
                    if (error.response.status === 400) {
                        if (error.response.data.username) {
                            setusernameHelperText(error.response.data.username)
                            setUsernameError(true)
                        }
                        if (error.response.data.firstName) {
                            setfirstNameHelperText(error.response.data.firstName)
                            setFirstNameError(true)
                        }
                        if (error.response.data.lastName) {
                            setlastNameHelperText(error.response.data.lastName)
                            setLastNameError(true)
                        }
                        if (error.response.data.email) {
                            setemailHelperText(error.response.data.email)
                            setEmailError(true)
                        }
                        if (error.response.data.password) {
                            setpasswordHelperText(error.response.data.password)
                            setPasswordError(true)
                        }
                        if (error.response.data.location) {
                            setSelectedLocationError(true)
                            setSelectedLocationHelpText(error.response.data.location)
                        }
                        if (error.response.data.courses) {
                            setSelectedCourseError(true)
                            setSelectedCourseHelperText(error.response.data.courses)
                        }
                        if (error.response.data.password_mismatch) {
                            setPasswordMismatchError(true)
                            setPasswordMismatchhelpText(error.response.data.password_mismatch)
                        }
                        if (error.response.data.instructor) {
                            setInstructorError(true)
                            setInstructorHelpText(error.response.data.instructor)
                        }
                    } else if (error.response.status === 500) {
                        setCommonPasswordError(true)
                        setCommonPasswordHelperText(APPLICATION_CONSTANTS.commonPasswordError)
                    }

                } else if (error.message) {
                    setBackDropOpen(false)
                    setNetworkError(true)
                    console.log("## ---- Signup API error message ---- ##", error.message);
                } else {
                    setBackDropOpen(false)
                    console.log("## ---- SIgnup API error request ---- ##", error.request);
                }
            })
    }

    // Redirect to login page if signup is successful
    if (accountCreated) {
        return <Redirect to='/login' />
    }

    const onFromSubmit = (event) => {
        event.preventDefault()
        setTCshow(false)
        postSignupData(username, firstName, lastName, email, password, confirmPassword, role, courseId, selectedLocation);
        setBackDropOpen(!backDropOpen)
    }

    const termsAndCondition = () => (
        <Dialog maxWidth='sm' scroll='body' open={showTC} onClose={() => setTCshow(false)}>
            <DialogTitle align="center">Terms and Conditions</DialogTitle>
            <Typography variant="body1" color="textSecondary" align='center' style={{marginLeft:'1rem', marginRight:'1rem'}}>
                We only store your personal data to recognize you. Your data is fully safe with us,
                and we don't disclose your data with any external channel or media. 
                The owner of the contents shared in this learning management system is QA TEK Solutions 
                and all the contents are fully copyrighted. You are allowed to watch the contents. 
                Downloading and sharing the contents publicly are strictly prohibited. 
                If we find your involvement in disclosing any of the contents to the public, 
                the QA TEK Management has legal right to take action against you according to the 
                law of the software copyright act.
            </Typography>
            <DialogActions>
                <Button onClick={() => setTCshow(false)} color="primary" className={classes.cancelButton}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    className={classes.button}
                    onClick={onFromSubmit}
                    >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )

    return (
        <Container className={classes.container}>
            <img src={logo} className={classes.logoStyle} />
            
            <Typography color='inherit' variant='h5' className={classes.title}>
                {headerTitle}
            </Typography>

            <Grid
                container
                direction='row'
                justify='center'>
                <Grid item xs={7} sm={4} md={4} lg={3.5}>
                    <Divider />
                </Grid>
            </Grid>
            <Grid
                container
                direction='row'
                justify='center'>
                <Grid item xs={7} sm={4} md={4} lg={3.5}>
                    <form action='#' 
                    //onSubmit={onFromSubmit} 
                    className={classes.form}>
                        {networkError ? <Alert severity="error" >{APPLICATION_CONSTANTS.networkError}</Alert> : <> </>}
                        {selectLocationInput}
                        {selectSessionInput}
                        {selectCourseInput}
                        {termsAndCondition()}

                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={usernameError}
                                id='outlined-basic'
                                label='Username'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setUsername(e.target.value)}
                                color='primary'
                                helperText={usernameHelperText}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={firstNameError}
                                id='outlined-basic'
                                label='First Name'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setFirstName(e.target.value)}
                                color='primary'
                                helperText={firstNameHelperText}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={lastNameError}
                                id='outlined-basic'
                                label='Last Name'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setLastName(e.target.value)}
                                color='primary'
                                helperText={lastNameHelperText}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={emailError || instructorError}
                                id='outlined-basic'
                                label='Email'
                                type='email'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setEmail(e.target.value)}
                                color='primary'
                                helperText={emailError ? emailHelperText : instructorHelpText}
                            />
                        </div>

                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={passwordError || commonPasswordError}
                                id='outlined-password-input'
                                label='Password'
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='current-password'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setPassword(e.target.value)}
                                color='primary'
                                helperText={commonPasswordError ? commonPasswordHelperText : passwordHelperText}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" className={classes.visibilityIcon} style={{ paddingRight: 0 }}>
                                        <IconButton
                                            className={classes.visibilityIcon}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className={classes.inputField}>
                            <TextField
                                fullWidth
                                error={passwordMismatchError}
                                id='outlined-password-input'
                                label='Confirm Password'
                                type={showConfirmPassword ? 'text' : 'password'}
                                autoComplete='current-password'
                                variant='outlined'
                                size='small'
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                color='primary'
                                helperText={passwordMismatchHelpText}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end" className={classes.visibilityIcon} style={{ paddingRight: 0 }}>
                                        <IconButton
                                            className={classes.visibilityIcon}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                        >
                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <Grid
                            container
                            direction='row'
                            justify='center'>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                <Button
                                    fullWidth
                                    //type='submit'
                                    variant='contained'
                                    className={classes.button}
                                    onClick={() => setTCshow(true)}
                                >Sign Up
                        </Button>
                            </Grid>
                        </Grid>

                        <Backdrop
                            className={classes.backdrop}
                            open={backDropOpen}
                            onClick={() => {
                                setBackDropOpen(false);
                            }}
                        >
                            <IoHourglassOutline style={{ fontSize: 35 }} />
                        </Backdrop>
                    </form>
                </Grid>
            </Grid>
            <Grid
                container
                direction='row'
                justify='center'>
                <Grid item xs={10} sm={6} md={6} lg={4}>
                    <Divider />
                </Grid>
            </Grid>
            <Typography variant='caption' color='textSecondary' display='block'>
                {'By signing up, you agree to our '}
                <Link className={classes.link}>Terms of Use</Link>
                {' and '}
                <Link className={classes.link}> Privacy Policy</Link>
                {'.'}
            </Typography>
        </Container>
    )
}

export default Signup
