// Ref: https://www.youtube.com/watch?v=V_06_fCbLFw

import { IconButton, Box, AppBar, Paper, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import FastForwardIcon from '@material-ui/icons/FastForward'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomInIcon from '@material-ui/icons/ZoomIn'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: '#fafafa',
        marginTop: theme.spacing(0.4),
    }
}))

const ControlPanel = (props) => {
    const { pageNumber, numPages, setPageNumber, scale, setScale, mobileView } = props
    const classes = useStyles()

    const isFirstPage = pageNumber === 1;
    const isLastPage = pageNumber === numPages;

    //const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
    //const lastPageClass = isLastPage ? 'disabled' : 'clickable';

    const goToFirstPage = () => {
        if (!isFirstPage) setPageNumber(1);
    };
    const goToPreviousPage = () => {
        if (!isFirstPage) setPageNumber(pageNumber - 1);
    };
    const goToNextPage = () => {
        if (!isLastPage) setPageNumber(pageNumber + 1);
    };
    const goToLastPage = () => {
        if (!isLastPage) setPageNumber(numPages);
    };

    const onPageChange = (e) => {
        const { value } = e.target;
        setPageNumber(Number(value));
    };

    const isMinZoom = scale < 0.6;
    const isMaxZoom = scale >= 4.0;

    //const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
    //const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

    const zoomOut = () => {
        if (!isMinZoom) setScale(scale - 0.1);
    };

    const zoomIn = () => {
        if (!isMaxZoom) setScale(scale + 0.1);
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 39) {
            goToNextPage()
        }
        else if (e.keyCode === 37) {
            goToPreviousPage()
        }
        else if (e.keyCode === 38) {
            zoomIn()
        }
        else if (e.keyCode === 40) {
            zoomOut()
        }
    }

    return (
        <AppBar color='transparent' elevation={0}> 
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                <Paper className={classes.paper} onKeyDown={handleKeyPress}>
                    {!mobileView && <IconButton
                        onClick={goToFirstPage}
                        autoFocus={true}
                    >
                        <FastRewindIcon />
                    </IconButton>}
                    <IconButton
                        onClick={goToPreviousPage}
                    >
                        <SkipPreviousIcon />
                    </IconButton>
                    <span>
                      <Typography variant="body2" display='inline'>Page{' '}</Typography>
                      <input
                          name="pageNumber"
                          type="number"
                          min={1}
                          max={numPages || 1}
                          value={pageNumber}
                          onChange={onPageChange}
                        />{' '}
                        <Typography variant="body2" display='inline'>of {numPages}</Typography>
                    </span>
                    <IconButton
                        onClick={goToNextPage}
                    >
                        <SkipNextIcon />
                    </IconButton>
                    {!mobileView && <IconButton
                        onClick={goToLastPage}
                    >
                        <FastForwardIcon />
                    </IconButton>}
                    {!mobileView && <span style={{ marginRight: "20px" }}></span>}
                    <IconButton
                        onClick={zoomOut}
                    >
                        <ZoomOutIcon />
                    </IconButton>
                    <Typography variant="body2" display='inline'>{(scale * 100).toFixed()}%</Typography>
                    <IconButton
                        onClick={zoomIn}
                    >
                        <ZoomInIcon />
                    </IconButton>
                </Paper>
            </Box>
        </AppBar>
    );
};

export default ControlPanel;
