import React, { useEffect, useState } from 'react'
import { Container, Divider, TextField, Typography, Button, Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/logo.jpg'
import { resetPassword } from '../actions/auth'
import { connect } from 'react-redux'
import axios from 'axios'
import { Redirect } from 'react-router'
import { IoHourglassOutline } from "react-icons/io5"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(14, 0, 6),
        textAlign: 'center',
        width: '25%'
    },
    logoStyle: {
        width: '50%',
        height: '50%',
    },
    inputField: {
        margin: theme.spacing(2),
    },
    button: {
        paddingRight: theme.spacing(7),
        paddingLeft: theme.spacing(7),
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}))

const ResetPassword = ({ resetPassword }) => {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [requestSend, setRequestSend] = useState(false)

    const [backDropOpen, setBackDropOpen] = useState(false)

    const sendResetRequest = async (email) => {
        setBackDropOpen(true)
        await resetPassword(email)
        setBackDropOpen(false)
        setRequestSend(true)
    }
    useEffect(() => {

    }, [])

    if (requestSend) {
        return <Redirect to='/reset-password-email-sent' />
    }

    return (
        <Container className={classes.container}>
            <img src={logo} className={classes.logoStyle} />
            <Typography variant='h5' color='inherit'>
                Send reset password request
            </Typography>
            <Divider />
            <div className={classes.inputField}>
                <TextField
                    value={email}
                    id='outlined-basic'
                    label='Email'
                    type='email'
                    variant='outlined'
                    size='small'
                    color='primary'
                    onChange={(event) => setEmail(event.target.value)} />
            </div>
            <Button
                variant="contained"
                className={classes.button}
                onClick={() => sendResetRequest(email)}
            >Send Request
            </Button>
            <Backdrop
                className={classes.backdrop}
                open={backDropOpen}
                onClick={() => {
                    setBackDropOpen(false);
                }}
            >
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </Container>
    )
}

export default connect(null, { resetPassword })(ResetPassword)
