import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: '#0B6AB0',
    },
    inputTextField: {
        marginBottom: theme.spacing(1)
    },
    addButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3f9142',
            color: '#ffffff',
        }
    },
    cancelButton: {
        textTransform: 'none',
    }
}))

const AddSessionDialog = ({ open, handleClose, sessions, setSessions, setOpenAddSnackbar, setOpenFailureSnackbar }) => {
    const classes = useStyles()
    const [title, setTitle] = useState('')
    const [details, setDetails] = useState('')

    const [titleError, setTitleError] = useState("")

    const handleCreateSession = () => {
        if (validate()) {
            handleClose()
            axios({
                method: 'POST',
                url: 'courses/academic-session-details/',
                data: {
                    title: title,
                    details: details,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            })
            .then((response) => {
                if (response.status === 201) {
                    console.log('## ---- Session Add Success ---- ##', response.status);
                    
                    setSessions([response.data, ...sessions])
                    setOpenAddSnackbar(true)
                } else {
                    console.log('## ---- Session Add Failed ---- ##', response.status);
                    setOpenFailureSnackbar(true)
                }
            })
            .catch((error) => {
                console.log('## ---- Session Add Error ---- ##', error);
                setOpenFailureSnackbar(true)
            })
        }
        
    }

    const validate = () => {
        var validation = true
        if (title.length == 0) {
            setTitleError('Title must not be kept blank')
            validation = false
        }
        else {
            setTitleError("")
        }

        return validation
    }

    return (
        <div>
            <Dialog 
                open={open} 
                onClose={handleClose}
                fullWidth={true}
                maxWidth='xs'>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant='h6'>Add Session</Typography>
                </DialogTitle>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateSession()
                }}>
                    <DialogContent dividers>
                        <TextField
                            autoFocus
                            error={titleError.length ? true : false}
                            label='Title'
                            variant='outlined'
                            size='small'
                            helperText={titleError}
                            fullWidth
                            onChange={(e) => setTitle(e.target.value)}
                            className={classes.inputTextField}
                        />
                        <TextField
                            id="details"
                            label="Details"
                            variant='outlined'
                            size='small'
                            multiline
                            rows={3}
                            rowsMax={4}
                            fullWidth
                            onChange={(e) => setDetails(e.target.value)}
                            className={classes.inputTextField}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className={classes.cancelButton}>
                            Cancel
                        </Button>
                        <Button className={classes.addButton} type='submit'>
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    )
}

export default AddSessionDialog
