import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography, Button, Chip } from '@material-ui/core'
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        padding: theme.spacing(1),
    },
    card: {
        //backgroundColor: '#fcfdfe',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    cardM: {
        //backgroundColor: '#fcfdfe',
        padding: theme.spacing(0),
        paddingRight: theme.spacing(2),
    },
    cardMedia: {
        paddingTop: '56.25%'  // aspect ratio 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            background: '#EDF0F5',
        },
        borderColor: '#0B6AB0',
    }
}));

const Course = ({ course, mobileView }) => {
    const classes = useStyles()

    return (
        <Grid item xs={12} md={6} className={classes.cardGrid}>
            <Card className={mobileView ? classes.cardM : classes.card} variant='outlined'>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="body1" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                            {course.title}
                        </Typography>
                        <Typography variant="overline">
                            Instructors:
                            {course.instructor.map((instructor, index) => (
                            <>{index == 0 ? ` ${instructor.name}` : `, ${instructor.name}`}</>
                        ))}
                        </Typography>
                        <Typography display='block' variant="caption" color="textSecondary" style={{ marginTop: 0, fontWeight: 'bold' }}>
                            {course.academic_session ? course.academic_session.title : "No Session"}
                        </Typography>
                        {course.is_archived ?
                            <Box mt={2}><Chip label='Archived' /></Box>
                            :
                            <Box mt={2}>{course.is_active ? <Chip label="Active" color='primary' /> : <Chip label="Inactive" />}</Box>
                        }
                        
                        {/* <div style={{ display: 'flex', direction: 'row' }}>
                            <Rating defaultValue={course.rating} precision={0.1} size="small" readOnly />
                        </div> */}
                    </CardContent>
                    {(course.is_active && !course.is_archived) ?
                        <Box display='flex' alignItems='center'>
                            <Link to={`/access-course/${course.id}`}
                                className={classes.link}>
                                <Button size='large' variant='outlined' color="primary" style={{ textTransform: 'none' }}>
                                    Visit Course
                                </Button>
                            </Link>
                        </Box>
                        :
                        <Box display='flex' alignItems='center'></Box>
                    }
                </div>
            </Card>
        </Grid>
    )
}

export default Course
