import { useState, useEffect, useContext } from "react";
import { useParams, useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Snackbar, Tabs, Tab, Paper, Button, Tooltip } from '@material-ui/core';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { MdSdStorage } from "react-icons/md";

import CourseContents from "./CourseContents";
import SubmittedHomeworks from "./SubmittedHomeworks";
import ManageExam from "../ManageExam";
// import ExamFeedbacks from "./ExamFeedbacks";
import StorageUsageDlg from "./StorageUsageDlg";
import ExamDashboard from "../ExamDashboard";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    tabContainer: {
        //margin: theme.spacing(3)
    },
    tab: {
        '&.MuiTab-root': {
            textTransform: 'none',
        },
        '&.Mui-selected': {
            fontWeight: 'bold',
            color: 'grey',
            borderBottom: '2px solid grey',
        }
    },
    header: {
        //marginLeft: theme.spacing(3),
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
        fontWeight: 'normal',
    },
    addButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none'
    },
    editIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(2)
    },
    returnIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ManageCourse() {
    const { courseId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [course, setCourse] = useState({});
    const [value, setValue] = useState(0);
    const [isAddWeek, setIsAddWeek] = useState(false);
    const [stUsageOpen, setStUsageOpen] = useState(false);

    useEffect(() => {
        const getData = async () => {
            await axios({
                method: 'GET',
                url: `courses/detail-unauth/${courseId}/`,
            }).then((res) => {
                if (res.status == 200) {
                    setCourse(res.data);
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        getData();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addWeek = () => {
        setIsAddWeek(true);
    }

    return (
        <>
            <Container maxWidth={false} className={classes.container}>
                <Box display='flex' flexDirectoin='row' justifyContent='space-between' mb={3} ml={3} mr={3}>
                    <Box display='flex' flexDirectoin='row' alignItems='center'>
                        <ArrowBackIosIcon className={classes.returnIcon} onClick={() => history.goBack()} />
                        <Typography variant='h6' className={classes.header} display='inline' alignItems='center'>{course.title}</Typography>
                        <Tooltip title='Edit Course' arrow placement="top">
                            <Link to={`/edit-course/${courseId}`}>
                                <EditIcon className={classes.editIcon} />
                            </Link>
                        </Tooltip>
                        <Tooltip title='Storage Usage' arrow placement="top">
                            <Link to='#'>
                                <MdSdStorage
                                    onClick={() => setStUsageOpen(true)}
                                    className={classes.editIcon}
                                />
                            </Link>
                        </Tooltip>
                    </Box>

                    {value === 0 && <Button
                        variant='contained'
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                        onClick={addWeek}
                    >
                        Add Week
                    </Button>}
                </Box>
                <Box ml={3} mr={3}>
                    <Paper variant='outlined' elevation={1} className={classes.tabContainer} square>
                        <Tabs
                            value={value}
                            indicatorColor='none'
                            textColor="primary"
                            onChange={handleChange}
                            aria-label="course-view"
                            variant="fullWidth"
                            centered
                        >
                            <Tab label="Course Contents" className={classes.tab} />
                            <Tab label="Submitted Homeworks" className={classes.tab} />
                            <Tab label="Manage Exam" className={classes.tab} />
                            {/* <Tab label="Exam Feedback" className={classes.tab} />  Removing as implemented exam feature */}
                            <Tab label="Exam Dashboard" className={classes.tab} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={value} index={0}>
                        <CourseContents courseId={courseId} isAddWeek={isAddWeek} setIsAddWeek={setIsAddWeek} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SubmittedHomeworks courseId={courseId} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ManageExam courseId={courseId} />
                    </TabPanel>
                    {/* <TabPanel value={value} index={3}>
                        <ExamFeedbacks courseId={courseId} />
                    </TabPanel> */}
                    <TabPanel value={value} index={3}>
                        <ExamDashboard courseId={courseId}/>
                    </TabPanel>
                </Box>
            </Container>

            <StorageUsageDlg courseId={courseId} open={stUsageOpen} setOpen={setStUsageOpen} />
        </>
    )
}

export default ManageCourse
