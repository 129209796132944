import { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Dialog, Grid, AppBar, Toolbar, Typography, Box, Button, Slide, Snackbar, Backdrop } from '@material-ui/core';
import { IoHourglassOutline } from "react-icons/io5";
import MuiAlert from '@material-ui/lab/Alert';
import { FiX, FiFileText } from "react-icons/fi";
import { userTime, utcTime } from "../../custom_util";
import ReactQuill from 'react-quill';
import axios from 'axios';
import moment from 'moment';
import { useConfirm } from "material-ui-confirm";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "black",
    },
    title: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    xIcon: {
        cursor: 'pointer',
    },
    input: {
        width: '100%',
        padding: 8,
        border: '0.5px solid #ccc',
        '&::placeholder': {
            color: "rgba(0,0,0,0.6)",
        },
        '&:focus': {
            outline: 'none !important',
        },
        resize: 'none',
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    saveBtn: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#0B6AB0',
        }
    },
    deleteBtn: {
        textTransform: 'none',
        color: '#C0392B',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        marginTop: "48px",
        color: '#0B6AB0',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
    },
}));

const modules = {
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            //{ indent: "-1" },
            //{ indent: "+1" }
        ],
        //["link", "image", "video"],
        ["image"],
        ["clean"]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateExam = ({ courseId, open, setOpen, exams, setExams, update = false, id = null, index = null }) => {  // id, index for exam edit
    const classes = useStyles();
    const confirm = useConfirm();
    const [title, setTitle] = useState("");
    const [duration, setDuration] = useState("");
    const [startTime, setStartTime] = useState("");
    const [instruction, setInstruction] = useState("");
    const [nyTime, setNYTime] = useState("")

    const [errorTitle, setErrorTitle] = useState("");
    const [errorDuration, setErrorDuration] = useState("");
    const [errorStartTime, setErrorStartTime] = useState("");
    const [errorInstruction, setErrorInstruction] = useState("");

    const [openCreateSnack, setOpenCreateSnack] = useState(false);
    const [openUpdateSnack, setOpenUpdateSnack] = useState(false);
    const [openDeleteSnack, setOpenDeleteSnack] = useState(false);
    const [openFailureSnack, setOpenFailureSnack] = useState(false);
    const [showNYTime, setShowNYTime] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            await axios({
                method: 'GET',
                url: `exams/exam-details/${id}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                setIsLoading(false);
                if (res.status == 200) {
                    setTitle(res.data.title);
                    setDuration(res.data.duration);
                    setStartTime(userTime(res.data.start_time, "YYYY-MM-DDTHH:mm"));
                    setInstruction(res.data.instruction);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        }
        if (open && update)
            getData();
    }, [open]);

    const handleSave = () => {
        if (validate()) {
            setOpen(false);
            axios({
                method: 'POST',
                url: `exams/exam-list/${courseId}/`,
                data: {
                    course_id: courseId,
                    title: title,
                    duration: duration,
                    start_time: utcTime(startTime, "YYYY-MM-DDTHH:mm"),
                    instruction: instruction
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 201) {
                    response.data.start_time = userTime(response.data.start_time, "YYYY-MM-DDTHH:mm");
                    setExams([response.data, ...exams]);
                    console.log('Exam create success!');
                    setOpenCreateSnack(true);
                } else {
                    console.log('Exam create failed!', response.status);
                    setOpenFailureSnack(true);
                }
            }).catch((error) => {
                console.log('Exam create error!', error);
                setOpenFailureSnack(true);
            })
        }
    }

    const handleUpdate = () => {
        if (validate()) {
            axios({
                method: 'PUT',
                url: `exams/exam-details/${id}/`,
                data: {
                    title: title,
                    duration: duration,
                    start_time: utcTime(startTime, "YYYY-MM-DDTHH:mm"),
                    instruction: instruction
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.data.start_time = userTime(response.data.start_time, "YYYY-MM-DDTHH:mm");
                    var values = [...exams];
                    values[index] = response.data;
                    setExams(values);

                    console.log('Exam update success!');
                    setOpenUpdateSnack(true);
                } else {
                    console.log('Exam update failed!', response.status);
                    setOpenFailureSnack(true);
                }
            }).catch((error) => {
                console.log('Exam update error!', error);
                setOpenFailureSnack(true);
            })
        }
    }

    const deleteConfirm = (index, id) => {
        confirm({
            title: <Typography variant='h6' style={{ fontWeight: 'normal' }}>Delete Exam</Typography>,
            description: <Typography variant='body1'>Are you sure to delete the exam?</Typography>,
            confirmationText: 'Delete',
            confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
            cancellationButtonProps: { className: classes.cancelBtn, disableElevation: true },
        })
            .then(() => deleteExam(index, id))
            .catch(() => console.log('Cancelled!'));
    }

    const deleteExam = () => {
        axios({
            method: 'DELETE',
            url: `exams/exam-details/${id}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 202) {
                var values = [...exams];
                values.splice(index, 1);
                setExams(values);

                console.log('Exam delete success!');

                setOpen(false);
                setOpenDeleteSnack(true);
            }
            else {
                console.log('Exam delete failed!');
                setOpenFailureSnack(true);
            }
        }).catch((error) => {
            console.log('Exam delete error!', error);
            setOpenFailureSnack(true);
        });
    }

    const validate = () => {
        var validated = true;

        if (title.trim()) {
            setErrorTitle("");
        }
        else {
            setErrorTitle('This field cannot be kept blank.');
            validated = false;
        }

        var num = parseInt(duration);
        if (isNaN(num)) {
            setErrorDuration("This field cannot be kept blank.");
            validated = false;
        }
        else if (num === 0) {
            setErrorDuration("Duration must not be a zero.");
            validated = false;
        }
        else if (num < 0) {
            setErrorDuration("Duration must not be negative.");
            validated = false;
        }
        else {
            setErrorDuration("");
        }

        if (startTime) {
            setErrorStartTime("");
        }
        else {
            setErrorStartTime('This field cannot be kept blank.');
            validated = false;
        }

        if (instruction.trim()) {
            setErrorInstruction("");
        }
        else if (instruction.length > 4000) {
            setErrorInstruction("Must be in 4000 characters.");
        }
        else {
            setErrorInstruction('This field cannot be kept blank.');
            validated = false;
        }

        return validated;
    }

    const handleCloseCreateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCreateSnack(false);
    }

    const handleCloseUpdateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUpdateSnack(false);
    }

    const handleCloseDeleteSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSnack(false);
    }

    const handleCloseFailureSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnack(false);
    }

    const handleChangeStartTime = (event) => {
        setStartTime(event.target.value);
        setShowNYTime(true);
        var ny = new Date(event.target.value).toLocaleString("en-US", {timeZone: "America/New_York"})
        setNYTime(ny);
    }

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} fullScreen>
                <AppBar className={classes.appBar} elevation={0}>
                    <Toolbar variant='dense'>
                        <FiFileText />
                        <Typography variant="body1" className={classes.title}>{update ? 'Edit Exam' : 'Create Exam'}</Typography>
                        <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
                    </Toolbar>
                </AppBar>

                <Backdrop className={classes.backdrop} open={isLoading}>
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                </Backdrop>
                {!isLoading && <>
                    <Container maxWidth={false}>
                        <Grid container spacing={2} style={{ marginTop: 8 }}>
                            <Grid item sm={12} md={6}>
                                <Typography variant="body2" color="initial">Title</Typography>
                                <input
                                    className={classes.input}
                                    type='text'
                                    value={title}
                                    placeholder='Type exam title here...'
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Typography variant='caption' style={{ color: '#C0392B' }}>{errorTitle}</Typography>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography variant="body2" color="initial">Duration</Typography>
                                <input
                                    className={classes.input}
                                    type='number'
                                    value={duration}
                                    placeholder='Type duration in minutes...'
                                    onChange={(e) => setDuration(e.target.value)}
                                />
                                <Typography variant='caption' style={{ color: '#C0392B' }}>{errorDuration}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant="body2" color="initial">Start Time</Typography>
                                <input
                                    className={classes.input}
                                    type='datetime-local'
                                    value={startTime}
                                    placeholder='Type duration in minutes...'
                                    onChange={handleChangeStartTime}
                                />
                                <Typography variant='caption' style={{ color: '#C0392B' }}>{errorStartTime}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography variant='body2' color='inital'>New York Time</Typography>
                                <input 
                                    className={classes.input}
                                    value={showNYTime ? nyTime : "Exam start time not selected yet"}
                                    disabled
                                />
                            </Grid>

                            <Grid item sm={12}>
                                <Typography variant="body2" color="initial">Instruction</Typography>
                                <ReactQuill
                                    style={{ height: "200px", marginBottom: '32px' }}
                                    value={instruction}
                                    placeholder='Type exam instruction here...'
                                    modules={modules}
                                    onChange={(value) => setInstruction(value)}
                                />
                                <Typography variant='caption' style={{ color: '#C0392B' }}>{errorInstruction}</Typography>
                            </Grid>
                        </Grid>
                        {!update ?
                            <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={2}>
                                <Button
                                    className={classes.cancelBtn}
                                    color="primary"
                                    size="small"
                                    onClick={() => setOpen(false)}
                                    disableElevation
                                >
                                    Close
                                </Button>
                                <Button
                                    className={classes.saveBtn}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    onClick={() => handleSave(false)}
                                >
                                    Save
                                </Button>
                            </Box>
                            :
                            <Box display='flex' flexDirection='row' justifyContent='space-between' mt={4} mb={2}>
                                <Button
                                    className={classes.deleteBtn}
                                    color="primary"
                                    size="small"
                                    onClick={deleteConfirm}
                                    disableElevation
                                >
                                    Delete
                                </Button>
                                <Box>
                                    <Button
                                        className={classes.cancelBtn}
                                        color="primary"
                                        size="small"
                                        onClick={() => setOpen(false)}
                                        disableElevation
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        className={classes.saveBtn}
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        disableElevation
                                        onClick={() => handleUpdate(false)}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Container>
                </>}
            </Dialog>

            <Snackbar open={openCreateSnack} autoHideDuration={4000} onClose={handleCloseCreateSnack}>
                <Alert onClose={handleCloseCreateSnack} severity="success">
                    Exam added.
                </Alert>
            </Snackbar>
            <Snackbar open={openUpdateSnack} autoHideDuration={4000} onClose={handleCloseUpdateSnack}>
                <Alert onClose={handleCloseUpdateSnack} severity="success">
                    Exam updated.
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteSnack} autoHideDuration={4000} onClose={handleCloseDeleteSnack}>
                <Alert onClose={handleCloseDeleteSnack} severity="success">
                    Exam deleted.
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
                <Alert onClose={handleCloseFailureSnack} severity="error">
                    Operation failed!
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreateExam;
