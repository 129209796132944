import { useState, useEffect } from 'react'
import { Dialog, DialogContent, Button, Typography, Box, Paper, Backdrop, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FiCheckSquare, FiAlignCenter } from "react-icons/fi";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
    },
    mcqIcon: {
        color: '#00BC9E',
        fontSize: 100,
    },
    descriptiveIcon: {
        color: '#0B6AB0',
        fontSize: 100,
    },
    iconContainer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fcfdfe',
            border: '1px solid #E0E0E0',
        }
    },
    cancelButton: {
        textTransform: 'none',
        color: '#0B6AB0'
    },
}));

const ChooseQTypeDlg = ({ examId, open, setOpen, setOpenDescr, setOpenMCQ }) => {
    const classes = useStyles();

    const handleChooseQ = (type) => {
        if (type === 'MCQ') {
            setOpen(false);
            setOpenMCQ(true);
        }
        else {
            setOpen(false);
            setOpenDescr(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    return (
        <Dialog scroll='body' maxWidth='xs' open={open} onClose={handleClose}>
            <DialogContent>
                <Typography variant="h6" color="initial" className={classes.title}>Choose Question Type</Typography>

                <Box display='flex'>
                    <Box p={3} ml={3} align='center' className={classes.iconContainer} onClick={() => handleChooseQ('MCQ')}>
                        <FiCheckSquare className={classes.mcqIcon} />
                        <Typography variant='body2'>MCQ</Typography>
                    </Box>
                    <Box p={3} mr={3} align='center' className={classes.iconContainer} onClick={() => handleChooseQ('Descriptive')}>
                        <FiAlignCenter className={classes.descriptiveIcon} />
                        <Typography variant='body2'>Descriptive</Typography>
                    </Box>
                </Box>
                
                <Box display='flex' flexDirection='column' alignItems='flex-end' mt={3} mb={1}>
                    <Button className={classes.cancelButton} color="primary" onClick={() => setOpen(false)} disableElevation>Close</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ChooseQTypeDlg;