import axios from 'axios'
import { parse } from 'fast-xml-parser'
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    USER_LOADED_FAIL,
    USER_LOADED_SUCCESS,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    AUTHORIZED_ADMIN,
    AUTHORIZED_INSTRUCTOR,
    AUTHORIZED_STUDENT,
    AUTHORIZED_FAIL,
    REFRESH_TOKEN_USED_SUCCESS,
    REFRESH_TOKEN_USED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    LOGOUT,
    NETWORK_ERROR
} from './types';

// LOAD USER
export const loadUser = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get("/auth/user/", config);
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

// CHECK USER AUTHENTICATION
export const checkAuthenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const body = JSON.stringify({ token: localStorage.getItem("access") });
    try {
      const response = await axios.post("/auth/token/verify/", body, config);
      if (response.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
      dispatch(logout());
      // USE REFRESH TOKEN IF ACCESS TOKEN IS EXPIRED
      // if (localStorage.getItem('refresh')) {
      //     const config = {
      //         headers: {
      //             'content-type': 'application/json',
      //         }
      //     };

      //     const body = JSON.stringify({ refresh: localStorage.getItem('refresh') });
      //     try {
      //         const response = await axios.post('/auth/token/refresh/', body, config)
      //         if (response.status === 200) {
      //             dispatch({
      //                 type: REFRESH_TOKEN_USED_SUCCESS,
      //                 payload: response.data
      //             });
      //         }
      //     } catch (error) {
      //         dispatch({
      //             type: REFRESH_TOKEN_USED_FAIL
      //         });
      //     }
      // } else {
      //     dispatch({
      //         type: REFRESH_TOKEN_USED_FAIL
      //     });
      // }
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

// LOGIN USER
export const login = (username, password) => async dispatch => {
  const config = {
      headers: {
          'content-type': 'application/json'
      }
  };
  await axios({
      method: 'GET',
      url: 'auth/get-username/',
      params: {
          username: username,
      },
  }).then((response) => {
      if (response.status === 200) {
          console.log('## --- Username Fetched Success ---- ##', response.status);
          username = response.data.username
      }
      
  }).catch((error) => {
      console.log('## ---- Username Fetched Error ---- ##', error);
  })
  const body = JSON.stringify({ username, password });

  try {
      const response = await axios.post('/auth/token/', body, config);

      dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data
      });
      dispatch(loadUser());
      handleUserLoginSession(true)
      
  } catch (error) {
      if (error.response) {
          dispatch({
              type: LOGIN_FAIL
          });
      } else if (error.message) {
          if (error.message === 'Network Error') {
              dispatch({
                  type: NETWORK_ERROR
              });
          } else {
              dispatch({
                  type: LOGIN_FAIL
              });
          }

      }

  }
};

// USER LOGIN SESSION
const handleUserLoginSession = async (isLoggedIn) => {
    var addr = "Retrieve failed"

    const success = async (position) => {
        const latitude  = position.coords.latitude;
        const longitude = position.coords.longitude;
        
        await axios({
            method: 'GET',
            url: `https://nominatim.openstreetmap.org/reverse?format=xml&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`,
        })
        .then((response) => {
            var obj = parse(response.data);
            addr = obj.reversegeocode.addressparts.city + ", " + obj.reversegeocode.addressparts.state + ", " + obj.reversegeocode.addressparts.country
            userLoginSession(isLoggedIn, addr);
        })
        .catch((error) => {
            console.log('Geolocation fetch error!', error);
            //alert("Error in fetching your location.")
            userLoginSession(isLoggedIn, addr);
        })
    }
    
    function error() {
        //if (isLoggedIn)
        //    alert('Unable to retrieve your location. Please allow location access from your browser to get better service.');
        console.log("Location retrieve error!");
        userLoginSession(isLoggedIn, addr);
    }

    if(!navigator.geolocation) {
        //alert('Location tracking system is not supported by your browser. Please try another browser.');
        console.log("Location tracking system is not supported by the browser.");
        userLoginSession(isLoggedIn, addr);
    } else {
        console.log('Fetching location.');
        navigator.geolocation.getCurrentPosition(success, error);
    }
}

const userLoginSession = async (isLoggedIn, addr) => {
    await axios({
        method: 'POST',
        url: 'auth/user-session/',
        data: {
            is_logged_in: isLoggedIn,
            address: addr,
        },
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    })
        .then((response) => {
            console.log('## ---- User Session Updated ---- ##', response.status);
        })
        .catch((error) => {
            console.log('## ---- User Session Error ---- ##', error);
    })
}

// CHECK AUTHORIZED USER
export const checkAuthorizedUser = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get("/auth/authorized-user/", config);
      if (response.status === 200) {
        if (response.data.authorize_admin) {
          dispatch({
            type: AUTHORIZED_ADMIN,
            payload: response.data,
          });
        } else if (response.data.authorize_instructor) {
          dispatch({
            type: AUTHORIZED_INSTRUCTOR,
            payload: response.data,
          });
        } else if (response.data.authorize_student) {
          dispatch({
            type: AUTHORIZED_STUDENT,
            payload: response.data,
          });
        } else {
          dispatch({
            type: AUTHORIZED_FAIL,
          });
        }
      } else {
        dispatch({
          type: AUTHORIZED_FAIL,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTHORIZED_FAIL,
      });
      // USE REFRESH TOKEN IF ACCESS TOKEN IS EXPIRED
      // if (localStorage.getItem('refresh')) {
      //     console.log("has refress token");
      //     const config = {
      //         headers: {
      //             'content-type': 'application/json',
      //         }
      //     };

      //     const body = JSON.stringify({ refresh: localStorage.getItem('refresh') });
      //     try {
      //         const response = await axios.post('/auth/token/refresh/', body, config)
      //         if (response.status === 200) {
      //             dispatch({
      //                 type: REFRESH_TOKEN_USED_SUCCESS,
      //                 payload: response.data
      //             });
      //         }
      //     } catch (error) {
      //         dispatch({
      //             type: REFRESH_TOKEN_USED_FAIL
      //         });
      //     }
      // } else {
      //     dispatch({
      //         type: REFRESH_TOKEN_USED_FAIL
      //     });
      // }
    }
  } else {
    dispatch({
      type: AUTHORIZED_FAIL,
    });
  }
};

export const resetPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });
  try {
    await axios.post("/auth-reset/users/reset_password/", body, config);
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const resetPasswordConfirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });
    console.log(body);
    try {
      await axios.post(
        "/auth-reset/users/reset_password_confirm/",
        body,
        config
      );
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

// LOGOUT USER
export const logout = () => dispatch => {
    userLoginSession(false, "")
    dispatch({
        type: LOGOUT
    });
}
