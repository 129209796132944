import { useState, useEffect } from 'react'
import { Dialog, DialogContent, Button, Typography, Box, Paper, Backdrop, TableContainer, Table, TableBody, TableRow, TableCell, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FiCheckSquare, FiAlignCenter } from "react-icons/fi";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
    },
    mcqIcon: {
        color: '#00BC9E',
        fontSize: 100,
    },
    descriptiveIcon: {
        color: '#0B6AB0',
        fontSize: 100,
    },
    iconContainer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fcfdfe',
            border: '1px solid #E0E0E0',
        }
    },
    cancelButton: {
        textTransform: 'none',
        color: '#0B6AB0'
    },
    saveButton: {
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#00BC9E',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#00BC9E',
        }
    }
}));

const PasteQDlg = ({ examId, open, setOpen, questions, setQuestions }) => {
    const classes = useStyles();

    const [question, setQuestion] = useState('');

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const saveQuestion = async () => {
         axios({
            method: 'POST',
            url: 'exams/exam-question-clone/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
            data: {
                exam_id: examId,
                question: question
            }
        }).then((response) => {
            console.log("# ---- Save question success ---- #");
            if (response.status === 201) {
                setQuestions([...questions, response.data]);
                setOpen(false);
            }
        }).catch((error) => {
            console.log("# ---- Save question error ---- #", error);
        })
    }

    return (
        <Dialog scroll='body' fullWidth open={open} onClose={handleClose}>
            <DialogContent>
                <Typography variant="h6" color="initial" className={classes.title}>Paste Question</Typography>

                <Box display='flex'>
                    <TextField
                        fullWidth
                        label="Paste"
                        multiline
                        rows={8}
                        variant="outlined"
                        onChange={(event) => setQuestion(event.target.value)}
                    />
                </Box>
                <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={3} mb={1}>
                    <Button className={classes.cancelButton} color="primary" onClick={() => setOpen(false)} disableElevation>Close</Button>
                    <Button className={classes.saveButton} onClick={() => saveQuestion()} disableElevation>Save</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default PasteQDlg;