import { useState, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import {
    Slide, Dialog, Backdrop, AppBar, Toolbar, Paper, Container, Tooltip, TextareaAutosize, TextField,
    Typography, Box, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, Grid, Button, DialogActions
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FiX, FiBookOpen, FiEdit, FiTrash, FiFileText, FiSquare, FiXSquare, FiCheckSquare } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import axios from 'axios';
import { useEffect } from "react";
import ReactQuill from 'react-quill';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import FileShow from './FileShow'
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "black",
    },
    title: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    xIcon: {
        cursor: 'pointer',
        
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    addQuestionBtn: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    addQuestionIcon: {
        color: '#0B6AB0',
        fontSize: 16,
    },
    addQuestionTxt: {
        color: '#0B6AB0',
        fontWeight: 'bold',
        marginLeft: 2,
    },
    tableContainer: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    noQContainer: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(2),
        minHeight: '80vh',
    },
    editIcon: {
        color: '#0B6AB0',
        fontSize: 16,
        cursor: 'pointer',
        marginRight: theme.spacing(1),
    },
    viewIcon: {
        color: '#0B6AB0',
        fontSize: 23,
        cursor: 'pointer',
    },
    downloadIcon: {
        color: '#0B6AB0',
        fontSize: 23,
        cursor: 'pointer',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    viewBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    tableContainer: {
        width: "100%",
        padding: theme.spacing(0),
        marginTop: theme.spacing(2),
    },
    tableGrid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(2)
    },
    questionNo: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    notAns: {
        // '&:hover': {
        //     background: 'grey',
        // },
        border: '1px solid grey',
        color: 'grey',
        borderRadius: 4,
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    wrongAns: {
        // '&:hover': {
        //     background: '#fff5f8',
        // },
        border: '1px solid #f44336',
        color: '#f44336',
        borderRadius: 4,
        alignItems: 'center',
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    correctAns: {
        // '&:hover': {
        //     background: '#f0fff0',
        // },
        border: '1px solid #4caf50',
        color: '#4caf50',
        borderRadius: 4,
        height: theme.spacing(3),
        marginLeft: theme.spacing(0.6),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    questionChoice: {
        marginLeft: theme.spacing(1)
    },
    questionChoiceCorrect: {
        marginLeft: theme.spacing(1),
        color: '#4caf50'
    },
    questionChoiceWrong: {
        marginLeft: theme.spacing(1),
        color: '#f44336'
    },
    feedbackInput: {
        '&:focus': {
            outline: '1px solid #0B6AB0',
            border: 'none'
        },
        border: '1px solid #B3B3B3',
        borderRadius: 4,
        marginTop: '4px',
        padding: theme.spacing(1)
    },
    obtainedMarksInput: {
        '&:focus': {
            outline: '1px solid #0B6AB0',
            border: 'none'
        },
        border: '1px solid #B3B3B3',
        borderRadius: 4,
        marginTop: '4px',
        padding: theme.spacing(1)
    },
    descriptiveQanswer: {
        '&:focus': {
            color: '#0B6AB0'
        },
        border: '1px solid #B3B3B3',
        borderRadius: 4,
        marginTop: '4px',
        padding: theme.spacing(1)
    },
    marksSubmitContainer: {
        backgroundColor: '#fff',
        marginTop: theme.spacing(2),
        position: "fixed",
        bottom: 0,
        width: '100%',
        // right: theme.spacing(2),
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    marksSubmitButton: {
        '&:hover': {
            backgroundColor: '#0B6AB0',
            color: '#FFFFFF',
        },
        textTransform: 'none',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: '#0B6AB0',
        color: '#FFFFFF',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    examFinishButton:{
        textTransform: 'none',
        backgroundColor: '#f44336',
        color: '#FFFFFF',
        marginLeft: '1rem',
        '&:hover': {
            backgroundColor: '#f44336',
            color: '#FFFFFF',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        //marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4)
    },
    contentContainer: {
        padding: theme.spacing(2),
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-around'
        //backgroundColor: '#fcfdfe',
    },
    obtainedMarksBox: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        marginTop: "48px",
        color: '#0B6AB0',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExamGradeView = ({ submissionId, submissionIndex, submissions, setSubmissions, open, setOpen, viewMode }) => {
    var mcqObtMarks = 0;
    var mcqTotalMarks = 0;
    var descriptiveObtMarks = 0;
    var descriptiveTotalMarks = 0;

    const classes = useStyles();
    const [student, setStudent] = useState({ name: '', id: null });
    const [isGraded, setIsGraded] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [studentExam, setStudentExam] = useState({is_finished:true})

    const [openSubmitSnack, setOpenSubmitSnack] = useState(false);
    const [openFillupSnack, setOpenFillupSnack] = useState(false);
    const [openFailureSnack, setOpenFailureSnack] = useState(false);
    const [openExamFinishSnack, setOpenExamFinishSnack] = useState(false);
    const [openImage, setImageOpen] = useState({status:false, url:"", type:""})

    const [isLoading, setIsLoading] = useState(false);

    const handleImageOpen = (data) => setImageOpen({status:false, url:"", type:""});

    useEffect(() => {
        setAnswers([]);
        const getData = async () => {
            setIsLoading(true)
            await axios({
                method: 'GET',
                url: `exams/exam-grade-details/${submissionId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status == 200) {
                    console.log("# ---- Exam grade details fetch success ---- #");
                    setStudent(response.data.student);
                    setIsGraded(response.data.is_graded);
                    setAnswers(response.data.answers);
                    setStudentExam(response.data.exam);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    console.log("# ---- Exam grade details fetch failure ---- #");
                }
            }).catch((error) => {
                setIsLoading(false);
                console.log("# ---- Exam grade details fetch error ---- #", error);
            })
        }

        open === true && getData();
    }, [open])
    
    const getMarks = () => {
        answers.map((answer, index) => {
            if (answer.type == 'MCQ') {
                mcqObtMarks += parseFloat(answer.ans && answer.ans.marks_obtained ? answer.ans.marks_obtained : 0 );
                mcqTotalMarks += parseFloat(answer.ques.marks ? answer.ques.marks : 0 );
            }
            else {
                descriptiveObtMarks += parseFloat(answer.ans && answer.ans.marks_obtained ? answer.ans.marks_obtained : 0);
                descriptiveTotalMarks += parseFloat(answer.ques.marks ? answer.ques.marks : 0 );
            }
        })

        return [mcqObtMarks, mcqTotalMarks, descriptiveObtMarks, descriptiveTotalMarks];
    }

    const submitExamGrade = () => {
        if (validate()) {
            axios({
                method: 'PUT',
                url: `exams/exam-grade-details/${submissionId}/`,
                data: {
                    total_marks_obtained: mcqObtMarks + descriptiveObtMarks,
                    answers: answers,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status == 200) {
                    console.log("# ----  Grading success ---- #");
                    setOpenSubmitSnack(true);

                    var values = [...submissions];
                    values[submissionIndex] = response.data;
                    setSubmissions(values);
                    isGraded || setOpen(false)
                }
                else {
                    console.log("# ---- Grading failure ---- #");
                    setOpenFailureSnack(true);
                }
            }).catch((error) => {
                console.log("# ---- Grading error ---- #", error);
                setOpenFailureSnack(true);
            })
        }
    }

    const handleChangeFeedback = (index, value) => {
        var values = [...answers];
        values[index].ans.feedback = value;
        setAnswers(values);
    }

    const handleChangeMarks = (index, value) => {
        if (isNaN(parseInt(value))) {
            value = null;
        }
        else if (value < 0) {
            value = 0;
        }

        var values = [...answers];
        values[index].ans.marks_obtained = value;
        setAnswers(values);
    }

    const validate = () => {
        for (var i=0; i<answers.length; i++) {
            if (answers[i].type == 'Descriptive' && answers[i].ans) {
                if (answers[i].ans.marks_obtained === null) {
                    setOpenFillupSnack(true);
                    return false;
                }
            }
        }

        return true;
    }

    const mcqHtml = (index, ques, ans) => (
        <Box mb={4}>
            <Box display='flex' justifyContent='space-between' mt={2} ml={3} mr={3}>
                {/* <Typography variant='body2'> */}
                    <Box display="flex" alignItems='flex-start'>
                        <span className={classes.questionNo}>Q{index + 1}</span>
                        <Typography display="inline" variant='body2' style={{wordWrap: "break-word", maxWidth: '70vw'}} dangerouslySetInnerHTML={{__html: ques.q_text}} />
                        {ans ?
                            ans.choice.length > 0 ?
                                ans.marks_obtained ?
                                    <Typography variant='caption' display='inline' className={classes.correctAns}>Correct</Typography>
                                    :
                                    <Typography variant='caption' display='inline' className={classes.wrongAns}>Wrong</Typography>
                                :
                                <Typography variant='caption' display='inline' className={classes.notAns}>Not Answered</Typography>
                            : undefined
                        }
                    </Box>
                {/* </Typography> */}
                <Typography variant='body2'>{ques.marks} Marks</Typography>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='flex-start' mt={1} ml={5} mr={3}>
                {ques && ques.choice && ques.choice.length ?
                    ques.choice.map((c, index) => {
                        if (c.correct) {
                            return (
                                <Typography variant='body2' className={classes.questionChoiceCorrect}><FiCheckSquare /> {c.text}</Typography>
                            )
                        } else {
                            return (
                                <Typography variant='body2' className={classes.questionChoice}><FiSquare /> {c.text}</Typography>
                            )
                        }
                    }) : undefined
                }
            </Box>
            {ans ?
                ans.choice.length > 0 ?
                    !ans.marks_obtained ?
                    <Box display='flex' justifyContent='flex-start' mt={1} ml={5} mr={3}>
                        <Typography variant="body2"><b>Selected Answer:</b></Typography>
                        {ans.choice.map((c, index) => (
                            <Typography variant='body2' className={classes.questionChoiceWrong}><FiXSquare /> {c.text}</Typography>
                        ))}
                    </Box>
                    : <></>
                    :
                    <></>
                : undefined
            }
        </Box>
    )

    const checkValidFileType = (file) => 
        (file.file.split(".").pop().toLowerCase() === "pdf" || file.file.split(".").pop().toLowerCase() === "png" || file.file.split(".").pop().toLowerCase() === "jpeg" || file.file.split(".").pop().toLowerCase() === "jpg")


    const descriptiveHtml = (index, ques, ans) => (
        <Box mb={4}>
            <Box display='flex' justifyContent='space-between' mt={2} ml={3} mr={3}>
                <Typography variant='body2'>
                    <Box display="flex">
                        <span className={classes.questionNo}>Q{index + 1}</span>
                        <Typography display="inline" variant='body2' style={{wordWrap: "break-word", maxWidth: '70vw'}} dangerouslySetInnerHTML={{__html: ques.q_text}} />
                    </Box>
                </Typography>
                <Typography variant='body2'>{ques.marks} Marks</Typography>
            </Box>
            {ans ?
                <Box display='flex' justifyContent='space-between' mt={1} ml={3} mr={3}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Box display='flex' flexDirection='column' justifyContent='flex-start'>
                                <Typography variant='body2'>Answer</Typography>
                                <Paper variant="outlined">
                                    <ReactQuill
                                        style={{ minHeight: '200px' }}
                                        value={ans.ans ? ans.ans : 'Not answered' || ''}
                                        readOnly={true}
                                        theme={"bubble"}
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                        {ans.files.length !== 0 && <Grid item xs={12}>
                            <Paper variant='outlined' className={classes.contentContainer}>
                                {ans.files.map((file, key) => (
                                    <div className={classes.content}>
                                        <Box display='flex' flexDirection='row' alignItems='center'>
                                            <Typography noWrap variant="body1" display="inline" style={{ maxWidth: '30vw', color:"#0B6AB0", marginRight:"1rem" }}>{key + 1}.</Typography>
                                            <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{file.name}</Typography>
                                            <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{file.file.split(".").pop()}</Typography>
                                            {/* file.file_type === "doc" && <Typography noWrap variant="body2" color="textSecondary" display="inline" style={{ maxWidth: '30vw', marginLeft:'5px' }}> (Document)</Typography>*/}
                                        </Box>
                                        <div>
                                            <Tooltip title='View' style={{marginRight: '1.5rem', pointerEvents: `${checkValidFileType(file) || "none"}`, color: `${checkValidFileType(file) || "gray"}` }}>
                                                <VisibilityIcon onClick={() =>setImageOpen({status:true, url:`${contentBaseURL}${file.file}`, type:file.file_type})} className={classes.viewIcon} />
                                            </Tooltip>
                                            <Tooltip title='Download'>
                                                <a target="_blank" rel="noreferrer" href={`${contentBaseURL}${file.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ))}
                            </Paper>
                        </Grid>}
                        <Grid item xs={12}>
                            <Box display='flex' flexDirection='column' justifyContent='flex-start'>
                                <Typography variant='body2'>Feedback</Typography>
                                <textarea
                                    rows={8}
                                    value={ans.feedback}
                                    placeholder={viewMode === 'Grade' ? "Type your feedback here..." : "No feedback provided."}
                                    disabled={viewMode === 'Result' ? true : false} 
                                    className={classes.feedbackInput}
                                    onChange={(e) => handleChangeFeedback(index, e.target.value)}>
                                </textarea>
                                <Typography variant='body2' style={{ marginTop: '4px' }}>Obtained Marks</Typography>
                                <input
                                    value={ans.marks_obtained}
                                    placeholder={viewMode === 'Grade' ? "Type marks here..." : "No marks provided."}
                                    type="number"
                                    className={classes.obtainedMarksInput}
                                    disabled={viewMode === 'Result' ? true : false} 
                                    onChange={(e) => handleChangeMarks(index, e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                :
                <Box ml={3} mr={3} mt={1} border={1} borderRadius="borderRadius" borderColor="#B3B3B3">
                    <Typography variant="h6" color="textSecondary" style={{padding: '5px'}}>Not answered.</Typography>
                </Box>
            }
        </Box>
    )

    const marksHtml = ([mcqObtMarks, mcqTotalMarks, descObtMarks, descTotalMarks]) => (
        <Box display='flex' ml={1}>
            <Box display='flex' ml={1} mr={1}>
                <Typography>MCQ: <span style={{ color: '#00BC9E' }}>{mcqObtMarks}</span> / <span style={{ color: '#0B6AB0' }}>{mcqTotalMarks}</span></Typography>
            </Box>
            <Box display='flex' ml={1} mr={1}>
                <Typography>Descriptive: <span style={{ color: '#00BC9E' }}>{descObtMarks}</span> / <span style={{ color: '#0B6AB0' }}>{descTotalMarks}</span></Typography>
            </Box>
            <Box display='flex' ml={1} mr={1}>
                <Typography style={{ fontWeight: 'bold' }}>Total: <span style={{ color: '#00BC9E' }}>{mcqObtMarks+descObtMarks}</span> / <span style={{ color: '#0B6AB0' }}>{mcqTotalMarks+descTotalMarks}</span></Typography>
            </Box>
        </Box>
    )

    const handleCloseSubmitSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSubmitSnack(false);
    }

    const handleCloseExamFinishSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenExamFinishSnack(false);
    }

    const handleCloseFillupSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFillupSnack(false);
    }

    const handleCloseFailureSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnack(false);
    }
    
    const handleExamFInish = () => {
        axios({
            method: 'PUT',
            url: `exams/instructor-exam-finish/${studentExam.id}/`,
            data: {
                'is_finished': true
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                console.log("# ----  Exam save as finished for student ---- #");
                setOpenExamFinishSnack(true);
                setStudentExam({...studentExam, is_finished:true})
            }
            else {
                console.log("# ---- Save failure ---- #");
                setOpenFailureSnack(true);
            }
        }).catch((error) => {
            console.log("# ---- Save error ---- #", error);
            setOpenFailureSnack(true);
        })
    }

    return (
        <div>
            <FileShow status={openImage} handleImageOpen={handleImageOpen}/>

                <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
                    <Backdrop className={classes.backdrop} open={isLoading}>
                        <IoHourglassOutline style={{ fontSize: 35 }} />
                    </Backdrop>
                    
                    <AppBar className={classes.appBar} elevation={0}>
                        <Toolbar variant='dense' style={{display:"flex", justifyContent: 'space-between'}}>
                            <Box display="flex" alignItems="center">
                                <FiFileText />
                                {!isLoading && <Typography variant="body1" className={classes.title}>{student.name} #{student.id}</Typography>}
                            </Box>
                            <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
                        </Toolbar>
                    </AppBar>
                    {!isLoading && <>
                    {viewMode === 'Grade' ? studentExam.is_finished ? <></> : <Box mt={2} ml={3} mr={3}>
                        <Paper variant='outlined' style={{padding: '1rem', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <Typography variant='body1'>This student has not finished exam. Please finish the exam for grading.</Typography>
                            <Button size="small" className={classes.examFinishButton} onClick={() => handleExamFInish()}>Finish</Button>
                        </Paper>
                    </Box> : <></>}

                    <Box pb={7}>
                        {answers && answers.map((answer, index) => (
                            answer.type === 'MCQ' ?
                                mcqHtml(index, answer.ques, answer.ans)
                                :
                                descriptiveHtml(index, answer.ques, answer.ans)
                        ))}
                    </Box>

                    <Box display='flex' borderTop={1} borderColor='#B3B3B3' alignItems='center' justifyContent='space-between' className={classes.marksSubmitContainer}>
                        {marksHtml(getMarks())}
                        <Box>
                            <Button className={classes.cancelBtn} onClick={() => setOpen(false)}>Close</Button>
                            {viewMode === 'Grade' ? 
                                <Button 
                                    size='small'
                                    variant="contained"
                                    disabled={!studentExam.is_finished}
                                    className={ classes.marksSubmitButton}
                                    onClick={() => submitExamGrade()}>
                                        {isGraded ? 'Update Grade' : 'Submit Grade'}
                                </Button> 
                            : <></>}
                        </Box>
                    </Box>
                    </>}
                </Dialog>

            <Snackbar open={openSubmitSnack} autoHideDuration={4000} onClose={handleCloseSubmitSnack}>
                <Alert onClose={handleCloseSubmitSnack} severity="success">
                    Grading {isGraded ? 'updated' : 'submitted'}.
                </Alert>
            </Snackbar>
            <Snackbar open={openExamFinishSnack} autoHideDuration={4000} onClose={handleCloseExamFinishSnack}>
                <Alert onClose={handleCloseExamFinishSnack} severity="success">
                    Operation Successful
                </Alert>
            </Snackbar>
            <Snackbar open={openFillupSnack} autoHideDuration={4000} onClose={handleCloseFillupSnack}>
                <Alert onClose={handleCloseFillupSnack} severity="info">
                    Please give marks to all descriptive answers.
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
                <Alert onClose={handleCloseFailureSnack} severity="error">
                    Operation failed!
                </Alert>
            </Snackbar>

            
        </div>
    )
}

export default ExamGradeView
