import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import BannerImage from '../../images/home_banner.jpg'

const useStyles = makeStyles((theme) => ({
    banner: {
        // position: 'absolute',
        background: 'linear-gradient(steelblue,black)', //theme.palette.grey[200],
        color: theme.palette.common.white,
        backgroundImage: `url(${BannerImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '60vh',
        // marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1)
    },
    overlay: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'absolute',
        padding: theme.spacing(20),
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    description: {
        padding: theme.spacing(3),
        backgroundColor: 'rgba(100,100,255,.3)',
    }
}));

const HomeBanner = () => {
    const classes = useStyles()
    return (
        <Paper className={classes.banner} elevation={3} square={true}>
            {/* <div className={classes.overlay}>
                <Typography variant="overline" paragraph className={classes.description}>
                    In the modern world,
                    the software is the modern part,
                    you can not think about the world without it,
                    and software industries even getting bigger and bigger every single day
                </Typography>
            </div> */}
        </Paper>
    )
}

export default HomeBanner
