import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Divider, Typography, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexGrow: 1,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    itemText: {
        flexGrow: 1
    },
    actionAcceptButton: {
        '&:hover': {
            background: '#f0fff0',
        },
        borderColor: '#4caf50',
        color: '#4caf50',
        margin: theme.spacing(1)
    },
    actionRejectButton: {
        '&:hover': {
            background: '#fff5f8',
        },
        borderColor: '#f44336',
        color: '#f44336',
        margin: theme.spacing(1)
    },
    line: {
        width: '100%'
    }
}))

const HoldRequests = ({ requests, onAccept, onReject }) => {
    const classes = useStyles()
    return (
        <div>
            <List>
                <ListItem className={classes.listItem}>
                    {requests.map((request, index) => (
                        <>
                            <div className={classes.itemContainer}>
                                <ListItemText className={classes.itemText}>
                                    <Typography variant='body1'>
                                        <b>{request.request_name}</b>
                                        {"'s "}({request.contact_email})
                                        {' request for new user as '} <b>{request.role}</b> {' role is on hold'}
                                    </Typography>
                                </ListItemText>
                                <Button
                                    className={classes.actionAcceptButton}
                                    variant='outlined'
                                    size='small'
                                    onClick={() => { onAccept(request.request_id, 'On Hold') }}>
                                    Accept
                                    </Button>
                                <Button
                                    className={classes.actionRejectButton}
                                    variant='outlined'
                                    size='small'
                                    onClick={() => { onReject(request.request_id, 'On Hold') }}>
                                    Reject
                                    </Button>
                            </div>

                            {index < (requests.length - 1) ?
                                <Divider className={classes.line} />
                                : undefined
                            }
                        </>
                    ))}
                </ListItem>
            </List>
        </div>
    )
}

export default HoldRequests
