import { useState, useEffect } from 'react'
import { Dialog, DialogContent, Button, Typography, Chip, Box, Paper, InputBase, Snackbar, Backdrop, FormControlLabel, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomSwitch = withStyles({
    switchBase: {
      color: "#0B6AB0",
      '&$checked': {
        color: "#0B6AB0",
      },
      '&$checked + $track': {
        backgroundColor: "#0B6AB0",
      },
    },
    checked: {},
    track: {},
  })(Switch);

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(0),
    },
    studentName: {
        //fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    courseTitle: {
        color: '#0B6AB0',
        fontWeight: 'normal',
        marginBottom: theme.spacing(0),
    },
    courseContainer: {
        padding: theme.spacing(2),
    },
    weekInput: {
        padding: theme.spacing(0),
        display: 'flex',
        alignItems: 'center',
        //width: 400,
    },
    inputBase: {
        marginLeft: theme.spacing(1),
        flex: 1,
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    holdButton: {
        textTransform: 'none',
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: '#ff9800',
        },
        color: 'white',
        margin: theme.spacing(0.25),
        marginLeft: theme.spacing(1),
    },
    weekChip: {
        margin: theme.spacing(0.25),
        marginLeft: theme.spacing(0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
    cancelButton: {
        textTransform: 'none',
    },
}))

const CourseAccessibilityDialog = ({ open, handleClose, studentId }) => {
    const classes = useStyles()
    const [weekNum, setWeekNum] = useState([])
    const [errorWeekNum, setErrorWeekNum] = useState([])
    const [studentName, setStudentName] = useState("")
    const [courses, setCourses] = useState([])
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getCurrentAccess = () => {
            setIsLoading(true)
            axios({
                method: 'GET',
                url: `students/student-course-week-accessibility/${studentId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    setStudentName(res.data.studentname)
                    setCourses(res.data.courses)
                    
                    setWeekNum(Array.from({length: res.data.courses.length}, i => i = ""));
                    setErrorWeekNum(Array.from({length: res.data.courses.length}, i => i = ""));
                    
                    setIsLoading(false)
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
        }
        if (open) {
            getCurrentAccess()
        }
    }, [open])

    const handleCourseAccess = (e, id, index) => {
        axios({
            method: 'POST',
            url: `students/student-course-hold-access/`,
            data: {
                id: id,
                hold_access: !e.target.checked,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status === 202) {  // Accepted
                var values = [...courses];
                values[index].hold_access = res.data.hold_access;
                setCourses(values);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleChangeWeekNum = (value, index) => {
        var values = [...weekNum]
        values[index] = value
        setWeekNum(values);
    }

    const handleAddHoldWeek = (userCourseId, index) => {
        // Validate
        const num = parseInt(weekNum[index]);
        if (isNaN(num)) {
            var values = [...errorWeekNum]
            values[index] = "Week number must not be kept blank"
            setErrorWeekNum(values)
        }
        else if (num===0) {
            var values = [...errorWeekNum]
            values[index] = "Week number must not be a zero"
            setErrorWeekNum(values);
        }
        else if (num < 0) {
            var values = [...errorWeekNum]
            values[index] = "Week number must not be negative"
            setErrorWeekNum(values);
        }
        else {
            var values = [...weekNum]
            values[index] = ""
            setWeekNum(values)

            axios({
                method: 'POST',
                url: `students/student-course-hold-week/`,
                data: {
                    id: userCourseId,
                    week: weekNum[index],
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status === 201) {
                    var values = [...courses]
                    values[index].holdweek = [...values[index].holdweek, res.data]
                    setCourses(values)
                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);
            });
        }
    }

    const handleRemoveHoldWeek = (holdWeekId, courseIndex, weekIndex) => {
        axios({
            method: 'DELETE',
            url: `students/student-course-hold-week/`,
            data: {
                id: holdWeekId,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status === 202) {
                var values = [...courses]
                values[courseIndex].holdweek.splice(weekIndex, 1);
                setCourses(values)
            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    }

    return (
        <div>
            {!isLoading &&
            <Dialog scroll='body' maxWidth='sm' open={open} onClose={handleClose} fullWidth>
                <DialogContent>
                    <Typography variant="h6" color="initial" className={classes.title}>Modify Course Access</Typography>
                    <Typography variant="body2" color="textSecondary" className={classes.studentName}>of {studentName}</Typography>

                    {courses.map((course, index) => (
                        <Paper variant='outlined' className={classes.courseContainer} square>  
                            <Typography variant="body1" className={classes.courseTitle}>{course.coursetitle}</Typography>
                            <Typography variant="body2" color="textSecondary">{course.academicsession}</Typography>
                            <Box mt={1}>
                                <FormControlLabel
                                    control={<CustomSwitch checked={!course.hold_access} onChange={(e) => handleCourseAccess(e, course.id, index)} />}
                                    label={course.hold_access ? "Access Disabled" : "Access Enabled"}
                                />
                            </Box>
                            {!course.hold_access &&
                                <>
                                <Box mt={1} display='flex'>
                                    <Paper component="form" elevation={0} variant='outlined' className={classes.weekInput}>
                                        <InputBase
                                            className={classes.inputBase}
                                            placeholder="Week Number"
                                            type='number'
                                            value={weekNum[index]}
                                            onChange={(e) => handleChangeWeekNum(e.target.value, index)}
                                            inputProps={{ 'aria-label': 'week number' }}
                                        />
                                        <Button variant="contained" size='small' color="primary" className={classes.holdButton} onClick={() => handleAddHoldWeek(course.id, index)} disableElevation>
                                            Hold
                                        </Button>
                                    </Paper>
                                </Box>
                                {errorWeekNum.length ?
                                    errorWeekNum[index].length ?
                                        <Typography variant="caption" color="secondary">{errorWeekNum[index]}</Typography>
                                        : <></> 
                                    :
                                    <></>
                                }
                                <Box mt={1}>
                                    {course.holdweek.length ?
                                    <>
                                    <Typography variant="body2" color="textSecondary">Holded Weeks</Typography> 
                                    {course.holdweek.map((hweek, i) => (
                                        <Chip
                                            className={classes.weekChip}
                                            label={`Week ${hweek.week}`}
                                            onDelete={() => handleRemoveHoldWeek(hweek.id, index, i)}
                                        />
                                    ))}
                                    </>
                                    :
                                    <Typography variant="body2" color="textSecondary">No holded week. Please put a week number and click <span style={{ color: '#ff9800' }}>"Hold"</span> to restrict course content access.</Typography>
                                    }
                                </Box>
                                </>
                            }
                        </Paper>
                    ))}
                    <Box display='flex' flexDirection='column' alignItems='flex-end' mt={3} mb={1}>
                        <Button className={classes.cancelButton} color="primary" onClick={handleClose} style={{ textTransform: 'none' }} disableElevation>Close</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            }
            <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    Operation failed!
                </Alert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
                {/* <Typography variant="h6" color="initial" style={{ marginLeft: '5px', fontWeight: 'normal' }}>Loading...</Typography> */}
            </Backdrop>
        </div>
    )
}

export default CourseAccessibilityDialog
