import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    padding: theme.spacing(0),
    "& .status.active": {
      color: "#4caf50",
    },
    "& .status.pending": {
      color: "#ff9800",
    },
    "& .status.notActive": {
      color: "#f44336",
    },
    marginTop: theme.spacing(2),
  },
}));

// Define column headers
const columns = [
  // { field: 'id', headerName: 'ID', width: 100 },
  { field: "title", headerName: "Title", width: 320 },
  { field: "coursecount", headerName: "Courses", width: 150 },
  { field: "studentcount", headerName: "Enrolled Students", width: 220 },
];

// Create rows
function rows(sessions) {
  const rows = [];
  sessions.map((session, index) =>
    rows.push({
      key: session.id,
      id: session.id,
      title: session.title,
      coursecount: session.coursecount,
      studentcount: session.studentcount,
    })
  );
  return rows;
}

const SessionListTable = ({
  sessions,
  handleChecked,
  handleSelectedSessions,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(sessions)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          handleChecked(newSelection.length);
          handleSelectedSessions(newSelection);
        }}
        pageSize={10}
      />
    </div>
  );
};

export default SessionListTable;
