import { Container, Box, Button, Grid, Paper, Typography, Snackbar, Backdrop } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import axios from 'axios'
import { DeleteOutlineRounded } from '@material-ui/icons'
import { useConfirm } from "material-ui-confirm"
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"

import AddSessionDialog from './AddSessionDialog'
import SessionListTable from './SessionListTable'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        // backgroundColor: '#000000'
    },
    gridContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    headerTypography: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    headerUnderline: {
        width: '50%',
        color: '#00BC9E',
        borderBottom: '2px solid'
    },
    addButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        marginLeft: theme.spacing(1)
    },
    cancelButton: {
        textTransform: 'none',
    },
    deleteButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    tableGrid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

function AcademicSessions() {
    const classes = useStyles()
    const confirm = useConfirm()
    const [sessions, setSessions] = useState([])
    const [selectedSessions, setSelectedSessions] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [disableDeleteButton, setDisableDeleteButton] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const [openAddSnackbar, setOpenAddSnackbar] = useState(false)
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false)
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false)

    const handleChecked = (selectedItem) => {
        if (selectedItem > 0) {
            setDisableDeleteButton(false)
        } else {
            setDisableDeleteButton(true)
        }
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleDelete = () => {
        confirm({title: <Typography variant='h6'>Delete Session</Typography>,
                description: <Typography varint='body2'>Are you sure to delete {selectedSessions.length===1 ? "this session?" : selectedSessions.length+" sessions?"}.</Typography>,
                 confirmationText: 'Delete',
                 confirmationButtonProps: {color: 'secondary', variant: 'contained', disableElevation: true},
                 cancellationButtonProps: {className: classes.cancelButton, color: 'primary', disableElevation: true},
                })
            .then(() => deleteSession())
            .catch(() => console.log('Cancelled!'));
    }

    const deleteSession = () => {
        
        axios({
            method: "delete",
            url: `courses/academic-session-details/`,
            data: {
                ids: selectedSessions,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(function (res) {
            if (res.status === 202) {
                console.log('Sessions deleted!');
                setSessions(res.data);

                setDisableDeleteButton(true);
                setOpenDeleteSnackbar(true);
            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleSelectedSessions = (selectedSessions) => {
        setSelectedSessions(selectedSessions)
    }

    useEffect(() => {
        const getSessions = async () => {
            setIsLoading(true)
            await axios
                .get('courses/auth-academic-session-list/', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('## ---- Session Fetch Success ---- ##', response.status);
                        setSessions(...sessions, response.data)
                        setIsLoading(false)
                    } else {
                        console.log('## ---- Session Fetch Failed ---- ##', response.status);
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log('## ---- Session Fetch Error ---- ##', error);
                    setIsLoading(false)
                })
        }
        getSessions();

    }, [])

    const handleCloseAddSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAddSnackbar(false);
    }

    const handleCloseDeleteSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSnackbar(false);
    }

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    }

    return (
        <>
        {!isLoading && <Container maxWidth={false} className={classes.container}>
            <Grid
                container
                spacing={2}
                direction='row'
                justify='space-between'
                className={classes.gridContainer}
            >
                <Grid item xs={4}>
                    <Typography variant='h6' display='inline' className={classes.headerTypography}>Sessions Dashboard</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Button
                        variant='contained'
                        startIcon={<DeleteOutlineRounded />}
                        onClick={() => handleDelete()}
                        disabled={disableDeleteButton}
                        className={classes.deleteButton}>
                        Delete Session
                    </Button>
                    <Button
                        variant='contained'
                        startIcon={<AddOutlinedIcon />}
                        style={{ textTransform: 'none' }}
                        onClick={handleClickOpen}
                        className={classes.addButton}>
                        Add Session
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
                className={classes.tableGrid}
            >
                <Grid item xs={12}>
                    <SessionListTable sessions={sessions} handleChecked={handleChecked} handleSelectedSessions={handleSelectedSessions} />
                </Grid>
            </Grid>
            {dialogOpen ? <AddSessionDialog open={dialogOpen} handleClose={handleClose} sessions={sessions} setSessions={setSessions}
                                            setOpenAddSnackbar={setOpenAddSnackbar} setOpenFailureSnackbar={setOpenFailureSnackbar}/> : undefined}

            <Snackbar open={openAddSnackbar} autoHideDuration={4000} onClose={handleCloseAddSnackbar}>
                <Alert onClose={handleCloseAddSnackbar} severity="success">
                    Session added.
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteSnackbar}>
                <Alert onClose={handleCloseDeleteSnackbar} severity="success">
                    Sessions deleted.
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    Operation failed!
                </Alert>
            </Snackbar>
        </Container>}

        <Backdrop className={classes.backdrop} open={isLoading}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
        </Backdrop>
        </>
    )
}

export default AcademicSessions
