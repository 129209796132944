import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import React from 'react';
import clsx from 'clsx';
import { useEffect } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { getLocalDateFormat, userTime } from '../../../custom_util';

const useStyles = makeStyles((theme) => ({
    table: {
        "& .status.running": {
            color: "red",
        },
        "& .status.finished": {
            color:'#00BC9E',
        },
    },
    additionIcon: {
        color: '#00BC9E',
    }
}));

const AttendeeListTable = ({ attendees, handleClickAdditon }) => {
    const classes = useStyles();
    const getDuration = (now, end) => {
        if(now < end){
            var delta = Math.abs(end - now) / 1000;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60);
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = parseInt(delta % 60)
            
            return {minutes, seconds};
        } else{
            var minutes = 0;
            var seconds = 0;

            return {minutes, seconds};
        }
    }
    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "name", headerName: "Name", width: 240 },
        { 
            field: "date", 
            headerName: "Date",
            width: 160 ,
            renderCell: (params) => {
                var html = <></>;
                html = userTime(params.value, 'DD MMM YYYY');
                return html;
            }
        },
        { 
            field: "time", 
            headerName: "Started At", 
            width: 160, 
            renderCell: (params) => {
                var html = <></>;
                html = userTime(params.value, 'hh:mm A');
                return html;
            }
        },
        { 
            field: "status", 
            headerName: "Status", 
            width: 140,
            renderCell: (params) => {
                var html = <></>
                if (params.value) {
                    html = 'Finished';
                } else {
                    html = 'Running';
                }
                return html;
                // var exam = params.value.exam;
                // var schedule = params.value.exam;
                // console.log(schedule);
                // var now = new Date();
                // var timer;
                // if (exam.is_enabled_all) {
                //     var html = '';
                //     var endTime = getLocalDateFormat(exam.end_time);
                    
                //     timer = setInterval(() => {
                //         var now = new Date();
                //         var {minutes, seconds} = getDuration(now, endTime);
                //         html = minutes + ':' + seconds;
                //         console.log(html);
                //         return html;
                //     }, 1000);
                //     clearInterval(timer);
                // } else {
                //     var html = <></>;
                //     var endTime = getLocalDateFormat(schedule.end_time);
                //     console.log(endTime);
                //     timer = setInterval(() => {
                //         var {minutes, seconds} = getDuration(now, endTime);
                //         html = minutes + ':' + seconds;
                //         console.log(html);
                //         return html;
                //     }, 1000);
                //     clearInterval(timer);
                // }
            },
            cellClassName: (params) =>
            clsx("status", {
                running: params.value == false,
                finished: params.value == true,
            }),
        },
        { field: "additionalMinute", headerName: "Additional (min)" , width: 200},
        { 
            field: "finishedAt", 
            headerName: "Finished At", 
            width: 160, 
            renderCell: (params) => {
                var html = <></>;
                if (params.value) {
                    html = userTime(params.value, 'hh:mm A');
                } else {
                    html = 'Not Finished';
                }
                return html;
            }
        },
        { 
            field: "action", 
            headerName: "Action", 
            width: 140,
            renderCell: (params) => {
              var icon = <></>;
              icon = (
                <Tooltip title='Additional Minute' arrow placement='right'>
                  <IconButton
                    className={classes.additionIcon}
                    onClick={(event) => handleClickAdditon(event, params.value.index, params.value.submissionId)}
                    >
                    <FiPlusCircle/>
                  </IconButton>
                </Tooltip>
              );
              return icon;
            } 
        },
    ]
    function rows(attendees) {
        const rows = [];
        attendees.map((attendee, index) =>
          rows.push({
            key: attendee.id,
            id: attendee.user.id,
            name: attendee.user.first_name + " " + attendee.user.last_name,
            date: attendee.started_at,
            time: attendee.started_at,
            status: attendee.is_finished,
            finishedAt: attendee.finished_at,
            // status: {exam: attendee.exam},
            additionalMinute: attendee.additonal_duration,
            action: {index: index, submissionId:attendee.id}
          })
        );
        return rows;
    }

    return (
        <div className={classes.table}>
            <DataGrid 
                autoHeight
                disableSelectionOnClick
                rowHeight={35}
                rows={rows(attendees)}
                columns={columns}
                onSelectionModelChange={(newSelection) => {
                //   handleChecked(newSelection.length);
                //   handleSelectedAdmins(newSelection);
                }}
                pageSize={15}
            />
        </div>
    );
};

export default AttendeeListTable;
