import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, IconButton, Switch, Tooltip } from "@material-ui/core";
import { FiEdit } from "react-icons/fi"
import { userTime } from "../../../custom_util";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: '#0B6AB0',
    fontSize: 16,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}))

const EnabledStudentList = ({ schedules, handleChangeEnabled, handleClickEdit }) => {
  const classes = useStyles();

  // Define column headers
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 250 },
    { field: "date", headerName: "Date", width: 240 },
    { field: "time", headerName: "Rescheduled Start Time", width: 300 },
    { 
      field: "enabled",
      headerName: "Enabled", 
      width: 200,
      renderCell: (params) => {
        var icon = <></>;
        icon = (
          <FormControlLabel
            control={<Switch
                      inputProps={{
                        studentId: params.value.id
                      }}
                      name={params.value.index} 
                      checked={params.value.enable}
                      color="primary"
                      onChange={handleChangeEnabled}
                    />} 
          />
        );
        return icon;
      }
    },
    { 
      field: "action", 
      headerName: "Action", 
      width: 140,
      renderCell: (params) => {
        var icon = <></>;
        icon = (
          <Tooltip title='Edit' arrow>
            <IconButton
              className={classes.editIcon}
              onClick={() => handleClickEdit(params.value.scheduleId,params.value.index)}>
              <FiEdit/>
            </IconButton>
          </Tooltip>
        );
        return icon;
      } 
    },
  ];

  function rows(schedules) {
    const rows = [];
    schedules.map((schedule, index) =>
      rows.push({
        key: schedule.id,
        id: schedule.user.id,
        name: schedule.user.first_name + " " + schedule.user.last_name,
        date: userTime(schedule.start_time, 'DD MMM YYYY'),
        time: userTime(schedule.start_time, 'hh:mm A'),
        enabled: {index: index, id: schedule.user.id, enable:schedule.is_enabled},
        action: {index: index, scheduleId:schedule.id}
      })
    );
    return rows;
  }

  return (
    <div>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(schedules)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
        //   handleChecked(newSelection.length);
        //   handleSelectedAdmins(newSelection);
        }}
        pageSize={15}
      />
    </div>
  );
};

export default EnabledStudentList;
