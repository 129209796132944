import React, { useState, useEffect } from 'react'
import { Container, Divider, TextField, Typography, Button, Backdrop, CircularProgress, InputAdornment, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../images/logo.jpg'
import { connect } from 'react-redux'
import { resetPasswordConfirm } from '../actions/auth'
import { Redirect } from 'react-router'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { IoHourglassOutline } from "react-icons/io5"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(14, 0, 6),
        textAlign: 'center',
        width: '25%'
    },
    logoStyle: {
        width: '50%',
        height: '50%',
    },
    inputField: {
        margin: theme.spacing(1),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4)
    },
    button: {
        paddingRight: theme.spacing(12),
        paddingLeft: theme.spacing(12),
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}))
const ResetPasswordConfirm = ({ match, resetPasswordConfirm }) => {
    const classes = useStyles()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [backDropOpen, setBackDropOpen] = useState(false)
    const [requestSend, setRequestSend] = useState(false)

    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const handleClickShowNewPassword = () => {
        if (newPassword !== '') {
            setShowNewPassword(!showNewPassword)
        } else {
            setShowNewPassword(false)
        }
    }

    const handleClickShowConfirmPassword = () => {
        if (confirmPassword !== '') {
            setShowConfirmPassword(!showConfirmPassword)
        } else {
            setShowConfirmPassword(false)
        }
    }

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const postPasswordResetConfirm = async (newPassword, confirmPassword) => {
        const uid = match.params.uid
        const token = match.params.token
        setBackDropOpen(true)
        await resetPasswordConfirm(uid, token, newPassword, confirmPassword)
        setRequestSend(true)
        setBackDropOpen(false)
    }
    if (requestSend) {
        return <Redirect to='/login' />
    }
    return (
        <Container className={classes.container}>
            <img src={logo} className={classes.logoStyle} />
            <Typography variant='h5' color='inherit'>
                Set new password
            </Typography>
            <Divider />
            <div className={classes.inputField}>
                <TextField
                    fullWidth
                    id="outlined-password-input"
                    label="New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    variant="outlined"
                    size="small"
                    color="primary"
                    onChange={(event) => setNewPassword(event.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end" className={classes.visibilityIcon} style={{ paddingRight: 0 }}>
                            <IconButton
                                className={classes.visibilityIcon}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                            >
                                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>,
                    }} />
            </div>
            <div className={classes.inputField}>
                <TextField
                    fullWidth
                    id="outlined-password-input"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete="confirm-password"
                    variant="outlined"
                    size="small"
                    color="primary"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end" className={classes.visibilityIcon} style={{ paddingRight: 0 }}>
                            <IconButton
                                className={classes.visibilityIcon}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                            >
                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>,
                    }} />
            </div>
            <Button
                variant="contained"
                className={classes.button}
                onClick={() => postPasswordResetConfirm(newPassword, confirmPassword)}
            >Confirm
            </Button>
            <Backdrop
                className={classes.backdrop}
                open={backDropOpen}
                onClick={() => {
                    setBackDropOpen(false);
                }}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </Container>
    )
}

export default connect(null, { resetPasswordConfirm })(ResetPasswordConfirm)
