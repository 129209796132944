import { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, LinearProgress, Paper, Tooltip, Dialog, DialogContent, Badge } from '@material-ui/core'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import HomeworkViewer from "./HomeworkViewer"
import { ManageComments } from "./HomeworkViewer";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function getFileExt(filename) {
    return filename.toLowerCase().split('.').pop();
}

const useStyles = makeStyles((theme) => ({
    fileIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    viewBtn: {
        cursor: 'pointer',
        color: 'white',
        fontWeight: "bold",
        backgroundColor: "#0B6AB0",
        padding: "3px 3px 3px",
        marginBottom: 2,
        borderRadius: 3,
        marginLeft: theme.spacing(2),
    },
    commentIcon: {
        color: 'grey',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
}));

function SingleSubmission({ submission }) {
    const classes = useStyles()
    const [isOpenViewer, setIsOpenViewer] = useState(false)
    const [isOpenCommentsDlg, setIsOpenCommentsDlg] = useState(false)
    const [isCommentSeen, setIsCommentSeen] = useState(submission.is_comment_seen)
    
    return (
        <>
        <Box display="flex" flexGrow={1} flexDirection='row' justifyContent='space-between'>
            <Box display='flex' flexDirection='row' alignItems='center' style={{ width: '30vw' }}>
                <Typography noWrap variant="body1" color="initial" display="inline">{submission.title ? submission.title : 'No title'}</Typography>
                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(submission.file)}</Typography>
            </Box>
            <Typography variant="body2" display='inline' color='textSecondary'>Submitted at {submission.submission_time}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
                {/* <Tooltip title='View'>
                    <DescriptionOutlinedIcon onClick={() => setIsOpenViewer(true)} className={classes.fileIcon} />
                </Tooltip> */}
                {/* <Tooltip title='Download'>
                    <a target="_blank" href={`${contentBaseURL}${submission.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                </Tooltip> */}
                <Tooltip title='Comments'>
                    <Badge color="secondary" variant="dot" invisible={isCommentSeen}>
                        <ChatBubbleOutlineIcon className={classes.commentIcon} onClick={() => {setIsOpenCommentsDlg(true); setIsCommentSeen(true)}} />
                    </Badge>
                </Tooltip>
                <Tooltip title='View'>
                    <DescriptionOutlinedIcon onClick={() => {setIsOpenViewer(true); setIsCommentSeen(true)}} className={classes.fileIcon} />
                </Tooltip>
                {/* <Typography variant="caption" className={classes.viewBtn} onClick={() => setIsOpenViewer(true)}>View</Typography> */}
            </Box>
        </Box>
        <HomeworkViewer
            open={isOpenViewer}
            setOpen={setIsOpenViewer}
            title={submission.title}
            id={submission.id}
            file={submission.file}
        />
        <Dialog maxWidth="sm" scroll='body' open={isOpenCommentsDlg} onClose={() => setIsOpenCommentsDlg(false)} fullWidth>
            {/* <DialogTitle><Typography noWrap variant="body1" display='inline' color="initial">{homeworksubmission.title}</Typography></DialogTitle> */}
            <DialogContent style={{ padding: 0 }}>
                <ManageComments id={submission.id}/>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default SingleSubmission
