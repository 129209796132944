import React from "react";
import clsx from "clsx";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip, Dialog, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    padding: theme.spacing(0),
    "& .status.active": {
      color: "#4caf50",
    },
    "& .status.pending": {
      color: "#ff9800",
    },
    "& .status.notActive": {
      color: "#f44336",
    },
    marginTop: theme.spacing(2),
  },
}));



const InstructorListTable = ({
  instructors,
  handleChecked,
  handleSelectedInstructors,
  handleAccessibility
}) => {
  const classes = useStyles();

  // Define column headers
const columns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "name", headerName: "Name", width: 220 },
  { field: "occupation", headerName: "Occupation", width: 230 },
  { field: "details", headerName: "Details", width: 250 },
  { field: "contact", headerName: "Contact", width: 250 },
  {
    field: "status",
    headerName: "Status",
    width: 180,
    cellClassName: (params) =>
      clsx("status", {
        active: params.value == "Active",
        pending: params.value === "Pending",
        notActive: params.value === "Not Active",
      }),
  },
  {
    field: "accessibility",
    headerName: "Accessibility",
    disableClickEventBubbling: true,
    width: 180,
    renderCell: (params) => {
      //return <Button variant='outlined' size='small' className={classes.holdButton} onClick={() => handleAccessibility(params.value)} disableElevation>Modify</Button>;
      return (
        <Tooltip title="Edit">
          <IconButton
            size="small"
            onClick={() => handleAccessibility(params.value)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

// Create rows
function rows(instructors) {
  const rows = [];
  instructors.map((instructor, index) =>
    rows.push({
      key: instructor.id,
      id: instructor.id,
      name: instructor.name,
      occupation: instructor.occupation,
      details: instructor.details,
      contact: instructor.contact_email,
      status: instructor.status,
      accessibility: instructor.id
    })
  );
  return rows;
}

  return (
    <div className={classes.tableContainer}>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(instructors)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          handleChecked(newSelection.length);
          handleSelectedInstructors(newSelection);
        }}
        pageSize={10}
      />
    </div>
  );
};

export default InstructorListTable;
