import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Container, Grid, IconButton, Paper, Typography, Divider, Backdrop, Tabs, Tab, Box, TextField, Tooltip, Button, Snackbar } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import { loadUser } from '../actions/auth'
import { IoHourglassOutline } from "react-icons/io5"

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(10),
    },
    avatar: {
        width: theme.spacing(25),
        height: theme.spacing(25),
        backgroundColor: '#7B68EE'
    },
    fileInput: {
        display: 'none'
    },
    cameraIconButton: {
        color: '#0B6AB0',
    },
    gridPaper: {
        margin: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(1)
    },
    inputField: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    editIconButton: {
        color: '#0B6AB0',
    },
    doneIconButton: {
        color: '#4caf50'
    },
    closeIconButton: {
        color: '#f44336'
    },
    photoCameraIcon: {
        position: 'absolute',
        right: 0,
        bottom: 15
    },
    buttonGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    confirmButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3f9142',
            color: '#ffffff',
        },
        marginRight: theme.spacing(1)
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}))
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const MyAccount = ({ loadUser }) => {
    const classes = useStyles()
    const [value, setValue] = useState(0)

    const [fullName, setFullName] = useState('')
    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [photoAvatar, setPhotoAvatar] = useState(false)
    const [avatarText, setAvatarText] = useState('')

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [usernameError, setUsernameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [currentPasswordError, setCurrentPasswordError] = useState(false)
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [currentPasswordMismatch, setCurrentPasswordMismatch] = useState(false)
    const [passwordMismatch, setPasswordMismatch] = useState(false)

    const [usernameHelpText, setUsernameHelpText] = useState('')
    const [emailHelpText, setEmailHelpText] = useState('')
    const [currentPasswordHelpText, setCurrentPasswordHelpText] = useState('')
    const [newPasswordHelpText, setNewPasswordHelpText] = useState('')
    const [confirmPasswordHelpText, setConfirmPasswordHelpText] = useState('')
    const [currentPasswordMismatchHelpText, setCurrentPasswordMismatchHelpText] = useState('')
    const [passwordMismatchHelpText, setPasswordMismatchHelpText] = useState('')

    const [userNameEdit, setUserNameEdit] = useState(false)
    const [firstNameEdit, setFirstNameEdit] = useState(false)
    const [lastNameEdit, setLastNameEdit] = useState(false)
    const [phoneNumberEdit, setPhoneNumberEdit] = useState(false)
    const [emailEdit, setEmailEdit] = useState(false)

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [openNetworkErrorSnackbar, setOpenNetworkErrorSnackbar] = useState(false)

    const [image, setImage] = useState('')
    const [imageFilename, setImageFilename] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleSelectImage = (file) => {
        setImage(file)
        setImageFilename(file.name)
        postAccountImage(file)
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    const handleCloseNetworkErrorSnackbar = () => {
        setOpenNetworkErrorSnackbar(false)
    }

    const postAccountImage = (image) => {
        const uploadImage = new FormData()
        uploadImage.append('image_file', image, image.name)
        axios({
            method: 'PUT',
            url: 'account/user-account/',
            data: uploadImage,
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Image Upload Success ---- ##', response.status);
                    setPhotoAvatar(true)
                    setImage(response.data.image)
                    loadUser()
                } else {
                    console.log('## ---- Image Upload Failed ---- ##', response.status);
                }
            })
            .catch((error) => {
                console.log('## ---- Image Upload Error ---- ##', error);
                setOpenNetworkErrorSnackbar(true)
            })
    }

    const changePassword = async (currentPassword, newPassword, confirmPassword) => {
        await axios({
            method: 'PUT',
            url: 'account/change-password/',
            data: {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }

        })
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Password Change Success ---- ##', response.status);
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                    setOpenSnackbar(true)
                } else {
                    console.log('## ---- Password Change Failed ---- ##', response.status);
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    console.log('## ---- Password Change Error ---- ##', error);
                    if (error.response.data.current_password) {
                        setCurrentPasswordError(true)
                        setCurrentPasswordHelpText(error.response.data.current_password)
                    }

                    if (error.response.data.new_password) {
                        setNewPasswordError(true)
                        setNewPasswordHelpText(error.response.data.new_password)
                    }

                    if (error.response.data.confirm_password) {
                        setConfirmPasswordError(true)
                        setConfirmPasswordHelpText(error.response.data.new_password)
                    }

                    if (error.response.data.mismatch) {
                        setPasswordMismatch(true)
                        setPasswordMismatchHelpText(error.response.data.mismatch)
                    }

                    if (error.response.data.current_password_mismatch) {
                        setCurrentPasswordMismatch(true)
                        setCurrentPasswordMismatchHelpText(error.response.data.current_password_mismatch)
                    }
                }
            })
    }

    const resetPasswordFields = () => {
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')

        setCurrentPasswordError(false)
        setNewPasswordError(false)
        setConfirmPasswordError(false)

        setCurrentPasswordHelpText('')
        setNewPasswordHelpText('')
        setConfirmPasswordHelpText('')

        setCurrentPasswordMismatch(false)
        setPasswordMismatch(false)
    }

    const changePersonalInformation = (username, firstName, lastName, email, phoneNumber) => {
        axios({
            method: 'PUT',
            url: 'account/change-account-information/',
            data: {
                username: username,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone_number: phoneNumber
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Change Information Success ---- ##', response.status);
                    setUsername(response.data.username)
                    setUserNameEdit(false)

                    setFirstName(response.data.first_name)
                    setFirstNameEdit(false)

                    setLastName(response.data.last_name)
                    setLastNameEdit(false)

                    setEmail(response.data.email)
                    setEmailEdit(false)

                    setPhoneNumber(response.data.phone_number)
                    setPhoneNumberEdit(false)

                    setFullName(response.data.user.first_name + ' ' + response.data.user.last_name)

                } else {
                    console.log('## ---- Change Information Failed ---- ##', response.status);
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    if (error.response.data.username) {
                        setUsernameError(true)
                        setUsernameHelpText(error.response.data.username)
                    }

                    if (error.response.data.email) {
                        setEmailError(true)
                        setEmailHelpText(error.response.data.email)
                    }
                }
                console.log('## ---- Change Information Error ---- ##', error);
            })

    }

    useEffect(() => {
        const getUserInformation = async () => {
            setIsLoading(true)
            await axios
                .get('account/user-account/', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false)
                        console.log('## ---- User Info Fetched ---- ##', response.status);
                        setFullName(response.data.user.first_name + ' ' + response.data.user.last_name)
                        setUsername(response.data.user.username)
                        setFirstName(response.data.user.first_name)
                        setLastName(response.data.user.last_name)
                        setEmail(response.data.user.email)
                        setPhoneNumber(response.data.phone_number)
                        if (response.data.image !== null) {
                            setPhotoAvatar(true)
                            setImage(response.data.image)
                        } else {
                            setPhotoAvatar(false)
                            setAvatarText(response.data.user.username.substring(0, 2).toUpperCase())
                        }
                    }
                })
                .catch()
        }
        getUserInformation();
    }, [])

    return (
        <>
            {
                !isLoading &&
                <Container className={classes.container}>
                    <Grid
                        container
                        direction='row'
                        justify='space-around'>
                        <Grid item xs={7} sm={6} md={5} lg={3}>
                            <input
                                accept="image/*"
                                className={classes.fileInput}
                                id="icon-button-file"
                                type="file"
                                onChange={(event) => handleSelectImage(event.target.files[0])} />
                            <label htmlFor="icon-button-file">
                                <IconButton className={classes.cameraIconButton} aria-label="upload picture" component="span">
                                    {photoAvatar ?
                                        <>
                                            <Avatar className={classes.avatar} src={`${contentBaseURL}${image}`} />
                                        </> :
                                        <>
                                            <Avatar className={classes.avatar}>
                                                <Typography variant='h1'>{avatarText}</Typography>
                                            </Avatar>
                                        </>}

                                    <PhotoCamera className={classes.photoCameraIcon} />
                                </IconButton>
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <div className={classes.textContainer}>
                                <Typography variant='h5'>{fullName}</Typography>
                                <Typography variant='body1'>Edit your personal information and change password here</Typography>
                            </div>
                            <Paper variant='outlined' className={classes.paper}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered>
                                    <Tab label='Personal Information' />
                                    <Tab label='Change Password' />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <Grid
                                        container
                                        direction='row'
                                        justify='center'
                                        spacing={1}>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' style={{ textAlign: 'right' }}>Username</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4} lg={5}>
                                            <TextField
                                                fullWidth
                                                disabled={userNameEdit ? false : true}
                                                error={usernameError}
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={username}
                                                helperText={usernameHelpText}
                                                onChange={(event) => setUsername(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} lg={3}>
                                            {userNameEdit ? <> </> :
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton
                                                        className={classes.editIconButton}
                                                        onClick={() => setUserNameEdit(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}

                                            {userNameEdit ?
                                                <>
                                                    <Tooltip title='Done' arrow>
                                                        <IconButton
                                                            className={classes.doneIconButton}
                                                            onClick={() => changePersonalInformation(username, firstName, lastName, email, phoneNumber)}>
                                                            <DoneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Cancel' arrow>
                                                        <IconButton
                                                            className={classes.closeIconButton}
                                                            onClick={() => setUserNameEdit(false)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                : <> </>}

                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>First Name</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4} lg={5}>
                                            <TextField
                                                fullWidth
                                                disabled={firstNameEdit ? false : true}
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={firstName}
                                                onChange={(event) => setFirstName(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} lg={3}>
                                            {firstNameEdit ? <> </> :
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton
                                                        className={classes.editIconButton}
                                                        onClick={() => setFirstNameEdit(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}

                                            {firstNameEdit ?
                                                <>
                                                    <Tooltip title='Done' arrow>
                                                        <IconButton
                                                            className={classes.doneIconButton}
                                                            onClick={() => changePersonalInformation(username, firstName, lastName, email, phoneNumber)}>
                                                            <DoneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Cancel' arrow>
                                                        <IconButton
                                                            className={classes.closeIconButton}
                                                            onClick={() => setFirstNameEdit(false)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </> : <> </>}
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>Last Name</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4} lg={5}>
                                            <TextField
                                                fullWidth
                                                disabled={lastNameEdit ? false : true}
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={lastName}
                                                onChange={(event) => setLastName(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} lg={3}>
                                            {lastNameEdit ? <> </> :
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton
                                                        className={classes.editIconButton}
                                                        onClick={() => setLastNameEdit(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}

                                            {lastNameEdit ?
                                                <>
                                                    <Tooltip title='Done' arrow>
                                                        <IconButton
                                                            className={classes.doneIconButton}
                                                            onClick={() => changePersonalInformation(username, firstName, lastName, email, phoneNumber)}>
                                                            <DoneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Cancel' arrow>
                                                        <IconButton
                                                            className={classes.closeIconButton}
                                                            onClick={() => setLastNameEdit(false)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </> : <> </>}

                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>Email</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4} lg={5}>
                                            <TextField
                                                fullWidth
                                                error={emailError}
                                                disabled={emailEdit ? false : true}
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={email}
                                                helperText={emailHelpText}
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} lg={3}>
                                            {emailEdit ? <> </> :
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton
                                                        className={classes.editIconButton}
                                                        onClick={() => setEmailEdit(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}

                                            {emailEdit ?
                                                <>
                                                    <Tooltip title='Done' arrow>
                                                        <IconButton
                                                            className={classes.doneIconButton}
                                                            onClick={() => changePersonalInformation(username, firstName, lastName, email, phoneNumber)}>
                                                            <DoneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Cancel' arrow>
                                                        <IconButton
                                                            className={classes.closeIconButton}
                                                            onClick={() => setEmailEdit(false)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </> : <> </>}
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>Phone Number</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={4} lg={5}>
                                            <TextField
                                                fullWidth
                                                disabled={phoneNumberEdit ? false : true}
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                value={phoneNumber}
                                                onChange={(event) => setPhoneNumber(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={4} lg={3}>
                                            {phoneNumberEdit ? <> </> :
                                                <Tooltip title='Edit' arrow>
                                                    <IconButton
                                                        className={classes.editIconButton}
                                                        onClick={() => setPhoneNumberEdit(true)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>}

                                            {phoneNumberEdit ?
                                                <>
                                                    <Tooltip title='Done' arrow>
                                                        <IconButton className={classes.doneIconButton}
                                                            onClick={() => changePersonalInformation(username, firstName, lastName, email, phoneNumber)}>
                                                            <DoneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Cancel' arrow>
                                                        <IconButton className={classes.closeIconButton}
                                                            onClick={() => setPhoneNumberEdit(false)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </> : <> </>}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={5}>
                                            {passwordMismatch ? <Alert severity='error'>{passwordMismatchHelpText}</Alert> : <> </>}
                                            {currentPasswordMismatch ? <Alert severity='error'>{currentPasswordMismatchHelpText}</Alert> : <> </>}
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction='row'
                                        justify='center'
                                        spacing={1}>
                                        <Grid item xs={5} sm={5} md={5} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>Current Password</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={5} lg={5}>
                                            <TextField
                                                fullWidth
                                                error={currentPasswordError}
                                                type='password'
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                helperText={currentPasswordHelpText}
                                                value={currentPassword}
                                                onChange={(event) => setCurrentPassword(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={2} md={2} lg={3}></Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>New Password</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={5} lg={5}>
                                            <TextField
                                                fullWidth
                                                error={newPasswordError}
                                                type='password'
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                helperText={newPasswordHelpText}
                                                value={newPassword}
                                                onChange={(event) => setNewPassword(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={2} md={2} lg={3}></Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={4}>
                                            <Box mt={1} pr={1}>
                                                <Typography variant='body1' mt={1} style={{ textAlign: 'right' }}>Confirm Password</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={5} lg={5}>
                                            <TextField
                                                fullWidth
                                                error={confirmPasswordError}
                                                type='password'
                                                id='outlined-basic'
                                                variant='outlined'
                                                size='small'
                                                helperText={confirmPasswordHelpText}
                                                value={confirmPassword}
                                                onChange={(event) => setConfirmPassword(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={1} sm={2} md={2} lg={3}></Grid>

                                    </Grid>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={5} sm={5} md={5} lg={4}></Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={5} className={classes.buttonGrid}>
                                            <Button
                                                className={classes.cancelButton}
                                                color='primary'
                                                onClick={() => resetPasswordFields()}
                                            >Cancel
                                            </Button>
                                            <Button
                                                className={classes.confirmButton}
                                                onClick={() => changePassword(currentPassword, newPassword, confirmPassword)}
                                            >Confirm
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2} lg={3}></Grid>
                                    </Grid>
                                </TabPanel>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                        <Alert onClose={handleCloseSnackbar} severity="success">
                            You have succesfully changed password!
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openNetworkErrorSnackbar} autoHideDuration={6000} onClose={handleCloseNetworkErrorSnackbar}>
                        <Alert onClose={handleCloseNetworkErrorSnackbar} severity="error">
                            Operation failed! Network or server error.
                        </Alert>
                    </Snackbar>
                </Container>
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
    isInstructor: state.auth.isInstructor,
    isStudent: state.auth.isStudent,
    user: state.auth.user
});

export default connect(mapStateToProps, { loadUser })(MyAccount)
