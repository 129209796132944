import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Tabs, Tab, Box, Grid, List, ListItem, ListItemText, Backdrop, CircularProgress } from '@material-ui/core'
import UserRequests from './UserRequests'
import HoldRequests from './HoldRequests'
import RejectedRequests from './RejectedRequests'
import axios from 'axios'
import qs from 'qs'
import './index.css'
import APPLICATION_CONSTANTS from '../../strings'
import { IoHourglassOutline } from "react-icons/io5"

const useStyles = makeStyles((theme) => ({
    sectionHeader: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    headerTypography: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    tabGrid: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const Dashboard = () => {
    const [value, setValue] = useState(0)
    const [newRequests, setNewRequests] = useState([])
    const [holdRequests, setHoldRequests] = useState([])
    const [rejectedRequests, setRejectedRequests] = useState([])

    const [noNewRequest, setNoNewRequest] = useState(false)
    const [noHoldRequest, setNoHoldRequest] = useState(false)
    const [noRejectRequest, setNoRejectRequest] = useState(false)

    const [noNewRequestText, setNoNewRequestText] = useState('')
    const [noHoldRequestText, setNoHoldRequestText] = useState('')
    const [noRejectRequestText, setNoRejectRequestText] = useState('')

    const [backdropOpen, setBackdropOpen] = useState(false)

    const classes = useStyles()

    // Accept request
    const acceptRequest = async (id, tabPanel) => {
        setBackdropOpen(true)
        if (tabPanel == 'New Request') {
            const body = qs.stringify({
                request_id: id,
                approval_status: 'Approved'
            })
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
            await axios
                .put('auth/user-request/', body, config)
                .then((response) => {
                    console.log('## ---- User Accepted ---- ##', response.data);
                    setNewRequests(newRequests.filter((newRequest) => newRequest.request_id !== id))
                    setBackdropOpen(false)
                })
                .catch((error) => {
                    console.log('## ---- User Accept Error ---- ##', error);
                    setBackdropOpen(false)
                })

        } else if (tabPanel == 'On Hold') {
            const body = qs.stringify(
                {
                    request_id: id,
                    approval_status: 'Approved'
                }
            )
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
            await axios
                .put('auth/user-request/', body, config)
                .then((response) => {
                    console.log('## ---- User Accepted ---- ##', response.data);
                    setHoldRequests(holdRequests.filter((holdRequest) => holdRequest.request_id !== id))
                    setBackdropOpen(false)
                })
                .catch((error) => {
                    console.log('## ---- User Accept Error ---- ##', error);
                })
        }
    }

    // Hold request
    const holdRequest = async (id) => {
        setBackdropOpen(true)
        const body = qs.stringify(
            {
                request_id: id,
                approval_status: 'On Hold'
            }
        )
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        await axios
            .put('auth/user-request/', body, config)
            .then((response) => {
                console.log('## ---- User On Hold ---- ##', response.data);
                setNewRequests(newRequests.filter((newRequest) => newRequest.request_id !== id))
                setBackdropOpen(false)
            })
            .catch((error) => {
                console.log('## ---- User On Hold Error ---- ##', error);
            })

    }

    // Reject request
    const rejectRequest = async (id, tabPanel) => {
        setBackdropOpen(true)
        if (tabPanel == 'New Request') {
            const body = qs.stringify(
                {
                    request_id: id,
                    approval_status: 'Rejected'
                }
            )
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
            await axios
                .put('auth/user-request/', body, config)
                .then((response) => {
                    console.log('## ---- User Rejected ---- ##', response.data);
                    setNewRequests(newRequests.filter((newRequest) => newRequest.request_id !== id))
                    setBackdropOpen(false)
                })
                .catch((error) => {
                    console.log('## ---- User Rejected Error ---- ##', error);
                })

        } else if (tabPanel == 'On Hold') {
            const body = qs.stringify(
                {
                    request_id: id,
                    approval_status: 'Rejected'
                }
            )
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
            await axios
                .put('auth/user-request/', body, config)
                .then((response) => {
                    console.log('## ---- User Rejected ---- ##', response.data);
                    setHoldRequests(holdRequests.filter((holdRequest) => holdRequest.request_id !== id))
                    setBackdropOpen(false)
                })
                .catch((error) => {
                    console.log('## ---- User Rejected Error ---- ##', error);
                })
        }
    }

    useEffect(() => {
        const getNewRequests = async () => {
            setBackdropOpen(true)
            await axios
                .get('auth/user-request/', {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    params: {
                        approval_status: 'Not Approved'
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setBackdropOpen(false)
                        if (response.data.length > 0) {
                            setNewRequests(...newRequests, response.data)
                        } else {
                            setNoNewRequest(true)
                            setNoHoldRequest(false)
                            setNoRejectRequest(false)
                            setNoNewRequestText(APPLICATION_CONSTANTS.noDataAvailable)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        getNewRequests();
        const getHoldRequests = async () => {
            await axios
                .get('auth/user-request/', {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    params: {
                        approval_status: 'On Hold'
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.length > 0) {
                            setHoldRequests(...holdRequests, response.data)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        getHoldRequests();
        const getRejectedRequests = async () => {
            await axios
                .get('auth/user-request/', {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    params: {
                        approval_status: 'Rejected'
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.length > 0) {
                            setRejectedRequests(...rejectedRequests, response.data)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        getRejectedRequests();
    }, [])

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                const getNewRequests = async () => {
                    await axios
                        .get('auth/user-request/', {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                            },
                            params: {
                                approval_status: 'Not Approved'
                            }
                        })
                        .then((response) => {
                            if (response.data.length > 0) {
                                setNewRequests(newRequests, response.data)
                            } else {
                                setNoNewRequest(true)
                                setNoHoldRequest(false)
                                setNoRejectRequest(false)
                                setNoNewRequestText(APPLICATION_CONSTANTS.noDataAvailable)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                getNewRequests()
                break;
            case 1:
                const getHoldRequests = async () => {
                    await axios
                        .get('auth/user-request/', {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                            },
                            params: {
                                approval_status: 'On Hold'
                            }
                        })
                        .then((response) => {
                            if (response.data.length > 0) {
                                setHoldRequests(holdRequests, response.data)
                            } else {
                                setNoHoldRequest(true)
                                setNoNewRequest(false)
                                setNoRejectRequest(false)
                                setNoHoldRequestText(APPLICATION_CONSTANTS.noDataAvailable)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                getHoldRequests()
                break;
            case 2:
                const getRejectedRequests = async () => {
                    await axios
                        .get('auth/user-request/', {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                            },
                            params: {
                                approval_status: 'Rejected'
                            }
                        })
                        .then((response) => {
                            if (response.data.length > 0) {
                                setRejectedRequests(rejectedRequests, response.data)
                            } else {
                                setNoRejectRequest(true)
                                setNoHoldRequest(false)
                                setNoRejectRequest(false)
                                setNoRejectRequestText(APPLICATION_CONSTANTS.noDataAvailable)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                getRejectedRequests()
                break;
            default:
                break;
        }
        setValue(newValue);

    };

    return (
        <>
            {
                !backdropOpen &&
                <div>
                    <Grid item xs={4} className={classes.sectionHeader}>
                        <Typography variant='h6' display='inline' className={classes.headerTypography}>User Approval Dashboard</Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center">
                        <Grid item xs={12} className={classes.tabGrid}>
                            <Paper variant='outlined'>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    centered
                                >
                                    <Tab label="New Request" />
                                    <Tab label="On Hold" />
                                    {/* <Tab label="Rejected" />                      */}
                                </Tabs>

                                {/* Show user requests */}
                                {noNewRequest ?
                                    <Paper variant='outlined' square>
                                        <Box height='10vh' display="flex" flexDirection='row' justifyContent='center'>
                                            <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                                                <Typography variant='h6' align='center' style={{ color: '#0B6AB0' }}>{noNewRequestText}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper> :
                                    <TabPanel value={value} index={0}>
                                        <UserRequests
                                            requests={newRequests}
                                            onAccept={acceptRequest}
                                            onHold={holdRequest}
                                            onReject={rejectRequest} />
                                    </TabPanel>
                                }

                                {/* Show on hold requests */}
                                {noHoldRequest ?
                                    <Paper variant='outlined' square>
                                        <Box height='10vh' display="flex" flexDirection='row' justifyContent='center'>
                                            <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                                                <Typography variant='h6' align='center' style={{ color: '#0B6AB0' }}>{noHoldRequestText}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper> :
                                    <TabPanel value={value} index={1}>
                                        <HoldRequests
                                            requests={holdRequests}
                                            onAccept={acceptRequest}
                                            onReject={rejectRequest} />
                                    </TabPanel>
                                }

                                {/* Show rejected requests */}
                                {noRejectRequest ?
                                    <Paper variant='outlined' square>
                                        <Box height='10vh' display="flex" flexDirection='row' justifyContent='center'>
                                            <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                                                <Typography variant='h6' align='center' style={{ color: '#0B6AB0' }}>{noRejectRequestText}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper> :
                                    <TabPanel value={value} index={2}>
                                        <RejectedRequests requests={rejectedRequests} />
                                    </TabPanel>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            }
            <Backdrop
                className={classes.backdrop}
                open={backdropOpen}
                // onClick={() => {
                //     setBackdropOpen(false);
                // }}
            >
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </>
    )
}

export default Dashboard
