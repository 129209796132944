import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Grid, Box, Typography, Tab, Tabs, Dialog, Button,
    Snackbar, Tooltip, TextField, LinearProgress, Backdrop, DialogContent, Badge
} from '@material-ui/core';
import axios from 'axios';
import {
    Player, LoadingSpinner, BigPlayButton, ControlBar,
    PlaybackRateMenuButton, ForwardControl, ReplayControl,
    RemainingTimeDisplay, CurrentTimeDisplay, DurationDisplay, TimeDivider,
    VolumeMenuButton, FullscreenToggle
} from 'video-react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useConfirm } from "material-ui-confirm"
import MuiAlert from '@material-ui/lab/Alert';
import { IoHourglassOutline } from "react-icons/io5"
import moment from "moment"
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import "./index.css";
import "../../../node_modules/video-react/dist/video-react.css";
import Overview from './Overview';
import ExamFeedback from './ExamFeedback';
import PdfViewer from './../PdfViewerSingle';
import FViewer from '../FViewer';
import TextFileReader from "../TextFileReader";
import { ManageComments } from "../ManageCourse/HomeworkViewer";
import GViewer from "../GViewer";
import { userTime } from "../../custom_util";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getFileExt(filename) {
    return filename.toLowerCase().split('.').pop();
}

function isOpenFViewer(ext) {
    var supportedFormats = ['csv', 'xlsx', 'docx'];

    return supportedFormats.indexOf(ext) !== -1;
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(7),
    },
    root: {
        //flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '100vh',
    },
    rootM: {
        //flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        //display: 'flex',
        //minHeight: '100vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#fcfdfe',
    },
    tabsM: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#fcfdfe',
        paddingLeft: theme.spacing(1),
    },
    tab2: {
        '&.MuiTab-root': {
            textTransform: 'none',
            //minWidth: 'auto',
        },
        '&.Mui-selected': {
            fontWeight: 'bold',
            color: '#0B6AB0',
        }
    },
    tab1: {
        '&.MuiTab-root': {
            //textTransform: 'none',
        },
    },
    videoSelector: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        overflow: 'hidden',
    },
    videoSelectorM: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        overflow: 'hidden',
    },
    noteSelector: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        //whiteSpace: 'nowrap',
        overflow: 'hidden',
        //textOverflow: 'ellipsis',
        //backgroundColor: '#fcfdfe',
    },
    noteSelectorM: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        overflow: 'hidden',
    },
    playIcon: {
        color: '#0B6AB0',
    },
    noteIcon: {
        color: '#0B6AB0',
    },
    playButton: {
        color: '#0B6AB0',
    },
    noteButton: {
        color: '#0B6AB0',
    },
    submissionContainer: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        backgroundColor: '#fcfdfe'
    },
    submissionContainerM: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(1),
        backgroundColor: '#fcfdfe'
    },
    contentContainer: {
        padding: theme.spacing(2),
    },
    contentContainerM: {
        padding: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fileIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    commentIcon: {
        color: 'grey',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
}));

function TabPanel(props) {
    const { children, value, index, mobileView, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={mobileView ? 1 : 3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    mobileView: PropTypes.any.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center" mb={2}>
        <Box minWidth={35} mr={2}>
          <Typography variant="body2" color="primary">Uploading</Typography>
        </Box>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function WeekView({ cindex, courseContent, courseContents, setCourseContents, mobileView }) {
    var tabIndex = -1;
    const classes = useStyles();
    const confirm = useConfirm();
    const [value, setValue] = useState(0);
    //const [courseContent, setCourseContent] = useState(courseContent);
    const [isOpenVideoDlg, setIsOpenVideoDlg] = useState(Array.from({length: courseContent.video.length}, i => i = false));
    const [isOpenNotePdfDlg, setIsOpenNotePdfDlg] = useState(Array.from({length: courseContent.lecturenote.length}, i => i = false));
    const [isOpenNoteDocDlg, setIsOpenNoteDocDlg] = useState(Array.from({length: courseContent.lecturenote.length}, i => i = false));
    const [isOpenNoteTxtDlg, setIsOpenNoteTxtDlg] = useState(Array.from({length: courseContent.lecturenote.length}, i => i = false));
    const [isOpenHomeworkPdfDlg, setIsOpenHomeworkPdfDlg] = useState(Array.from({length: courseContent.homework.length}, i => i = false));
    const [isOpenHomeworkDocDlg, setIsOpenHomeworkDocDlg] = useState(Array.from({length: courseContent.homework.length}, i => i = false));
    const [isOpenHomeworkTxtDlg, setIsOpenHomeworkTxtDlg] = useState(Array.from({length: courseContent.homework.length}, i => i = false));
    const [isOpenSubmissionDlg, setIsOpenSubmissionDlg] = useState(Array.from({length: courseContent.homeworksubmission.length}, i => i = false));
    const [isOpenReviewPdfDlg, setIsOpenReviewPdfDlg] = useState(Array.from({length: courseContent.homeworksubreview.length}, i => i = false));
    const [isOpenReviewDocDlg, setIsOpenReviewDocDlg] = useState(Array.from({length: courseContent.homeworksubreview.length}, i => i = false));
    const [isOpenReviewTxtDlg, setIsOpenReviewTxtDlg] = useState(Array.from({length: courseContent.homeworksubreview.length}, i => i = false));
    const [isOpenCommentsDlg, setIsOpenCommentsDlg] = useState(Array.from({length: courseContent.homeworksubmission.length}, i => i = false));
    // const [isCommentSeen, setIsCommentSeen] = useState(Array.from(courseContent.homeworksubmission, (elem, index) => {return elem.is_comment_seen }));
    const [submissionTitle, setSubmissionTitle] = useState("");
    const [errorSubmissionTitle, setErrorSubmissionTitle] = useState("");
    const [uploadFile, setUploadFile] = useState();
    const [isUploadSubmission, setIsUploadSubmission] = useState(true);
    const [submissionFilename, setSubmissionFilename] = useState('No file selected');
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false);
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const [deadlineReached, setDeadlineReached] = useState(false);

    useEffect(() => {
        setInterval(() => {
            if (courseContent.homework_deadline) {
                if (moment() - moment(userTime(courseContent.homework_deadline, "YYYY-MM-DDTHH:mm")) > 0) {
                    setDeadlineReached(true);
                }
                else {
                    setDeadlineReached(false);
                }
            }
        }, 1000);
    }, [courseContent.homework_deadline]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeSubmissionTitle = (newValue) => {
        setSubmissionTitle(newValue);
    };

    const incTabIndex = () => {
        tabIndex++;

        return tabIndex;
    }

    const resetTabIndex = () => {
        tabIndex = -1;
    }

    const handleClickOpenVideo = (index) => {
        var values = [...isOpenVideoDlg];
        values[index] = true;
        setIsOpenVideoDlg(values);
    }

    const handleCloseVideo = (index) => {
        var values = [...isOpenVideoDlg];
        values[index] = false;
        setIsOpenVideoDlg(values);
    }

    const handleClickOpenNote = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenNotePdfDlg];
            values[index] = true;
            setIsOpenNotePdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenNoteDocDlg];
            values[index] = true;
            setIsOpenNoteDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenNoteTxtDlg];
            values[index] = true;
            setIsOpenNoteTxtDlg(values);
        }
    }

    const handleCloseNote = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenNotePdfDlg];
            values[index] = false;
            setIsOpenNotePdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenNoteDocDlg];
            values[index] = false;
            setIsOpenNoteDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenNoteTxtDlg];
            values[index] = false;
            setIsOpenNoteTxtDlg(values);
        }
    }

    const handleClickOpenHomework = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenHomeworkPdfDlg];
            values[index] = true;
            setIsOpenHomeworkPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenHomeworkDocDlg];
            values[index] = true;
            setIsOpenHomeworkDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenHomeworkTxtDlg];
            values[index] = true;
            setIsOpenHomeworkTxtDlg(values);
        }
    }

    const handleCloseHomework = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenHomeworkPdfDlg];
            values[index] = false;
            setIsOpenHomeworkPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenHomeworkDocDlg];
            values[index] = false;
            setIsOpenHomeworkDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenHomeworkTxtDlg];
            values[index] = false;
            setIsOpenHomeworkTxtDlg(values);
        }
    }

    const handleClickOpenSubPdf = (index) => {
        var values = [...isOpenSubmissionDlg];
        values[index] = true;
        setIsOpenSubmissionDlg(values);
    }

    const handleCloseSubPdf = (index) => {
        var values = [...isOpenSubmissionDlg];
        values[index] = false;
        setIsOpenSubmissionDlg(values);
    }

    const handleClickOpenReview = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenReviewPdfDlg];
            values[index] = true;
            setIsOpenReviewPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenReviewDocDlg];
            values[index] = true;
            setIsOpenReviewDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenReviewTxtDlg];
            values[index] = true;
            setIsOpenReviewTxtDlg(values);
        }
    }

    const handleCloseReview = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenReviewPdfDlg];
            values[index] = false;
            setIsOpenReviewPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenReviewDocDlg];
            values[index] = false;
            setIsOpenReviewDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenReviewTxtDlg];
            values[index] = false;
            setIsOpenReviewTxtDlg(values);
        }
    }

    const handleClickOpenComments = (index) => {
        var values = [...isOpenCommentsDlg];
        values[index] = true;
        setIsOpenCommentsDlg(values);

        var values = [...courseContents];
        values[cindex].homeworksubmission[index].is_comment_seen = true;
        setCourseContents(values);
    }

    const handleCloseComments = (index) => {
        var values = [...isOpenCommentsDlg];
        values[index] = false;
        setIsOpenCommentsDlg(values);
    }

    const deleteSubmissionConfirm = (index, id, title) => {
        confirm({description: <Typography varint='body2'>Deleting <strong>{title}</strong>.</Typography>,
                 confirmationText: 'Delete',
                 confirmationButtonProps: {color: 'secondary', variant: 'contained', disableElevation: true},
                 cancellationButtonProps: {variant: 'contained', disableElevation: true},
                })
            .then(() => handleDelete(index, id))
            .catch(() => console.log('Cancelled!'));
    }

    const handleDelete = (index, id) => {
        axios({
            method: "delete",
            url: `courses/homework-submission/${id}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(function (res) {
            if (res.status === 202) {
                console.log('Submission deleted!');
                
                var values = [...courseContents];
                values[cindex].homeworksubmission.splice(index, 1);
                setCourseContents(values);
                
                setOpenDeleteSnackbar(true);
            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleUpload = (id) => {
        const uploadData = new FormData();

        if (submissionTitle) {
            // Show progressbar
            setShowProgress(true);
            uploadData.append('homeworksubmission', uploadFile, uploadFile.name);
            uploadData.append('title', submissionTitle);
            axios({
                method: "POST",
                url: `courses/homework-submission/${id}/`,
                data: uploadData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgress(percentCompleted);
                },
            }).then(function (res) {
                if (res.status === 201) {
                    console.log('Submission uploaded.');
                    
                    // Hide progressbar
                    setShowProgress(false);
                    // Disable upload button
                    setIsUploadSubmission(true);
                    // Clear title
                    setSubmissionTitle("");
                    // Clear error message
                    setErrorSubmissionTitle("");
                    // Add content field
                    var values = [...courseContents];
                    values[cindex].homeworksubmission = [...courseContent.homeworksubmission, {id: res.data.id, title: res.data.title ? res.data.title : 'No title',
                                                                         file: res.data.file, coursecontent: null, student: null, is_comment_seen: true}];
                    setCourseContents(values);
                    setIsOpenSubmissionDlg([...isOpenSubmissionDlg, false]);
                    setIsOpenCommentsDlg([...isOpenCommentsDlg, false]);
                                                                        
                    // Clear added file
                    setSubmissionFilename('No file selected');

                    setOpenUploadSnackbar(true);
                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);

                // Hide progressbar
                setShowProgress(false);
                setIsUploadSubmission(true);
            });
        }
        else {
            setErrorSubmissionTitle('Title must not be kept blank');
        }
    
    }

    const handleSelect = (file) => {
        // Set file for upload
        setUploadFile(file);

        // Enable upload button
        setIsUploadSubmission(false);

        // Set filename
        setSubmissionFilename(file.name);
    }

    const getDurationFormatted = (seconds) => {
        if (seconds > 3600) {
            var hour = Math.floor(seconds / 3600);
            var min = Math.floor((seconds - (hour * 3600)) / 60);
            var sec = seconds - hour * 3600 - min * 60;
            
            return `${hour} hr ${min} min`;
        }
        if (seconds > 60) {
            var min = Math.floor(seconds / 60);
            var sec = seconds - min * 60;
            
            return `${min} min ${sec} sec`;
        }
        else {
            return `${seconds} sec`;
        }
    }

    const handleCloseUploadSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUploadSnackbar(false);
    };

    const handleCloseDeleteSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };
  
    return (
      <Paper elevation={0} square>
        <Tabs
          value={value}
          indicatorColor='none'
          textColor="primary"
          onChange={handleChange}
          aria-label="week-view"
          variant="scrollable"
          centered
        >
          {courseContent.video.length && <Tab className={classes.tab2} label="Video" />}
          {courseContent.lecturenote.length && <Tab className={classes.tab2} label="Lecture Note" />}
          {courseContent.homework.length && <Tab className={classes.tab2} label="Homework" />}
        </Tabs>

        {resetTabIndex()}
        {courseContent.video.length ?
            <TabPanel value={value} index={incTabIndex()} mobileView={mobileView}>
                {courseContent.video.map((video, index) => (
                    <div>
                        <Paper
                            className={mobileView ? classes.videoSelectorM : classes.videoSelector}
                            variant='outlined'
                            square
                            onClick={() => handleClickOpenVideo(index)}
                        >
                            <Box display='flex' flexDirection='row' justifyContent='space-between'>
                                <Box display='flex' flexDirection='row'>
                                    <PlayCircleOutlineIcon className={classes.playIcon} />
                                    <Box display='flex' flexDirection='column'>
                                        {mobileView ?
                                            <Typography noWrap variant='body1' display='inline' style={{ marginLeft: '10px', width: '50vw' }}>{video.title}</Typography>
                                            :
                                            <Typography variant='body1' display='inline' style={{ marginLeft: '10px' }}>{video.title}</Typography>
                                        }
                                        <Typography variant="caption" color="textSecondary" style={{ marginLeft: '10px' }}>{video.duration ? getDurationFormatted(video.duration) : '0 sec'}</Typography>
                                    </Box>
                                </Box>
                                <Button variant="text" color="default" className={classes.playButton}>
                                    Play
                                </Button>
                            </Box>
                        </Paper>
                        <Dialog maxWidth={false} scroll='body' open={isOpenVideoDlg[index]} onClose={() => handleCloseVideo(index)}>
                            <Box width={mobileView ? '100vw' : '60vw'} onContextMenu={e => e.preventDefault()} alignItems='center'>
                                <Player
                                    src={`${contentBaseURL}${video.file}`}
                                >
                                    <LoadingSpinner />
                                    <BigPlayButton position="center" />
                                    <ControlBar>
                                        <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={8} />
                                        <ReplayControl seconds={10} order={2} />
                                        <ForwardControl seconds={10} order={3} />
                                        {mobileView && <VolumeMenuButton disabled />}
                                        {mobileView && <CurrentTimeDisplay disabled />}
                                        {mobileView && <TimeDivider disabled />}
                                        {mobileView && <DurationDisplay disabled />}
                                        {mobileView && <RemainingTimeDisplay order={4} />}
                                        {/* {mobileView && <FullscreenToggle order={0} />} */}
                                    </ControlBar>
                                </Player>
                            </Box>
                        </Dialog>
                    </div>
                ))}
            </TabPanel>
            :
            <></>
        }

        {courseContent.lecturenote.length ?
            <TabPanel value={value} index={incTabIndex()} mobileView={mobileView}>
                {courseContent.lecturenote.map((lecturenote, index) => (
                    <div>
                        <Paper
                            className={mobileView ? classes.noteSelectorM : classes.noteSelector}
                            variant='outlined'
                            square
                            onClick={() => handleClickOpenNote(index, lecturenote.file)}
                        >
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                <Box display='flex' flexDirection='row'>
                                    <DescriptionOutlinedIcon className={classes.noteIcon} />
                                    {mobileView ?
                                        <Typography noWrap variant='body1' display='inline' style={{ marginLeft: '10px', width: '50vw' }}>{lecturenote.title}</Typography>
                                        :
                                        <Typography variant='body1' display='inline' style={{ marginLeft: '10px' }}>{lecturenote.title}</Typography>
                                    }
                                </Box>
                                <Button variant="text" color="default" className={classes.noteButton}>
                                    View
                                </Button>
                            </Box>
                        </Paper>
                        <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenNotePdfDlg[index]} onClose={() => handleCloseNote(index, lecturenote.file)}>
                            <PdfViewer file={`${contentBaseURL}${lecturenote.file}`} />
                        </Dialog>
                        <Dialog maxWidth={false} scroll='body' open={isOpenNoteDocDlg[index]} onClose={() => handleCloseNote(index, lecturenote.file)} fullWidth>
                            <FViewer file={`${contentBaseURL}${lecturenote.file}`} type={getFileExt(lecturenote.file)} />
                        </Dialog>
                        <Dialog maxWidth={false} scroll='body' open={isOpenNoteTxtDlg[index]} onClose={() => handleCloseNote(index, lecturenote.file)} fullWidth>
                            <TextFileReader txt={`${contentBaseURL}${lecturenote.file}`}/>
                        </Dialog>
                    </div>
                ))}
            </TabPanel>
            :
            <></>
        }

        {courseContent.homework.length ?
            <TabPanel value={value} index={incTabIndex()} mobileView={mobileView}>                                
                {/* Homework submission section */}
                <Paper variant='outlined' className={mobileView ? classes.submissionContainerM : classes.submissionContainer} square>
                    {showProgress && <LinearProgressWithLabel value={progress} />}
                    {deadlineReached && <Grid item xs={12}>
                        <Typography variant="body2" color="secondary" align="center">Homework submission time is over.</Typography>
                    </Grid>}
                    <Box display="flex" flexDirection="column" mb={1}>
                        <Typography variant="body1" color="initial" style={{ fontWeight: 'bold' }}>Submit Homework</Typography>
                        {courseContent.homework_deadline && <Typography variant="caption" color="initial">Deadline: {userTime(courseContent.homework_deadline, "YYYY-MM-DD HH:mm")}</Typography>}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={0} variant='outlined' square className={mobileView ? classes.contentContainerM : classes.contentContainer}>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Submitted Files</Typography>
                                {courseContent.homeworksubmission.length ?
                                    courseContent.homeworksubmission.map((homeworksubmission, i) => (
                                        <div className={classes.content}>
                                            <Box display='flex' flexDirection='row' alignItems='center'>
                                                <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: mobileView ? '40vw' : '20vw' }}>{homeworksubmission.title ? homeworksubmission.title : 'No title'}</Typography>
                                                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(homeworksubmission.file)}</Typography>
                                            </Box>
                                            <Box display='flex' flexDirection='row' alignItems="center">
                                                <Tooltip title='Comments'>
                                                    <Badge color="secondary" variant="dot" invisible={homeworksubmission.is_comment_seen}>
                                                        <ChatBubbleOutlineIcon className={classes.commentIcon} onClick={() => handleClickOpenComments(i)} />
                                                    </Badge>
                                                </Tooltip>
                                                <Tooltip title='View'>
                                                    <DescriptionOutlinedIcon onClick={() => handleClickOpenSubPdf(i)} className={classes.fileIcon} />
                                                </Tooltip>
                                                <Tooltip title='Download'>
                                                    <a target="_blank" href={`${contentBaseURL}${homeworksubmission.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                                </Tooltip>
                                                {!deadlineReached && <Tooltip title='Delete'>
                                                    <DeleteOutlineOutlinedIcon onClick={() => deleteSubmissionConfirm(i, homeworksubmission.id, homeworksubmission.title)} className={classes.deleteIcon} />
                                                </Tooltip>}
                                                <Dialog maxWidth={false} scroll='paper' open={isOpenSubmissionDlg[i]} onClose={() => handleCloseSubPdf(i)} fullWidth>
                                                    {/* <PdfViewer file={`${contentBaseURL}${homeworksubmission.file}`} /> */}
                                                    <GViewer url={`${contentBaseURL}${homeworksubmission.file}`} />
                                                </Dialog>
                                                <Dialog maxWidth={mobileView ? false : "sm"} scroll='body' open={isOpenCommentsDlg[i]} onClose={() => handleCloseComments(i)} fullWidth>
                                                    {/* <DialogTitle><Typography noWrap variant="body1" display='inline' color="initial">{homeworksubmission.title}</Typography></DialogTitle> */}
                                                    <DialogContent style={{ padding: 0 }}>
                                                        <ManageComments id={homeworksubmission.id}/>
                                                    </DialogContent>
                                                </Dialog>
                                            </Box>
                                        </div>
                                    ))
                                    :
                                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.uploadContainer}>
                            <div style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center' }}>
                                <div>
                                    <TextField 
                                        label="Set Submission Title"
                                        value={submissionTitle}
                                        onChange={(e) => handleChangeSubmissionTitle(e.target.value)}
                                        style={{ marginBottom: '10px' }}
                                        size="small"
                                        error={errorSubmissionTitle ? errorSubmissionTitle.length === 0 ? false : true : false }
                                        helperText={errorSubmissionTitle}
                                        fullWidth
                                        variant='outlined'
                                        disabled={deadlineReached}
                                    />
                                    <div style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row' }}>
                                        <Button
                                            variant="contained"
                                            size='small'
                                            color="primary"
                                            onClick={() => handleUpload(courseContent.id)}
                                            disabled={isUploadSubmission}
                                            startIcon={<CloudUploadOutlinedIcon />}
                                            style={{ marginBottom: mobileView ? '5px' : '0px' }}
                                            className={classes.uploadButton}
                                            disableElevation

                                        >
                                            Upload
                                        </Button>
                                        <Button className={classes.addContentButton} variant="outlined" size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: mobileView ? '0px' : '10px' }} disableElevation disabled={deadlineReached}>
                                            Add Submission File
                                            <input
                                                type="file"
                                                onChange={(e) => handleSelect(e.target.files[0])}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                    <Typography
                                        variant="caption"
                                        color='textSecondary'
                                        style={{ marginTop: '5px' }}
                                    >
                                        Added file: {submissionFilename}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        {courseContent.homeworksubreview.length ?
                            <Grid item xs={12}>
                                <Box mb={1} mt={2}>
                                    <Typography variant="body1" color="initial" style={{ fontWeight: 'bold' }}>Instructor Feedback</Typography>
                                </Box>
                                {courseContent.homeworksubreview.map((review, index) => (
                                    <div>
                                        <Paper
                                            className={mobileView ? classes.noteSelectorM : classes.noteSelector}
                                            variant='outlined'
                                            square
                                            onClick={() => handleClickOpenReview(index, review.file)}
                                        >
                                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                                <Box display='flex' flexDirection='row'>
                                                    <DescriptionOutlinedIcon className={classes.noteIcon} />
                                                    {mobileView ?
                                                        <Typography noWrap variant='body1' display='inline' style={{ marginLeft: '10px', width: '50vw' }}>{review.title}</Typography>
                                                        :
                                                        <Typography variant='body1' display='inline' style={{ marginLeft: '10px' }}>{review.title}</Typography>
                                                    }
                                                </Box>
                                                <Button variant="text" color="default" className={classes.noteButton}>
                                                    View
                                                </Button>
                                            </Box>
                                        </Paper>
                                        <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenReviewPdfDlg[index]} onClose={() => handleCloseReview(index, review.file)}>
                                            <PdfViewer file={`${contentBaseURL}${review.file}`} />
                                        </Dialog>
                                        <Dialog maxWidth={false} scroll='body' open={isOpenReviewDocDlg[index]} onClose={() => handleCloseReview(index, review.file)} fullWidth>
                                            <FViewer file={`${contentBaseURL}${review.file}`} type={getFileExt(review.file)} />
                                        </Dialog>
                                        <Dialog maxWidth={false} scroll='body' open={isOpenReviewTxtDlg[index]} onClose={() => handleCloseReview(index, review.file)} fullWidth>
                                            <TextFileReader txt={`${contentBaseURL}${review.file}`}/>
                                        </Dialog>
                                    </div>
                                ))}
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>
                </Paper>
                
                <Box mb={1}>
                    <Typography variant="h6" color='textSecondary' style={{ color: '#0B6AB0' }}>Homework Papers</Typography>
                </Box>
                {courseContent.homework.map((homework, index) => (
                    <div>
                        <Paper
                            className={mobileView ? classes.noteSelectorM : classes.noteSelector}
                            variant='outlined'
                            square
                            onClick={() => handleClickOpenHomework(index, homework.file)}
                        >
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                <Box display='flex' flexDirection='row'>
                                    <DescriptionOutlinedIcon className={classes.noteIcon} />
                                    {mobileView ?
                                        <Typography noWrap variant='body1' display='inline' style={{ marginLeft: '10px', width: '50vw' }}>{homework.title}</Typography>
                                        :
                                        <Typography variant='body1' display='inline' style={{ marginLeft: '10px' }}>{homework.title}</Typography>
                                    }
                                </Box>
                                <Button variant="text" color="default" className={classes.noteButton}>
                                    View
                                </Button>
                            </Box>
                        </Paper>
                        <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenHomeworkPdfDlg[index]} onClose={() => handleCloseHomework(index, homework.file)}>
                            <PdfViewer file={`${contentBaseURL}${homework.file}`} />
                        </Dialog>
                        <Dialog maxWidth={false} scroll='body' open={isOpenHomeworkDocDlg[index]} onClose={() => handleCloseHomework(index, homework.file)} fullWidth>
                            <FViewer file={`${contentBaseURL}${homework.file}`} type={getFileExt(homework.file)} />
                        </Dialog>
                        <Dialog maxWidth={false} scroll='body' open={isOpenHomeworkTxtDlg[index]} onClose={() => handleCloseHomework(index, homework.file)} fullWidth>
                            <TextFileReader txt={`${contentBaseURL}${homework.file}`}/>
                        </Dialog>
                    </div>
                ))}

                <Snackbar open={openUploadSnackbar} autoHideDuration={4000} onClose={handleCloseUploadSnackbar}>
                    <Alert onClose={handleCloseUploadSnackbar} severity="success">
                        File uploaded successfully.
                    </Alert>
                </Snackbar>
                <Snackbar open={openDeleteSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteSnackbar}>
                    <Alert onClose={handleCloseDeleteSnackbar} severity="success">
                        Content deleted.
                    </Alert>
                </Snackbar>
                <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
                    <Alert onClose={handleCloseFailureSnackbar} severity="error">
                        Operation failed!
                    </Alert>
                </Snackbar>
            </TabPanel>
            :
            <></>
        }
      </Paper>
    );
  }


function AccessCourse() {
    var tabIndex = -1;
    const classes = useStyles();
    const { courseId } = useParams();
    const [value, setValue] = useState(0);
    const [courseContents, setCourseContents] = useState([]);
    const [examFeedbacks, setExamFeedbacks] = useState([]);
    const [course, setCourse] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        // checkAuthenticated();
        // loadUser();
        // checkAuthorizedUser();
        const setResponsiveness = () => {
          return window.innerWidth < 900 ?
            setMobileView(true) : setMobileView(false)
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => {
          window.removeEventListener('resize', () => setResponsiveness())
        }
    }, []);

    const incTabIndex = () => {
        tabIndex++;

        return tabIndex;
    }
    
    const resetTabIndex = () => {
        tabIndex = -1;
    }

    useEffect(() => {
        const getData = async () => {
          await axios({
            method: 'GET',
            url: `courses/detail/${courseId}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
          }).then((res) => {
            if (res.status == 200) {
                setCourse(res.data.course);
                setCourseContents(res.data.contents);
                setExamFeedbacks(res.data.examfeedbacks);
                setIsLoading(false);
            }
          }).catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
        }
    
        getData();
    }, []);
  

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.container}>
            {!isLoading ?
                <div className={mobileView ? classes.rootM : classes.root}>
                    <Tabs
                        orientation={mobileView ? "horizontal" : "vertical"}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="weeks"
                        indicatorColor="primary"
                        className={mobileView ? classes.tabsM : classes.tabs}
                        style={{ width: mobileView ? 'auto' : '12vw' }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#0B6AB0"
                            }
                        }}
                    >
                        <Tab label="Overview" className={classes.tab1} />
                        {courseContents.map((courseContent, index) => (
                            (courseContent.video.length || courseContent.lecturenote.length || courseContent.homework.length) &&
                                <Tab label={`Week ${courseContent.week}`} className={classes.tab1} />
                            
                        ))}
                        {examFeedbacks.length && <Tab label="Exam" className={classes.tab1} />}
                    </Tabs>
                    
                    {resetTabIndex()}
                    <TabPanel value={value} index={incTabIndex()} mobileView={mobileView} style={{ width: mobileView ? 'auto' : '88vw' }}>
                        <Overview course={course} mobileView={mobileView} />
                    </TabPanel>
                    {courseContents.map((courseContent, index) => (
                        courseContent.video.length || courseContent.lecturenote.length || courseContent.homework.length ?
                            <TabPanel value={value} index={incTabIndex()} mobileView={mobileView} style={{ width: mobileView ? 'auto' : '88vw' }}>
                                <WeekView
                                    cindex={index}
                                    courseContent={courseContent}
                                    courseContents={courseContents}
                                    setCourseContents={setCourseContents}
                                    mobileView={mobileView}
                                />
                            </TabPanel>
                            :
                            <></>
                    ))}
                    {examFeedbacks.length ?
                        <TabPanel value={value} index={incTabIndex()} mobileView={mobileView} style={{ width: mobileView ? 'auto' : '88vw' }}>
                            <ExamFeedback examFeedbacks={examFeedbacks} mobileView={mobileView} />
                        </TabPanel>
                        :
                        <></>
                    }
                </div>
                :
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                </Backdrop>
            }
        </div>
    );
}

export default AccessCourse