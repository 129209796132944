import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Backdrop, Snackbar, DialogActions, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import qs from 'qs'
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: '#0B6AB0',
    },
    inputTextField: {
        marginBottom: theme.spacing(1)
    },
    addButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3f9142',
            color: '#ffffff',
        }
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}))

const AddInstructorDialog = ({ open, close, handleChangeInstructors }) => {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [occupation, setOccupation] = useState('')
    const [details, setDetails] = useState('')

    const [nameError, setNameError] = useState(false)
    const [contactEmailError, setContactEmailError] = useState(false)

    const [nameHelperText, setNameHelperText] = useState('')
    const [contactEmailHelperText, setContactEmailHelperText] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [openAddSnackbar, setOpenAddSnackbar] = useState(false)
    const [openAddFailedSnackbar, setOpenAddFailedSnackbar] = useState(false)

    const postInstructorData = async (name, contactEmail, occupation, details, close, handleChangeInstructors) => {
        const body = qs.stringify({
            name: name,
            contact_email: contactEmail,
            occupation: occupation,
            details: details
        })
        setIsLoading(true)
        await axios
            .post('instructors/instructor-list/', body, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then((response) => {
                if (response.status === 201) {
                    setOpenAddSnackbar(true)
                    console.log('## ---- Instructor Added Success ---- ##', response.status);
                    handleChangeInstructors(response.data)  // Notify instructors useState that new instructor has been added
                    close()  // Close the dialog
                    setIsLoading(false)
                } else {
                    console.log('## ---- Instructor Added Failed ---- ##', response.status);
                    setOpenAddFailedSnackbar(true)
                }
            })
            .catch((error) => {
                console.log('## ---- Instructor Added Error ---- ##', error);
                setIsLoading(false)
                if (error.response.status === 400) {
                    console.log(error.response.data.name);
                    if (error.response.data.name === '') {
                        setNameError(true)
                        setNameHelperText('This field is required')
                    }
                    if (error.response.data.contact_email === '') {
                        setContactEmailError(true)
                        setContactEmailHelperText('This field is required')
                    }
                } else {
                    setOpenAddFailedSnackbar(true)
                }
            })
    }
    
    const handleCloseInstructorAddSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAddSnackbar(false)
    }

    const handleCloseAddFailedSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAddFailedSnackbar(false)
    }

    return (
        <div>
            <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth='xs'>
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                    <Typography variant='h6'>Add Instructor</Typography>
                </DialogTitle>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    postInstructorData(name, contactEmail, occupation, details, close, handleChangeInstructors)
                }}>
                    <DialogContent dividers>
                        <TextField
                            autoFocus
                            error={nameError}
                            id='name'
                            label='Instructor Name'
                            variant='outlined'
                            size='small'
                            helperText={nameHelperText}
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                            className={classes.inputTextField}
                        />
                        <TextField
                            error={contactEmailError}
                            id="email"
                            label="Contact Email"
                            type="email"
                            size='small'
                            variant='outlined'
                            helperText={contactEmailHelperText}
                            fullWidth
                            onChange={(e) => setContactEmail(e.target.value)}
                            className={classes.inputTextField}
                        />
                        <TextField
                            id="occupation"
                            label="Occupation"
                            variant='outlined'
                            size='small'
                            fullWidth
                            onChange={(e) => setOccupation(e.target.value)}
                            className={classes.inputTextField}
                        />
                        <TextField
                            id="details"
                            label="Details"
                            variant='outlined'
                            size='small'
                            multiline
                            rows={3}
                            rowsMax={4}
                            fullWidth
                            onChange={(e) => setDetails(e.target.value)}
                            className={classes.inputTextField}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={close} color="primary" className={classes.cancelButton}>
                            Cancel
                        </Button>
                        <Button className={classes.addButton} type='submit'>
                            Add
                        </Button>
                    </DialogActions>
                </form>
                <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
            </Dialog>
            <Snackbar open={openAddSnackbar} autoHideDuration={4000} onClose={handleCloseInstructorAddSnackbar}>
                <Alert onClose={handleCloseInstructorAddSnackbar} severity='success'>
                    You have added an instructor
                </Alert>
            </Snackbar>

            <Snackbar open={openAddFailedSnackbar} autoHideDuration={4000} onClose={handleCloseAddFailedSnackbar}>
                <Alert onClose={handleCloseAddFailedSnackbar} severity='error'>
                    Operation failed!
                </Alert>
            </Snackbar>
        </div >
    )
}

export default AddInstructorDialog
