import { AppBar, Box, Dialog, makeStyles, Slide, Toolbar, Typography } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { AiOutlineFolderView } from 'react-icons/ai'
import { FiX } from 'react-icons/fi';
import ExamDurationAddition from './ExamDurationAddition';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "black",
    },
    title: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    xIcon: {
        cursor: 'pointer',
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    container: {
        margin: theme.spacing(2)
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const ExamRunningView = ({ examId, examTitle, open, setOpen }) => {
    const classes = useStyles();
    return (
        <div>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} elevation={0}>
                    <Toolbar variant='dense'>
                        <AiOutlineFolderView />
                        <Typography variant="body1" className={classes.title}>{examTitle}</Typography>
                        <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
                      
                    </Toolbar>
                </AppBar>
                <Box  mt={2} ml={3} mr={3}>
                    <ExamDurationAddition 
                        examId={examId}
                        open={open} 
                    />
                </Box>
            </Dialog>
        </div>
    );
};

export default ExamRunningView;
