import React, { useEffect, useState } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Backdrop, Typography, Paper, TableContainer, Table, TableHead, TableCell, Box, TableBody, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import APPLICATION_CONSTANTS from '../../strings'
import axios from 'axios'
import { IoHourglassOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: '#0B6AB0',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    tableHeader: {
        fontWeight: "bold"
    },
    forceLogoutButton: {
        '&:hover': {
            background: '#fff5f8',
        },
        borderColor: '#f44336',
        color: '#f44336',
        margin: theme.spacing(1),
        textTransform: 'none'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#0B6AB0",
        "&.MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.02)",
        },
      },
}))


const StudentLoginSessionDialog = ({ open, close, studentName, studentId }) => {
    const classes = useStyles()
    // console.log(sessions);
    const [sessions, setSessions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getSessionList = async () => {
            setIsLoading(true)
            await axios({
                method: 'GET',
                url: 'students/user-login-session-list/',
                params: {
                    student_id: studentId,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((result) => {
                if (result.status === 200) {
                    console.log("## ---- User Login Session List Fetch Success --- ##", result.status);
                    setSessions(result.data)
                    setIsLoading(false)
                } else {
                    console.log("## ---- User Login Session List Fetch Failed --- ##", result.status);
                    setIsLoading(false)
                }
                
            }).catch((error) => {
                console.log("## ---- User Login Session List Fetch Error --- ##", error);
                setIsLoading(false)
            })
        }
        if (open) {
            getSessionList()
        }
    }, [open])
    
    const forceLogout = async (sessionId, index) => {
        await axios({
            method: 'PUT',
            url: 'students/user-login-session-list/',
            data: {
                student_id: studentId,
                session_id: sessionId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((result) => {
            if (result.status === 200) {
                console.log("## ---- Force logout success ---- ##", result.status);

                var values = [...sessions]
                values[index].status = result.data.status
                setSessions(values)
            } else {
                console.log("## ---- Force logout failed ---- ##", result.status);
            }
        }).catch((error) => {
            console.log("## ---- Force logout error ---- ##", error);
        })
    }
    return (
        <div>
            {!isLoading && 
            <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth='lg'>
            <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                <Typography variant='h6'>Login Session</Typography>
            </DialogTitle>
            <DialogContent>
                {sessions.length > 0 ?
                    <>
                        <Box margin={1}>
                            <Typography variant='body1'><b>{studentName}</b>{' Login session'}</Typography>
                        </Box>
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                                <TableHead>
                                    <TableCell className={classes.tableHeader}>Device No</TableCell>
                                    <TableCell className={classes.tableHeader}>Address</TableCell>
                                    <TableCell className={classes.tableHeader}>Last Login</TableCell>
                                    <TableCell className={classes.tableHeader}>Last Logout</TableCell>
                                    <TableCell className={classes.tableHeader}>Status</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        sessions.map((sessionRow, index) => (
                                            <TableRow key={sessionRow.device_count}>
                                                <TableCell>{sessionRow.device_count}</TableCell>
                                                <TableCell>{sessionRow.address}</TableCell>
                                                <TableCell>{sessionRow.login_time}</TableCell>
                                                <TableCell>{sessionRow.logout_time}</TableCell>
                                                {sessionRow.status ?
                                                    <TableCell style={{ color: 'green', }}>
                                                        <Grid container direction='row'
                                                        justifyContent="center"
                                                        alignItems="center">
                                                            <Grid item xs={3} sm={3}>
                                                                Online
                                                            </Grid>
                                                            <Grid item xs={9} sm={9}>
                                                                <Button 
                                                                    className={classes.forceLogoutButton} 
                                                                    variant='outlined'
                                                                    size='small'
                                                                    onClick={() => forceLogout(sessionRow.id, index)}>
                                                                    Force Logout
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </TableCell> :
                                                    <TableCell style={{ color: 'red', }}>Offline</TableCell>
                                                }

                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <Typography
                        variant='h6'
                        align='center'
                        style={{ color: '#0B6AB0' }}>
                        {APPLICATION_CONSTANTS.noDataAvailable}
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancelButton} color="primary" onClick={close}>Cancel</Button>
            </DialogActions>
            </Dialog>
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </div>
    )
}

export default StudentLoginSessionDialog
