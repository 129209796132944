import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Document, Page, pdfjs } from 'react-pdf'
import ControlPanel from './ControlPanel'
import { Paper } from '@material-ui/core';
import { composeClasses } from '@material-ui/data-grid';
import { CallMissedSharp } from '@material-ui/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  page: {
  },
  document: {
  }
}))

const PdfViewerSingle = ({ file }) => {
  const classes = useStyles()
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileView, setMobileView] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  useEffect(() => {
      // checkAuthenticated();
      // loadUser();
      // checkAuthorizedUser();
      const setResponsiveness = () => {
        return window.innerWidth < 900 ?
          setMobileView(true) : setMobileView(false)
      }
      setResponsiveness();
      window.addEventListener('resize', () => setResponsiveness())

      return () => {
        window.removeEventListener('resize', () => setResponsiveness())
      }
  }, []);

  return (
    <div>
        <ControlPanel
            scale={scale}
            setScale={setScale}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            mobileView={mobileView}
        />
        <Document
            file={file}
            className="pdf-container"
            onContextMenu={(e) => e.preventDefault()}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            <Page className={classes.page} pageNumber={pageNumber} scale={scale} width={mobileView ? window.innerWidth*0.95 : window.innerWidth*0.5} />
        </Document>
    </div>
  )
}

export default PdfViewerSingle
