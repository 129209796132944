import { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Snackbar, Backdrop,TextField, Paper,
            Tooltip, Chip } from '@material-ui/core';
import { IoHourglassOutline } from "react-icons/io5";
import MuiAlert from '@material-ui/lab/Alert';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill, {Quill} from 'react-quill';
import ImageUploader from "quill-image-uploader";
import ImageResize from 'quill-image-resize-module-react';
import axios from "axios";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import qs from 'qs';

//import 'react-quill/dist/quill.snow.css';
import "./../index.css";
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageUploader", ImageUploader);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '100%',
        padding: 8,
        border: '0.5px solid #ccc',
        '&::placeholder': {
            color: "rgba(0,0,0,0.6)",
        },
        '&:focus': {
            outline: 'none !important',
        },
        resize: 'none',
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    addAnotherBtn: {
        textTransform: 'none',
        backgroundColor: '#00BC9E',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#00BC9E',
        }
    },
    saveBtn: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#0B6AB0',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        color: '#0B6AB0',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        },
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
        fontSize: "21px"
    },
    deleteButton:{
        margin: 0,
        "&:hover":{
            backgroundColor: 'transparent'
        }
    },
    contentContainer: {
        padding: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    fileList: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(0.5),
        borderColor: "#0B6AB0",
        // color: 'white',
        // '&:hover': {
        //     backgroundColor: '#A9A9A9',
        // },
        // '&:focus': {
        //     backgroundColor: '#A9A9A9',
        // },
    },
}));

const modules = {
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", "image"],
        [
            { list: "ordered" },
            { list: "bullet" },
            //{ indent: "-1" },
            //{ indent: "+1" }
        ],
        //["link", "image", "video"],
        // ["image"],
        ["clean"]
    ],
    imageUploader:{
        upload: file => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("name", "test");
                formData.append("question", 1);
                axios({
                    method: 'POST',
                    url: `exams/exam-question-picture-upload/`,
                    data: formData,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                }).then(response => {
                    resolve(response.data.file)
                })
                .catch(error =>{ 
                    reject("Upload Rejected")
                });
            })
        }
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};

const CreateDescrQDlg = ({ examId, open, setOpen, questions, setQuestions, update=false, questionId=null, updateQuestion }) => {  // questionId for update only
    const classes = useStyles();
    const [addAnotherEnabled, setAddAnotherEnabled] = useState(true);
    const [qText, setQText] = useState('');
    const [marks, setMarks] = useState('');
    const [note, setNote] = useState('');

    const [errorQText, setErrorQText] = useState("");
    const [errorMarks, setErrorMarks] = useState("");

    const [openCreateSnack, setOpenCreateSnack] = useState(false);
    const [openUpdateSnack, setOpenUpdateSnack] = useState(false);
    const [openFailureSnack, setOpenFailureSnack] = useState(false);
    const [image, setImage] = useState({file:undefined, name:undefined});
    const [images, setImages] = useState([]);
    const [openFileCreateSnack, setOpenFileCreateSnack] = useState(false)
    const [openFileDeleteSnack, setOpenFileDeleteSnack] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        init();
        const getData = async () => {
            setIsLoading(true)
            await axios({
                method: 'GET',
                url: `exams/question-details/${questionId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status == 200) {
                    console.log(res.data)
                if (res.data.type === 'Descriptive') {
                    setQText(res.data.descriptive_q.q_text);
                    setMarks(res.data.descriptive_q.marks);
                    setNote(res.data.descriptive_q.note);
                    setImages(res.data.question_picture)
                }
                setIsLoading(false)
            }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
        }
        if (open && update)
          getData();
    }, [open]);

    const init = () => {
        setQText('');
        setMarks('');
        setNote('');

        setErrorQText("");
        setErrorMarks("");
    }
    
    const handleSave = (addAnother) => {
        if (validate()) {
            if (addAnother) {
                setAddAnotherEnabled(false);
            }
            else {
                setOpen(false);
            }
            setIsLoading(true)
            axios({
                method: 'POST',
                url: `exams/question-list/${examId}/`,
                data: {
                    type: "Descriptive",
                    q_text: qText,
                    marks: marks,
                    note: note,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                setAddAnotherEnabled(true);
                if (response.status === 201) {
                    console.log('Descriptive question create success!');
                    setQuestions([...questions, response.data]);

                    setQText("");
                    setMarks("");
                    setNote("");
                    setIsLoading(false)
                    setOpenCreateSnack(true);
                } else {
                    console.log('Descriptive question create failed!', response.status);
                    setOpenFailureSnack(true);
                    setIsLoading(false)
                }
            }).catch((error) => {
                setAddAnotherEnabled(true);
                console.log('Descriptive question create error!', error);
                setOpenFailureSnack(true);
                setIsLoading(false)
            })
        }
    }

    const handleUpdate = () => {
        if (validate()) {
            setIsLoading(true)
            axios({
                method: 'PUT',
                url: `exams/question-details/${questionId}/`,
                data: {
                    type: "Descriptive",
                    q_text: qText,
                    marks: marks,
                    note: note,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Descriptive question update success!');
                    updateQuestion(response.data)
                    setOpenUpdateSnack(true);
                    setIsLoading(false)
                } else {
                    console.log('Descriptive question update failed!', response.status);
                    setOpenFailureSnack(true);
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.log('Descriptive question update error!', error);
                setIsLoading(false)
                setOpenFailureSnack(true);
            })
        }
    }

    const validate = () => {
        var validated = true;

        if (qText.trim()) {
            setErrorQText("");
        }
        else {
            setErrorQText('This field cannot be kept blank.');
            validated = false;
        }

        var num = parseFloat(marks);
        if (isNaN(num)) {
            setErrorMarks("This field cannot be kept blank.");
            validated = false;
        }
        else if (num===0) {
            setErrorMarks("Marks must not be a zero.");
            validated = false;
        }
        else if (num < 0) {
            setErrorMarks("Marks must not be negative.");
            validated = false;
        }
        else {
            setErrorMarks("");
        }

        return validated;
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const handleCloseCreateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCreateSnack(false);
    }

    const handleCloseUpdateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUpdateSnack(false);
    }

    const handleCloseFailureSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnack(false);
    }

    const uploadImage = () => {
        const formData = new FormData();
        formData.append("file", image.file);
        formData.append("name", image.name);
        formData.append("question", questionId);
        axios({
            method: 'POST',
            url: `exams/exam-question-picture-upload/`,
            data: formData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(response => {
            setOpenFileCreateSnack(true)
            setImage({file:undefined, name:undefined})
            setImages([...images, response.data])
        })
        .catch(error =>{ 
            console.log("File upload Failed", error.data)
        });
    }

    const deleteImage = (id) => {
        axios({
            method: 'DELETE',
            url: `exams/exam-question-picture-delete/${id}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(response => {
            if(response.status === 204){
                let x = images.filter(file => file.id !== id)
                setImages(x)
                setOpenFileDeleteSnack(true)
                //showError(true, `${fileType === "img"? "Picture" : "File" } Deleted`, "success")
            }
        })
        .catch(err => {
            console.log("File Delete Failed", err.status);
            setIsLoading(false)
        })
    }

    return (
        <>
        <Dialog scroll='body' maxWidth='sm' open={open} onClose={handleClose} fullWidth style={{minHeight: '50vh'}}>
            <DialogContent>
                <Typography variant="h6" color="initial" className={classes.title}>{update ? 'Edit Descriptive Question' : 'Create Descriptive Question'}</Typography>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                </Backdrop>
                <Box>
                    <Typography variant="body2" color="initial">Question</Typography>
                    <ReactQuill
                        theme="snow"
                        value={qText}
                        placeholder='Type your question here...'
                        modules={modules}
                        onChange={(value) => setQText(value)}
                    />
                    <Typography variant='caption' style={{ color: '#C0392B' }}>{errorQText}</Typography>
                </Box>

                <Box mt={2}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box style={{width: "55%"}}>
                            <input
                                className={classes.input}
                                type="text"
                                value={image.name || ""}
                                onChange={(e) => setImage({...image, name:e.target.value})}
                                placeholder='Enter File Title here...'
                            />
                        </Box>
                        <div>
                            <div>
                                <Button
                                    variant="contained"
                                    size='small'
                                    color="primary"
                                    onClick={() => uploadImage()}
                                    disabled={image.file===undefined || image.name===undefined}
                                    startIcon={<CloudUploadOutlinedIcon />}
                                    disableElevation
                                    className={classes.uploadButton}
                                >
                                    Upload
                                </Button>
                                <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                    {/* Add {fileType === "img"? "Image" : "Document" } */}
                                    Add File
                                    <input
                                        type="file"
                                        id="input_field"
                                        accept={"*"}
                                        onChange={(e) => {
                                            setImage({...image, file:e.target.files[0]})
                                            e.target.value = null
                                        }}
                                        
                                        hidden
                                    />
                                </Button>
                            </div>
                            <Typography
                                variant="caption"
                                color='textSecondary'
                                //style={{ marginTop: '2px' }}
                            >
                                Added file: {image.file && image.file.name || "No file selected"}
                            </Typography>
                            </div>
                    </div>
                </Box>

                {images.length ?  <Paper component="ul" elevation={0} className={classes.fileList}>
                    {images.map((file, i) => (
                        <li style={{paddingRight: "5px"}} key={i}>
                            <Chip   
                                label={file.name}
                                onDelete={() => deleteImage(file.id)}
                            />
                        </li>
                    )) }
                </Paper>: <></>}

                <Box mt={2}>
                    <Typography variant="body2" color="initial">Marks</Typography>
                    <input
                        className={classes.input}
                        type='number'
                        value={marks}
                        placeholder='Type question marks here...'
                        onChange={(e) => setMarks(e.target.value)}
                    />
                    <Typography variant='caption' style={{ color: '#C0392B' }}>{errorMarks}</Typography>
                </Box>

                <Box mt={2}>
                    <Typography variant="body2" color="initial">Note for Grader</Typography>
                    <textarea
                        className={classes.input}
                        rows={3}
                        type='number'
                        value={note}
                        placeholder='Type note here...'
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Box>
                
                {update ?
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={2}>
                        <Button
                            className={classes.cancelBtn}
                            color="primary"
                            size="small"
                            onClick={() => setOpen(false)}
                            disableElevation
                        >
                            Close
                        </Button>
                        <Button
                            className={classes.saveBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </Box>
                    :
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={2}>
                        <Button
                            className={classes.cancelBtn}
                            color="primary"
                            size="small"
                            onClick={() => setOpen(false)}
                            disableElevation
                        >
                            Close
                        </Button>
                        <Button
                            className={classes.addAnotherBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disabled={!addAnotherEnabled}
                            disableElevation
                            onClick={() => handleSave(true)}
                        >
                            Save & Add Another
                        </Button>
                        <Button
                            className={classes.saveBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={() => handleSave(false)}
                        >
                            Save
                        </Button>
                    </Box>
                }
                {/* </>} */}
            </DialogContent>
        </Dialog>
        
        <Snackbar open={openCreateSnack} autoHideDuration={4000} onClose={handleCloseCreateSnack}>
            <Alert onClose={handleCloseCreateSnack} severity="success">
                Question added.
            </Alert>
        </Snackbar>
        <Snackbar open={openFileCreateSnack} autoHideDuration={4000} onClose={() => setOpenFileCreateSnack(false)}>
            <Alert onClose={() => setOpenFileCreateSnack(false)} severity="success">
                File added.
            </Alert>
        </Snackbar>
        <Snackbar open={openFileDeleteSnack} autoHideDuration={4000} onClose={() => setOpenFileDeleteSnack(false)}>
            <Alert onClose={() => setOpenFileDeleteSnack(false)} severity="error">
                File deleted.
            </Alert>
        </Snackbar>
        <Snackbar open={openUpdateSnack} autoHideDuration={4000} onClose={handleCloseUpdateSnack}>
            <Alert onClose={handleCloseUpdateSnack} severity="success">
                Question updated.
            </Alert>
        </Snackbar>
        <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
            <Alert onClose={handleCloseFailureSnack} severity="error">
                Operation failed!
            </Alert>
        </Snackbar>

        {/* <Backdrop className={classes.backdrop} open={true}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
        </Backdrop> */}
        </>
    )
}

export default CreateDescrQDlg;