import React, { useRef } from 'react'
import IdleTimer from 'react-idle-timer'
import { logout } from '../actions/auth'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const UserIdleTimer = ({ logout }) => {
    const history = useHistory()
    const idleTimerRef = useRef(null)
    const onIdle = () => {
        logout()
        history.push("/login")
    }

    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={1800 * 1000} onIdle={onIdle}></IdleTimer>
        </div>
    )
}

export default connect(null, { logout })(UserIdleTimer);
