import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Box, Typography, TextField, Button, LinearProgress, Dialog, Tooltip } from '@material-ui/core';
import { Player, LoadingSpinner, BigPlayButton, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
//import qs from 'qs'
import { DeleteOutlineRounded } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useConfirm } from "material-ui-confirm"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import moment from "moment";

import "../../../node_modules/video-react/dist/video-react.css";
import PdfViewer from '../PdfViewerSingle';
import FViewer from "../FViewer";
import TextFileReader from "../TextFileReader";
import SetHomeworkDeadline from "./SetHomeworkDeadline";
import { userTime } from "../../custom_util";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function getFileExt(filename) {
    return filename.toLowerCase().split('.').pop();
}

function isOpenFViewer(ext) {
    var supportedFormats = ['csv', 'xlsx', 'docx'];

    return supportedFormats.indexOf(ext) !== -1;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        //marginBottom: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    contentHeader: {
        marginBottom: theme.spacing(3),
    },
    contentContainer: {
        padding: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fileIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    playIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(3),
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    dialogTitle: {
        color: '#0B6AB0',
    },
    setDeadlineBtn: {
        color: '#fff',
        backgroundColor: "#0B6AB0",
        //fontWeight: 'bold',
        cursor: 'pointer',
        padding: '2px',
        borderRadius: '2px',
    },
    changeDeadlineBtn: {
        color: '#fff',
        backgroundColor: "#ff9800",
        //fontWeight: 'bold',
        cursor: 'pointer',
        padding: '2px',
        borderRadius: '2px',
        marginLeft: theme.spacing(1),
    }
}));

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center" mb={2}>
            <Box minWidth={35} mr={2}>
                <Typography variant="body2" color="primary">Uploading</Typography>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function WeekContent({ index, privilege, courseContent_, showProgress, progress,
    isUploadVideo, isUploadNote, isUploadHomework,
    videoFilename, noteFilename, homeworkFilename, videoTitle, noteTitle, homeworkTitle, errorVideoTitle,
    errorNoteTitle, errorHomeworkTitle, handleChangeTitle, handleSelect,
    handleUpload, handleDelete, handleDeleteWeek }) {
    const classes = useStyles();
    const confirm = useConfirm();
    const [courseContent, setCourseContent] = useState(courseContent_)

    const [isOpenVideoDlg, setIsOpenVideoDlg] = useState(Array.from({ length: courseContent.video.length }, i => i = false));
    
    const [isOpenNotePdfDlg, setIsOpenNotePdfDlg] = useState(Array.from({ length: courseContent.lecturenote.length }, i => i = false));
    const [isOpenNoteDocDlg, setIsOpenNoteDocDlg] = useState(Array.from({ length: courseContent.lecturenote.length }, i => i = false));
    const [isOpenNoteTxtDlg, setIsOpenNoteTxtDlg] = useState(Array.from({ length: courseContent.lecturenote.length }, i => i = false));
    
    const [isOpenHomeworkPdfDlg, setIsOpenHomeworkPdfDlg] = useState(Array.from({ length: courseContent.homework.length }, i => i = false));
    const [isOpenHomeworkDocDlg, setIsOpenHomeworkDocDlg] = useState(Array.from({ length: courseContent.homework.length }, i => i = false));
    const [isOpenHomeworkTxtDlg, setIsOpenHomeworkTxtDlg] = useState(Array.from({ length: courseContent.homework.length }, i => i = false));
    
    const [isOpenHomeworkDeadlineDlg, setIsOpenHomeworkDeadlineDlg] = useState(false);
    
    const handleClickOpenVideo = (index) => {
        var values = [...isOpenVideoDlg];
        values[index] = true;
        setIsOpenVideoDlg(values);
    }

    const handleCloseVideo = (index) => {
        var values = [...isOpenVideoDlg];
        values[index] = false;
        setIsOpenVideoDlg(values);
    }

    const handleClickOpenNotePdf = (index) => {
        var values = [...isOpenNotePdfDlg];
        values[index] = true;
        setIsOpenNotePdfDlg(values);
    }
    const handleClickOpenNoteDoc = (index) => {
        var values = [...isOpenNoteDocDlg];
        values[index] = true;
        setIsOpenNoteDocDlg(values);
    }
    const handleClickOpenNoteTxt = (index) => {
        var values = [...isOpenNoteTxtDlg];
        values[index] = true;
        setIsOpenNoteTxtDlg(values);
    }

    const handleCloseNotePdf = (index) => {
        var values = [...isOpenNotePdfDlg];
        values[index] = false;
        setIsOpenNotePdfDlg(values);
    }
    const handleCloseNoteDoc = (index) => {
        var values = [...isOpenNoteDocDlg];
        values[index] = false;
        setIsOpenNoteDocDlg(values);
    }
    const handleCloseNoteTxt = (index) => {
        var values = [...isOpenNoteTxtDlg];
        values[index] = false;
        setIsOpenNoteTxtDlg(values);
    }

    const handleClickOpenHomeworkPdf = (index) => {
        var values = [...isOpenHomeworkPdfDlg];
        values[index] = true;
        setIsOpenHomeworkPdfDlg(values);
    }
    const handleClickOpenHomeworkDoc = (index) => {
        var values = [...isOpenHomeworkDocDlg];
        values[index] = true;
        setIsOpenHomeworkDocDlg(values);
    }
    const handleClickOpenHomeworkTxt = (index) => {
        var values = [...isOpenHomeworkTxtDlg];
        values[index] = true;
        setIsOpenHomeworkTxtDlg(values);
    }

    const handleCloseHomeworkPdf = (index) => {
        var values = [...isOpenHomeworkPdfDlg];
        values[index] = false;
        setIsOpenHomeworkPdfDlg(values);
    }
    const handleCloseHomeworkDoc = (index) => {
        var values = [...isOpenHomeworkDocDlg];
        values[index] = false;
        setIsOpenHomeworkDocDlg(values);
    }
    const handleCloseHomeworkTxt = (index) => {
        var values = [...isOpenHomeworkTxtDlg];
        values[index] = false;
        setIsOpenHomeworkTxtDlg(values);
    }

    const deleteConfirm = (i, id, title, type) => {
        confirm({
            title: <Typography variant='h6' className={classes.dialogTitle}>Delete Content</Typography>,
            description: <Typography variant='body1'>Are you sure to delete <strong>{title}</strong>?</Typography>,
            confirmationText: 'Delete',
            confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
            cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
        })
            .then(() => handleDelete(index, i, id, type))
            .catch(() => console.log('Cancelled!'));
    }

    const deleteConfirmWeek = (index, week) => {
        confirm({
            title: <Typography variant='h6' className={classes.dialogTitle}>Delete Week</Typography>, 
            description: <Typography varint='body1'>Are you sure to delete <strong>Week {week}</strong>?</Typography>,
            confirmationText: 'Delete',
            confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
            cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
        })
            .then(() => handleDeleteWeek(index, week))
            .catch(() => console.log('Cancelled!'));
    }
    
    return (
        <Paper elevation={1} className={classes.paper} variant='outlined' square>
            {showProgress && <LinearProgressWithLabel value={progress} />}
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" className={classes.contentHeader}>Week {courseContent.week}</Typography>
                {privilege === 'Admin' || privilege === 'AdminInstructor' ?
                    <Tooltip title='Delete Week'>
                        <ClearIcon className={classes.deleteIcon} onClick={() => deleteConfirmWeek(index, courseContent.week)} />
                    </Tooltip>
                    :
                    <></>
                }
            </Box>

            <Grid container spacing={4}>
                {/* Video section */}
                <Grid item xs={12}>
                    <Typography variant="body1" color="initial" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Videos</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper variant='outlined' square className={classes.contentContainer}>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Uploaded Videos</Typography>
                                {courseContent.video.length ?
                                    courseContent.video.map((video, i) => (
                                        <div className={classes.content}>
                                            <Box display='flex' flexDirection='row' alignItems='center'>
                                                <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{video.title ? video.title : 'No title'}</Typography>
                                                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(video.file)}</Typography>
                                            </Box>
                                            <div>
                                                <Tooltip title='Play'>
                                                    <PlayCircleFilledWhiteOutlinedIcon onClick={() => handleClickOpenVideo(i)} className={classes.playIcon} />
                                                </Tooltip>
                                                <Tooltip title='Download'>
                                                    <a target="_blank" href={`${contentBaseURL}${video.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                                </Tooltip>
                                                {privilege === 'Admin' || privilege === 'AdminInstructor' ?
                                                    <Tooltip title='Delete'>
                                                        <DeleteOutlineOutlinedIcon onClick={() => deleteConfirm(i, video.id, video.title, 'video')} className={classes.deleteIcon} />
                                                    </Tooltip>
                                                    :
                                                    <></>
                                                }
                                                <Dialog maxWidth={false} scroll='body' open={isOpenVideoDlg[i]} onClose={() => handleCloseVideo(i)}>
                                                    <Box width='60vw' onContextMenu={e => e.preventDefault()} alignItems='center'>
                                                        <Player
                                                            src={`${contentBaseURL}${video.file}`}
                                                        >
                                                            <LoadingSpinner />
                                                            <BigPlayButton position="center" />
                                                            <ControlBar>
                                                                <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={8} />
                                                                <ReplayControl seconds={10} order={2} />
                                                                <ForwardControl seconds={10} order={3} />
                                                            </ControlBar>
                                                        </Player>
                                                    </Box>
                                                </Dialog>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.uploadContainer}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        variant='outlined'
                                        label="Set Video Title"
                                        value={videoTitle}
                                        onChange={(e) => handleChangeTitle(index, e.target.value, 'video')}
                                        style={{ marginBottom: '10px' }}
                                        size="small"
                                        error={errorVideoTitle ? errorVideoTitle.length === 0 ? false : true : false}
                                        helperText={errorVideoTitle}
                                        fullWidth
                                    />
                                    <div>
                                        <Button
                                            variant="contained"
                                            size='small'
                                            color="primary"
                                            onClick={() => handleUpload(index, courseContent.id, 'video')}
                                            disabled={isUploadVideo}
                                            startIcon={<CloudUploadOutlinedIcon />}
                                            disableElevation
                                            className={classes.uploadButton}
                                        >
                                            Upload
                                        </Button>
                                        <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                            Add Video
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={(e) => handleSelect(index, e.target.files[0], 'video')}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                    <Typography
                                        variant="caption"
                                        color='textSecondary'
                                        style={{ marginTop: '5px' }}
                                    >
                                        Added file: {videoFilename}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Lecture note section  */}
                <Grid item xs={12}>
                    <Typography variant="body1" color="initial" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Lecture Notes</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={0} variant='outlined' square className={classes.contentContainer}>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Uploaded Notes</Typography>
                                {courseContent.lecturenote.length ?
                                    courseContent.lecturenote.map((lecturenote, i) => (
                                        <div className={classes.content}>
                                            <Box display='flex' flexDirection='row' alignItems='center'>
                                                <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{lecturenote.title ? lecturenote.title : 'No title'}</Typography>
                                                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(lecturenote.file)}</Typography>
                                            </Box>
                                            <div>
                                                {
                                                    (() => {
                                                        if (`${lecturenote.file}`.toLowerCase().endsWith('.pdf')) {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenNotePdf(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                        else if (isOpenFViewer(getFileExt(lecturenote.file))) {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenNoteDoc(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                        else if (getFileExt(lecturenote.file) === 'txt') {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenNoteTxt(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                    })()
                                                }
                                                <Tooltip title='Download'>
                                                    <a target="_blank" href={`${contentBaseURL}${lecturenote.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                                </Tooltip>
                                                {privilege === 'Admin' || privilege === 'AdminInstructor' ?
                                                    <Tooltip title='Delete'>
                                                        <DeleteOutlineOutlinedIcon onClick={() => deleteConfirm(i, lecturenote.id, lecturenote.title, 'note')} className={classes.deleteIcon} />
                                                    </Tooltip>
                                                    :
                                                    <></>
                                                }
                                                <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenNotePdfDlg[i]} onClose={() => handleCloseNotePdf(i)}>
                                                    <PdfViewer file={`${contentBaseURL}${lecturenote.file}`} />
                                                </Dialog>
                                                <Dialog maxWidth={false} scroll='body' open={isOpenNoteDocDlg[i]} onClose={() => handleCloseNoteDoc(i)} fullWidth>
                                                    <FViewer file={`${contentBaseURL}${lecturenote.file}`} type={getFileExt(lecturenote.file)} />
                                                </Dialog>
                                                <Dialog maxWidth={false} scroll='body' open={isOpenNoteTxtDlg[i]} onClose={() => handleCloseNoteTxt(i)} fullWidth>
                                                    <TextFileReader txt={`${contentBaseURL}${lecturenote.file}`} />
                                                </Dialog>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.uploadContainer}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        label="Set Note Title"
                                        value={noteTitle}
                                        onChange={(e) => handleChangeTitle(index, e.target.value, 'note')}
                                        style={{ marginBottom: '10px' }}
                                        size="small"
                                        error={errorNoteTitle ? errorNoteTitle.length === 0 ? false : true : false}
                                        helperText={errorNoteTitle}
                                        fullWidth
                                        variant='outlined'
                                    />
                                    <div>
                                        <Button
                                            variant="contained"
                                            size='small'
                                            color="primary"
                                            onClick={() => handleUpload(index, courseContent.id, 'note')}
                                            disabled={isUploadNote}
                                            startIcon={<CloudUploadOutlinedIcon />}
                                            disableElevation
                                            className={classes.uploadButton}
                                        >
                                            Upload
                                        </Button>
                                        <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                            Add Note
                                            <input
                                                type="file"
                                                accept=".pdf, .docx, .xlsx, .csv, .txt"
                                                onChange={(e) => handleSelect(index, e.target.files[0], 'note')}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                    <Typography
                                        variant="caption"
                                        color='textSecondary'
                                        style={{ marginTop: '5px' }}
                                    >
                                        Added file: {noteFilename}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Homework section */}
                <Grid item xs={12}>
                    <Box display='flex' flexDirection="row">
                        <Typography variant="body1" color="initial" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Homeworks</Typography>
                        
                        <Box ml={2}>
                            {courseContent.homework_deadline ?
                                <>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography variant="caption" color="initial">Deadline: {userTime(courseContent.homework_deadline, "MM/DD/YYYY hh:mm A")}</Typography>
                                        <Typography
                                            variant="caption"
                                            color="initial"
                                            className={classes.changeDeadlineBtn}
                                            onClick={() => setIsOpenHomeworkDeadlineDlg(true)}
                                        >
                                            Change
                                        </Typography>
                                    </Box>
                                </>
                                :
                                <Typography
                                    variant="caption"
                                    color="initial"
                                    className={classes.setDeadlineBtn}
                                    onClick={() => setIsOpenHomeworkDeadlineDlg(true)}
                                >
                                    Set Deadline
                                </Typography>
                            }
                        </Box>
                    </Box>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={0} variant='outlined' square className={classes.contentContainer}>
                                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Uploaded Homeworks</Typography>
                                {courseContent.homework.length ?
                                    courseContent.homework.map((homework, i) => (
                                        <div className={classes.content}>
                                            <Box display='flex' flexDirection='row' alignItems='center'>
                                                <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{homework.title ? homework.title : 'No title'}</Typography>
                                                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(homework.file)}</Typography>
                                            </Box>
                                            <div>
                                                {
                                                    (() => {
                                                        if (`${homework.file}`.toLowerCase().endsWith('.pdf')) {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenHomeworkPdf(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                        else if (isOpenFViewer(getFileExt(homework.file))) {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenHomeworkDoc(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                        else if (getFileExt(homework.file) === 'txt') {
                                                            return <Tooltip title='View'>
                                                                <DescriptionOutlinedIcon onClick={() => handleClickOpenHomeworkTxt(i)} className={classes.fileIcon} />
                                                            </Tooltip>
                                                        }
                                                    })()
                                                }
                                                <Tooltip title='Download'>
                                                    <a target="_blank" href={`${contentBaseURL}${homework.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                                </Tooltip>
                                                {privilege === 'Admin' || privilege === 'AdminInstructor' || privilege === 'Instructor' ?
                                                    <Tooltip title='Delete'>
                                                        <DeleteOutlineOutlinedIcon onClick={() => deleteConfirm(i, homework.id, homework.title, 'homework')} className={classes.deleteIcon} />
                                                    </Tooltip>
                                                    :
                                                    <></>
                                                }
                                                <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenHomeworkPdfDlg[i]} onClose={() => handleCloseHomeworkPdf(i)}>
                                                    <PdfViewer file={`${contentBaseURL}${homework.file}`} />
                                                </Dialog>
                                                <Dialog maxWidth={false} scroll='body' open={isOpenHomeworkDocDlg[i]} onClose={() => handleCloseHomeworkDoc(i)} fullWidth>
                                                    <FViewer file={`${contentBaseURL}${homework.file}`} type={getFileExt(homework.file)} />
                                                </Dialog>
                                                <Dialog maxWidth={false} scroll='body' open={isOpenHomeworkTxtDlg[i]} onClose={() => handleCloseHomeworkTxt(i)} fullWidth>
                                                    <TextFileReader txt={`${contentBaseURL}${homework.file}`} />
                                                </Dialog>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.uploadContainer}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <div>
                                    <TextField
                                        label="Set Homework Title"
                                        value={homeworkTitle}
                                        onChange={(e) => handleChangeTitle(index, e.target.value, 'homework')}
                                        style={{ marginBottom: '10px' }}
                                        size="small"
                                        error={errorHomeworkTitle ? errorHomeworkTitle.length === 0 ? false : true : false}
                                        helperText={errorHomeworkTitle}
                                        fullWidth
                                        variant='outlined'
                                    />
                                    <div>
                                        <Button
                                            variant="contained"
                                            size='small'
                                            color="primary"
                                            onClick={() => handleUpload(index, courseContent.id, 'homework')}
                                            disabled={isUploadHomework}
                                            startIcon={<CloudUploadOutlinedIcon />}
                                            disableElevation
                                            className={classes.uploadButton}
                                        >
                                            Upload
                                        </Button>
                                        <Button variant='outlined' className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                            Add Homework
                                            <input
                                                type="file"
                                                accept=".pdf, .docx, .xlsx, .csv, .txt"
                                                onChange={(e) => handleSelect(index, e.target.files[0], 'homework')}
                                                hidden
                                            />
                                        </Button>
                                    </div>
                                    <Typography
                                        variant="caption"
                                        color='textSecondary'
                                        style={{ marginTop: '5px' }}
                                    >
                                        Added file: {homeworkFilename}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <SetHomeworkDeadline
                courseContent={courseContent}
                setCourseContent={setCourseContent}
                open={isOpenHomeworkDeadlineDlg}
                setOpen={setIsOpenHomeworkDeadlineDlg}
                dateTime={userTime(courseContent.homework_deadline, "YYYY-MM-DDTHH:mm")}
            />
        </Paper>
    )
}

export default WeekContent