import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import {Grid, Box, Typography, Paper,
    Button,TextField, Tooltip  } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        //marginBottom: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    contentHeader: {
        marginBottom: theme.spacing(3),
    },
    contentContainer: {
        padding: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fileIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    playIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
        fontSize: "21px"
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(3),
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    dialogTitle: {
        color: '#0B6AB0',
    },
    setDeadlineBtn: {
        color: '#fff',
        backgroundColor: "#0B6AB0",
        //fontWeight: 'bold',
        cursor: 'pointer',
        padding: '2px',
        borderRadius: '2px',
    },
    changeDeadlineBtn: {
        color: '#fff',
        backgroundColor: "#ff9800",
        //fontWeight: 'bold',
        cursor: 'pointer',
        padding: '2px',
        borderRadius: '2px',
        marginLeft: theme.spacing(1),
    },
    colorPrimary: {
        backgroundColor: '#aec3d4',
    },
    barColorPrimary: {
        backgroundColor: '#0B6AB0',
    },
    deleteButton:{
        margin: 0,
        "&:hover":{
            backgroundColor: 'transparent'
        }
    }

}));


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };


function PictureUpload({ fileData, examId, showError, question, addFile}) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState()
    const [name, setName] = useState("")
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
  const [uploadOrDownloadCount, setUploadOrDownloadCount] = useState(0);

    useEffect(() => {
        if(fileData.length > 0){
            //let x = fileData[0].files.filter(file => file.file_type === fileType && file)
            setFiles(fileData[0].files);
        }
    },[fileData])
    
    const deleteFile = (id) => {
        setIsLoading(true)
        axios({
            method: 'DELETE',
            url: `exams/file-delete/${id}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(response => {
            if(response.status === 204){
                let x = files.filter(file => file.id !== id)
                setFiles(x)
                setIsLoading(false)
                showError(true, `File Deleted`, "success")
                //showError(true, `${fileType === "img"? "Picture" : "File" } Deleted`, "success")
            }
        })
        .catch(err => {
            console.log("File Delete Failed", err.status);
            setIsLoading(false)
        })
    }

    const uploadFile = () => {
        if(!name){
            setError(true)
        } else {
            // if(fileType === "img" && files.length >= 5){
            //     showError(true, "You uploaded maximum number of file", "error")
            // } else if(fileType === "doc" && files.length >= 1){
            //     showError(true, "You uploaded maximum number of file", "error")
            // }
            if(files.length >=5)
                showError(true, "You uploaded maximum number of file", "error")
            else{
                setIsLoading(true)
                const bodyFormData = new FormData();
                bodyFormData.append("file", file);
                bodyFormData.append("name", name);
                fileData.length && bodyFormData.append("id", fileData[0].id)
                let fileType;
                if(file.type.split("/")[0] === "image")
                    fileType = "img"
                else fileType = "doc"
                bodyFormData.append("file_type", fileType)
    
                axios({
                    method: "POST",
                    url: `exams/file-upload/${examId}/${question}/`,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    data: bodyFormData,
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent
                        let parcent = Math.floor( (loaded * 100) / total )
                        if(parcent <= 100)
                            setUploadOrDownloadCount(parcent)
                    }
                }).then(response => {
                    const last_file = response.data.files.length - 1
                    setFiles([...files, response.data.files[last_file]])
                    // if(response.data.files[last_file].file_type === "img"){
                    //     setFiles([...files, response.data.files[last_file]])
                    // }
                    // else setFiles([response.data.files[last_file]])
                    addFile(response.data, question)
                    setName("")
                    setFile()
                    //showError(true, `${fileType === "img"? "Picture" : "File" } Uploaded`, "success")
                    showError(true, `File Uploaded`, "success")
                    setIsLoading(false)
                    setError(false)
                    setUploadOrDownloadCount(0)
                }).catch(err => {
                    console.log("File Upload Failed ---- ##", err.status)
                    setUploadOrDownloadCount(0)
                    setIsLoading(false)
                    showError(true, err.response.data.error, "error")
                });
            }
        }
    }

    // const totalUploaded = () => {

    //     if(fileType === "img"){

    //     } else{}
    // }
    
    return <Grid container spacing={4}>
  {/* Video section */}
            <Grid item xs={12}>
                <Typography variant="body1" color="initial" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Files</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {(uploadOrDownloadCount <= 100 && uploadOrDownloadCount > 0) && <LinearProgress variant="determinate" value={uploadOrDownloadCount} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>}
                        <Paper variant='outlined' square className={classes.contentContainer}>
                            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Uploaded Files
                                {` (${files.length}/5 Uploaded)`}</Typography>
                                
                            {files.length ?
                                files.map((image, i) => (
                                <div className={classes.content}>
                                    <Box display='flex' flexDirection='row' alignItems='center'>
                                        <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{image.name}</Typography>
                                        <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{image.file.split(".").pop()}</Typography>
                                        <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{image.size}KB</Typography>
                                    </Box>
                                    <div>  
                                    <Tooltip title='Delete'>
                                        <Button startIcon={<DeleteOutlineOutlinedIcon className={classes.deleteIcon} /> }
                                                onClick={() =>deleteFile(image.id)}
                                                className={classes.deleteButton}
                                                disabled={isLoading}></Button>
                                    </Tooltip>   
                                    </div>
                                </div>
                                )) :
                        
                            <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography> }
                            {/* {(uploadOrDownloadCount <= 100 && uploadOrDownloadCount > 0) && <LinearProgressWithLabel value={uploadOrDownloadCount} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>} */}
                            
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.uploadContainer}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div>
                                <TextField
                                    variant='outlined'
                                    label={`Set File Title`}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ marginBottom: '10px' }}
                                    size="small"
                                    error={error}
                                    //   helperText={errorVideoTitle}
                                    fullWidth
                                />
                                <div>
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="primary"
                                        onClick={() => uploadFile()}
                                        disabled={file===undefined || name===undefined}
                                        startIcon={<CloudUploadOutlinedIcon />}
                                        disableElevation
                                        className={classes.uploadButton}
                                    >
                                        Upload
                                    </Button>
                                    <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                        {/* Add {fileType === "img"? "Image" : "Document" } */}
                                        Add File
                                        <input
                                            type="file"
                                            id="input_field"
                                            // accept={fileType === "img" ? "image/*" : "*"}
                                            accept={"*"}
                                            onChange={(e) => {
                                                setFile(e.target.files[0])
                                                e.target.value = null
                                            }}
                                            
                                            hidden
                                        />
                                    </Button>
                                </div>
                                <Typography
                                    variant="caption"
                                    color='textSecondary'
                                    style={{ marginTop: '5px' }}
                                >
                                    Added file: {file && file.name || "No file selected"}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>

  }

export default PictureUpload;
