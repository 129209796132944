import React, { useState } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Grid, Typography, Container, Paper, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import axios from 'axios'
import { connect } from 'react-redux'
import { login } from '../actions/auth'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(18),
    },
    paper: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6)
    },
    grid: {
        padding: theme.spacing(1)
    },
    inputField: {
        margin: theme.spacing(1),
    },
    title: {
        color: '#0B6AB0',
        marginBottom: theme.spacing(1),
    },
    form: {
        textAlign: 'center',
        margin: theme.spacing(2)
    },
    button: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
    },
    linkButton: {
        backgroundColor: '#FFFFFF',
        '&:hover': {
            background: '#FFFFFF',
        },
        color: '#0B6AB0',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
        margin: theme.spacing(1)
    },
    resendCode: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        fontSize: 13,
    }
}))

const TwoFactorAuthentication = ({ login, isAuthenticated }) => {
    const classes = useStyles()
    const location = useLocation()

    var username = location.state.username
    var password = location.state.password

    const [verificationError, setVerificationError] = useState('')

    const [verificationCode, setVerificationCode] = useState('')

    const verify2factorAuth = async () => {
        var deviceVerified = false
        await axios({
            method: 'POST',
            url: `auth/totp-verify/${verificationCode}/`,
            data: {
                username: username
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log('## ---- Two factor verified ---- ##', response.status);
                    deviceVerified = true
                } else {
                    console.log('## ---- Two factor verification failed ---- ##', response.status);
                }
            })
            .catch((error) => {
                console.log('## ---- Two factor verification error ---- ##', error);
                if (error.response.status === 400) {
                    if (error.response.data.error) {
                        setVerificationError(true)
                    }
                }
            })

        if (deviceVerified) {
            await login(username, password)
        }
    }

    const sendVerificationCode = async () => {
        await axios({
            method: 'POST',
            url: 'auth/create-totp/',
            data: {
                username: username
            }
        })
        .then((response) => {
            if (response.status === 201) {
                console.log('## ---- Verification code sent ---- ##', response.status);
            }
        })
        .catch((error) => {
            console.log('## ---- Verification code sending error ---- ##', error);
        })
    }

    if (isAuthenticated) {
        return <Redirect to='/' />
    }

    let errorMsg = <> </>
    if (verificationError) {
        errorMsg = <Alert severity="error" className={classes.alert}>Verification code is not valid</Alert>
    }

    const onFormSubmit = (event) => {
        event.preventDefault()
        verify2factorAuth()
    }

    return (

        <Container className={classes.container}>
            <Paper variant='outlined' className={classes.paper}>
                <Grid
                    container
                    direction='row'
                    justify='center'
                    spacing={2}
                    className={classes.grid}>
                    <Grid item xs={9} sm={8} md={8} lg={7}>
                        <Typography variant='h6' style={{ textAlign: 'center' }} className={classes.title}>Two factor authentication</Typography>
                        <Typography variant='body1' style={{ textAlign: 'center' }}>A verification code has been sent to your email. This code will be valid for 30 seconds</Typography>
                        <Grid
                            container
                            direction='row'
                            justify='center'>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                {errorMsg}
                                <form action='#' onSubmit={onFormSubmit} className={classes.form}>
                                    <div className={classes.inputField}>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            label="Verification Code"
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            onChange={(event) => setVerificationCode(event.target.value)}>
                                        </TextField>
                                    </div>
                                    <Grid
                                        container
                                        direction='row'
                                        justify='center'>
                                        <Grid item xs={6} sm={6} md={6} lg={6}>
                                            <Button
                                                fullWidth
                                                type='submit'
                                                variant="contained"
                                                className={classes.button}
                                            >Confirm
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        className={classes.linkButton}
                                        style={{ textTransform: 'None' }}
                                        onClick={() => sendVerificationCode()}>
                                        Resend Code?
                                    </Button>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    networkError: state.auth.networkError,
});

export default connect(mapStateToProps, { login })(TwoFactorAuthentication)
