import { Box, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types';
import { useState } from 'react'
import PerExamProgress from './PerExamProgress';
import CumulativeProgress from './CumulativeProgress';


const useStyles = makeStyles((theme) => ({
    examPaper: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ExamDashboard = ({ courseId }) => {
    const classes = useStyles()

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

  return (
    <Paper elavation={0} square variant='outlined' className={classes.examPaper}>
        <Box  p={3}>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                centered>
                <Tab label='Individual Exam Progress' />
                <Tab label='Cumulative Progress' />
            </Tabs>
            <TabPanel value={value} index={0}>
                <PerExamProgress courseId={courseId} />
            </TabPanel>
            <TabPanel  value={value} index={1}>
                <CumulativeProgress courseId={courseId} />
            </TabPanel>
        </Box>
    </Paper>
  )
}

export default ExamDashboard