import './App.css'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import Login from './components/Login'
import Signup from './components/Signup'
import UserIdleTimer from './components/UserIdleTimer'
import AcademicSessions from './components/AcademicSessions'
import AllCourses from './components/AllCourses'
import UserApproval from './components/UserApproval'
import StudentList from './components/StudentList'
import AdminCourses from './components/AdminCourses'
import CreateCourse from './components/CreateCourse'
import InstructorCourses from './components/InstructorCourses'
import ManageCourse from './components/ManageCourse'
import StudentCourses from './components/StudentCourses'
import AccessCourse from './components/AccessCourse'
import Instructors from './components/Instructors'
import CourseDetails from './components/CourseDetails'
import ResetPassword from './components/ResetPassword'
import ResetPasswordConfirm from './components/ResetPasswordConfirm'
import ResetPasswordEmailSent from './components/ResetRequestEmailSent'
import Enrollments from './components/Enrollments'
import MyAccount from './components/MyAccount'
import TwoFactorAuthentication from './components/TwoFactorAuthentication'
import AdminList from './components/AdminList'
import ExamInstruction from './components/ExamInstruction'
import { connect } from 'react-redux'
import { checkAuthenticated, checkAuthorizedUser, loadUser } from './actions/auth'
import { SettingsRemote } from '@material-ui/icons';
import { ConfirmProvider } from "material-ui-confirm"
import EditCourse from './components/EditCourse'
import ExamHistory from './components/ExamHistory'
import ExamQuestion from './components/ExamQuestion'
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL

function App({ isAuthenticated, isAdmin, isInstructor, isStudent, checkAuthenticated, checkAuthorizedUser, loadUser }) {
  const init = () => {
    checkAuthenticated();
    loadUser();
    checkAuthorizedUser();
  }
  init();

  let iniPath

  if (isAdmin) {
    iniPath = '/user-approval'
  } else if (isInstructor) {
    iniPath = '/instructor-courses'
  } else if (isStudent) {
    iniPath = '/all-courses'
  } else {
    iniPath = '/'
  }

  return (
    <ConfirmProvider>
        <UserIdleTimer/>
        {isAuthenticated ?
          <Router>
              <Switch key={"nav"}>
                <Route component={NavBar} />
              </Switch>
            <Switch key={"comps"}>
              <Route exact path='/' render={(props) => {
                return (
                  isAuthenticated ?
                    <Redirect to={iniPath} />
                    :
                    <Redirect to ='/all-courses' />
                )
              }} />
              
              <Route exact path='/all-courses' component={AllCourses} />
              <Route path='/login' component={Login} />
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/two-factor-authentication' component={TwoFactorAuthentication} />
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/reset-password-email-sent' component={ResetPasswordEmailSent} />
              <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPasswordConfirm} /> 
              <Route exact path='/academic-sessions' component={AcademicSessions} />
              <Route exact path='/admin-courses' component={AdminCourses} />
              <Route exact path='/create-course' component={CreateCourse} />
              <Route exact path='/students' component={StudentList} />
              <Route exact path='/user-approval' component={UserApproval} />
              <Route exact path='/instructor-courses' component={InstructorCourses} />
              <Route exact path='/instructor-course/:courseId' component={ManageCourse} />
              <Route exact path='/student-courses' component={StudentCourses} />
              <Route exact path='/exam-history' component={ExamHistory} />
              <Route exact path='/exam-instructions/:examId' component={ExamInstruction} />
              <Route exact path='/exam/:examId' component={ExamQuestion} />
              <Route exact path='/access-course/:courseId' component={AccessCourse} />
              <Route exact path='/instructors' component={Instructors} />
              <Route exact path='/course-details/:courseId' component={CourseDetails} />
              <Route exact path='/enrollments' component={Enrollments} />
              <Route exact path='/my-account' component={MyAccount} />
              <Route exact path='/admins' component={AdminList}/>
              <Route exact path='/edit-course/:courseId/' component={EditCourse}/>
              <Route exact path='/exam-history' component={ExamHistory} />
            </Switch> 
          </Router>
        :
          <Router>
            <Switch key={"nav"}>
              <Route component={NavBar} />
            </Switch>
            <Switch key={"comps"}>
              <Route exact path='/' render={(props) => {
                return (
                  isAuthenticated ?
                    <Redirect to={iniPath} />
                    :
                    <Redirect to ='/all-courses' />
                )
              }} />
              <Route exact path='/all-courses' component={AllCourses} />
              <Route exact path='/course-details/:courseId' component={CourseDetails} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/two-factor-authentication' component={TwoFactorAuthentication} />
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/reset-password-email-sent' component={ResetPasswordEmailSent} />
              <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPasswordConfirm} /> 
              <Redirect to='/login' />
            </Switch> 
          </Router>
        } 
      <Footer />
    </ConfirmProvider>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
  isInstructor: state.auth.isInstructor,
  isStudent: state.auth.isStudent
});

export default connect(mapStateToProps, { checkAuthenticated, checkAuthorizedUser, loadUser })(App);