import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Container, Toolbar, Typography, Button, useScrollTrigger, Badge, 
  ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Avatar, 
  IconButton, Drawer, Dialog, DialogTitle, DialogActions, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkAuthenticated, checkAuthorizedUser, loadUser, logout } from '../actions/auth'
import { useHistory } from 'react-router-dom'
import logo from '../images/logo.jpg'
import MenuIcon from '@material-ui/icons/Menu';

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#EDF0F5',
    position: 'fixed'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#008DE9'
  },
  title: {
    flexGrow: 1,
    color: '#0B6AB0',
    marginLeft: theme.spacing(0),
  },
  privilege: {
    color: 'grey',
    marginLeft: theme.spacing(1),
  },
  iconLink: {
    color: '#0B6AB0',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  link: {
    color: '#0B6AB0',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '1 2rem',
    marginRight: theme.spacing(2),
    height: '100%',
    cursor: 'pointer',
    '&.active': {
      fontWeight: 'bold',
    }
  },
  linkMobile: {
    color: '#0B6AB0',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  scrollPaper: {
    alignItems: 'baseline'
  },
  linkSignup: {
    backgroundColor: '#0B6AB0',
    '&:hover': {
      background: '#0B6AB0',
    },
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&.active': {
      color: 'steelblue'
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  linkLogout: {
    backgroundColor: '#EDF0F5',
    '&:hover': {
      background: '#EDF0F5',
    },
    borderColor: '#0B6AB0',
    color: '#0B6AB0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&.active': {
      color: 'steelblue'
    }
  },
  logo: {
    height: theme.spacing(5),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  },
  menuItem: {
    color: '#0B6AB0'
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: theme.spacing(2),
    backgroundColor: '#7B68EE'
  },
  button: {
    backgroundColor: '#0B6AB0',
    '&:hover': {
        background: '#0B6AB0',
    },
    color: '#FFFFFF',
    cursor: 'pointer',
    '&.active': {
        color: 'steelblue'
    },
},
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NavBar = ({ isAuthenticated, isAdmin, isInstructor, isStudent, user, logout }) => {
  const history = useHistory()
  const classes = useStyles()
  let actions
  let homeUrl = ''

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const [mobileView, setMobileView] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [showAlert, setAlertShow] = useState(false)

  let avatarText = '';
  let avatar = '';
  let photoAvatar = false;
  if (user) {
    if (user.userinformation.image) {
      photoAvatar = true
      avatar = `${contentBaseURL}${user.userinformation.image}`
    } else {
      photoAvatar = false
      avatarText = user.username.substring(0, 2).toUpperCase()
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open])

  useEffect(() => {
    // checkAuthenticated();
    // loadUser();
    // checkAuthorizedUser();
    const setResponsiveness = () => {
      return window.innerWidth < 900 ?
        setMobileView(true) : setMobileView(false)
    }
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness())

    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, []);

  let privilege = ''
  if (isAdmin) {
    privilege = 'Admin'
  }
  else if (isInstructor) {
    privilege = 'Instructor'
  }
  else if (isStudent) {
    privilege = 'Student'
  }
  else {
    privilege = ''
  }

  const logoutUser = () => {
    logout();
    history.push("/login");
  }

  // Set user app bar links
  if (isAdmin) {
    homeUrl = '/user-approval'
    actions = <>
      <Link className={classes.link} to='/user-approval'>User Approval</Link>
      <Link className={classes.link} to='/admins'>Admins</Link>
      <Link className={classes.link} to='/students'>Students</Link>
      <Link className={classes.link} to='/instructors'>Instructors</Link>
      <Link className={classes.link} to='/academic-sessions'>Sessions</Link>
      <Link className={classes.link} to='/admin-courses'>Courses</Link>
      <Link className={classes.link} to='/enrollments'>Enrollments</Link>
    </>
  } else if (isInstructor) {
    homeUrl = '/instructor-courses'
    actions = <>
      {/* <Link className={classes.link}>Students</Link> */}
      <Link className={classes.link} to='/instructor-courses'>My Courses</Link>
    </>
  } else if (isStudent) {
    homeUrl = '/all-courses'
    actions = <>
      <Link className={classes.link} to='/all-courses'>All Courses</Link>
      <Link className={classes.link} to='/student-courses'>My Learnings</Link>
      <Link className={classes.link} to='/exam-history'>Exams</Link>
    </>
  } else {
    homeUrl = '/all-courses'
    actions = <>
      <Link className={classes.link} to='/all-courses'>All Courses</Link>
    </>
  }


  // Sign up menu item - Admin, Instructor, Student
  let signupMenu
  signupMenu = <>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <Link to={{
                  pathname: '/signup',
                  signupProps: 'Student'
                }} style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleClose} className={classes.menuItem} >Student</MenuItem>
                </Link>
                <Link to={{
                  pathname: '/signup',
                  signupProps: 'Instructor'
                }} style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleClose} className={classes.menuItem} >Instructor</MenuItem>
                </Link>
                <Link to={{
                  pathname: '/signup',
                  signupProps: 'Admin'
                }} style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={handleClose} className={classes.menuItem} >Admin</MenuItem>
                </Link>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>

  // Set display for mobile
  const displayMobile = () => {
    return (
      <Toolbar variant='dense'>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon className={classes.menuItem} />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div>{getDrawerChoices()}</div>
        </Drawer>
        <Typography variant="h6" className={classes.title} style={{ fontSize: 14 }}>
          <Link className={classes.iconLink} to={homeUrl}><b> QA Tek LMS</b></Link>
          <Typography
            variant='body1'
            display='inline'
            className={classes.privilege}
            style={{ fontSize: 12 }}>
            {privilege}
          </Typography>
        </Typography>
        <>
          {isAuthenticated ?
            <>
              <Button
                variant='outlined'
                className={classes.linkLogout}
                onClick={(e) => logoutUser()}
              >
                Logout
                      </Button>
              <Link to='/my-account' style={{ textDecoration: 'none' }}>
                {photoAvatar ? <Avatar className={classes.avatar} src={avatar} />
                  : <Avatar className={classes.avatar}>{avatarText}</Avatar>}
              </Link>
            </>
            :
            <>
              <Button
                color="default"
                className={classes.linkSignup}
                variant='contained'
                disableElevation
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>Sign up</Button>
              {signupMenu}
            </>
          }
        </>
      </Toolbar>
    );
  };


  // Side drawer items
  let headerData = []
  if (isAuthenticated) {
    if (isAdmin) {
      headerData = [
        {
          label: 'User Approval',
          href: '/user-approval'
        },
        {
          label: 'Students',
          href: '/students'
        },
        {
          label: 'Instructors',
          href: '/instructors'
        },
        {
          label: 'Courses',
          href: '/admin-courses'
        },
        {
          label: 'Enrollments',
          href: '/enrollments'
        },
        {
          label: 'Sessions',
          href: '/academic-sessions'
        }
      ]
    } else if (isInstructor) {
      headerData = [
        {
          label: 'My Courses',
          href: '/instructor-courses'
        }
      ]
    } else if (isStudent) {
      headerData = [
        {
          label: 'All Courses',
          href: '/all-courses'
        },
        {
          label: 'My Learnings',
          href: '/student-courses'
        },
        {
          label: 'Exams',
          href: '/exam-history'
        }
      ]
    }
  } else {
    headerData = [
      {
        label: 'All Courses',
        href: '/all-courses'
      },
      {
        label: 'Login',
        href: '/login'
      },
    ]
  }

  const getDrawerChoices = () => {
    return headerData.map(({ label, href }) => {
      return (
        <Link to={href} className={classes.linkMobile}>
          <MenuItem onClick={() => setDrawerOpen(false)}>{label}</MenuItem>
        </Link>
      );
    });
  };

  const alertForLeavingWebsite = () => (
    <Dialog maxWidth='sm' open={showAlert} onClose={() => setAlertShow(false)}
            TransitionComponent={Transition}
            style={{scrollPaper: classes.scrollPaper}}>
            <Typography variant="subtitle1" align='center' style={{margin:'1rem'}}>
                You are leaving this website and enter into another website. Confirm to enter..
            </Typography>
            <DialogActions>
                <Button onClick={() => setAlertShow(false)} color="primary" className={classes.cancelButton}>
                    Cancel
                </Button>
                
                <a target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://qateksolutions.com/"
                    style={{textDecoration: 'none'}}>
                  <Button
                      variant='contained'
                      className={classes.button}
                      onClick={ () => setAlertShow(false)}
                      >
                        Confirm
                  </Button>
                </a>
            </DialogActions>
        </Dialog>
  )

  return (
    <div>
      {alertForLeavingWebsite()}
      <ElevationScroll>
        <AppBar position="static" className={classes.appBar}>
          {mobileView ? displayMobile() :
            <Container maxWidth={false}>
              <Toolbar variant='dense'>
                <img className={classes.logo} src={logo} alt='logo' onClick={() => setAlertShow(true)}/>
                <Typography variant="h6" className={classes.title}>
                  <Link className={classes.iconLink} to={homeUrl}><b> QA Tek LMS</b></Link> <Typography variant='body1' display='inline' className={classes.privilege}>{privilege}</Typography>
                </Typography>
                <>
                  {isAuthenticated ?
                    <>
                      {actions}
                      <Button
                        variant='outlined'
                        className={classes.linkLogout}
                        onClick={(e) => logoutUser()}
                      >
                        Logout
                      </Button>
                      <Link to='/my-account' style={{ textDecoration: 'none' }}>
                        {photoAvatar ? <Avatar className={classes.avatar} src={avatar} />
                          : <Avatar className={classes.avatar}>{avatarText}</Avatar>}
                      </Link>
                    </>
                    :
                    <>
                      {actions}
                      <Link to='/login' className={classes.link} exact={true}>
                        Login
                      </Link>
                      <Button
                        color="default"
                        className={classes.linkSignup}
                        variant='contained'
                        disableElevation
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>Sign up</Button>
                        {signupMenu}
                    </>
                  }
                </>

              </Toolbar>
            </Container>
          }

        </AppBar>
      </ElevationScroll>
    </div >
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
  isInstructor: state.auth.isInstructor,
  isStudent: state.auth.isStudent,
  user: state.auth.user
});

export default connect(mapStateToProps, { logout })(NavBar);
