import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Snackbar,
  Grid,
} from "@material-ui/core";
import logo from "../images/logo.jpg";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { login, checkApprovedUser } from "../actions/auth";
import APPLICATION_CONSTANTS from "../strings";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import axios from "axios";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(16, 0, 6),
    textAlign: "center",
  },
  inputField: {
    margin: theme.spacing(1),
  },
  title: {
    color: "#454545",
    marginLeft: theme.spacing(0),
  },
  form: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1)
  },
  logoStyle: {
    width: "20%",
    height: "20%",
  },
  button: {
    // paddingRight: theme.spacing(11),
    // paddingLeft: theme.spacing(11),
    backgroundColor: "#0B6AB0",
    "&:hover": {
      background: "#0B6AB0",
    },
    color: "#FFFFFF",
    cursor: "pointer",
    "&.active": {
      color: "steelblue",
    },
  },
  forgetPassword: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    fontSize: 13,
  },
}));

const Login = ({ login, isAuthenticated, user }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [usernameHelpText, setUsernameHelpText] = useState("");
  const [passwordHelpText, setPasswordHelpText] = useState("");

  const history = useHistory();

  let approved = false;
  let deviceNotRecognized = true;
  var passwordOrUsernameIncorrect = false;
  var serverError = false;
  let isValidate = false;

  const handleClickShowPassword = () => {
    if (password !== "") {
      setShowPassword(!showPassword);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const [loginError, setLoginError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  let allowLogin = true;
  // const [allowLogin, setAllowLogin] = useState(false);
  // let disallowLogin = false;
  const [disallowLogin, setDisallowLogin] = useState(false);
  let errorMsg = <> </>;
  let disallowLoginMsg = <> </>;

  const postLoginData = async (username, password) => {
    isValidate = validate();
    await axios
      .get("/auth/approved-user/", {
        params: {
          username: username,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.is_approved) {
            console.log("## ---- User Approved ---- ##", response.status);
            approved = true;
          } else {
            console.log("## ---- User Not Approved ---- ##", response.status);
            approved = false;
          }
        }
      })
      .catch((error) => {
        console.log("## ---- User Approval Error ---- ##", error);
        if (error.response.status === 404 && isValidate) {
          console.log("## ---- User Not Found ---- ##", error.response.status);
          setLoginError(true);
        }
      });

    await axios({
      method: "GET",
      url: "auth/authenticated-device/",
      params: {
        username: username,
        password: password,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.device_not_recognized) {
            console.log(
              "## ---- Device Not Recognized ---- ##",
              response.status
            );
            deviceNotRecognized = true;
          } else {
            deviceNotRecognized = false;
          }
        }
      })
      .catch((error) => {
        console.log("## ---- Device Not Recognized Error ---- ##", error);
        if (error.response != null) {
          if (error.response.status === 400) {
            if (error.response.data.password) {
              passwordOrUsernameIncorrect = true;
              setLoginError(true);
            }
          }
        }
      });

    // await axios({
    //   method: "GET",
    //   url: "auth/logged-in-device-count/",
    //   params: {
    //     username: username,
    //   },
    // })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       if (response.data.logged_in_device_count) {
    //         console.log(
    //           "## ----Logged In Device Count ---- ##",
    //           response.status
    //         );
    //         if (parseInt(response.data.logged_in_device_count) > 1) {
    //           console.log(
    //             "## ---- Do not allow login ---- ##",
    //             response.status
    //           );
    //           // setAllowLogin(false);
    //           allowLogin = false;
    //         } else {
    //           // setAllowLogin(true);
    //           allowLogin = true;
    //           console.log("## ---- Allow login ---- ##", response.status);
    //         }
    //       } else {
    //         console.log(
    //           "## ---- Logged In Device Count Failed  ---- ##",
    //           response.status
    //         );
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("## ---- Logged In Device Count Error ---- ##", error);
    //   });

    const sendVerificationCode = async () => {
      await axios({
        method: "POST",
        url: "auth/create-totp/",
        data: {
          username: username,
        },
      })
        .then((response) => {
          if (response.status === 201) {
            console.log(
              "## ---- Verification code sent ---- ##",
              response.status
            );
          }
        })
        .catch((error) => {
          console.log("## ---- Verification code sending error ---- ##", error);
          //serverError = true
        });
    };

    // If user is approved, login and authenticate if device is not recognized
    if (!serverError) {
      if (validate()) {
        if (approved) {
          if (passwordOrUsernameIncorrect == false) {
            if (allowLogin) {
              if (deviceNotRecognized) {
                if (validate()) {
                  sendVerificationCode();
                  history.push({
                    pathname: "/two-factor-authentication",
                    state: {
                      username: username,
                      password: password,
                    },
                  });
                }
              } else {
                await login(username, password);
                if (user === null) {
                  setLoginError(true);
                }
              }
            } else {
              setDisallowLogin(true);
              // disallowLogin = true;
            }
          }
        } else {
          setSnackbarOpen(true);
        }
      }
    } else {
      setNetworkError(true);
    }
  };

  const validate = () => {
    var validated = true;

    if (username == "") {
      setUsernameError(true);
      validated = false;
      setUsernameHelpText("This can not be kept empty");
    }

    if (password == "") {
      setPasswordError(true);
      validated = false;
      setPasswordHelpText("This can not be kept empty");
    }
    return validated;
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (networkError) {
    errorMsg = (
      <Alert severity="error">{APPLICATION_CONSTANTS.networkError}</Alert>
    );
  } else {
    if (disallowLogin) {
      disallowLoginMsg = (
        <Alert severity="warning" className={classes.alert}>
          <Typography inline>
            You have two device logged in! Please logout from any of those to
            login a new device.
          </Typography>
        </Alert>
      );
    }
    if (loginError || passwordOrUsernameIncorrect) {
      errorMsg = (
        <Alert severity="error" className={classes.alert}>
          Username or password incorrect
        </Alert>
      );
    }
  }

  const onFromSubmit = (event) => {
    event.preventDefault();
    postLoginData(username, password);
  };

  return (
    <Container className={classes.container}>
      <img src={logo} className={classes.logoStyle} />
      <Typography color="inherit" variant="h5" className={classes.title}>
        Login to your account
      </Typography>

      <Grid container direction="row" justify="center">
        <Grid item xs={7} sm={4} md={4} lg={3}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={7} sm={4} md={4} lg={3}>
          {errorMsg}
        </Grid>
        <Grid item xs={7} sm={4} md={4} lg={3}>
          {disallowLoginMsg}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center">
        <Grid item xs={7} sm={4} md={4} lg={3}>
          <form action="#" onSubmit={onFromSubmit} className={classes.form}>
            <div className={classes.inputField}>
              <TextField
                fullWidth
                error={usernameError}
                id="outlined-basic"
                label="Username or Email"
                variant="outlined"
                size="small"
                color="primary"
                helperText={usernameHelpText}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className={classes.inputField}>
              <TextField
                id="outlined-password-input"
                label="Password"
                error={passwordError}
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                variant="outlined"
                size="small"
                color="primary"
                onChange={(e) => setPassword(e.target.value)}
                helperText={passwordHelpText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: 0 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Grid container direction="row" justify="center">
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  className={classes.button}
                >
                  Login
                </Button>
              </Grid>
            </Grid>

            <Typography variant="body2" className={classes.forgetPassword}>
              Forget password?{" "}
              <Link
                to="/reset-password"
                style={{ textDecoration: "none", color: "#0B6AB0" }}
              >
                Reset password
              </Link>
            </Typography>
          </form>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Your request to join LMS is not approved yet!
        </Alert>
      </Snackbar>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
