import { useState, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography, Button, Box, Menu, MenuItem, Backdrop } from '@material-ui/core'
import Course from './Course'
import axios from 'axios'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { IoHourglassOutline } from "react-icons/io5"

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    link: {
        textDecoration: 'none',
    },
    addButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none',
        marginLeft: theme.spacing(1),
    },
    sessionButton: {
        // backgroundColor: '#0B6AB0',
        // '&:hover': {
        //     background: '#0B6AB0',
        // },
        // color: '#FFFFFF',
        textTransform: 'none',
        //marginLeft: theme.spacing(1),
    },
    gridContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    formControl: {
        minWidth: '20vw',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

const AdminCourses = () => {
    const classes = useStyles()
    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState("")
    const [courses, setCourses] = useState([])
    const [isCourseLoading, setIsCourseLoading] = useState(true)

    useEffect(() => {
        const getSessions = () => {
            axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    var _sessions = response.data;
                    var _sessions = [..._sessions, {id: 0, title: "All Sessions", details: "", studentcount: ""}]
                    setSessions(_sessions);
                    setSelectedSession(_sessions[0].title)
                    getCourses(_sessions[0].id)
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }
        getSessions();
    }, [])

    const getCourses = async (sessionId) => {
        setIsCourseLoading(true)
        await axios({
            method: 'GET',
            url: `courses/list/${sessionId}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((response) => {
            if (response.status == 200) {
                setCourses(response.data)
            }
            setIsCourseLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setIsCourseLoading(false);
        })
    }

    const handleSessionSelect = (id, title, popupState) => {
        popupState.close()
        setSelectedSession(title)
        getCourses(id)
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Box display='flex' justifyContent='space-between' mb={2} ml={3} mr={3}>
                <Typography variant='h6' className={classes.header} display='inline'>All Courses</Typography>
                <Box display="flex" flexDirection="row">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <Fragment>
                            <Button 
                                className={classes.sessionButton}
                                variant="contained"
                                color="default"
                                startIcon={<KeyboardArrowDownIcon />}
                                {...bindTrigger(popupState)}
                            >
                                {selectedSession}
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {sessions.map((session, index) => (
                                    <MenuItem onClick={() => handleSessionSelect(session.id, session.title, popupState)}>{session.title}</MenuItem>
                                ))}
                            </Menu>
                            </Fragment>
                        )}
                    </PopupState>
                    <Link to='/create-course' className={classes.link}>
                        <Button
                            variant='contained'
                            className={classes.addButton}
                            startIcon={<AddIcon />}
                        >
                            Add Course
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Grid container className={classes.gridContainer} spacing={2}>
                {courses.length ?
                    courses.map((course, index) => (
                        <Course key={index} course={course} />
                    ))
                    :
                    <Grid item xs={12}>
                        <Typography variant='h5' align="center" color="textSecondary" style={{ marginTop: '60px' }}>{isCourseLoading ? 
                        <Backdrop className={classes.backdrop} open={isCourseLoading}>
                            <IoHourglassOutline style={{ fontSize: 35 }} />
                        </Backdrop> : 'No course available'}</Typography>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}

export default AdminCourses
