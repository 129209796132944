import { Box, Card, Backdrop, CardContent, Grid, Typography, Button, Chip, Switch, FormControlLabel, Snackbar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect } from 'react'
import { useConfirm } from "material-ui-confirm"
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        padding: theme.spacing(1),
    },
    card: {
        //backgroundColor: '#fcfdfe',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
    },
    cardMedia: {
        paddingTop: '56.25%'  // aspect ratio 16:9
    },
    cardContent: {
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
    },
    formControlLabel: {
        // marginTop: theme.spacing(2),
    },
    viewButton: {
        '&:hover': {
            // background: '#0B6AB0',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
        textTransform: 'none',
        marginLeft: theme.spacing(1),
    },
    archiveButton1: {
        '&:hover': {
            background: '#fff5f8',
        },
        borderColor: '#f44336',
        color: '#f44336',
        textTransform: 'none',
    },
    archiveButton: {
        marginTop: 8,
        color: '#f44336',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    archiveInIcon: {
        fontSize: 24,
        color: '#f44336',
        cursor: 'pointer',
    },
    archiveOutIcon: {
        fontSize: 24,
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    confirmArchivalButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    confirmUnrchivalButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}));

const Course = ({ course }) => {
    const classes = useStyles();
    const confirm = useConfirm();
    const [isActive, setIsActive] = useState(null);
    const [isArchived, setIsArchived] = useState(null);
    const [archivalProgress, setArchivalProgress] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState({
        open: false, message: "", severity: "success"
    });

    useEffect(() => {
        setIsActive(course.is_active);
        setIsArchived(course.is_archived);
    }, [course]);

    const handleConfirmStatusChange = (e) => {
        confirm({
                title: <Typography variant='h6'>Change Course Status</Typography>,
                description: `Are you sure to change the course status to ${e.target.checked ? 'active' : 'inactive'}?`,
                confirmationText: 'Ok', 
                confirmationButtonProps: { className: classes.confirmUnrchivalButton, disableElevation: true },
                cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
            })
            .then(() => changeStatus(!e.target.checked))
            .catch(() => setIsActive(e.target.checked));
    }

    const changeStatus = (status) => {
        axios({
            method: 'PUT',
            url: `courses/status/${course.id}/`,
            data: {
                status: status
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                console.log('Status changed!');
                setIsActive(status);
            }
        }).catch((error) => {
            console.log(error);
            setIsActive(!status);
        });
    }

    const handleConfirmArchival = () => {
        confirm({
            title: <Typography variant='h6'>Course Archival</Typography>, 
            description: isArchived ?
                            <>
                                <Typography varint='body1'>Are you sure to unarchive this course?</Typography>
                                <Typography variant='body1' color="secondary" style={{ marginTop: 8 }}>
                                    Before unarchival, you need to upload the zip file through a ftp client (e.g. filezilla).
                                </Typography>
                                <Typography variant='body1' color="primary" style={{ marginTop: 8 }}>Upload the zip file manually by following below steps:</Typography>
                                <ol style={{ marginLeft: 24 }}>
                                    <li><Typography variant='body1'>Open filezilla. Connect to QATekLMS ftp server by giving provided credentials.</Typography></li>
                                    <li><Typography variant='body1'>Go to /home/qatekadmin. Upload the zip file here.</Typography></li>
                                    <li><Typography variant='body1'>Click "Unarchive" button.</Typography></li>
                                </ol>
                                <Typography variant='body1' style={{ fontStyle: 'italic', marginTop: 16 }}>
                                    [Take a screenshot of this for future reference.]
                                </Typography>
                            </>
                            :
                            <>
                                <Typography varint='body1'>Are you sure to archive this course?</Typography>
                                <Typography variant='body1' style={{ marginTop: 8 }}>
                                    After your confirmation, all the course data will be zipped into a archive file.
                                    The process may take time according to course volume.
                                    Then you need to download the zip file through a ftp client (e.g. filezilla).
                                </Typography>
                                <Typography variant='body1' color="primary" style={{ marginTop: 8 }}>Download the zip file manually by following below steps:</Typography>
                                <ol style={{ marginLeft: 24 }}>
                                <li><Typography variant='body1'>Click "Archive" button. Wait for archival success message.</Typography></li>
                                    <li><Typography variant='body1'>Open filezilla. Connect to QATekLMS ftp server by giving provided credentials.</Typography></li>
                                    <li><Typography variant='body1'>Go to /home/qatekadmin. A zip file can be found named course_archive_[course id].zip . Download the zip file into a local folder.</Typography></li>
                                    <li><Typography variant='body1'>Delete the zip file from the server.</Typography></li>
                                </ol>
                                <Typography variant='body1' color='secondary' style={{ marginTop: 8 }}>Attention:</Typography>
                                <ul style={{ marginLeft: 24 }}>
                                    <li><Typography variant='body1' color='secondary'>Do not leave the page while archiving. This can cause data lose.</Typography></li>
                                    <li><Typography variant='body1' color='secondary'>Be careful while deleting any file inside the server.</Typography></li>
                                    <li><Typography variant='body1' color='secondary'>Do not change the zip file name.</Typography></li>
                                </ul>
                                <Typography variant='body1' style={{ fontStyle: 'italic', marginTop: 16 }}>
                                    [Take a screenshot of this for future reference.]
                                </Typography>
                            </>,
            confirmationText: isArchived ? 'Unarchive' : 'Archive', 
            confirmationButtonProps: { className: isArchived ? classes.confirmUnrchivalButton : classes.confirmArchivalButton, disableElevation: true },
            cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
        })
            .then(handleArchival)
            .catch(() => console.log('Cancelled!'));
    }

    const handleArchival = () => {
        setArchivalProgress(true);
        axios({
            method: 'PUT',
            url: `courses/archival/`,
            data: {
                course_id: course.id,
                is_archived: !isArchived,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            setArchivalProgress(false);
            if (res.status == 202) {
                console.log('Archival status changed!');
                setIsArchived(!isArchived);
                setOpenSnackbar({open: true, message: `The course ${isArchived ? 'unarchived' : 'archived'} successfully.`, severity: 'success'});
            }
            else {
                setOpenSnackbar({open: true, message: 'Operation failed!', severity: 'error'});
            }
        }).catch((error) => {
            setArchivalProgress(false);
            setOpenSnackbar({open: true, message: error.response.data.message, severity: 'error'});
            console.log(error);
        });
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenSnackbar({...openSnackbar, open: false});
    }
    
    return (
        <Grid item xs={12} md={6} className={classes.cardGrid}>
            <Card className={classes.card} variant='outlined'>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="body1" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                            {course.title}
                        </Typography>
                        <Box mb={0}>
                            <Typography variant="overline">
                                {course.instructor.map((instructor, index) => (
                                    <>{index == 0 ? instructor.name : `, ${instructor.name}`}</>
                                ))}
                            </Typography>
                            <Typography variant="overline"> | Enrolled Students {course.student_count}</Typography>
                        </Box>
                        <Typography display='block' variant="caption" color="textSecondary" style={{ marginTop: 0, fontWeight: 'bold' }}>
                            {course.academic_session ? course.academic_session.title : "No Session"}
                        </Typography>
                        {/* <div style={{ display: 'flex', direction: 'row' }}>
                            <Rating defaultValue={course.rating} precision={0.1} size="small" readOnly />
                        </div> */}
                        {/* <Box mt={2}>{course.is_active ? <Chip label="Active" color='primary' /> : <Chip label="Inactive" />}</Box> */}
                        
                        <Box mt={2} display="flex" flexDirection="row" alignItems="center">
                            {!isArchived &&
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={<Switch checked={isActive} onChange={handleConfirmStatusChange} color="primary" />}
                                    label={isActive ? 'Active' : 'Inactive'}
                                />
                            }
                            
                            <Box display="flex" flexDirection="row" alignItems="center">
                                {isArchived ? 
                                    <BiArchiveOut className={classes.archiveOutIcon} onClick={handleConfirmArchival} />
                                    :
                                    <BiArchiveIn className={classes.archiveInIcon} onClick={handleConfirmArchival} />
                                }
                                <Typography variant="body1" style={{ marginLeft: 8 }}>{isArchived ? 'Unarchive' : 'Archive'}</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                    <Box display='flex' flexDirection='column' justifyContent='center' alignItems="center">
                        {isArchived ?
                    
                            <Chip label="Archived" />
                            :
                            <Link to={`/instructor-course/${course.id}`} className={classes.link}>
                                <Button
                                    size='large'
                                    variant='outlined'
                                    color="primary"
                                    className={classes.viewButton}
                                >
                                    View
                                </Button>
                            </Link>}
                    </Box>
                    
                </div>
            </Card>
            
            <Snackbar open={openSnackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={openSnackbar.severity}>
                    {openSnackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={archivalProgress}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        align="center"
                        style={{ fontWeight: 'normal' }}
                    >
                        Archiving the course... This may take several minutes.
                    </Typography> 
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        align="center"
                        style={{ fontWeight: 'normal' }}
                    >
                        Please do not leave the page until the process is completed.
                    </Typography>
                </Box>
            </Backdrop>
        </Grid>
    )
}

export default Course
