import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, Dialog, Backdrop, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Paper } from '@material-ui/core';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { IoHourglassOutline } from "react-icons/io5";

import WeekContent from './WeekContent';
import { Rotate90DegreesCcw } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    contentHeader: {
        marginBottom: theme.spacing(2),
    },
    contentContainer: {
        padding: theme.spacing(2),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    gridContainer: {
        //paddingLeft: theme.spacing(3),
        //paddingRight: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    cancelButton: {
        textTransform: 'none',
    },
    addButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    }
}));

function CourseContents({ courseId, isAddWeek, setIsAddWeek }) {
    const classes = useStyles();
    const [privilege, setPrivilege] = useState("");
    //const [course, setCourse] = useState({});
    const [courseContents, setCourseContents] = useState([]);
    const [showProgresses, setshowProgresses] = useState([]);
    const [isUploadVideos, setIsUploadVideos] = useState([]);
    const [isUploadNotes, setIsUploadNotes] = useState([]);
    const [isUploadHomeworks, setIsUploadHomeworks] = useState([]);
    const [videoFilenames, setVideoFilenames] = useState([]);
    const [noteFilenames, setNoteFilenames] = useState([]);
    const [homeworkFilenames, setHomeworkFilenames] = useState([]);
    const [videoTitles, setVideoTitles] = useState([]);
    const [noteTitles, setNoteTitles] = useState([]);
    const [homeworkTitles, setHomeworkTitles] = useState([]);
    const [errorVideoTitles, setErrorVideoTitles] = useState([]);
    const [errorNoteTitles, setErrorNoteTitles] = useState([]);
    const [errorHomeworkTitles, setErrorHomeworkTitles] = useState([]);    
    const [uploadFile, setUploadFile] = useState();
    const [progress, setProgress] = useState(0);
    const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false);
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false);
    const [openDeleteWeekSnackbar, setOpenDeleteWeekSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const [openDuplicateWeekSnackbar, setOpenDuplicateWeekSnackbar] = useState(false);
    const [openAddWeekSnackbar, setOpenAddWeekSnackbar] = useState(false);
    const [weekNum, setWeekNum] = useState("");
    const [errorWeekNum, setErrorWeekNum] = useState("");
    //const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
        await axios({
            method: 'GET',
            url: `courses/content-list/${courseId}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                //setCourse(res.data.course);
                setCourseContents(res.data.contents);
                setPrivilege(res.data.role);

                setIsLoading(false);
                
                // Init upload button state
                setIsUploadVideos(Array.from({length: res.data.contents.length}, i => i = true));
                setIsUploadNotes(Array.from({length: res.data.contents.length}, i => i = true));
                setIsUploadHomeworks(Array.from({length: res.data.contents.length}, i => i = true));

                setshowProgresses(Array.from({length: res.data.contents.length}, i => i = false));

                // Init filenames
                setVideoFilenames(Array.from({length: res.data.contents.length}, i => i = 'No video selected'));
                setNoteFilenames(Array.from({length: res.data.contents.length}, i => i = 'No note selected'));
                setHomeworkFilenames(Array.from({length: res.data.contents.length}, i => i = 'No note selected'));

                // Init titles
                setVideoTitles(Array.from({length: res.data.contents.length}, i => i = ''));
                setNoteTitles(Array.from({length: res.data.contents.length}, i => i = ''));
                setHomeworkTitles(Array.from({length: res.data.contents.length}, i => i = ''));

                // Init error messages
                setErrorVideoTitles(Array.from({length: res.data.contents.length}, i => i = ''));
                setErrorNoteTitles(Array.from({length: res.data.contents.length}, i => i = ''));
                setErrorHomeworkTitles(Array.from({length: res.data.contents.length}, i => i = ''));
            }
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
        }

        getData();
    }, []);

    const handleChangeTitle = (index, value, type) => {
        if (type === 'video') {
            var values = [...videoTitles];
            values[index] = value;
            setVideoTitles(values);
        }
        else if (type === 'note') {
            var values = [...noteTitles];
            values[index] = value;
            setNoteTitles(values);
        }
        else {  // homework
            var values = [...homeworkTitles];
            values[index] = value;
            setHomeworkTitles(values);
        }
    }

    const handleSelect = (index, file, type) => {
        // Set file for upload
        setUploadFile(file);

        if (type=='video') {
            // Enable upload button
            var value = [...isUploadVideos];
            value[index] = false;
            setIsUploadVideos(value);

            // Set filename
            var value = [...videoFilenames];
            value[index] = file.name;
            setVideoFilenames(value);
        }
        else if (type === 'note') {
            var value = [...isUploadNotes];
            value[index] = false;
            setIsUploadNotes(value);

            var value = [...noteFilenames];
            value[index] = file.name;
            setNoteFilenames(value);
        }
        else {  // homework
            var value = [...isUploadHomeworks];
            value[index] = false;
            setIsUploadHomeworks(value);

            var value = [...homeworkFilenames];
            value[index] = file.name;
            setHomeworkFilenames(value);
        }
    }

    const handleUpload = (index, id, type) => {
        const uploadData = new FormData();
        if (type == 'video') {
            var title = videoTitles[index];
            if (title) {
                // Show progressbar
                var value = [...showProgresses];
                value[index] = true;
                setshowProgresses(value);
                uploadData.append('video', uploadFile, uploadFile.name);
                uploadData.append('title', title);

                axios({
                    method: "PUT",
                    url: `courses/video/${id}/`,
                    data: uploadData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    onUploadProgress: function(progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setProgress(percentCompleted);
                    },
                }).then(function (res) {
                    if (res.status === 201) {
                        console.log('Video uploaded.');
                        setOpenUploadSnackbar(true);
                        
                        // Hide progressbar
                        var value = [...showProgresses];
                        value[index] = false;
                        setshowProgresses(value);
                        // Disable upload button
                        var value = [...isUploadVideos];
                        value[index] = true;
                        setIsUploadVideos(value);
                        // Clear title
                        var values = [...videoTitles];
                        values[index] = '';
                        setVideoTitles(values);
                        // Clear error message
                        var values = [...errorVideoTitles];
                        values[index] = '';
                        setErrorVideoTitles(values);
                        // Add content field
                        var values = [...courseContents];
                        values[index].video = [...values[index].video, {id: res.data.id, title: res.data.title ? res.data.title : 'No title',
                                                                        file: res.data.file, coursecontent: null}];
                        setCourseContents(values);
                        // Clear added file
                        var values = [...videoFilenames];
                        values[index] = 'No video selected'
                        setVideoFilenames(values);
                    }
                }).catch((error) => {
                    console.log(error);
                    setOpenFailureSnackbar(true);

                    // Hide progressbar
                    var value = [...showProgresses];
                    value[index] = false;
                    setshowProgresses(value);
                    // Disable upload button
                    var value = [...isUploadVideos];
                    value[index] = true;
                    setIsUploadVideos(value);
                });
            }
            else {
                var values = [...errorVideoTitles];
                values[index] = 'Title must not be kept blank';
                setErrorVideoTitles(values);
            }
        }
        else if (type === 'note') {
            var title = noteTitles[index];
            
            if (title) {
                // Show progressbar
                var value = [...showProgresses];
                value[index] = true;
                setshowProgresses(value);
                uploadData.append('lecture_note', uploadFile, uploadFile.name);
                uploadData.append('title', title);
                axios({
                    method: "PUT",
                    url: `courses/lecture-note/${id}/`,
                    data: uploadData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    onUploadProgress: function(progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setProgress(percentCompleted);
                    },
                }).then(function (res) {
                    console.log(res);
                    if (res.status === 201) {
                        console.log('Lecture note uploaded.');
                        setOpenUploadSnackbar(true);

                        // Hide progressbar
                        var value = [...showProgresses];
                        value[index] = false;
                        setshowProgresses(value);
                        // Disable upload button
                        var value = [...isUploadNotes];
                        value[index] = true;
                        setIsUploadNotes(value);
                        // Clear title
                        var values = [...noteTitles];
                        values[index] = '';
                        setNoteTitles(values);
                        // Clear error message
                        var values = [...errorNoteTitles];
                        values[index] = '';
                        setErrorNoteTitles(values);
                        // Add content field
                        var values = [...courseContents];
                        values[index].lecturenote = [...values[index].lecturenote, {id: res.data.id, title: res.data.title ? res.data.title : 'No title',
                                                                            file: res.data.file, coursecontent: null}];
                        setCourseContents(values);
                        // Clear added file
                        var values = [...noteFilenames];
                        values[index] = 'No note selected'
                        setNoteFilenames(values);
                    }
                }).catch((error) => {
                    console.log(error);
                    setOpenFailureSnackbar(true);

                    // Hide progressbar
                    var value = [...showProgresses];
                    value[index] = false;
                    setshowProgresses(value);
                    // Disable upload button
                    var value = [...isUploadNotes];
                    value[index] = true;
                    setIsUploadNotes(value);
                });
            }
            else {
                var values = [...errorNoteTitles];
                values[index] = 'Title must not be kept blank';
                setErrorNoteTitles(values);
            }
        }
        else {  // homework
            var title = homeworkTitles[index];
            if (title) {
                // Show progressbar
                var value = [...showProgresses];
                value[index] = true;
                setshowProgresses(value);
                uploadData.append('homework', uploadFile, uploadFile.name);
                uploadData.append('title', title);
                axios({
                    method: "PUT",
                    url: `courses/homework/${id}/`,
                    data: uploadData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                    onUploadProgress: function(progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setProgress(percentCompleted);
                    },
                }).then(function (res) {
                    if (res.status === 201) {
                        console.log('Homework uploaded.');
                        setOpenUploadSnackbar(true);

                        // Hide progressbar
                        var value = [...showProgresses];
                        value[index] = false;
                        setshowProgresses(value);
                        // Disable upload button
                        var value = [...isUploadHomeworks];
                        value[index] = true;
                        setIsUploadHomeworks(value);
                        // Clear title
                        var values = [...homeworkTitles];
                        values[index] = '';
                        setHomeworkTitles(values);
                        // Clear error message
                        var values = [...errorHomeworkTitles];
                        values[index] = '';
                        setErrorHomeworkTitles(values);
                        // Add content field
                        var values = [...courseContents];
                        values[index].homework = [...values[index].homework, {id: res.data.id, title: res.data.title ? res.data.title : 'No title',
                                                                            file: res.data.file, coursecontent: null}];
                        setCourseContents(values);
                        // Clear added file
                        var values = [...homeworkFilenames];
                        values[index] = 'No note selected'
                        setHomeworkFilenames(values);
                    }
                }).catch((error) => {
                    console.log(error);
                    setOpenFailureSnackbar(true);

                    // Hide progressbar
                    var value = [...showProgresses];
                    value[index] = false;
                    setshowProgresses(value);
                    // Disable upload button
                    var value = [...isUploadHomeworks];
                    value[index] = true;
                    setIsUploadHomeworks(value);
                });
            }
            else {
                var values = [...errorHomeworkTitles];
                values[index] = 'Title must not be kept blank';
                setErrorHomeworkTitles(values);
            }
        }
    }

    const handleDelete = (i, j, id, type) => {  // i : week index, j : file index
        if (type==='video') {
            axios({
                method: "delete",
                url: `courses/video/${id}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then(function (res) {
                if (res.status === 202) {
                    console.log('Video deleted!');
                    setOpenDeleteSnackbar(true);

                    // Delete video from list
                    var values = [...courseContents];
                    values[i].video.splice(j, 1);
                    setCourseContents(values);

                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);
            });
        }
        else if (type === 'note') {
            axios({
                method: "delete",
                url: `courses/lecture-note/${id}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then(function (res) {
                if (res.status === 202) {
                    console.log('Note deleted!');
                    setOpenDeleteSnackbar(true);

                    // Delete video from list
                    var values = [...courseContents];
                    values[i].lecturenote.splice(j, 1);
                    setCourseContents(values);
                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);
            });
        }
        else {  // homework
            axios({
                method: "delete",
                url: `courses/homework/${id}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then(function (res) {
                if (res.status === 202) {
                    console.log('Homework deleted!');
                    setOpenDeleteSnackbar(true);

                    // Delete video from list
                    var values = [...courseContents];
                    values[i].homework.splice(j, 1);
                    setCourseContents(values);
                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);
            });
        }
    }

    const handleDeleteWeek = (index, week) => {
        axios({
            method: "delete",
            url: `courses/content-list/${courseId}/`,
            data: {
                week: week,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(function (res) {
            if (res.status === 202) {
                console.log('Week deleted!');
                setOpenDeleteWeekSnackbar(true);

                // Delete week from list
                var values = [...courseContents];
                values.splice(index, 1);
                setCourseContents(values);

            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleCloseAddWeekDlg = () => {
        setIsAddWeek(false);
    }

    const handleAddWeek = () => {
        // Validate
        const num = parseInt(weekNum);
        if (isNaN(num)) {
            setErrorWeekNum("Week number must not be kept blank");
        }
        else if (num===0) {
            setErrorWeekNum("Week number must not be a zero");
        }
        else if (num < 0) {
            setErrorWeekNum("Week number must not be negative");
        }
        else {
            setErrorWeekNum("");
            setIsAddWeek(false);

            axios({
                method: 'POST',
                url: `courses/content-list/${courseId}/`,
                data: {
                    week: weekNum,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status === 201) {
                    setshowProgresses([...showProgresses, false]);
                    setIsUploadVideos([...isUploadVideos, true]);
                    setIsUploadNotes([...isUploadNotes, true]);
                    setIsUploadHomeworks([...isUploadHomeworks, true]);
                    setVideoFilenames([...videoFilenames, ""]);
                    setNoteFilenames([...noteFilenames, ""]);
                    setHomeworkFilenames([...homeworkFilenames, ""]);
                    setVideoTitles([...videoTitles, ""]);
                    setNoteTitles([...noteTitles, ""]);
                    setHomeworkTitles([...homeworkTitles, ""]);
                    setErrorVideoTitles([...errorVideoTitles, ""]);
                    setErrorNoteTitles([...errorNoteTitles, ""]);
                    setErrorHomeworkTitles([...errorHomeworkTitles, ""]);

                    // Add contents
                    setCourseContents([...courseContents, res.data]);

                    setOpenAddWeekSnackbar(true);
                }
            }).catch((error) => {
                if (error.response.status === 409) {
                    setOpenDuplicateWeekSnackbar(true);
                }
                else {
                    console.log(error);
                    setOpenFailureSnackbar(true);
                }
            });
        }

        
        
    }

    const handleChangeWeekNum = (value) => {
        setWeekNum(value);
    }

    const handleCloseUploadSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUploadSnackbar(false);
    };

    const handleCloseDeleteSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSnackbar(false);
    };

    const handleCloseDeleteWeekSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteWeekSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    const handleCloseDuplicateWeekSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDuplicateWeekSnackbar(false);
    };
    const handleCloseAddWeekSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAddWeekSnackbar(false);
    };

    return (
        <>
        {!isLoading &&
            <div className={classes.gridContainer}>
                {courseContents.length ?
                    courseContents.map((courseContent, index) => (
                        <WeekContent
                            key={index}
                            index={index}
                            privilege={privilege}
                            courseContent_={courseContents[index]}
                            showProgress={showProgresses[index]}
                            progress={progress}
                            isUploadVideo={isUploadVideos[index]}
                            isUploadNote={isUploadNotes[index]}
                            isUploadHomework={isUploadHomeworks[index]}
                            videoFilename={videoFilenames[index]}
                            noteFilename={noteFilenames[index]}
                            homeworkFilename={homeworkFilenames[index]}
                            videoTitle={videoTitles[index]}
                            noteTitle={noteTitles[index]}
                            homeworkTitle={homeworkTitles[index]}
                            errorVideoTitle={errorVideoTitles.length ? errorVideoTitles[index] : null}
                            errorNoteTitle={errorNoteTitles.length ? errorNoteTitles[index] : null}
                            errorHomeworkTitle={errorHomeworkTitles.length ? errorHomeworkTitles[index] : null}
                            handleChangeTitle={handleChangeTitle}
                            handleSelect={handleSelect}
                            handleUpload={handleUpload}
                            handleDelete={handleDelete}
                            handleDeleteWeek={handleDeleteWeek}
                        />
                    ))
                    :
                    <Paper variant='outlined' square>
                        <Box height='60vh' display="flex" flexDirection='row' justifyContent='center'>
                            <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                                <Typography variant="h5" color="initial" align="center" style={{ color: '#0B6AB0' }}>No Content Found</Typography>
                                <Typography variant="body1" color="textSecondary" align='center'>Please click "+ Add Week" button to proceed.</Typography>
                            </Box>
                        </Box>
                    </Paper>
                }
                <Dialog maxWidth='sm' scroll='body' open={isAddWeek} onClose={handleCloseAddWeekDlg}>
                    <DialogTitle id="form-dialog-title">Add Week</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            variant='outlined'
                            margin="dense"
                            label="Week Number"
                            type="number"
                            style={{ marginBottom: '15px' }}
                            value={weekNum}
                            onChange={(e) => handleChangeWeekNum(e.target.value)}
                            error={errorWeekNum.length ? true : false}
                            helperText={errorWeekNum}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseAddWeekDlg} color="primary" className={classes.cancelButton}>
                            Cancel
                        </Button>
                        <Button onClick={handleAddWeek} className={classes.addButton}>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        }
        
        <Snackbar open={openUploadSnackbar} autoHideDuration={4000} onClose={handleCloseUploadSnackbar}>
            <Alert onClose={handleCloseUploadSnackbar} severity="success">
                File uploaded successfully.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteSnackbar}>
            <Alert onClose={handleCloseDeleteSnackbar} severity="success">
                Content deleted.
            </Alert>
        </Snackbar>
        <Snackbar open={openDeleteWeekSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteWeekSnackbar}>
            <Alert onClose={handleCloseDeleteWeekSnackbar} severity="success">
                Week deleted.
            </Alert>
        </Snackbar>
        <Snackbar open={openAddWeekSnackbar} autoHideDuration={4000} onClose={handleCloseAddWeekSnackbar}>
            <Alert onClose={handleCloseAddWeekSnackbar} severity="success">
                Week added.
            </Alert>
        </Snackbar>
        <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
            <Alert onClose={handleCloseFailureSnackbar} severity="error">
                Operation failed!
            </Alert>
        </Snackbar>
        <Snackbar open={openDuplicateWeekSnackbar} autoHideDuration={4000} onClose={handleCloseDuplicateWeekSnackbar}>
            <Alert onClose={handleCloseDuplicateWeekSnackbar} severity="error">
                Week already exists!
            </Alert>
        </Snackbar>

        <Backdrop className={classes.backdrop} open={isLoading}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
            {/* <Typography variant="h6" color="initial" style={{ marginLeft: '5px', fontWeight: 'normal' }}>Loading...</Typography> */}
        </Backdrop>
        </>
    )
}

export default CourseContents;