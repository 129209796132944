import React from 'react'
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    padding: theme.spacing(0),
    "& .status.active": {
      color: "#4caf50",
    },
    "& .status.pending": {
      color: "#ff9800",
    },
    "& .status.notActive": {
      color: "#f44336",
    },
    marginTop: theme.spacing(2),
  },
}));

// Define column headers
const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 220 },
    { field: "mcq", headerName: "MCQ", width: 230 },
    { field: "description", headerName: "Descriptive", width: 250 },
    { field: "rank", headerName: "Rank", width: 120 },
];

// Create rows
function rows(results) {
    const rows = [];
    results.map((result, index) =>
    rows.push({
        key: result.id,
        id: result.user.id,
        name: result.user.first_name + " " + result.user.last_name,
        mcq: result.mcq_marks,
        description: result.descriptive_q_marks,
        rank: index+1,
        })
    );
    return rows;
}

const ResultListTable = ({ results }) => {
    const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(results)}
        columns={columns}
        pageSize={10}
      />
    </div>
  )
}

export default ResultListTable