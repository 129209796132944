import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Backdrop, Paper, useTheme, Snackbar  } from "@material-ui/core";
import {useLocation} from 'react-router-dom'
import MuiAlert from "@material-ui/lab/Alert";
import { IoHourglassOutline } from "react-icons/io5";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';

import UpcomingExam from './UpcomingExam.js'
import HistoryList from './HistoryList.js'
import NoticeBox from './NoticeBox'
import { LocationOn } from "@material-ui/icons";
import { v5 as uuidv5 } from 'uuid';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    horizontalItem: {
        display: 'flex',
        justifyContent: 'space-between',
        p:5
    },
    rightMargin:{
        marginRight: 3,
        "&:hover": {
            marginRight: theme.spacing(1)
        }
    },
    upcomingContainer: {
        padding: 8,
        marginTop: 15,
    },
    iconStyle:{
        position: 'relative',
        top: 2,
        marginRight: 2
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },

}));
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const ExamHistory = ({  }) => {
    const location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const [course, setCourse] = useState({})
    const [isCourseLoading, setIsCourseLoading] = useState(false)
    const [openSnackBar, setSnackBar] = useState({isOpen:false, message:"", status:""})
    
    useEffect(() => {
        setIsCourseLoading(true)
        const getExams = () => {
            axios({
                method: 'GET',
                url: 'exams/student-exam/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    //'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Exams fetch success!', response.status);
                    setCourse(response.data);
                    setIsCourseLoading(false)
                } else {
                    console.log('Exams fetch failed!', response.status);
                    setIsCourseLoading(false)
                }
            }).catch((error) => {
                console.log('Exams fetch failed!', error);
                setIsCourseLoading(false)
            })
        }
        getExams()
    }, [])

    useEffect(()=>{
        location.state ? setSnackBar({isOpen:true, message:location.state.message, status:location.state.status}) : setSnackBar({isopen:false})
    }, [location.state])

    return (
        <Container maxWidth={false} className={classes.root}>
            <div style={{marginLeft: '24px', marginRight: '24px'}}>
            {isCourseLoading ? <Backdrop className={classes.backdrop} open={isCourseLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop> :
                <Grid container spacing={3} direction={!matches ? "column-reverse": "row"}>
                    <Grid item xs={12} sm={9}>
                        <UpcomingExam upcoming={course.upcoming}/>
                        <HistoryList history={course.history}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <NoticeBox courses={course.upcoming}/>
                    </Grid>
                </Grid>}
        <Snackbar
            open={openSnackBar.isOpen}
            autoHideDuration={4000}
            onClose={() => setSnackBar({isOpen:false})}
        >
            <Alert onClose={() => setSnackBar({isOpen:false})} severity={openSnackBar.status}>
                {openSnackBar.message}
            </Alert>
        </Snackbar>
            </div>     
        </Container>
    );
};

export default ExamHistory;
