import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip, Dialog, IconButton } from "@material-ui/core";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  holdButton: {
    textTransform: "none",
    "&:hover": {
      background: "#cfe9fc",
    },
    borderColor: "#0B6AB0",
    color: "#0B6AB0",
  },
}));

const StudentListTable = ({
  students,
  handleChecked,
  handleSelectedStudents,
  handleAccessibility,
  handleShowSession,
}) => {
  const classes = useStyles();
  // Define column headers
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 250 },
    // { field: 'contactNumber', headerName: 'Contact Number', width: 280 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "location", headerName: "Location", width: 180 },
    { field: "academicSession", headerName: "Enrolled Session", width: 300 },
    { field: "enrolledCourse", headerName: "Enrolled Course", width: 420 },
    // { field: 'action', headerName: 'Action', width: 120 }
    {
      field: "accessibility",
      headerName: "Accessibility",
      disableClickEventBubbling: true,
      width: 180,
      renderCell: (params) => {
        //return <Button variant='outlined' size='small' className={classes.holdButton} onClick={() => handleAccessibility(params.value)} disableElevation>Modify</Button>;
        return (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={() => handleAccessibility(params.value)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "session",
      headerName: "Session Manager",
      disableClickEventBubbling: true,
      width: 200,
      renderCell: (params) => {
        var icon = <> </>;
        icon = (
          <Tooltip title="View">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                handleShowSession(params.value.session, params.value.name, params.value.id)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        );
        return icon;
      },
    },
  ];

  function rows(students) {
    const rows = [];

    if (students.length) {
      students.map((student, index) =>
        rows.push({
          key: student.id,
          id: student.id,
          name: student.name,
          // contactNumber: student.contact_number,
          email: student.email,
          location: student.location,
          academicSession: student.academic_session,
          enrolledCourse: student.course_name.map((course) => course),
          accessibility: student.id,
          session: {
            session: student.session,
            name: student.name,
            id: student.id
          },
        })
      );
    }

    return rows;
  }

  return (
    <div>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowHeight={40}
        rows={rows(students)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          handleChecked(newSelection.length);
          handleSelectedStudents(newSelection);
        }}
        pageSize={10}
        // components={{
        //   Toolbar: GridToolbar,
        // }}
        // filterModel={{
        //   items: [
        //     { columnField: "name", operatorValue: "contains", value: "" },
        //   ],
        // }}
      />
    </div>
  );
};

export default StudentListTable;
