import { Box, Container, Grid, Typography, Button, Backdrop, Snackbar } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check';
import EnrollmentListTable from './EnrollmentListTable'
import EnrollApproveDialog from './EnrollApproveDialog'
import EnrollRejectDialog from './EnrollRejectDialog'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { IoHourglassOutline } from "react-icons/io5"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  headerTypography: {
    color: "#0B6AB0",
    borderBottom: "2px solid #00BC9E",
  },
  tableGrid: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  approveButton: {
    textTransform: "none",
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#4caf50",
    },
    color: "#FFFFFF",
    marginLeft: theme.spacing(1),
  },
  rejectButton: {
    textTransform: "none",
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#f44336",
    },
    color: "#FFFFFF",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#0B6AB0",
    "&.MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
}));

const Enrollments = () => {
  const classes = useStyles();
  const [enrollments, setEnrollments] = useState([]);
  const [disableApproveButton, setDisableApproveButton] = useState(true);
  const [disableRejectButton, setDisableRejectButton] = useState(true);
  const [selectedEnrollments, setSelectedEnrollments] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
  const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false)

  const handleChecked = (selectedItem) => {
    if (selectedItem > 0) {
      setDisableApproveButton(false);
      setDisableRejectButton(false);
    } else {
      setDisableApproveButton(true);
      setDisableRejectButton(true);
    }
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
    setApprovalStatus("Approved");
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClickRejectDialogOpen = () => {
    setRejectDialogOpen(true);
    setApprovalStatus("Rejected");
  };

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  };

  const handleSeletcedEnrollments = (selected) => {
    setSelectedEnrollments(selected);
  };

  const handleUpdateEnrollments = (updateEnrollments) => {
    setEnrollments(updateEnrollments);
  };

  useEffect(() => {
    const getEnrollments = async () => {
      setIsLoading(true);
      await axios
        .get("students/enrollment-list/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(
              "## ---- Enrollments Fetch Success ---- ##",
              response.status
            );
            setEnrollments(response.data);
            setIsLoading(false);
          } else {
            console.log(
              "## ---- Enrollments Fetch Failed ---- ##",
              response.status
            );
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("## ---- Enrollments Fetch Error ---- ##", error);
          setIsLoading(false);
        });
    };
    getEnrollments();
  }, []);

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenSuccessSnackbar(false);
  };

  const handleCloseFailureSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }

      setOpenFailureSnackbar(false);
  };
  
  return (
    <>
      {!isLoading && (
        <Container maxWidth={false} className={classes.container}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            className={classes.gridContainer}
          >
            <Grid item xs={4}>
              <Typography
                variant="h6"
                display="inline"
                className={classes.headerTypography}
              >
                Course Enrollments
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                className={classes.rejectButton}
                disabled={disableRejectButton}
                startIcon={<ThumbDownAltOutlinedIcon />}
                variant="contained"
                onClick={handleClickRejectDialogOpen}
              >
                Reject
              </Button>
              <Button
                className={classes.approveButton}
                disabled={disableApproveButton}
                startIcon={<CheckIcon />}
                variant="contained"
                onClick={handleClickOpen}
              >
                Approve
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} className={classes.tableGrid}>
              <EnrollmentListTable
                enrollments={enrollments}
                handleChecked={handleChecked}
                handleSelectedEnrollments={handleSeletcedEnrollments}
              />
            </Grid>
          </Grid>
          <EnrollApproveDialog
            open={dialogOpen}
            close={handleClose}
            selectedEnrollments={selectedEnrollments}
            approvalStatus={approvalStatus}
            handleUpdateEnrollments={handleUpdateEnrollments}
            setDisableApproveButton={setDisableApproveButton}
            setOpenSuccessSnackbar={setOpenSuccessSnackbar}
            setOpenFailureSnackbar={setOpenFailureSnackbar}
          />
          <EnrollRejectDialog
            open={rejectDialogOpen}
            close={handleRejectDialogClose}
            selectedEnrollments={selectedEnrollments}
            approvalStatus={approvalStatus}
            handleUpdateEnrollments={handleUpdateEnrollments}
            setDisableRejectButton={setDisableRejectButton}
            setOpenSuccessSnackbar={setOpenSuccessSnackbar}
            setOpenFailureSnackbar={setOpenFailureSnackbar}
          />
        </Container>
      )}
      <Snackbar open={openSuccessSnackbar} autoHideDuration={4000} onClose={handleCloseSuccessSnackbar}>
        <Alert onClose={handleCloseSuccessSnackbar} severity="success">
          Operation success!
        </Alert>
      </Snackbar>
      <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
        <Alert onClose={handleCloseFailureSnackbar} severity="error">
          Operation failed!
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <IoHourglassOutline style={{ fontSize: 35 }} />
      </Backdrop>
    </>
  );
};

export default Enrollments;
