import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Paper, Typography, Grid, TextField, Box, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { userTime, utcTime } from '../../../custom_util';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: '#0B6AB0',
  },
  dialogTitleName: {
    color: 'grey',
    margin: '4px'
  },
  editConfirmButton: {
    backgroundColor: '#4caf50',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#3f9142',
        color: '#ffffff',
    },
  },
  cancelButton: {
      textTransform: 'none',
  },
}));

const ExamScheduleEditDialog = ({ examScheduleId, open, close, setExamSchedules, examSchedules, scheduleIndex }) => {
  const classes = useStyles();
  
  const [studentName, setStudentName] = useState('');
  const [examTime, setExamTime] = useState('');

  const [isLoading, setIsloading] = useState(false);

  const [openSchedulEditSuccess , setOpenScheduleEditSuccess] = useState(false);

  useEffect(() => {
    const getExamSchedule = async () => {
      setIsloading(true);
      await axios({
        method: 'GET',
        url: 'exams/exam-schedule/',
        params: {
          schedule_id: examScheduleId
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        }
      }).then((response) => {
        console.log('# ---- Individual exam schedule fetch success ----#');
        setIsloading(false);
        setStudentName(response.data.user.first_name + " " + response.data.user.last_name);
        setExamTime(response.data.start_time);
      }).catch((error) => {
        setIsloading(false);
        console.log('# ---- Individual exam schedule fetch error ---- #');
      })
    }

    if (open) {
      getExamSchedule();
    }
  }, [open])

  const submitEditSchedule = async() => {
    await axios({
      method: 'PUT',
      url: 'exams/exam-schedule/',
      data: {
        schedule_id: examScheduleId,
        start_time: utcTime(examTime, "YYYY-MM-DDTHH:mm")
      },
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      }
    }).then((response) => {
      console.log('# Exam schedule edit success ---- #');
      if (response.status === 200) {
        var values = [...examSchedules];
        values[scheduleIndex].start_time = userTime(response.data.start_time, "YYYY-MM-DDTHH:mm");
        setExamSchedules(values);
        setOpenScheduleEditSuccess(true);
        close();
      }
    }).catch((error) => {
      console.log('# Exam schedule edit error ---- #', error.response.status);
    })
  }

  const handleCloseScheduleSuccessSnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenScheduleEditSuccess(false);
}


  return (
  <div>
    {!isLoading && 
        <Dialog open={open} onClose={close} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Typography className={classes.dialogTitle} variant='h6'>Edit Exam Schedule 
            <Typography variant='caption' className={classes.dialogTitleName}>{studentName}</Typography>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Paper variant='outlined'>
              <Box m={1}>
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  spacing={2}>
                    <Grid item xs={2} sm={3} lg={4} xl={5}>
                        <Box display='flex' justifyContent="flex-end">
                            <label>Start Time</label>
                        </Box>
                    </Grid>
                    <Grid item xs={10} sm={9} lg={8} xl={7}>
                        <TextField
                            type='datetime-local'
                            value={examTime}
                            onChange={(event) => setExamTime(event.target.value)}
                        />
                    </Grid>
                </Grid>
              </Box>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={close}>Cancel</Button>
          <Button className={classes.editConfirmButton} onClick={() => submitEditSchedule()}>Confirm</Button>
        </DialogActions>
    </Dialog>
    }

    <Snackbar open={openSchedulEditSuccess} autoHideDuration={4000} onClose={handleCloseScheduleSuccessSnack} >
      <Alert onClose={handleCloseScheduleSuccessSnack} severity='success'>
        Exam schedule successfully edited.
      </Alert>
    </Snackbar>
  </div>)
};

export default ExamScheduleEditDialog;
