// From https://gist.github.com/longsangstan/3c259ed76c25784bd17694aab05a37af

import React from 'react';
import { Box } from '@material-ui/core';

import './index.css';

//const corsProxy = 'https://cors.bridged.cc/';

class TextFileReader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: ""
		};
	}

	componentDidMount() {
		this.readTextFile(this.props.txt);
	}

	readTextFile = file => {
		var rawFile = new XMLHttpRequest();
		rawFile.open("GET", file, false);
		rawFile.onreadystatechange = () => {
			if (rawFile.readyState === 4) {
				if (rawFile.status === 200 || rawFile.status == 0) {
					var allText = rawFile.responseText;
					this.setState({
						text: allText
					});
				}
			}
		};
		rawFile.send(null);
	};

	render() {
		return (
			<div className="pdf-container">
                <Box p={3}>
                    {this.state.text.split("\n").map((item, key) => {
                        return <span key={key}>{item}<br /></span>;
                    })}
                </Box>
			</div>
		);
	}
}

export default TextFileReader;