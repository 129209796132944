import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Snackbar } from '@material-ui/core';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';

import { userTime, utcTime } from "../../custom_util";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    cancelButton: {
        textTransform: 'none',
    },
    setButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        '&:disabled': {
            color: '#fff',
            backgroundColor: 'grey',
        },
        color: '#FFFFFF'
    }
}));

function SetHomeworkDeadline({ courseContent, setCourseContent, open, setOpen, dateTime }) {
    const classes = useStyles();
    const [value, setValue] = useState(dateTime);
    const [isChanged, setIsChanged] = useState(false);
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    
    const handleChange = (newValue) => {
        setIsChanged(true);
        setValue(newValue);
    }

    const handleSetHomeworkDeadline = () => {
        setOpen(false);

        axios({
            method: 'PUT',
            url: `courses/homework-deadline/`,
            data: {
                id: courseContent.id,
                date_time: value ? utcTime(value, "YYYY-MM-DDTHH:mm") : null,
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                var value = {...courseContent};
                value.homework_deadline = res.data.homework_deadline ? userTime(res.data.homework_deadline, "YYYY-MM-DDTHH:mm") : null;
                setCourseContent(value);
                
                setIsChanged(false);
                setOpenSuccessSnackbar(true);
            }
            else {
                setOpenFailureSnackbar(true);
            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    return (
        <>
        <Dialog maxWidth='sm' scroll='body' open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Set homework deadline</DialogTitle>
            <DialogContent>
                <form className={classes.container} noValidate>
                    <TextField
                        label="Pick date & time"
                        type="datetime-local"
                        variant="outlined"
                        value={value}
                        onChange={(e) => handleChange(e.target.value)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </form>
                <Box mb={2}></Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary" className={classes.cancelButton}>
                    Cancel
                </Button>
                <Button onClick={handleSetHomeworkDeadline} className={classes.setButton} disabled={!isChanged}>
                    Set
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={openSuccessSnackbar} autoHideDuration={4000} onClose={handleCloseSuccessSnackbar}>
            <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                Homework deadline updated.
            </Alert>
        </Snackbar>
        <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
            <Alert onClose={handleCloseFailureSnackbar} severity="error">
                Operation failed!
            </Alert>
        </Snackbar>
        </>
    )
}

export default SetHomeworkDeadline