import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
  Backdrop
} from "@material-ui/core";
import axios from "axios";
import qs from "qs";
import { IoHourglassOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: "#0B6AB0",
  },
  rejectConfirmButton: {
    textTransform: "none",
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#f44336",
    },
    color: "#FFFFFF",
  },
  cancelButton: {
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#0B6AB0",
    "&.MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
  },
}));

const EnrollRejectDialog = ({
  open,
  close,
  selectedEnrollments,
  approvalStatus,
  handleUpdateEnrollments,
  setDisableRejectButton,
  setOpenSuccessSnackbar,
  setOpenFailureSnackbar,
}) => {
  const classes = useStyles();
  const [isRejecting, setIsRejecting] = useState(false)

  const rejectEnrollments = async () => {
    close();
    setIsRejecting(true)
    await axios({
      method: "PUT",
      url: "students/enrollment-list/",
      data: {
        approval_status: approvalStatus,
        selected_enrollments: selectedEnrollments,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((response) => {
        setIsRejecting(false)
        if (response.status === 200) {
          console.log(
            "## --- Enrollments Update Success ---- ##",
            response.status
          );
          handleUpdateEnrollments(response.data);
          setDisableRejectButton(true)
          setOpenSuccessSnackbar(true)
        } else {
          console.log(
            "## --- Enrollments Update Failed ---- ##",
            response.status
          );
          setOpenFailureSnackbar(true)
        }
      })
      .catch((error) => {
        console.log("## --- Enrollments Update Error ---- ##", error);
        setOpenFailureSnackbar(true)
      });
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <Typography variant="h6">Reject Enrollment</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to reject this enrollment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            color="primary"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            className={classes.rejectConfirmButton}
            onClick={() =>
              rejectEnrollments()
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={isRejecting}>
        <IoHourglassOutline style={{ fontSize: 35 }} />
      </Backdrop>
    </div>
  );
};

export default EnrollRejectDialog;
