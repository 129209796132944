import { Box, Button, Grid, Paper, Typography, Backdrop } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import InstructorListTable from './InstructorListTable'
import AddInstructorDialog from './AddInstructorDialog'
import DeleteInstructorDialog from './DeleteInstructorDialog'
import axios from 'axios'
import { DeleteOutlineRounded } from '@material-ui/icons'
import { IoHourglassOutline } from "react-icons/io5"
import CourseAccessibilityDialog from './CourseAccessibilityDialog'

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    headerTypography: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    headerUnderline: {
        width: '50%',
        color: '#00BC9E',
        borderBottom: '2px solid'
    },
    addButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        marginLeft: theme.spacing(1)
    },
    deleteButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    tableGrid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

const Dashboard = () => {
    const classes = useStyles()
    const [instructors, setInstructors] = useState([])
    const [selectedInstructors, setSelectedInstructors] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [disableDeleteButton, setDisableDeleteButton] = useState(true)
    const [accessibilityDialogOpen, setAccessibilityDialogOpen] = useState(false)
    const [instructorId, setInstructorId] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handleChecked = (selectedItem) => {
        if (selectedItem > 0) {
            setDisableDeleteButton(false)
        } else {
            setDisableDeleteButton(true)
        }
    }

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClickDeleteOpen = () => {
        setDeleteDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleCloseDelete = () => {
        setDeleteDialogOpen(false)
    }

    const handleChangeInstructors = (updatedInstructors) => {
        setInstructors(updatedInstructors)
    }

    const handleSelectedInstructors = (selectedInstructors) => {
        setSelectedInstructors(selectedInstructors)
    }

    const handleAccessibility = (id) => {
        setInstructorId(id)
        setAccessibilityDialogOpen(true)
    }

    const handleCloseAccessibilityDialog = () => {
        setAccessibilityDialogOpen(false)
    }

    useEffect(() => {
        const getInstructors = async () => {
            setIsLoading(true)
            await axios
                .get('instructors/instructor-list/', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('## ---- Instructor Fetch Success ---- ##', response.status);
                        setInstructors(...instructors, response.data)
                        setIsLoading(false)
                    } else {
                        console.log('## ---- Instructor Fetch Failed ---- ##', response.status);
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log('## ---- Instructor Fetch Error ---- ##', error);
                    setIsLoading(false)
                })
        }
        getInstructors();

    }, [])

    return (
        <>
            {
                !isLoading &&
                <div>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justify='space-between'
                        className={classes.gridContainer}
                    >
                        <Grid item xs={4}>
                            <Typography variant='h6' display='inline' className={classes.headerTypography}>Instructors Dashboard</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Button
                                variant='contained'
                                startIcon={<DeleteOutlineRounded />}
                                onClick={handleClickDeleteOpen}
                                disabled={disableDeleteButton}
                                className={classes.deleteButton}>
                                Delete Instructor
                            </Button>
                            <Button
                                variant='contained'
                                startIcon={<AddOutlinedIcon />}
                                style={{ textTransform: 'none' }}
                                onClick={handleClickOpen}
                                className={classes.addButton}>
                                Add Instructor
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center"
                        className={classes.tableGrid}
                    >
                        <Grid item xs={12}>
                            <InstructorListTable instructors={instructors} handleChecked={handleChecked} handleSelectedInstructors={handleSelectedInstructors} handleAccessibility={handleAccessibility} />
                        </Grid>
                    </Grid>
                    {dialogOpen ? <AddInstructorDialog open={dialogOpen} close={handleClose} handleChangeInstructors={handleChangeInstructors}  /> : undefined}
                    <DeleteInstructorDialog open={deleteDialogOpen} close={handleCloseDelete} selectedInstructors={selectedInstructors} handleChangeInstructors={handleChangeInstructors} />
                    <CourseAccessibilityDialog open={accessibilityDialogOpen} handleClose={handleCloseAccessibilityDialog} studentId={instructorId} />
                </div>
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </>
    )
}

export default Dashboard
