import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Grid, Typography, Button, Backdrop } from '@material-ui/core'
import { DeleteOutlineRounded } from '@material-ui/icons'
import AdminListTable from './AdminListTable'
import DeleteAdminDialog from './DeleteAdminDialog'
import axios from 'axios'
import { IoHourglassOutline } from "react-icons/io5"

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        // backgroundColor: '#000000'
    },
    gridContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    headerTypography: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    tableGrid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    deleteButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

const AdminList = () => {
    const classes = useStyles()
    const [admins, setAdmins] = useState([])
    const [selectedAdmins, setSelectedAdmins] = useState([])

    const [disableDeleteButton, setDisableDeleteButton] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const handleChecked = (selectedItem) => {
        if (selectedItem > 0) {
            setDisableDeleteButton(false)
        } else {
            setDisableDeleteButton(true)
        }
    }

    const handleClickOpen = () => {
        setDialogOpen(true)
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleSelectedAdmins = (selectedAdmins) => {
        setSelectedAdmins(selectedAdmins)
    }

    const handleChangeAdmins = (updatedAdmins) => {
        setAdmins(updatedAdmins)
    }

    useEffect(() => {
        const getStudents = async () => {
            setIsLoading(true)
            await axios
                .get('admins/admin-list/', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('## ---- Admins Fetch Success ---- ##', response.status);
                        setAdmins(...admins, response.data)
                        setIsLoading(false)
                    } else {
                        console.log('## ---- Admins Fetch Failed ---- ##', response.status);
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log('## ---- Admins Fetch Error ---- ##', error);
                })
        }
        getStudents()
    }, [])

    return (
        <>
            {
                !isLoading &&
                <Container maxWidth={false} className={classes.container}>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justify='space-between'
                        className={classes.gridContainer}>
                        <Grid item xs={4}>
                            <Typography variant='h6' display='inline' className={classes.headerTypography}>Admins</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Button
                                startIcon={<DeleteOutlineRounded />}
                                variant='contained'
                                className={classes.deleteButton}
                                disabled={disableDeleteButton}
                                onClick={handleClickOpen}>
                                Delete Admin
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center">
                        <Grid item xs={12} className={classes.tableGrid}>
                            <AdminListTable admins={admins} handleChecked={handleChecked} handleSelectedAdmins={handleSelectedAdmins} />
                        </Grid>
                    </Grid>
                    <DeleteAdminDialog open={dialogOpen} close={handleClose} selectedAdmins={selectedAdmins} handleChangeAdmins={handleChangeAdmins} />

                </Container>
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </>

    )
}

export default AdminList
