import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Grid, Box, Typography, Tab, Tabs } from '@material-ui/core';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    rootM: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0),
    },
    title: {
        color: '#0B6AB0',
    },
    subtitle: {
        color: 'grey',
    },
    details: {
        marginTop: theme.spacing(0),
        width: '50vw',
    },
    detailsTitle: {
        color: '#0B6AB0',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    instructorTitle: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        color: '#0B6AB0',
    },
    instructorDetails: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

function Overview({ course, mobileView }) {
    const classes = useStyles();

    return (
        <div className={mobileView ? classes.rootM : classes.root}>
            <Typography variant="h5" color="initial" className={classes.title}>{course.title}</Typography>
            <Typography variant="h6" color="initial" className={classes.subtitle}>Duration: {course.duration} weeks</Typography>
            
            <Typography variant="h6" color="initial" className={classes.detailsTitle}>About</Typography>
            <Grid container>
                <Grid item xs={12} className={classes.details}>
                    <Paper elevation={1} variant='outlined'>
                        <ReactQuill
                            value={course.details || ''}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Typography variant="h5" color="initial" className={classes.instructorTitle}>Instructors</Typography>
            <Grid container>
                {course.instructor && course.instructor.map((ins, index) => (
                    <Grid item xs={12} spacing={2}>
                        <Paper elevation={1} className={classes.instructorDetails} variant='outlined'>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>{ins.name}</Typography>
                            <Typography variant="body2" style={{ marginBottom: '15px' }}>{ins.occupation}</Typography>
                            <Typography variant="body2">{ins.details}</Typography>
                            {/* <Typography variant="body2"><span>Email:</span> <i>{ins.contact_email}</i></Typography> */}
                        </Paper>
                    </Grid>
                ))}
            </Grid>           
        </div>
    );
}

export default Overview