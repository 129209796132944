import React from 'react'
import { DataGrid } from "@material-ui/data-grid";
import { createStyles, createTheme, makeStyles, withStyles  } from "@material-ui/core/styles";
import { LinearProgress, Typography } from '@material-ui/core';
import clsx from "clsx";


const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: `1px solid ${theme.palette.divider}`,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 16,
        borderRadius: 6,
      },
      value: {
        position: 'absolute',
        lineHeight: '14px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      bar: {
        height: '100%',
        '&.low': {
          backgroundColor: '#f44336',
        },
        '&.medium': {
          backgroundColor: '#ff9800',
        },
        '&.high': {
          backgroundColor: '#4caf50',
        },
      },
    }),
  { defaultTheme },
);


const ProgressBar = (props) => {
  const { value } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={classes.value}
      >{value}</div>
      <div
        className={clsx(classes.bar, {
          low: parseFloat(value.split('%')[0]) < 50,
          medium: parseFloat(value.split('%')[0]) >= 50 && parseFloat(value.split('%')[0]) <= 90,
          high: parseFloat(value.split('%')[0]) > 90
        })}
        style={{ maxWidth: value }}
      />
    </div>
  );
};

// Define column headers
const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 350 },
    { field: "examAttended", headerName: "Exams Attended", width: 350 },
    { field: "progress", headerName: "Cumulative Percentage", width: 350,
      renderCell: (params) => {
        return <ProgressBar value={params.value}/>
      }
    },
    // { field: "rank", headerName: "Rank", width: 120 },
];

// Create rows
function rows(results, totalExam) {
    const rows = [];
    results.map((result, index) =>
    rows.push({
        key: result.id,
        id: result.id,
        name: result.name,
        examAttended: result.exam_count + "/" + totalExam,
        progress: result.percentage + "%",
        })
    );
    return rows;
}


const CumulativeResultListTable = ({ results, totalExam }) => {
    const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(results, totalExam)}
        columns={columns}
        pageSize={10}
      />
    </div>
  )
}

export default CumulativeResultListTable