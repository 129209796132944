import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography, Box, Menu, MenuItem, Button, Backdrop } from '@material-ui/core'
import Course from './Course'
import { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { IoHourglassOutline } from "react-icons/io5"
import HomeBanner from './HomeBanner'

const useStyles = makeStyles((theme) => ({
    container: {
        //display: 'flex',
        //flexWrap: 'wrap',
        //justifyContent: 'space-around',
        //overflow: 'hidden',
        //margin: '15px 10px 15px 10px',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(10),
        backgroundColor: theme.palette.background.paper,
    },
    gridContainer: {
        //flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        //transform: 'translateZ(0)',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    gridTile: {
        position: 'relative',
        float: 'left',
        overflow: 'hidden',
        height: '100% !important'
    },
    sectionHeader: {
        color: '#008DE9',
        fontFamily: 'Raleway',
        fontSize: 32,
        borderBottom: '2px solid grey',
        borderTop: '2px solid grey',
    },
    sessionButton: {
        //backgroundColor: '#fcfdfe',
        // '&:hover': {
        //     background: '#0B6AB0',
        // },
        // color: '#FFFFFF',
        textTransform: 'none',
        //marginLeft: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}));

const AllCourses = () => {
    const classes = useStyles()
    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState("")
    const [courses, setCourses] = useState([])
    const [isCourseLoading, setIsCourseLoading] = useState(true)
    
    useEffect(() => {
        const getSessions = () => {
            axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    var _sessions = response.data;
                    var _sessions = [..._sessions, {id: 0, title: "All Sessions", details: "", studentcount: ""}]
                    setSessions(_sessions);
                    setSelectedSession(_sessions[0].title)
                    getCourses(_sessions[0].id)
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }
        getSessions();
    }, [])

    const getCourses = async (sessionId) => {
        setIsCourseLoading(true)
        await axios({
            method: 'GET',
            url: `courses/list/${sessionId}/`,
        }).then((response) => {
            if (response.status == 200) {
                setCourses(response.data)
            }
            setIsCourseLoading(false)
        })
        .catch((error) => {
            console.log(error);
            setIsCourseLoading(false);
        })
    }

    const handleSessionSelect = (id, title, popupState) => {
        popupState.close()
        setSelectedSession(title)
        getCourses(id)
    }

    return (
        <>
            <HomeBanner />
            <Container maxWidth={false} className={classes.container}>
                <Box display="flex" flexDirection="column" justifyContent="center" mb={4} mt={3} ml={3} mr={3}>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="button" component="h4" align="center" display="inline" className={classes.sectionHeader}>
                            Courses
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <Fragment>
                                <Button 
                                    className={classes.sessionButton}
                                    variant="contained"
                                    color="default"
                                    startIcon={<KeyboardArrowDownIcon />}
                                    {...bindTrigger(popupState)}
                                >
                                    {selectedSession}
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {sessions.map((session, index) => (
                                        <MenuItem onClick={() => handleSessionSelect(session.id, session.title, popupState)}>{session.title}</MenuItem>
                                    ))}
                                </Menu>
                                </Fragment>
                            )}
                        </PopupState>
                    </Box>
                </Box>
                <Grid container className={classes.gridContainer} spacing={4} justify="center">
                    {courses.length ?
                        courses.map((course, index) => (
                            <Course key={index} course={course} />
                        ))
                        :
                        <Grid item xs={12}>
                            <Typography variant='h5' align="center" color="textSecondary" style={{ marginTop: '40px' }}>{isCourseLoading ?
                                <Backdrop className={classes.backdrop} open={isCourseLoading}>
                                    <IoHourglassOutline style={{ fontSize: 35 }} />
                                </Backdrop> : 'No course available'}</Typography>
                        </Grid>
                    }
                </Grid>
            </Container>
        </>
    )
}

export default AllCourses
