import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Paper, useTheme,} from "@material-ui/core";
import { FiFileText, FiChevronRight } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import {getLocalDateFormat} from '../../custom_util'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import moment from 'moment';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    iconStyle:{
        position: 'relative',
        top: 2,
        marginRight: 4
    },
    tableHeader:{
        fontWeight: 'bold'
    },
    examEnterButton:{
        color: '#0B6AB0',
        textDecoration: 'none',
        marginRight: 3,
        "&:hover > $examEnterArrow": {
            marginLeft: theme.spacing(1),
            textDecoration: 'none',
        }
    },
    examEnterArrow: {
        color: '#0B6AB0',
        position: 'relative',
        top: '3px',
        "&:hover": {
            marginLeft: theme.spacing(1),
            textDecoration: 'none',
        }
    },
    noNoticeMessage:{
        paddingBottom: 50,
        paddingTop: 50,
        // fontWeight: 'bold',
        textAlign: 'center',
        height: '100%',
    }

}));

const NoticeBox = ({ courses }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [course, setCourse] = useState({})

    const [day, setDay] = useState('_ _')
    const [hour, setHour] = useState('_ _')
    const [minute, setMinute] = useState('_ _')
    const [second, setSecond] = useState('_ _')
    const [noNotice, setNoNotice] = useState(false)
    const [runningExam, setRunningExam] = useState(false)

    const get_duaration = () => {
        const now = new Date()
        //const end = new Date(course.start_time)
        const end = getLocalDateFormat(course.start_time)
        //userTime(course.start_time, "YYYY-MM-DDTHH:mm").toDate()
    
        if(now < end){
            var delta = Math.abs(end - now) / 1000;
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = parseInt(delta % 60)

            setDay(days)
            setHour(hours)
            setMinute(minutes)
            setSecond(seconds)
        } else{
            setDay(0)
            setHour(0)
            setMinute(0)
            setSecond(0)
            setRunningExam(true)
        }
    }

    useEffect(() => {
        let timer = null
        timer = setInterval(() => course && get_duaration(), 1000)

        return () => clearInterval(timer)
    }, [second])

    useEffect(() => {
        course && get_duaration()
    }, [course])

    useEffect(() => {
        if(courses && courses.length){
            let start = getLocalDateFormat(courses[0].start_time)
            let end = getLocalDateFormat(courses[0].end_time)
            setCourse(courses[0])
            setNoNotice(false)
            if(start < new Date() && end > new Date()){
                setRunningExam(true)
            } else setRunningExam(false)
        } else setNoNotice(true)
    }, [courses])

    return (
        <Paper variant="outlined" style={{marginTop: matches ? 45 : 15}}>
            <Box p={2} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.13)' }}>
                <FiFileText color="red" className={classes.iconStyle}/> Notice
            </Box>
            {noNotice ? 
                <Typography variant="h5" className={classes.noNoticeMessage} color="textSecondary">
                    No notice avialable
                </Typography>
                :
                <Box p={2}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                        <Typography style={{color: "gray"}}>Before Next Exam</Typography>
                        {runningExam ? <Typography variant="h6" style={{color: "#00BC9E"}}>Exam Running</Typography> :
                        second !== "_ _" && <Typography variant="h6" style={{color: "#00BC9E"}}>
                                {day ? `${day}d : ` : ''}{hour ? `${hour}h : `: ''}{minute ? `${minute}m : `: ''}{second && `${second}s`}
                            </Typography>
                        }
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography noWrap style={{color: "gray", maxWidth:"19vw"}}>{course && course.title}</Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" height='100%'>
                            <Link className={classes.examEnterButton} to={`/exam-instructions/${course && course.id}`}>Enter 
                            <FiChevronRight className={classes.examEnterArrow}/> </Link>
                        </Box>
                    </Box>
                </Box>
            }
        </Paper>
    )
}

export default NoticeBox
