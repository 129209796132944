import { useEffect, useState, useRef } from "react";
import { Box, Typography } from '@material-ui/core';
import { IoHourglassOutline } from "react-icons/io5";

// Ref: https://stackoverflow.com/a/61451002/7609325
function GViewer({url, sBox=false}) {
    const [iframeTimeoutId, setIframeTimeoutId] = useState(undefined);
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        const intervalId = setInterval(
            updateIframeSrc, 1000 * 3
        );
        setIframeTimeoutId(intervalId)
    },[]);

    function iframeLoaded() {
        clearInterval(iframeTimeoutId);
        setIsLoading(false);
    }
    function getIframeLink() {
        return `https://docs.google.com/gview?url=${url}&embedded=true`;
    }
    function updateIframeSrc() {
        if(iframeRef.current){
            iframeRef.current.src = getIframeLink();
        }
    }

    return (
        <>
        {isLoading && 
            <Box display="flex" flexDirection="column" height={window.innerHeight*0.9} justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IoHourglassOutline style={{ fontSize: 35, color: "#0B6AB0" }} />
                    <Typography variant="h6" color="initial" align="center" style={{ color: "grey", fontWeight: 'normal' }}>Loading... Please wait a moment.</Typography>
                </Box>
            </Box>
        }
        <iframe
            onLoad={iframeLoaded}
            onError={updateIframeSrc}
            sandbox={sBox}
            ref={iframeRef}
            src={getIframeLink()}
            width="100%"
            frameborder="0"
            height={isLoading ? 0 : window.innerHeight*0.9}
        />
        </>
    );
}

export default GViewer;