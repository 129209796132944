import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Box, Typography, TextField, StepContent, Button, Snackbar, Backdrop, InputAdornment, Select, MenuItem, Chip, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import 'react-quill/dist/quill.snow.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IoHourglassOutline } from "react-icons/io5";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    paper: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(3),
        padding: theme.spacing(5),
        //background: "#fdfdfd",
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    textField: {
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(1)
    },
    details: {
        marginBottom: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        minWidth: '20vw',
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    returnIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(3)
    },
    image: {
        //height: '50vh',
        width: '50vw',
        padding: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },
    menuItemRoot: {
        "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
            backgroundColor: "#0B6AB0",

        }
    },
    menuItemSelected: {
        color: '#FFFFFF'
    },
    updateButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        marginLeft: theme.spacing(1)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    addImageButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
}));

const modules = {
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        ["link", "image", "video"],
        ["clean"]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};


const EditCourse = ({ isInstructor }) => {
    const classes = useStyles()
    const history = useHistory()
    const { courseId } = useParams()

    const [title, setTitle] = useState("")
    const [details, setDetails] = useState("")
    const [duration, setDuration] = useState("")
    // const [fee, setFee] = useState("")
    const [image, setImage] = useState("")
    const [selectedInstructors, setSelectedInstructors] = useState([])
    const [instructors, setInstructors] = useState([])
    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState("")
    const [imageFilename, setImageFilename] = useState("")
    const [handleChange, setHandleChange] = useState(false)
    const [changedInstructors, setChangedInstructors] = useState([])
    const [instructorIds, setInstructorIds] = useState([])
    const [selectedSessionId, setSelectedSessionId] = useState("")
    const [updateImage, setUpdateImage] = useState(false)
    const [showUpdatedImage, setShowUpdatedImage] = useState("")
    const [privilege, setPrivilege] = useState("")

    const [errorTitle, setErrorTitle] = useState("");
    const [errorDuration, setErrorDuration] = useState("");
    const [errorFee, setErrorFee] = useState("");
    const [errorSelectedInst, setErrorSelectedInst] = useState(false);
    const [errorSelectedSession, setErrorSelectedSession] = useState(false);

    const [openUpdateSnackbar, setOpenUpdateSnackbar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const getInstructors = () => {

            axios({
                method: 'GET',
                url: 'instructors/instructor-list/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {

                    console.log('Instructors fetch success!', response.status);
                    setInstructors(response.data)
                } else {
                    console.log('Instructors fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Instructors fetch failed!', error);
            })
        }
        const getSessions = () => {
            axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    setSessions(response.data);
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }

        const getCourseDetails = () => {
            setIsLoading(true)
            axios({
                method: 'GET',
                url: `courses/course-details/${courseId}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false)
                        console.log('## ---- Course Details Fetched ---- ##', response.status);
                        setPrivilege(response.data.role)

                        var course = response.data.course
                        if (course.title) {
                            setTitle(course.title)
                        }

                        if (course.details) {
                            setDetails(course.details)
                        }

                        if (course.duration) {
                            setDuration(course.duration)
                        }

                        // if (course.fee) {
                        //     setFee(course.fee)
                        // }

                        if (course.image) {
                            setImage(course.image)
                        }

                        if (course.instructor) {
                            var data = course.instructor
                            var instructorNames = []
                            var ids = []
                            for (let index = 0; index < data.length; index++) {
                                instructorNames.push(data[index].name)
                                ids.push(data[index].id)
                            }
                            setSelectedInstructors(...selectedInstructors, instructorNames)
                            setInstructorIds(...instructorIds, ids)
                        }

                        if (course.academic_session) {
                            setSelectedSession(course.academic_session.title)
                            setSelectedSessionId(course.academic_session.id)
                        }
                    } else {
                        console.log('## ---- Course Details Fetch Failed ---- ##', response.status);
                    }
                })
                .catch((error) => {
                    console.log('## ---- Course Details Fetch Error ---- ##', error);
                })
        }
        getCourseDetails()
        getSessions()
        getInstructors()
    }, [])

    const handleSelectInstructor = (event) => {
        setHandleChange(true)
        setChangedInstructors(event.target.value)
        var id = event.currentTarget.getAttribute("id")
        if (instructorIds.includes(parseInt(id))) {
            var ids = [...instructorIds];
            var index = ids.indexOf(parseInt(id))
            ids.splice(index, 1);
            setInstructorIds(ids);
        }
        else {
            setInstructorIds([...instructorIds, parseInt(id)]);
        }
    }

    const handleSelectSession = (event) => {
        setSelectedSession(event.target.value)
        var id = event.currentTarget.getAttribute("id")
        setSelectedSessionId(id)
    }

    const handleSelectImage = (file) => {
        setUpdateImage(true)
        setImage(file)
        setImageFilename(file.name)
        setShowUpdatedImage(URL.createObjectURL(file))
    }

    const updateCourse = () => {
        if (validate()) {
            const uploadData = new FormData();
            if (updateImage) {
                uploadData.append('image', image, image.name);
            } else {
                uploadData.append('image', '');
            }

            uploadData.append('instructor_ids', JSON.stringify(instructorIds));
            uploadData.append('academic_session_id', selectedSessionId);
            uploadData.append('title', title);
            uploadData.append('details', details);
            uploadData.append('duration', duration);
            // uploadData.append('fee', fee);
            axios({
                method: 'PUT',
                url: `courses/course-details/${courseId}`,
                data: uploadData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status == 200) {
                    console.log('## ---- Course Update Success ---- ##', response.status);
                    setOpenUpdateSnackbar(true);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    const handleCloseUpdateSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUpdateSnackbar(false);
    };

    const validate = () => {
        var validated = true;

        if (title == '') {
            setErrorTitle('Must not be kept blank');
            validated = false;
        }
        else {
            setErrorTitle('');
        }
        if (isNaN(parseInt(duration))) {
            setErrorDuration('Must be an integer');
            validated = false;
        }
        else {
            setErrorDuration('');
        }
        // if (isNaN(parseInt(fee))) {
        //     setErrorFee('Must be a number');
        //     validated = false;
        // }
        // else {
        //     setErrorFee('');
        // }

        if (instructorIds.length === 0) {
            setErrorSelectedInst(true);
            validated = false;
        }
        else {
            setErrorSelectedInst(false);
        }

        if (selectedSession === "") {
            setErrorSelectedSession(true);
            validated = false;
        }
        else {
            setErrorSelectedSession(false);
        }

        return validated;
    }

    return (
        <div>
            <>
                {
                    !isLoading &&
                    <Container maxWidth={false} className={classes.container}>
                        <Box display='flex' flexDirectoin='row' alignItems='center'>
                            <ArrowBackIosIcon className={classes.returnIcon} onClick={() => history.goBack()} />
                            <Typography variant='h6' className={classes.header} display='inline'>Edit Course</Typography>
                        </Box>
                        <Paper elevation={1} className={classes.paper} variant='outlined' square>
                            <Grid container
                                direction='row'
                                justify="center"
                            >
                                <Grid item xs={9} md={6} lg={6}>
                                    {updateImage ? showUpdatedImage && <img src={showUpdatedImage} className={classes.image} /> :
                                        <img src={`${contentBaseURL}${image}`} className={classes.image} />}
                                </Grid>
                            </Grid>
                            <Box display="flex" flexDirection="column" mb={2}>
                                <Box display='flex' flexDirection='row' alignItems='center'>
                                    <Button variant="outlined" className={classes.addImageButton} size='small' component="label" startIcon={<AddAPhotoOutlinedIcon />} style={{ textTransform: 'none' }} disableElevation>
                                        Change Course Image
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) => handleSelectImage(event.target.files[0])}
                                            hidden
                                        />
                                    </Button>
                                    <Typography variant="body2" color="textSecondary" style={{ marginLeft: '10px' }}>Selected file: {imageFilename ? imageFilename : 'No image selected'}</Typography>
                                </Box>
                            </Box>
                            <Grid container>
                                <Grid item xs={8}>
                                    <TextField
                                        className={classes.textField}
                                        label="Course Title"
                                        variant="outlined"
                                        size='small'
                                        value={title}
                                        error={errorTitle.length === 0 ? false : true}
                                        helperText={errorTitle}
                                        onChange={(event) => setTitle(event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        className={classes.textField}
                                        label="Duration (weeks)"
                                        variant="outlined"
                                        size='small'
                                        type='number'
                                        value={duration}
                                        error={errorDuration.length === 0 ? false : true}
                                        helperText={errorDuration}
                                        onChange={(event) => setDuration(event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextField
                                        className={classes.textField}
                                        label="Fee ($)"
                                        variant="outlined"
                                        size='small'
                                        type='number'
                                        value={fee}
                                        error={errorFee.length === 0 ? false : true}
                                        helperText={errorFee}
                                        onChange={(event) => setFee(event.target.value)}
                                        fullWidth
                                    />
                                </Grid> */}
                            </Grid>
                            <Typography variant="subtitle1" style={{ color: "gray" }}>
                                Details
                            </Typography>
                            <ReactQuill
                                className={classes.details}
                                theme="snow"
                                value={details}
                                modules={modules}
                                onChange={(details) => setDetails(details)}
                            />
                            {isInstructor ? <> </> :
                                <Box>
                                    <FormControl
                                        // error={errorSelectedInst}
                                        variant="outlined"
                                        size='small'
                                        className={classes.formControl}>
                                        <InputLabel>Select Instructors</InputLabel>
                                        <Select
                                            multiple
                                            value={handleChange ? changedInstructors : selectedInstructors}
                                            label="Select Instructors"
                                            onChange={handleSelectInstructor}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {selected ? selected.map((value, index) => (
                                                        <Chip key={index} label={value} className={classes.chip} />
                                                    )) : undefined}
                                                </div>
                                            )}
                                        >
                                            {instructors.length > 0 ?
                                                instructors.map((instructor, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={instructor.name}
                                                        id={instructor.id}
                                                        classes={{
                                                            root: classes.menuItemRoot,
                                                            selected: classes.menuItemSelected
                                                        }}
                                                    >{instructor.name}</MenuItem>
                                                )) : <><MenuItem key={0}>No instructor available</MenuItem> </>
                                            }
                                        </Select>
                                        {errorSelectedInst && <FormHelperText>Must not be kept blank</FormHelperText>}
                                    </FormControl>
                                </Box>
                            }

                            <Box mt={3}>
                                <FormControl
                                    error={errorSelectedSession}
                                    variant="outlined"
                                    size='small'
                                    className={classes.formControl}>
                                    <InputLabel>Select Session</InputLabel>
                                    <Select
                                        value={selectedSession}
                                        label="Select Session"
                                        onChange={handleSelectSession}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                <Chip key={selected} label={selected} className={classes.chip} />
                                            </div>
                                        )}
                                    >
                                        {sessions.length > 0 ?
                                            sessions.map((session, index) => (
                                                <MenuItem key={session.id} value={session.title} id={session.id}>{session.title}</MenuItem>
                                            )) : <><MenuItem key={0}>No Session available</MenuItem> </>
                                        }
                                    </Select>
                                    {errorSelectedSession && <FormHelperText>Must not be kept blank</FormHelperText>}
                                </FormControl>
                            </Box>

                            <div className={classes.buttons}>
                                <Button color="primary" onClick={() => history.goBack()}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" className={classes.updateButton} onClick={updateCourse}>
                                    Update
                                </Button>
                            </div>
                        </Paper>
                    </Container>
                }

                <Snackbar open={openUpdateSnackbar} autoHideDuration={4000} onClose={handleCloseUpdateSnackbar}>
                    <Alert onClose={handleCloseUpdateSnackbar} severity="success">
                        Course updated successfully!
                    </Alert>
                </Snackbar>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                </Backdrop>
            </>
        </div>
    )
}

const mapStateToProps = state => ({
    isInstructor: state.auth.isInstructor,
});

export default connect(mapStateToProps)(EditCourse)
