import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Container,Grid, Box, Typography, Paper, useTheme, Dialog, AppBar, Toolbar, IconButton, Tooltip,
    Button, Tabs, Tab, Backdrop, Snackbar, Checkbox, Chip, Slide   } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useParams, useHistory } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IoHourglassOutline } from "react-icons/io5";
import { FiInfo } from "react-icons/fi";
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactQuill, {Quill} from 'react-quill';
import { useConfirm } from "material-ui-confirm";
import ExamTimer from './ExamTimer';
import CloseIcon from '@material-ui/icons/Close';
import PictureUpload from './PictureUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageUploader from "quill-image-uploader";
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import "./examQuestion.css"
import GViewer from "../GViewer"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { connect } from 'react-redux';
import {userTime, getLocalDateFormat, fromNowMoment, webSocketBase} from '../../custom_util'

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

Quill.register("modules/imageUploader", ImageUploader);
// hljs.configure({   
//     languages: ['javascript', 'ruby', 'python']
//   });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// var IMGUR_CLIENT_ID = 'bb57a9e7d2fc8a4';  // Created with nextgendigitechnl@gmail.com
// var IMGUR_API_URL = 'https://api.imgur.com/3/image';

const useStyles = makeStyles((theme) => ({
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    iconStyle:{
        position: 'relative',
        marginRight: 3,
    },
    tableHeader:{
        fontWeight: 'bold'
    },
    rightMargin:{
        marginRight: 3,
        "&:hover": {
            marginRight: theme.spacing(1)
        }
    },
    details: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(3),
        // height: theme.spacing(50),
    },
    horizontalItem: {
        display: 'flex',
        justifyContent: 'space-between',
        p: 5
    },
    tabs:{
        paddingTop: theme.spacing(6),
        backgroundColor: '#FCFDFE',
        // position: 'sticky',
        // height: '100%',
        "& .MuiTabs-indicator": {
            backgroundColor: '#3b98da',
        },
    },
    tab:{
        minWidth: '100%',
        width: '100%',
        border: '2px solid #edf0f5',
        borderRadius: '5px',
    },
    bottomNavigation:{
        position: 'fixed',
        bottom: theme.spacing(7),
        right: 0,
        width: '95vw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
    },
    finishBtn: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none',
        marginLeft: 8,
        marginRight: 8,
    },
    confirmButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        marginTop: "48px",
        color: '#0B6AB0',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
    },
    fileList: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(0.5),
        backgroundColor: "#0B6AB0",
        color: 'white',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        '&:focus': {
            backgroundColor: '#0B6AB0',
        },
    },
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "#0B6AB0",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: "black",
        maxWidth: '30vw',
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box>
            <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


let modules = {
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", 'code-block'],
        [
            { list: "ordered" },
            { list: "bullet" },
            //{ indent: "-1" },
            //{ indent: "+1" }
        ],
        //["link", "image", "video"],
        //["image"],
        ["clean"]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};


function ExamQuestion({ user }) {
    const classes = useStyles();
    const confirm = useConfirm();
    const history = useHistory();
    const loaded = useRef(false)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const { examId } = useParams();

    const[value, setValue] = useState(0)
    const [isCourseLoading, setIsCourseLoading] = useState(true);
    const [questions, setQuestions] = useState()
    const [error, setError] = useState()
    const [answers, setAnswers] = useState({"ans": [], 'curr': null})
    const [exam, setExam] = useState()
    const [openSnackBar, setSnackBar] = useState({isOpen:false, message:"", status:""})
    const [examFinish, setExamFinish] = useState(false);
    const [examEndTime, setExamEndTime] = useState('')
    const [finalDescAnswer, setFinalDescAnswer] = useState({"ans": [], 'curr': 0})
    const [loadingFlag, setLoadingFlag] = useState()
    const [openFile, setOpenFile] = useState({open:false, title:"", link:""})


    // const get_duaration = () => {
    //     const now = new Date()
    //     //const end = new Date(exam.end_time)
    //     const end = getLocalDateFormat(exam.end_time)

    //     if(now < end){
    //         var delta = Math.abs(end - now) / 1000;

    //         // calculate (and subtract) whole minutes
    //         var minutes = Math.floor(delta / 60);
    //         delta -= minutes * 60;

    //         // what's left is seconds
    //         var seconds = parseInt(delta % 60)
    //         setHours(hours)
    //         setMinute(minutes)
    //         setSecond(seconds)
    //     } else{
    //         setHours(0)
    //         setMinute(0)
    //         setSecond(0)
    //     }
    // }

    // useEffect(() => {
    //     let timer = null
    //     if(minute === 0 && second === 0) confirmExamFinish("Your time is over. Please submit your answer.")
    //     timer = setInterval(() => exam && get_duaration(), 1000)
    //     return () => clearInterval(timer)
    // }, [second, exam])

    const handleValue = (data) => setValue(data)
    const makeExamFinish = (data) => setExamFinish(data)

    useEffect(() => {
        setIsCourseLoading(true)
        const getExams = () => {
            axios({
                method: 'GET',
                url: `exams/exam-participate/${examId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Exams fetch success!', response.status);
                    setExamEndTime(response.data.end_time);
                    setExam(response.data);
                    setQuestions(response.data.question);
                    setIsCourseLoading(false);
                    let descAns = {}
                    response.data.question.filter(ques => ques.type === "Descriptive" && ques.descriptive_q.descriptive_q_ans.length > 0)
                                                        .map(ques => 
                                                            descAns[ques.descriptive_q.id] = {
                                                                id: ques.descriptive_q.id,
                                                                ans: ques.descriptive_q.descriptive_q_ans[0].ans,
                                                                type:"Descriptive"
                                                            }
                                                        )
                    setAnswers({...finalDescAnswer, ans:descAns, curr: null})
                    
                } else {
                    console.log('Exams fetch failed!', response.status);
                    setIsCourseLoading(false)
                }
            }).catch((error) => {
                console.log('Exams fetch failed!', error.response.data);
                setIsCourseLoading(true)
                setError(error.response.data.error)
            })
        }
        getExams()
    }, [])

    const handleMCQSubmit = (option, event=null) => {
        if(option.type === "MCQ"){
            setLoadingFlag(option.ans)
            setExamFinish(true)
        }

        axios({
            method: 'PUT',
            url: `exams/exam-participate/${examId}/`,
            data: option,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                console.log('Exams saved success!',  response.status);
                if(response.data.type === "M"){
                    let x = questions.map((question) => {
                        if(response.data.type === "M" && question.type === "MCQ"){
                            if(question.mcq.id === response.data.id)
                                question.mcq = response.data
                        }
                        return question
                    })
                    setQuestions(x);
                } else if(response.data.type === "D"){
                    let x = questions.map(question => {
                        if(response.data.type === "D" && question.type === "Descriptive"){
                            if(question.descriptive_q.id === option.id){
                                question.descriptive_q.descriptive_q_ans[0].ans = option.ans
                            }
                        }
                        return question
                    })
                    setQuestions(x);
                }
                setIsCourseLoading(false);
                setSnackBar({isOpen:false, message:"Saved", status:"success"})
                setExamFinish(false)
            } else if(response.status === 201){
                console.log('Exams saved success!',  response.status);
                if(response.data.type === "D"){
                    let x = questions.map(question => {
                        if(response.data.type === "D" && question.type === "Descriptive"){
                            if(question.descriptive_q.id === option.id){
                                question.descriptive_q.descriptive_q_ans[0] = response.data.ans
                            }
                        }
                        return question
                    })
                    setQuestions(x);
                }

            } else {
                console.log('Exams saved failed!', response.status);
                setSnackBar({isOpen:true, message:"Something wrong, please try again!", status:"error"})
                setIsCourseLoading(false)
                setExamFinish(false)
            }
        }).catch((error) => {
            console.log('Exams saved ss failed!', error);
                setSnackBar({isOpen:true, message:"Something wrong, please try again!", status:"error"})
                setIsCourseLoading(false)
                setExamFinish(false)
        })
    }
    
    const finishExam = () => {
        axios({
            method: 'POST',
            url: `exams/exam-participate/${examId}/`,
            data: {'data': "Exam Finished", "desc": answers.ans},
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                console.log('Exams saved success and Finished!', response.status);
                history.push({pathname:'/exam-history', state:{openSnackBar:true, message:"You answer successfully submitted", status:"success"}})
            } else {
                console.log('Exams saved failed and not finished!', response.status);
            }
        }).catch((error) => {
            console.log('Exams saved failed and not finished!', error);
        })
    }

    useEffect(() => {
        if (loaded.current){
            const delay = setTimeout(() => {
                !examFinish && answers.curr !== null && answers.ans[answers.curr].type === "Descriptive" && handleMCQSubmit(answers.ans[answers.curr])
            }, 5000)
            return () => clearTimeout(delay)
        } else loaded.current = true
    }, [answers])

    useEffect(() => {
        var examEndPoint = webSocketBase() + '/exam-socket/'
        var examSocket = new WebSocket(examEndPoint);
        
        examSocket.onmessage = (message) => {
            var data = JSON.parse(message.data);
            if (data) {
                if (data.event == 'change_exam_duration_all') {
                    if (data.user_id === user.id && data.exam_id === parseInt(examId)) {
                        setExamEndTime(data.end_time);
                        setSnackBar({isOpen:true, message: 'You have given additional exam duration.', status: 'info'});
                    }
                }
            }
        };
        examSocket.onerror = function() {
            console.log('Connection Error');
        };
        return () => examSocket.onclose;
    }, [])

    useEffect(() => {
        var examEndPoint = webSocketBase() + '/exam-socket/'
        var examSocket = new WebSocket(examEndPoint);
        
        examSocket.onmessage = (message) => {
            var data = JSON.parse(message.data);
            if (data && user) {
                if (data.event == 'change_exam_duration_individual') {
                    if (data.user_id === user.id && data.exam_id === parseInt(examId)) {
                        setExamEndTime(data.end_time);
                        setSnackBar({isOpen:true, message: 'You have given additional exam duration.', status: 'info'});
                    }
                }
            }
        };
        examSocket.onerror = function() {
            console.log('Connection Error');
        };
        return () => examSocket.onclose;
    }, [user])
    

    const showError = (show, message, status) => {
        setSnackBar({isOpen:show, message:message, status:status})
    }

    const singleMcqOption = (question) => (
        <Box>
            {examQuestion(question)}
            {question.choice.map((option, key) => (
                <Box display="flex" justifyContent="space-between" style={{padding: 10}}>
                    <Button onClick={() => !examFinish && handleMCQSubmit({id:question.id, type:"MCQ", ans:option.id})} disabled={examFinish && true}>
                        {question.mcq_ans.length && question.mcq_ans[0].choice.id === option.id ? <CheckBoxIcon style={{color:"#00BC9E"}}/> : <CheckBoxOutlineBlankIcon/>}
                    </Button>
                    <Paper variant='outlined' style={{padding: 5, width: '95%', textAlign: 'center'}}>
                        {option.text}
                    </Paper>   
                </Box>
            ))}
        </Box>
    )

    const multipleOptionMcq = (question) => (
        <Box>
            {examQuestion(question)}
            {question.choice.map((option, key) => (
                <Box display="flex" justifyContent="space-between" style={{padding: 10}}>
                    <Checkbox
                        checked={option.is_selected}
                        style={{transform: "scale(1)"}}
                        disabled={examFinish && true}
                        onChange={(event) => !examFinish && handleMCQSubmit({id:question.id, type:"MCQ", ans:option.id}, event)}
                        name="test"
                        color="default"
                        icon={examFinish && loadingFlag === option.id ? <CircularProgress size={24} style={{color:"#00BC9E", }}/> : <CheckBoxOutlineBlankIcon />}
                        checkedIcon={examFinish && loadingFlag === option.id ? <CircularProgress size={24} style={{color:"#00BC9E", }}/> : <CheckBoxIcon style={{color:"#00BC9E"}}/>}
                        />
                <Paper variant='outlined' style={{padding: 5, width: '95%', textAlign: 'center'}}>
                    {option.text}
                </Paper> 
            </Box>
            ))}
        </Box>
    )

    //{"id":1, "type": "MCQ", "ans":1}

    const examQuestion = (question, question_picture) => {
        return <Grid container spacing={2}>
            <Grid item sm={10} xs={10}>
                <Box width='100%' mb={2} display="flex">
                    <Typography display="inline" variant='body1' style={{wordWrap: "break-word", paddingRight:"1rem"}} dangerouslySetInnerHTML={{__html: question.q_text}} />
                    {/* <Typography display="inline" variant='body1' style={{color:"#a2a6a3", fontSize:'.9rem'}}>Save</Typography> */}
                </Box>
                <Paper component="ul" elevation={0} className={classes.fileList}>
                    {question_picture && question_picture.map((file, i) => (
                        <li key={i}>
                            <Chip
                                //icon={icon}
                                label={file.name}
                                clickable
                                onClick={() => setOpenFile({open:true, title:file.name, link: file.file})}
                                onDelete={() => setOpenFile({open:true, title:file.name, link: file.file})}
                                deleteIcon={<VisibilityIcon style={{color: "white"}}/>}
                                className={classes.chip}
                            />
                        </li>
                    ))}
                </Paper>
                
            </Grid>
            <Grid item sm={2} xs={2}>
                <Box textAlign='right'><Typography variant="body2">{question.marks} Marks</Typography></Box>
            </Grid>
        </Grid>
    }
    
    const descriptiveQuestion = (question, question_picture) => {
        return <Box>
            {examQuestion(question, question_picture)}
            <ReactQuill
                className={classes.details}
                readOnly={examFinish && true}
                theme="snow"
                modules={modules}
                placeholder="Type your answer here..."
                defaultValue={question.descriptive_q_ans.length && question.descriptive_q_ans[0].ans}
                onChange={(value) => {
                    //setAnswers({id: question.id, type:"Descriptive", ans:value})
                    //setFinalDescAnswer(prevState => {...finalDescAnswer.ans, [question.id]: value})
                    setAnswers({...answers, 
                        ans:{...answers.ans, [question.id]: {id: question.id, type:"Descriptive", ans:value}},
                        curr: question.id})
                    //question.descriptive_q_ans[0].ans = value
                }}
            />
        </Box>
    }
    
    const confirmExamFinish = (des="Are you sure to finish the exam?") => {
        confirm({
            title: <Typography variant='h6' style={{ fontWeight: 'normal' }}>Finish Exam</Typography>,
            description: <Typography variant='body1'>{des}</Typography>,
            confirmationText: 'Confirm',
            confirmationButtonProps: { className: classes.confirmButton, disableElevation: true },
            cancellationButtonProps: { className: classes.cancelBtn, disableElevation: true },
        })
        .then(() => finishExam())
        .catch(() => console.log('Cancelled!'));
    }

    const addFile = (answer, question_id) => {
        let x = questions.map(question => {
            if(question.type === "Descriptive"){
                if(question.descriptive_q.id === question_id){
                    question.descriptive_q.descriptive_q_ans[0] = answer
                }
            }
            return question
        })
        setQuestions(x);
    }

    const QuestionFileViewr = () => {
        return (
            <Dialog fullScreen open={openFile.open} onClose={() => setOpenFile({...openFile, open:false})} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} elevation={0}>
                    <Toolbar variant='dense'>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <IconButton edge="start" size="small" color="textSecondary" onClick={() => setOpenFile({...openFile, open:false})}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography noWrap variant="body1" display="inline" className={classes.title}>
                                    {openFile.title}
                                </Typography>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                {}
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <GViewer url={`${contentBaseURL}${openFile.link}`} sBox="allow-scripts allow-same-origin"/>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
   
    return (
        <Grid container style={{height: '100vh'}}>
            {QuestionFileViewr()}
            {isCourseLoading ? 
                    <Backdrop className={classes.backdrop} open={isCourseLoading}>
                        {error ?
                            <FiInfo style={{ fontSize: 35 }} />
                            : <IoHourglassOutline style={{ fontSize: 35 }} /> }
                        <Typography> {error} </Typography>
                    </Backdrop> :  <> 
            <Grid item sm={1} xs={1}>
                <Paper style={{height:"100%", width: '5vw', marginBottom: '3.5rem'}}>
                    <Tabs
                        value={value}
                        textColor='primary'
                        variant="scrollable"
                        orientation="vertical"
                        aria-label="week-view"
                        
                        className={classes.tabs}
                        onChange={(event, newValue) => setValue(newValue)}
                    >
                        {questions.map((question, index) =>(
                            <Tab className={classes.tab} label={`Q${index+1}`}
                                style={question.type === "MCQ" ? 
                                        question.mcq.selected_count ?
                                                {backgroundColor:"#00BC9E", color:'white'}
                                                : {backgroundColor:"inherit"} :
                                        question.descriptive_q.id in answers.ans ?
                                                {backgroundColor:"#00BC9E", color:'white'}
                                                : {backgroundColor:"inherit"}
                                            }/>
                        ))}
                        {/*style={question.type === "MCQ" ? question.mcq.mcq_ans.length && {backgroundColor: 'green'} :  question.descriptive_q.descriptive_q_ans.length && {backgroundColor: 'green'} } 
                        <Tab className={classes.tab} label="Q3"/>
                        <Tab className={classes.tab} label="Q4"/> */}
                    </Tabs>
                </Paper>
            </Grid>

            <Grid item sm={11} xs={11}>
            
                <Box pt={10} pb={12} pr={5}>
                    {questions.map((question, key) => (
                        <TabPanel value={value} index={key} key={key}>
                            {question.type === "Descriptive" ? descriptiveQuestion(question.descriptive_q, question.question_picture) : multipleOptionMcq(question.mcq, question.question_picture)}
                            {question.type === "Descriptive" && <PictureUpload fileData={question.descriptive_q.descriptive_q_ans}
                                examId={examId} showError={showError} question={question.descriptive_q.id} addFile={addFile}/>}
                            {/* question.type === "Descriptive" && <PictureUpload fileType={"doc"} fileData={question.descriptive_q.descriptive_q_ans}
                                examId={examId} showError={showError} question={question.descriptive_q.id} addFile={addFile}/> */}
                        </TabPanel>
                    ))}

                </Box>
                <ExamTimer confirmExamFinish={confirmExamFinish} value={value} exam={exam} handleValue={handleValue} makeExamFinish={makeExamFinish}/>  
            </Grid>
            </>}
            
        <Snackbar
            open={openSnackBar.isOpen}
            autoHideDuration={2500}
            onClose={() => setSnackBar(prevState => ({...prevState, isOpen:false}))}
        >
            <Alert onClose={() => setSnackBar(prevState => ({...prevState, isOpen:false}))} severity={openSnackBar.status}>
                {openSnackBar.message}
            </Alert>
        </Snackbar>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

export default connect(mapStateToProps) (ExamQuestion)


