import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
//import { flexbox } from '@material-ui/system';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // height: '100%',
        position: 'fixed',
        width: '100%',
        bottom: '0',
        //minHeight: '100vh',
    },
    footer: {
        padding: theme.spacing(2, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    footerM: {
        padding: theme.spacing(1, 1),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

function Copyright({mobileView}) {
    return (
        mobileView ?
            <Grid container>
                <Grid item xs={12}>
                    <Typography noWrap variant="caption" color="textSecondary" >
                        {'© '} {new Date().getFullYear()} { }
                        <Link color="inherit" href="https://qateksolutions.com/" target="_blank">
                            QA Tek Solutions Inc.| All rights reserved.
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography noWrap variant="caption" color="textSecondary">
                        {'Powered by '}
                        <Link color="inherit" href="http://nextgendigitech.nl/" target="_blank">
                            NextGen DigiTech.
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            :
            <Grid container>
                <Grid item xs={6} md={6}>
                    <Typography noWrap variant="body2" color="textSecondary" >
                        {'Copyright © '} {new Date().getFullYear()} { }
                        <Link color="inherit" href="https://qateksolutions.com/" target="_blank">
                            QA Tek Solutions Inc.| All rights reserved to QA Tek Solutions Inc.
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography noWrap variant="body2" color="textSecondary" style={{ textAlign: 'right' }}>
                        {'Powered by '}
                        <Link color="inherit" href="http://nextgendigitech.nl/" target="_blank">
                            NextGen DigiTech.
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
    );
}

const Footer = () => {
    const classes = useStyles()
    const [mobileView, setMobileView] = React.useState(false);

    React.useEffect(() => {
        // checkAuthenticated();
        // loadUser();
        // checkAuthorizedUser();
        const setResponsiveness = () => {
          return window.innerWidth < 900 ?
            setMobileView(true) : setMobileView(false)
        }
        setResponsiveness();
        window.addEventListener('resize', () => setResponsiveness())
    
        return () => {
          window.removeEventListener('resize', () => setResponsiveness())
        }
    }, []);

    return (
        <div className={classes.root} >
            <footer className={mobileView ? classes.footerM : classes.footer}>
                <Container maxWidth={false} className={classes.row}>
                    <Copyright mobileView={mobileView} />
                </Container>
            </footer>
        </div>
    )
}

export default Footer
