import { Box, FormControlLabel, Paper, MenuItem, Typography, TextField, Grid, useTheme, Button, Collapse, Snackbar, Backdrop, FormControl, InputLabel, Select, Chip, ListItemText, FormHelperText } from '@material-ui/core';
import { FiCalendar, FiClock, FiTrendingUp } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import axios from "axios";
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EnabledStudentList from './EnabledStudentList';
import { IoHourglassOutline } from 'react-icons/io5';
import ExamScheduleEditDialog from './ExamScheduleEditDialog';
import { userTime, utcTime } from '../../../custom_util';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    examActionSep: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    calendarIcon: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    clockIcon: {
        color: '#AF2323',
        marginRight: 4,
    },
    durationIcon: {
        color: '#00BC9E',
        marginLeft: 8,
        marginRight: 4,
    },
    fieldset: {
        border: '1px solid #0B6AB0',
        borderRadius: 4,
        margin: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    legend: {
        color: '#0B6AB0',
        paddingLeft: '2px',
        paddingRight: '2px',
        fontSize: 16
    },
    input: {
        width: '350px',
        marginLeft: '8px'
    },
    saveButton: {
        backgroundColor: '#4caf50',
        color: '#ffffff',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#3f9142',
            color: '#ffffff',
        },
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
        fontSize: 15
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
    scheduleButton: {
        marginLeft: '0px',
        '&:hover': {
            background: '#fff7eb',
        },
        borderColor: '#ff9800',
        color: '#ff9800',
        margin: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 350,
        width: 350
    },
    menuItemRoot: {
        "&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover": {
            backgroundColor: "#0B6AB0",

        }
    },
    menuItemSelected: {
        color: '#FFFFFF'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const examStatusColor = {
    'Unpublished': 'grey',
    'Published': '#0B6AB0',
    'Finished': '#00BC9E',
}

const Schedule = ({ examId, examEnableAll, open }) => {
    const classes = useStyles();
    const [enableAll, setEnableAll] = useState({
        enable: examEnableAll,
        enableText: examEnableAll ? 'Enabled All' : 'Disabled All',
        tooltip: examEnableAll ? 'Enabled: Exam will start at the same time for all students' : 'Disbaled: Exam will not start at the same time for all students'
    });

    const [examDate, setExamDate] = useState('');
    const [examStartTime, setExamStartTime] = useState('');
    const [examDuration, setExamDuration] = useState('');
    const [examStatus, setExamStatus] = useState('');
    const [students, setStudents] = useState([]);
    const [studentSearchLoading, setStudentSearchLoading] = useState(true);
    const [examSchedules, setExamSchedules] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectExamTime, setSelectExamTime] = useState('');
    const [examScheduleId, setExamScheduleId] = useState('');
    const [scheduleIndex, setScheduleIndex] = useState('');
    const [participants, setParticipants] = useState(0);
    const [showScheduleHtml, setShowScheduleHtml] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [sessionId, setSessionId] = useState([]);
    const [sessionStudents, setSessionStudents] = useState([]);
    const [selectStudentName, setSelectStudentName] = useState([]);
    const [selectSession, setSelectSession] = useState([]);

    const [selectSessionErrorText, setSelectSessionErrorText] = useState(false);
    const [selectStudentsErrorText, setSelectStudentsErrorText] = useState(false);
    const [selectExamTimeErrorText, setSelectExamTimeErrorText] = useState(false);

    const [openExamScheduleSnack, setOpenExamScheduleSnack] = useState(false);
    const [openExamScheduleErrorSnack, setOpenExamScheduleErrorSnack] = useState(false);

    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);

    const [openExamScheduleDialog, setOpenExamScheduleDialog] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const getExamDetails = async () => {
            await axios({
                method: 'GET',
                url:  `exams/exam-details/${examId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                console.log("# ---- Exam details success ---- #", response.status);
                setExamDate(response.data.start_time);
                setExamStartTime(response.data.start_time);
                setExamDuration(response.data.duration);
                setExamStatus(response.data.status);
            }).catch((error) => {
                console.log("# ---- Exam details error ---- #", error);
            })
        }
        if (open) {
            getExamDetails();
        }
        
    }, [open])

    useEffect(() => {
        const getExamSchedules = async () => {
            setIsLoadingSchedule(true);
            await axios({
                method: 'GET',
                url: 'exams/exam-schedule-list/',
                params: {
                    exam_id: examId
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                console.log("# ---- Exam schedule list success ---- #");
                setIsLoadingSchedule(false);
                setExamSchedules([]);
                setExamSchedules(response.data.users)
                setParticipants(response.data.participants)
                console.log(response.data);
            }).catch((error) => {
                console.log("# ---- Exam schedule list error ---- #", error);
                setIsLoadingSchedule(false);
            })
        }
        if (open) {
            getExamSchedules();  
        }
        
    }, [open])

    useEffect(() => {
        const getSessions = async () => {
            await axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    setSessions(response.data);
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }
        getSessions()
    }, [])
    

    const handleChangeEnableAll = (event) => {
        if (event.target.checked) {
            setEnableAll({...enableAll, enable: event.target.checked, enableText: 'Enabled All', tooltip: 'Enabled: Exam will start at the same time for all students'})
        } else {
            setEnableAll({...enableAll, enable: event.target.checked, enableText: 'Disabled All', tooltip: 'Disbaled: Exam will not start at the same time for all students'})
        }
        enableExam(event.target.checked);
    }

    const handleSelectedStudent = (values) => {
        setSelectedStudents(values);
    }

    const handleChangeStudentSearch = (value) => {
        getStudents(value);
    }

    const handleChangeEnabled = (event) => {
        var studentId = event.target.getAttribute('studentId');
        var index = event.target.name
        specifiStudentEnableExam(studentId, index, event.target.checked);
    }

    const handleCloseExamScheduleSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenExamScheduleSnack(false);
    }

    const handleCloseExamScheduleErrorSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenExamScheduleErrorSnack(false);
    }

    const handleClickEdit = (scheduleId, index) => {
        setScheduleIndex(index);
        setExamScheduleId(scheduleId);
        setOpenExamScheduleDialog(true);
    }

    const handleCloseScheduleEditDialog = () => {
        setOpenExamScheduleDialog(false);
    }

    const handleChangeSession = (event) => {
        var id =  event.currentTarget.getAttribute("id");
        setSessionId(id);
        getSessionStudents(id);
        setSelectSession(event.target.value)
    }

    const handleChangeStudentSelect = (event) => {
        setSelectStudentName(event.target.value);

        var id = event.currentTarget.getAttribute("id");
        setSelectedStudents([...selectedStudents, id]);
    }

    const getStudents = async (searchKey) => {
        await axios({
            method: 'GET',
            url: 'students/filtered-students/',
            params: {
                search_key: searchKey
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Students fetch success ---- #");
            setStudents(response.data);
            setStudentSearchLoading(false);
        }).catch((error) => {
            console.log("# ---- Students fetch error ---- #", error);
        })
    }

    const validate = () => {
        var validated = true;
        
        if (sessionId.length > 0) {
            validated = true;
            setSelectSessionErrorText("")
        } else {
            validated = false;
            setSelectSessionErrorText("This field can not be kept blank");
        }
        if (selectExamTime) {
            validated = true;
            setSelectExamTimeErrorText("");
        } else {
            validated = false;
            setSelectExamTimeErrorText("This field can not be kept blank.")
        }

        if (selectedStudents.length > 0) {
            validated = true;
            setSelectStudentsErrorText("");
        } else {
            validated = false;
            console.log(selectedStudents);
            setSelectStudentsErrorText("This field can not be kept blank.");
        }

        return validated;
    }

    const saveExamSchedule = async () => {
        if (validate()) {
            await axios({
                method: 'POST',
                url: 'exams/exam-schedule-list/',
                data: {
                    exam_id: examId,
                    selected_students: selectedStudents,
                    selected_time: utcTime(selectExamTime, "YYYY-MM-DDTHH:mm")
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                }
            }).then((response) => {
                console.log("# ---- Exam Schedule save success ---- #", response.status);
                setExamSchedules([]);
                setExamSchedules(response.data);
                if (response.status === 201) {
                    setOpenExamScheduleSnack(true);
                    setSelectedStudents([]);
                    setSelectExamTime("");
                }
                
            }).catch((error) => {
                console.log("# ---- Exam Schedule save error ---- #", error.response.status);
                if (error.response.status === 409) {
                    setOpenExamScheduleErrorSnack(true);
                }
            })
        }
    }

    const enableExam = async (isEnabledAll) => {
        await axios({
            method: "PUT",
            url: "exams/exam-enable/",
            data: {
                exam_id: examId,
                is_enabled_all: isEnabledAll
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Exam enable success ---- #", response.status);
            if (response.status === 200) {
                // setEnableAll({...enableAll, enable: response.data.is_enabled_all});
                setExamSchedules([]);
            }
        }).catch((error) => {
            console.log("# ---- Exam enable error ---- #", error.response.status);
        })
    }

    const specifiStudentEnableExam = async (studentId, index, isEnabled) => {
        await axios({
            method: 'PUT',
            url: 'exams/exam-schedule-list/',
            data: {
                exam_id: examId,
                student_id: studentId,
                is_enabled: isEnabled
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Student specific enable success ---- #", response.status);
            var values = [...examSchedules];
            values[index].is_enabled = response.data.is_enabled;
            setExamSchedules(values);

        }).catch((error) => {
            console.log("# ---- Student specific enable error ---- #", error.response.status);
        })
    }

    const getSessionStudents = async (sessionId) => {
        await axios({
            method: 'GET',
            url: 'students/session-student-list/',
            params: {
                'session_id': sessionId,
                'exam_id': examId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            console.log("# ---- Session student fetch success ---- #", response.status);
            if (response.status === 200) {
                setSessionStudents(response.data);
            }
        }).catch((error) => {
            console.log("# ---- Session student fetch error ---- #", error.response.status);
            if (error.response.status === 404) {
                setSessionStudents([]);
            }
        })
    }

    const ScheduleHtml = () => {
        return (
            <fieldset className={classes.fieldset}>
            <legend>
                <Typography variant='caption'  className={classes.legend}>Exam schedule</Typography>
            </legend>
            <Grid
                container
                spacing={2}
                alignItems='center'>
                    <Grid item xs={3} sm={4} lg={5} xl={6}>
                        <Box display='flex' justifyContent="flex-end">
                            <label>Select Session</label>
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={8} lg={7} xl={6}>
                        <FormControl 
                            variant="outlined" 
                            size="small" 
                            className={classes.formControl}
                            error={selectSessionErrorText}
                            >
                            <InputLabel>Session</InputLabel>
                            <Select
                                label="Select Course"
                                variant="outlined"
                                MenuProps={MenuProps}
                                value={selectSession}
                                onChange={handleChangeSession}>
                                {sessions.map((session, index) => (
                                    <MenuItem
                                        key={index}
                                        value={session.title}
                                        id={session.id}
                                        classes={{
                                            root: classes.menuItemRoot,
                                            selected: classes.menuItemSelected
                                        }}
                                    >
                                        {session.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {selectSessionErrorText && <FormHelperText style={{ color: 'red'}}>This field can not be kept blank</FormHelperText>}
                        </FormControl>
                    </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                alignItems="center"
                >
                <Grid item xs={3} sm={4} lg={5} xl={6}>
                    <Box display='flex' justifyContent="flex-end">
                        <label>Select student</label>
                    </Box>
                </Grid>
                <Grid item xs={9} sm={8} lg={7} xl={6}>
                    <FormControl variant="outlined" size="small" className={classes.formControl}>
                        <InputLabel>Select Student</InputLabel>
                        <Select
                            label="Select Student"
                            variant="outlined"
                            multiple
                            value={selectStudentName}
                            error={selectStudentsErrorText}
                            onChange={handleChangeStudentSelect}
                            renderValue={(selected) => (
                                <div>
                                    {
                                        selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))
                                    }
                                </div>
                            )}
                            MenuProps={MenuProps}
                            >
                            {sessionStudents.length ? 
                                sessionStudents.map((student, index) => (
                                <MenuItem
                                    key={index}
                                    value={student.name}
                                    id={student.id}
                                    classes={{
                                        root: classes.menuItemRoot,
                                        selected: classes.menuItemSelected
                                    }}
                                >
                                    <ListItemText primary={student.name}/>
                                </MenuItem>))
                            : 
                            <MenuItem>No student available</MenuItem>
                            }
                        </Select>
                        {selectStudentsErrorText && <FormHelperText style={{ color: 'red'}}>This field can not be kept blank</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                alignItems='center'>
                    <Grid item xs={3} sm={4} lg={5} xl={6}>
                        <Box display='flex' justifyContent="flex-end">
                            <label>Start Time</label>
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={8} lg={7} xl={6}>
                        <TextField
                            value={selectExamTime}
                            error={selectExamTimeErrorText ? true : false}
                            helperText={selectExamTimeErrorText}
                            className={classes.input}
                            type='datetime-local'
                            onChange={(event) => setSelectExamTime(event.target.value)}
                        />
                    </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                alignItems='center'>
                    <Grid item xs={2} sm={4} lg={5} xl={6}></Grid>
                    <Grid item xs={10} sm={6} lg={6} xl={6}>
                        <Box display="flex" justifyContent="flex-start">
                            <Button 
                                className={classes.saveButton}
                                onClick={() => saveExamSchedule()}>
                                    Save</Button>
                        </Box>
                    </Grid>
            </Grid>
        </fieldset>
        )
    }

    const handleClickScheduleExam = () => {
        if (!showScheduleHtml) {
            setShowScheduleHtml(true);
        } else {
            setShowScheduleHtml(false);
        }
    }

  return (
      <>
        <Paper elevation={0} variant='outlined'>
            <Box display='flex' p={1} justifyContent='space-between'>
                <Box>
                <Typography variant='body2'>Primary Scheduled Timings</Typography>
                    <Box display='flex' flexDirection='row' alignItems='center' mb={1}>
                        <FiCalendar className={classes.calendarIcon} />
                        <Typography variant='body2'>{userTime(examDate, 'DD MMM YYYY')}</Typography>
                    </Box>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <FiClock className={classes.clockIcon} />
                        <Typography variant='body2'>{userTime(examStartTime, 'hh:mm A')}</Typography>
                        <FiTrendingUp className={classes.durationIcon} />
                        <Typography variant='body2'>{examDuration} min</Typography>
                        
                    </Box>
                    <Button 
                        className={classes.scheduleButton}
                        variant='outlined'
                        size='small'
                        onClick={() => handleClickScheduleExam()}>Schedule
                    </Button>
                </Box>
                <Box>
                    <Typography variant="body2">Participants: {participants!== 0 ? participants : "N/A"}</Typography>
                    <Typography variant="body2">Not Participated: {participants!== 0 ? examSchedules.length-participants : "N/A"}</Typography>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Typography variant="body2">Status: </Typography>
                        <Typography variant='caption' style={{ marginLeft: 4, marginTop:4, fontWeight: 'bold', color: examStatusColor[examStatus] }}>{examStatus}</Typography>
                    </Box>
                    
                    {/* <Tooltip title={enableAll.tooltip} placement="left" arrow>
                        <FormControlLabel 
                            control={<Switch 
                                        checked={enableAll.enable} 
                                        onChange={handleChangeEnableAll}
                                        color="primary"
                                    />}
                            label={enableAll.enableText}
                            style={{color: '#0B6AB0'}}
                        />
                    </Tooltip> */}
                </Box>
            </Box>
            <Collapse in={!enableAll.enable}>
                {/* <fieldset className={classes.fieldset}>
                    <legend>
                        <Typography variant='caption'  className={classes.legend}>Customized exam schedule</Typography>
                    </legend>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        >
                        <Grid item xs={3} sm={4} lg={5} xl={6}>
                            <Box display='flex' justifyContent="flex-end">
                                <label>Choose student</label>
                            </Box>
                        </Grid>
                        <Grid item xs={9} sm={8} lg={7} xl={6}>
                            <Autocomplete
                                multiple
                                options={students}
                                disableCloseOnSelect
                                onChange={(event, value) => handleSelectedStudent(value)}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        {option.name} #{option.id}
                                    </React.Fragment>
                                )}
                                loading={studentSearchLoading}
                                noOptionsText={studentSearchLoading ? '' : 'No student to match your search'}
                                style={{ width: 350, marginLeft: '8px' }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="outlined" 
                                        size="small" 
                                        label="Enter student name" 
                                        placeholder="" 
                                        error={selectStudentsErrorText ? true : false}
                                        helperText={selectStudentsErrorText}
                                        onChange={(event) => handleChangeStudentSearch(event.target.value)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignItems='center'>
                            <Grid item xs={3} sm={4} lg={5} xl={6}>
                                <Box display='flex' justifyContent="flex-end">
                                    <label>Start Time</label>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8} lg={7} xl={6}>
                                <TextField
                                    value={selectExamTime}
                                    error={selectExamTimeErrorText ? true : false}
                                    helperText={selectExamTimeErrorText}
                                    className={classes.input}
                                    type='datetime-local'
                                    onChange={(event) => setSelectExamTime(event.target.value)}
                                />
                            </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignItems='center'>
                            <Grid item xs={2} sm={4} lg={5} xl={6}></Grid>
                            <Grid item xs={10} sm={6} lg={6} xl={6}>
                                <Box display="flex" justifyContent="flex-start">
                                    <Button 
                                        className={classes.saveButton}
                                        onClick={() => saveExamSchedule()}>
                                            Save</Button>
                                </Box>
                            </Grid>
                    </Grid>
                </fieldset> */}
                <Collapse in={showScheduleHtml}>
                    <ScheduleHtml />
                </Collapse>
                <Box display='flex' mt={1} ml={1} mr={1}>
                    <Typography  variant='h6' className={classes.header} display='inline'>Exam Schedule List</Typography>
                </Box>
                <Grid
                    className={classes.tableGrid}
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center">
                        <Grid item xs={12}>
                            <Box m={1}>
                                {!isLoadingSchedule && 
                                    <EnabledStudentList 
                                        schedules={examSchedules} 
                                        handleChangeEnabled={handleChangeEnabled}
                                        handleClickEdit={handleClickEdit}/>}
                            </Box>
                        </Grid>
                </Grid>
            </Collapse>
        </Paper>

        <ExamScheduleEditDialog
            examScheduleId={examScheduleId} 
            open={openExamScheduleDialog} 
            close={handleCloseScheduleEditDialog}
            setExamSchedules={setExamSchedules}
            examSchedules={examSchedules}
            scheduleIndex={scheduleIndex}
        />

        <Snackbar open={openExamScheduleSnack} autoHideDuration={4000} onClose={handleCloseExamScheduleSnack}>
            <Alert onClose={handleCloseExamScheduleSnack} severity="success">
                Exam Scheduled Successfully.
            </Alert>
        </Snackbar>

        <Snackbar open={openExamScheduleErrorSnack} autoHideDuration={4000} onClose={handleCloseExamScheduleErrorSnack}>
            <Alert onClose={handleCloseExamScheduleErrorSnack} severity="error">
                Student has already scheduled exam
            </Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={isLoadingSchedule}>
            <IoHourglassOutline style={{ fontSize: 35 }}/>
        </Backdrop>
    </>
    )
};

export default Schedule;
