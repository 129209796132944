import { useState, useEffect, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Slide, Dialog, Backdrop, AppBar, Toolbar, Paper,
         Typography, Box, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FiX, FiBookOpen, FiEdit, FiTrash } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import axios from 'axios';
import { useConfirm } from "material-ui-confirm";
import { BsClipboard, BsClipboardCheck } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import ChooseQTypeDlg from "./ChooseQTypeDlg";
import CreateMCQDlg from "./CreateMCQDlg";
import CreateDescrQDlg from "./CreateDescrQDlg";
import PasteQDlg from "./PasteQDlg";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: "#EDF0F5",
    color: "black",
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  xIcon: {
    cursor: 'pointer',
  },
  header: {
    color: '#0B6AB0',
    borderBottom: '2px solid #00BC9E',
  },
  addQuestionBtn: {
    color: '#0B6AB0',
    cursor: 'pointer',
  },
  addQuestionIcon: {
    color: '#0B6AB0',
    fontSize: 16,
  },
  addQuestionTxt: {
    color: '#0B6AB0',
    fontWeight: 'bold',
    marginLeft: 2,
    cursor: 'pointer'
  },
  tableContainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  noQContainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(2),
    minHeight: '80vh',
  },
  editIcon: {
    color: '#0B6AB0',
    fontSize: 16,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  deleteIcon: {
    color: '#C0392B',
    fontSize: 16,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  clipBoardIcon: {
    color: '#FFCC00',
    fontSize: 16,
    cursor: 'pointer',
  },
  checkClipBoardIcon: {
    color: '#00BC9E',
    fontSize: 16,
    cursor: 'pointer',
  },
  deleteConfirmButton: {
    textTransform: 'none',
    backgroundColor: '#f44336',
    '&:hover': {
        backgroundColor: '#f44336',
    },
    color: '#FFFFFF'
  },
  cancelBtn: {
    textTransform: 'none',
    color: '#0B6AB0',
  },
  examActionSep: {
    color: 'grey',
    marginRight: 4,
    marginLeft: 4
},
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      marginTop: "48px",
      color: '#0B6AB0',
      display: 'flex',
      flexDirection: 'column',
      '&.MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0.05)'
      },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionList = ({ examId, examTitle, open, setOpen }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [openQTypeDlg, setOpenQTypeDlg] = useState(false);
  const [openCreateDescrDlg, setOpenCreateDescrDlg] = useState(false);
  const [openCreateMCQDlg, setOpenCreateMCQDlg] = useState(false);
  const [hideCopyIcon, setHideCopyIcon] = useState(null);
  const [openEditDescrDlg, setOpenEditDescrDlg] = useState(false);
  const [openEditMCQDlg, setOpenEditMCQDlg] = useState(false);
  const [openPasteQDlg, setOpenPasteQDlg] = useState(false);
  const [editQId, setEditQId] = useState(null);
  const [questionFile, setQuestionFile] = useState('');
  const [questionDetails, setQuestionDetails] = useState(null);

  const [openDeleteSnack, setOpenDeleteSnack] = useState(false);
  const [openFailureSnack, setOpenFailureSnack] = useState(false);
  const [openImportSnack, setOpenImportSnack] = useState(false);
  const [openQuestionPasteSnack, setOpenQuestionPasteSnack] = useState(false);
  const [openQuestionPasteErrorSnack, setOpenQuestionPasteErrorSnack] = useState(false);


  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await axios({
          method: 'GET',
          url: `exams/question-list/${examId}/`,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
          },
      }).then((res) => {
          setIsLoading(false);
          if (res.status == 200) {
            setQuestions(res.data);
          }
      }).catch((error) => {
          setIsLoading(false);
          console.log(error);
      });
    }
    if (open)
      getData();
  }, [open]);

  useEffect(() => {
    
    if (open) {
      
    }
  }, [open])
  
  const handleEdit = (index, id, type) => {
    setEditQId(id);
    if (type === 'Descriptive') {
      setOpenEditDescrDlg(true);
    }
    else {
      setOpenEditMCQDlg(true);
    }
  }

  const deleteConfirm = (index, id) => {
    confirm({
        title: <Typography variant='h6' style={{ fontWeight: 'normal' }}>Delete Question</Typography>,
        description: <Typography variant='body1'>Are you sure to delete the question?</Typography>,
        confirmationText: 'Delete',
        confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
        cancellationButtonProps: { className: classes.cancelBtn, disableElevation: true },
    })
        .then(() => deleteQuestion(index, id))
        .catch(() => console.log('Cancelled!'));
  }

  const deleteQuestion = (index, id) => {
    axios({
      method: 'DELETE',
      url: `exams/question-details/${id}/`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },
    }).then((res) => {
        if (res.status == 202) {
          var values = [...questions];
          values.splice(index, 1);
          setQuestions(values);

          console.log('Question delete success!');

          setOpenDeleteSnack(true);
        }
        else {
          console.log('Question delete failed!');
          setOpenFailureSnack(true);
        }
    }).catch((error) => {
        console.log('Question delete error!', error);
        setOpenFailureSnack(true);
    });
  }

  const exportQuestion = async () => {
    await axios({
      method: 'GET',
      url: 'exams/exam-question-clone-all/',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },
      params: {
        exam_id: examId
      }
    }).then((response) => {
      console.log('# --- Export exam success ---- #');
      if (response.status === 200) {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response.data));
        var downloadFile = document.createElement('a');
        downloadFile.setAttribute("href", dataStr);
        downloadFile.setAttribute("download", "question_exam_" + examId + ".json");
        downloadFile.click();
      }
    }).catch((error) => {
      console.log('# ---- Export exam error ---- #', error);
      setOpenFailureSnack(true);
    })
  }

  const removeHTMLTags = (html) => {
    var regX = /(<([^>]+)>)/ig;
    var txt = html.replace(regX, "");
    if (txt.length > 80) {
      var tdot = "...";
    }
    else {
      var tdot = "";
    }
    return html.replace(regX, "").slice(0, 80) + tdot;
  }

  const handleCloseDeleteSnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpenDeleteSnack(false);
  }

  const handleCloseFailureSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailureSnack(false);
  }

  const handleCloseImportSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenImportSnack(false);
  }

  const handleCloseQuestionPasteSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenQuestionPasteSnack(false);
  }

   const handleCloseQuestionPasteErrorSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenQuestionPasteErrorSnack(false);
   }
  
  const updateQuestion = (question) => {
    let x = questions.map(q => q.id === question.id ? question : q)
    setQuestions(x);
  }

  const handleChangeQuestionImport = (file) => {
    importQuestionFile(file)
  }

  const handlePasteQuestion = () => {
    setIsLoading(true);
    setTimeout(async () => {
      const question = await navigator.clipboard.readText();
      axios({
        method: 'POST',
        url: 'exams/exam-question-clone/',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },
        data: {
            exam_id: examId,
            question: question
        }
      }).then((response) => {
          console.log("# ---- Save question success ---- #");
          if (response.status === 201) {
            setIsLoading(false);
            setQuestions([...questions, response.data]);
            // setOpen(false);
            setOpenQuestionPasteSnack(true);
          }
      }).catch((error) => {
        setIsLoading(false);
        console.log("# ---- Save question error ---- #", error);
        if (error.response.status === 400) {
          if (error.response.data.json_decode_error) {
            setOpenQuestionPasteErrorSnack(true);
          }
        } else {
          setOpenFailureSnack(true);
        }
      })
    }, 2000);
  }

  const importQuestionFile = (file) => {
    const uploadFile = new FormData();
    uploadFile.append('question_file', file);
    uploadFile.append('exam_id', examId);
    setIsLoading(true);
    axios({
      method: 'POST',
      url: 'exams/exam-question-clone-all/',
      data: uploadFile,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      }
    }).then((response) => {
      console.log("# ---- Upload file success ----#");
      setIsLoading(false);
      if (response.status === 201) {
        setQuestions(response.data);
        setOpenImportSnack(true);
      }
    }).catch((error) => {
      console.log("# ---- Upload file error ---- #", error);
      setIsLoading(false);
      setOpenFailureSnack(true);
    })
  }

  const copyQuestionToClipboard = async (id, index) => {
    await axios({
      method: 'GET',
      url: `exams/question-details/${id}/`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },
    }).then((response) => {
      console.log("# ---- Question details fetch success ---- ");
      if (response.status === 200) {
        setHideCopyIcon(index);
        setQuestionDetails(JSON.stringify(response.data));
        if (navigator && navigator.clipboard && navigator.clipboard.writeText)
          return navigator.clipboard.writeText(JSON.stringify(response.data));
        return Promise.reject('The Clipboard API is not available.');
      }
    }).catch((error) => {
      console.log("# ---- Question details fetch error ---- #", error);
      setOpenFailureSnack(true);
    })
  };

  return (
    <div>
      
        <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
          <Backdrop className={classes.backdrop} open={isLoading}>
              <IoHourglassOutline style={{ fontSize: 35 }} />
          </Backdrop>
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar variant='dense' style={{display:"flex", justifyContent: 'space-between'}}>
              <Box display="flex" alignItems="center">
                <FiBookOpen />
                {!isLoading && <Typography variant="body1" className={classes.title}>{examTitle}</Typography>}
              </Box>
              <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
            </Toolbar>
          </AppBar>

          {!isLoading && <>
          <Box display='flex' justifyContent='space-between' mt={2} ml={3} mr={3}>
            <Typography variant='h6' className={classes.header} display='inline'>Questions</Typography>
            <Box display="flex" flexDirection="row" alignItems='center' className={classes.addQuestionBtn} >
            <input 
                  type='file' 
                  id="file-upload"
                  hidden
                  onChange={(event) => {
                    handleChangeQuestionImport(event.target.files[0]);
                  }}
                />
              <label htmlFor="file-upload">
                <Tooltip title="As JSON" placement="top" arrow>
                  <Typography variant='body2' className={classes.addQuestionTxt}>Import</Typography>
                </Tooltip>
              </label>
              <Typography variant="caption" className={classes.examActionSep}> | </Typography>
              <Tooltip title="As JSON" placement="top" arrow>
                <Typography variant='body2' className={classes.addQuestionTxt} onClick={() => exportQuestion()}>Export</Typography>
              </Tooltip>
              <Typography variant="caption" className={classes.examActionSep}> | </Typography>
              <Typography variant='body2' className={classes.addQuestionTxt} onClick={() => setOpenQTypeDlg(true)}>Add Question</Typography>
              <Typography variant="caption" className={classes.examActionSep}> | </Typography>
              <Typography variant='body2' className={classes.addQuestionTxt} onClick={handlePasteQuestion}>Paste</Typography>
            </Box>
          </Box>
          {questions.length > 0 ?
            <Paper className={classes.tableContainer} elevation={0} variant='outlined'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Sl.</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Question</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Marks</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questions.map((question, index) => (
                    <TableRow key={question.id}>
                      <TableCell>
                        {index+1}
                      </TableCell>
                      <TableCell style={{ wordWrap: 'break-word' }}>
                          {question.type==='Descriptive' ? removeHTMLTags(question.descriptive_q.q_text) : removeHTMLTags(question.mcq.q_text)}
                      </TableCell>
                      <TableCell>{question.type}</TableCell>
                      <TableCell>
                        {question.type==='Descriptive' ? question.descriptive_q.marks : question.mcq.marks}
                      </TableCell>
                      <TableCell>
                        <FiEdit className={classes.editIcon} onClick={() => handleEdit(index, question.id, question.type)} />
                        <FiTrash className={classes.deleteIcon} onClick={() => deleteConfirm(index, question.id)} />
                        {
                          hideCopyIcon === index ? 
                          <BsClipboardCheck className={classes.checkClipBoardIcon}/> 
                          : 
                          <MdContentCopy className={classes.clipBoardIcon} onClick={() => copyQuestionToClipboard(question.id, index)}/>
                        }
                        {/* <MdContentCopy className={classes.clipBoardIcon} onClick={() => copyQuestionToClipboard(question.id, index)}/> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            :
            <Paper className={classes.noQContainer} variant='outlined'>
              <Box height='100%' display="flex" flexDirection='row' justifyContent='center'>
                  <Box width='100%' display='flex' flexDirection='column' justifyContent='center'>
                      <Typography variant="h5" color="initial" align="center" style={{ color: '#0B6AB0' }}>No Question Found</Typography>
                      <Typography variant="body1" color="textSecondary" align='center'>Please click "+ Add Question" button to proceed.</Typography>
                  </Box>
              </Box>
            </Paper>
          }
          </>}
        </Dialog>

      {/* Create dialogs */}
      <ChooseQTypeDlg examId={examId} open={openQTypeDlg} setOpen={setOpenQTypeDlg} setOpenMCQ={setOpenCreateMCQDlg} setOpenDescr={setOpenCreateDescrDlg} />
      <CreateMCQDlg examId={examId} open={openCreateMCQDlg} setOpen={setOpenCreateMCQDlg} questions={questions} setQuestions={setQuestions} />
      <CreateDescrQDlg examId={examId} open={openCreateDescrDlg} setOpen={setOpenCreateDescrDlg} questions={questions} setQuestions={setQuestions} />
      
      {/* Edit dialogs */}
      <CreateMCQDlg examId={examId} open={openEditMCQDlg} setOpen={setOpenEditMCQDlg} questions={questions} setQuestions={setQuestions} update={true} questionId={editQId} updateQuestion={updateQuestion}/>
      <CreateDescrQDlg examId={examId} open={openEditDescrDlg} setOpen={setOpenEditDescrDlg} questions={questions} setQuestions={setQuestions} update={true} questionId={editQId} updateQuestion={updateQuestion} />
      
      <PasteQDlg examId={examId} open={openPasteQDlg} setOpen={setOpenPasteQDlg} questions={questions} setQuestions={setQuestions} />

      <Snackbar open={openDeleteSnack} autoHideDuration={4000} onClose={handleCloseDeleteSnack}>
        <Alert onClose={handleCloseDeleteSnack} severity="success">
          Question deleted.
        </Alert>
      </Snackbar>
      <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
        <Alert onClose={handleCloseFailureSnack} severity="error">
          Operation failed!
        </Alert>
      </Snackbar>

      <Snackbar open={openImportSnack} autoHideDuration={4000} onClose={handleCloseImportSnack}>
        <Alert onClose={handleCloseImportSnack} severity="success">
          Question import successful!
        </Alert>
      </Snackbar>

      <Snackbar open={openQuestionPasteSnack} autoHideDuration={4000} onClose={handleCloseQuestionPasteSnack}>
        <Alert onClose={handleCloseQuestionPasteSnack} severity="success">
          Question paste successful!
        </Alert>
      </Snackbar>

      <Snackbar open={openQuestionPasteErrorSnack} autoHideDuration={4000} onClose={handleCloseQuestionPasteErrorSnack}>
        <Alert onClose={handleCloseQuestionPasteErrorSnack} severity="error">
          Please copy a question correctly!
        </Alert>
      </Snackbar>

      {/* <Backdrop className={classes.backdrop} open={isLoading}>
        <IoHourglassOutline style={{ fontSize: 35 }} />
      </Backdrop> */}
    </div>
  );
};

export default QuestionList;