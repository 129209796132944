import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Backdrop, Typography } from '@material-ui/core'
import axios from 'axios'
import qs from 'qs'
import { IoHourglassOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        color: '#0B6AB0',
    },
    approveConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#4caf50',
        '&:hover': {
            backgroundColor: '#4caf50',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}))

const EnrollApproveDialog = ({ open, close, selectedEnrollments, approvalStatus, handleUpdateEnrollments,
                               setDisableApproveButton, setOpenSuccessSnackbar, setOpenFailureSnackbar }) => {

    const classes = useStyles()
    const [isApproving, setIsApproving] = useState(false)

    const approveEnrollments = async () => {
        close()
        setIsApproving(true)
        await axios({
            method: "PUT",
            url: "students/enrollment-list/",
            data: {
              approval_status: approvalStatus,
              selected_enrollments: selectedEnrollments,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          })
            .then((response) => {
              setIsApproving(false)
              if (response.status === 200) {
                console.log(
                  "## --- Enrollments Update Success ---- ##",
                  response.status
                );
                handleUpdateEnrollments(response.data);
                setDisableApproveButton(true)
                setOpenSuccessSnackbar(true)
              } else {
                console.log(
                  "## --- Enrollments Update Failed ---- ##",
                  response.status
                );
                setOpenFailureSnackbar(true)
              }
            })
            .catch((error) => {
              console.log("## --- Enrollments Update Error ---- ##", error);
              setIsApproving(false)
              setOpenFailureSnackbar(true)
            });
    }
    return (
        <div>
            <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth='xs'>
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    <Typography variant='h6'>Approve Enrollment</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure to approve this enrollment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} color="primary" onClick={close}>Cancel</Button>
                    <Button className={classes.approveConfirmButton} onClick={() => approveEnrollments(selectedEnrollments)}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={isApproving}>
                {/* <IoHourglassOutline style={{ fontSize: 35 }} /> */}
                <Typography variant="h6" color="initial">Approving... Please wait a moment.</Typography>
            </Backdrop>
        </div>
    )
}

export default EnrollApproveDialog
