import { useState, useEffect, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Slide, Dialog, Backdrop, AppBar, Toolbar, Paper, Container, Tooltip,
         Typography, Box, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, Grid, Button } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FiX, FiBookOpen, FiEdit, FiTrash } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import axios from 'axios';
import { useConfirm } from "material-ui-confirm";
import ExamGradeView from "./ExamGradeView";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
      backgroundColor: "#EDF0F5",
      color: "black",
    },
    title: {
      flex: 1,
      marginLeft: theme.spacing(1),
    },
    xIcon: {
      cursor: 'pointer',
    },
    header: {
      color: '#0B6AB0',
      borderBottom: '2px solid #00BC9E',
    },
    addQuestionBtn: {
      color: '#0B6AB0',
      cursor: 'pointer',
    },
    addQuestionIcon: {
      color: '#0B6AB0',
      fontSize: 16,
    },
    addQuestionTxt: {
      color: '#0B6AB0',
      fontWeight: 'bold',
      marginLeft: 2,
    },
    tableContainer: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    noQContainer: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(2),
      minHeight: '80vh',
    },
    editIcon: {
      color: '#0B6AB0',
      fontSize: 16,
      cursor: 'pointer',
      marginRight: theme.spacing(1),
    },
    deleteIcon: {
      color: '#C0392B',
      fontSize: 16,
      cursor: 'pointer',
    },
    deleteConfirmButton: {
      textTransform: 'none',
      backgroundColor: '#f44336',
      '&:hover': {
          backgroundColor: '#f44336',
      },
      color: '#FFFFFF'
    },
    cancelBtn: {
      textTransform: 'none',
      color: '#0B6AB0',
    },
    viewBtn: {
      textTransform: 'none',
      color: '#0B6AB0',
    },
    tableContainer: {
      width: "100%",
      padding: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    tableGrid: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
  }));

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



const ExamSubmissionList = ({examId, examTitle, open, setOpen, submissions, setSubmissions, loading}) => {
    const classes = useStyles();
    const [openGradeViewDialog, setOpenGradeViewDialog] = useState(false);
    const [submissionId, setSubmissionId] = useState(null);
    const [submissionIndex, setSubmissionIndex] = useState(null);

    const showGradingView = (submissionId, index) => {
      // setOpen(false);
      setOpenGradeViewDialog(true);
      setSubmissionId(submissionId);
      setSubmissionIndex(index);
    }

    const columns = [
      { field: 'serial', headerName: 'Sl.', width: 150 },
      {
        field: 'name',
        headerName: 'Student',
        width: 450,
      },
      {
        field: 'marksObtained',
        headerName: 'Marks Obtained',
        width: 380,
        renderCell: (params) => {
          var marksObtainedHtml = <> </>;
          if (params.value.isGraded) {
            marksObtainedHtml = (
              <Typography variant="body2">
                <span style={{color: '#00BC9E'}}>{params.value.totalMarksObtained}</span>/<span style={{color: '#0B6AB0'}}>{params.value.totalMarks}</span>
              </Typography>
            );
          } else {
            marksObtainedHtml = (
              <Typography variant="body2">
                <span style={{color: '#AF2323'}}>Ungraded</span>
              </Typography>
            );
          }
          return marksObtainedHtml;
        }
      },
      {
          field: 'actions',
          headerName: 'Actions',
          disableClickEventBubbling: true,
          width: 200,
          renderCell: (params) => {
              var icon = <> </>;
              icon = (
              <Tooltip title="View" arrow>
                  <Button onClick={() => showGradingView(params.value.id, params.value.index)} style={{textTransform: 'none', color: '#0B6AB0'}}>View</Button>
              </Tooltip>
              );
              return icon;
          },
      },
    ];

    function rows(submissions) {
      var rows = [];
      submissions.map((submission, index) =>
        rows.push({
          id: submission.id,
          serial: index+1,
          name: submission.user.first_name + " " + submission.user.last_name + " #" + submission.user.id ,
          marksObtained: {
            totalMarks:submission.total_marks,
            totalMarksObtained:submission.total_marks_obtained,
            isGraded:submission.is_graded
          },
          actions: { id: submission.id, index: index }
        })
      )
      return rows;
    }

    return (
        <div>
        {!loading &&
          <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} elevation={0}>
              <Toolbar variant='dense'>
                <FiBookOpen />
                <Typography variant="body1" className={classes.title}>{examTitle}</Typography>
                <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
              </Toolbar>
            </AppBar>
            <Box display='flex' justifyContent='space-between' mt={2} ml={3} mr={3}>
              <Typography variant='h6' className={classes.header} display='inline'>Exam Submission List</Typography>
            </Box>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center"
              className={classes.tableGrid}
            >
            <Grid item xs={12}>
              <DataGrid
              autoHeight
                      rows={rows(submissions)}
                      columns={columns}
                      pageSize={10}
                      rowHeight={35}
                      disableSelectionOnClick
                  />
              </Grid>
            </Grid>
          </Dialog>
        }
        
        
        <ExamGradeView
          submissionId={submissionId}
          submissionIndex={submissionIndex}
          submissions={submissions}
          setSubmissions={setSubmissions}
          open={openGradeViewDialog}
          setOpen={setOpenGradeViewDialog}
          viewMode="Grade"/>

        {/* <Snackbar open={openDeleteSnack} autoHideDuration={4000} onClose={handleCloseDeleteSnack}>
          <Alert onClose={handleCloseDeleteSnack} severity="success">
            Question deleted.
          </Alert>
        </Snackbar>
        <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
          <Alert onClose={handleCloseFailureSnack} severity="error">
            Operation failed!
          </Alert>
        </Snackbar>

        <Backdrop className={classes.backdrop} open={isLoading}>
          <IoHourglassOutline style={{ fontSize: 35 }} />
        </Backdrop> */}
    </div>
    )
}

export default ExamSubmissionList
