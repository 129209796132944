import React, { useState, forwardRef, useEffect } from 'react';
import {
    Slide, Dialog, Backdrop, AppBar, Toolbar, Paper, Container, Tooltip, TextareaAutosize, TextField,
    Typography, Box, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, Grid, Button, DialogActions
} from "@material-ui/core";
import { FiX } from "react-icons/fi";
import { makeStyles } from "@material-ui/core/styles";
import PdfViewer from '../../PdfViewerSingle';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "black",
    },
    xIcon: {
        cursor: 'pointer',
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(0),
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FileShow({status, handleImageOpen}) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    return <Dialog  maxWidth={false} open={status.status} onClose={() => {
                    setOpen(false)
                    handleImageOpen(false)
                }} TransitionComponent={Transition} classes={{ paper: classes.dialog }} fullScreen={status.type === "img"}>
        <AppBar className={classes.appBar} elevation={0}>
            <Toolbar variant='dense'>
                <FiX className={classes.xIcon} onClick={() => handleImageOpen(false)} />
            </Toolbar>
        </AppBar>
        {status.type === "img" ? <img src={status.url} alt="exam_file" style={{height: '100%'}}/> : 
        <PdfViewer file={status.url}/>}
    </Dialog>;
}

export default FileShow;
