import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Container, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(22, 0, 6),
        textAlign: 'center',
        width: '50%'
    },
    headerTitle: {
        marginLeft: theme.spacing(20),
        marginRight: theme.spacing(20),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    emailSentContent: {
        margin: theme.spacing(2),
    },
    button: {
        paddingRight: theme.spacing(7),
        paddingLeft: theme.spacing(7),
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        cursor: 'pointer',
        '&.active': {
            color: 'steelblue'
        },
    },
}))

const ResetRequestEmailSent = () => {
    const classes = useStyles()
    return (
        <Container className={classes.container}>
            <Typography variant='h4' className={classes.headerTitle}>
                Password Reset Sent
            </Typography>
            <Typography variant='body1' className={classes.emailSentContent} inline>
                We have emailed you instructions for setting your new password, if an account exists with the email you entered. You should recieve shortly.
               <br />
               <b>If you do not recieve an email, please make sure you have entered the address you registered with, and check your spam folder.</b> 
            </Typography>
            <Link to='/all-courses' style={{ textDecoration: 'none'}}>
            <Button className={classes.button} startIcon={<ArrowBackIcon />} >
                Return Home
            </Button>
            </Link>
        </Container>
    )
}

export default ResetRequestEmailSent
