import React, { useState, useEffect } from "react";
import StudentListTable from "./StudentListTable";
import DeleteStudentDialog from "./DeleteStudentDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Snackbar,
  Backdrop,
} from "@material-ui/core";
import axios from "axios";
//import qs from 'qs'
import { DeleteOutlineRounded } from "@material-ui/icons";
import StudentLoginSessionDialog from "./StudentLoginSessionDialog";
import MuiAlert from "@material-ui/lab/Alert";
//import { useConfirm } from "material-ui-confirm";
import CourseAccessibilityDialog from "./CourseAccessibilityDialog";
import { IoHourglassOutline } from "react-icons/io5";
import SearchBar from "material-ui-search-bar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    // backgroundColor: '#000000'
  },
  gridContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  headerTypography: {
    color: "#0B6AB0",
    borderBottom: "2px solid #00BC9E",
  },
  tableGrid: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  deleteButton: {
    textTransform: "none",
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#f44336",
    },
    color: "#FFFFFF",
  },
  holdButton: {
    //textTransform: 'none',
    backgroundColor: "#ff9800",
    "&:hover": {
      backgroundColor: "#ff9800",
    },
    color: "white",
  },
  unholdButton: {
    //textTransform: 'none',
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#4caf50",
    },
    color: "white",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#0B6AB0",
    "&.MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
  searchBox: {
    marginLeft: theme.spacing(8),
  },
}));

function StudentList() {
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openAccessibilityDlg, setOpenAccessibilityDlg] = useState(false);
  const [sessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("")
  const [openHoldDlg, setOpenHoldDlg] = useState(false);
  const [weekNum, setWeekNum] = useState("");
  const [selectedStudent, setSelectedStudent] = useState();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    const getStudents = async () => {
      setIsLoading(true);
      await axios
        .get("students/student-list/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(
              "## ---- Students Fetch Success ---- ##",
              response.status
            );
            setStudents(...students, response.data);
            setIsLoading(false);
          } else {
            console.log(
              "## ---- Students Fetch Failed ---- ##",
              response.status
            );
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("## ---- Students Fetch Error ---- ##", error);
          setIsLoading(false);
        });
    };
    getStudents();
  }, []);

  const handleChecked = (selectedItem) => {
    if (selectedItem > 0) {
      setDisableDeleteButton(false);
    } else {
      setDisableDeleteButton(true);
    }
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSeletcedStudents = (seletedStudents) => {
    setSelectedStudents(seletedStudents);
  };

  const handleChangeStudents = (updatedStudents) => {
    setStudents(updatedStudents);
  };

  const handleShowSession = (session, studentName, studentId) => {
    setSessions(session);
    setStudentName(studentName);
    setStudentId(studentId)
    setSessionDialogOpen(true);
  };

  const handleCloseSessionDialog = () => {
    setSessionDialogOpen(false);
  };

  const handleAccessibility = (studentId) => {
    setSelectedStudent(studentId);
    setOpenAccessibilityDlg(true);
  };

  const handleCloseAccessibilityDlg = () => {
    setOpenAccessibilityDlg(false);
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccessSnackbar(false);
  };

  const handleCloseFailureSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailureSnackbar(false);
  };

  const filteredStudents = async (searchKey) => {
    await axios
      .get("students/filtered-students/", {
        params: {
          search_key: searchKey,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(
            "## ---- Filtered Students Fetch Success ---- ##",
            response.status
          );
          if (response.data.length) {
            setStudents(response.data);
          } else {
            setStudents(response.data);
          }
        } else {
          console.log(
            "## ---- Filtered Students Fetch Failed ---- ##",
            response.status
          );
        }
      })
      .catch((error) => {
        console.log("## ---- Filtered Students Fetch Error ---- ##", error);
      });
  };

  return (
    <>
      {!isLoading && (
        <Container maxWidth={false} className={classes.container}>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.gridContainer}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={4} md={3} style={{ textAlign: "right" }}>
              <Button
                startIcon={<DeleteOutlineRounded />}
                variant="contained"
                className={classes.deleteButton}
                disabled={disableDeleteButton}
                onClick={handleClickOpen}
              >
                Delete Student
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            className={classes.gridContainer}
          >
            <Grid item xs={4}>
              <Typography
                variant="h6"
                display="inline"
                className={classes.headerTypography}
              >
                Student List
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={3}
              className={classes.searchBox}
              style={{ textAlign: "right" }}
            >
              <SearchBar
                style={{
                  marginLeft: "50px",
                }}
                value={searchKey}
                onChange={(newValue) => setSearchKey(newValue)}
                onRequestSearch={() => filteredStudents(searchKey)}
                onCancelSearch={() => filteredStudents("")}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} className={classes.tableGrid}>
              <StudentListTable
                students={students}
                handleChecked={handleChecked}
                handleSelectedStudents={handleSeletcedStudents}
                handleAccessibility={handleAccessibility}
                setOpenFailureSnackbar={setOpenFailureSnackbar}
                handleShowSession={handleShowSession}
              />
            </Grid>
          </Grid>

          <DeleteStudentDialog
            open={dialogOpen}
            close={handleClose}
            selectedStudents={selectedStudents}
            handleChangeStudents={handleChangeStudents}
            setDisableDeleteButton={setDisableDeleteButton}
          />
          <CourseAccessibilityDialog
            open={openAccessibilityDlg}
            handleClose={handleCloseAccessibilityDlg}
            studentId={selectedStudent}
          />
          <StudentLoginSessionDialog
            open={sessionDialogOpen}
            close={handleCloseSessionDialog}
            studentName={studentName}
            studentId={studentId}
          />

          <Snackbar
            open={openSuccessSnackbar}
            autoHideDuration={4000}
            onClose={handleCloseSuccessSnackbar}
          >
            <Alert onClose={handleCloseSuccessSnackbar} severity="success">
              Operation success!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openFailureSnackbar}
            autoHideDuration={4000}
            onClose={handleCloseFailureSnackbar}
          >
            <Alert onClose={handleCloseFailureSnackbar} severity="error">
              Operation failed!
            </Alert>
          </Snackbar>
        </Container>
      )}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <IoHourglassOutline style={{ fontSize: 35 }} />
      </Backdrop>
    </>
  );
}

export default StudentList;
