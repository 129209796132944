import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Box, Typography, TextField, StepContent, Button, Snackbar, InputAdornment, Select, MenuItem, Chip, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom'
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import 'react-quill/dist/quill.snow.css';
import "./index.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    paper: {
        margin: theme.spacing(3),
        marginTop: theme.spacing(3),
        padding: theme.spacing(5),
        //background: "#fdfdfd",
    },
    header: {
        //marginLeft: theme.spacing(3),
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    textField: {
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(1)
    },
    details: {
        marginBottom: theme.spacing(3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        minWidth: '20vw',
    },
    chip: {
        marginLeft: theme.spacing(1),
    },
    returnIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(3),
    },
    addImageButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    submitButton: {
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        backgroundColor: '#0B6AB0',
        color: '#FFFFFF'
    }
}));

const modules = {
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        ["link", "image", "video"],
        ["clean"]
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};

function CreateCourse() {
    const classes = useStyles();
    const history = useHistory();
    const [openSubmitSnackbar, setOpenSubmitSnackbar] = useState(false);

    const [title, setTitle] = useState("");
    const [details, setDetails] = useState("");
    const [duration, setDuration] = useState("");
    // const [fee, setFee] = useState("");
    const [image, setImage] = useState("");
    const [imageFilename, setImageFilename] = useState("");

    const [instructors, setInstructors] = useState([]);
    const [selectedInstructors, setSelectedInstructors] = useState([]);
    const [selectedInstIds, setSelectedInstIds] = useState([]);

    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState("");
    const [selectedSessionId, setSelectedSessionId] = useState("");

    const [errorTitle, setErrorTitle] = useState("");
    const [errorDetails, setErrorDetails] = useState("");
    const [errorDuration, setErrorDuration] = useState("");
    const [errorFee, setErrorFee] = useState("");
    const [errorImage, setErrorImage] = useState(false);
    const [errorSelectedInst, setErrorSelectedInst] = useState(false);
    const [errorSelectedSession, setErrorSelectedSession] = useState(false);

    useEffect(() => {
        const getInstructors = () => {
            axios({
                method: 'GET',
                url: 'instructors/instructor-list/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Instructors fetch success!', response.status);
                    setInstructors(response.data);
                } else {
                    console.log('Instructors fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Instructors fetch failed!', error);
            })
        }
        const getSessions = () => {
            axios({
                method: 'GET',
                url: 'courses/academic-session-list/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('Sessions fetch success!', response.status);
                    setSessions(response.data);
                } else {
                    console.log('Sessions fetch failed!', response.status);
                }
            }).catch((error) => {
                console.log('Sessions fetch failed!', error);
            })
        }
        getInstructors();
        getSessions();
    }, [])

    const handleSelectInstructor = (event) => {
        setSelectedInstructors(event.target.value);

        var id = event.currentTarget.getAttribute("id")
        if (selectedInstIds.includes(id)) {
            var ids = [...selectedInstIds];
            var index = ids.indexOf(id)
            ids.splice(index, 1);
            setSelectedInstIds(ids);
        }
        else {
            setSelectedInstIds([...selectedInstIds, id]);
        }
    };

    const handleSelectSession = (event) => {
        setSelectedSession(event.target.value);

        var id = event.currentTarget.getAttribute("id")
        setSelectedSessionId(id);
    };

    const handleSelectImage = (file) => {
        setImage(file);
        setImageFilename(file.name);
    }

    const submitCourse = () => {
        if (validate()) {
            const uploadData = new FormData();
            uploadData.append('image', image, image.name);
            uploadData.append('instructor_ids', JSON.stringify(selectedInstIds));
            uploadData.append('title', title);
            uploadData.append('details', details);
            uploadData.append('duration', duration);
            // uploadData.append('fee', fee);
            axios({
                method: 'POST',
                url: `courses/list/${selectedSessionId}/`,
                data: uploadData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status == 201) {
                    console.log('Course created!');
                    setOpenSubmitSnackbar(true);

                    setTitle("");
                    setDetails("");
                    setDuration("");
                    // setFee("");
                    setImageFilename("");

                    setTimeout(function () {
                        history.push("/admin-courses");
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const handleCloseSubmitSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSubmitSnackbar(false);
    };

    const validate = () => {
        var validated = true;

        if (title == '') {
            setErrorTitle('Must not be kept blank');
            validated = false;
        }
        else {
            setErrorTitle('');
        }
        if (isNaN(parseInt(duration))) {
            setErrorDuration('Must be an integer');
            validated = false;
        }
        else {
            setErrorDuration('');
        }
        // if (isNaN(parseInt(fee))) {
        //     setErrorFee('Must be a number');
        //     validated = false;
        // }
        // else {
        //     setErrorFee('');
        // }
        if (imageFilename === '') {
            setErrorImage(true);
            validated = false;
        }
        else {
            setErrorImage(false);
        }

        if (selectedInstIds.length === 0) {
            setErrorSelectedInst(true);
            validated = false;
        }
        else {
            setErrorSelectedInst(false);
        }

        if (selectedSession === "") {
            setErrorSelectedSession(true);
            validated = false;
        }
        else {
            setErrorSelectedSession(false);
        }

        return validated;
    }

    return (
        <>
            <Container maxWidth={false} className={classes.container}>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <ArrowBackIosIcon className={classes.returnIcon} onClick={() => history.goBack()} />
                    <Typography variant='h5' className={classes.header} display='inline'>Add Course</Typography>
                </Box>
                <Paper elevation={1} className={classes.paper} variant='outlined' square>
                    <Box display="flex" flexDirection="column" mb={2}>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Button variant="outlined" className={classes.addImageButton} size='small' component="label" startIcon={<AddAPhotoOutlinedIcon />} style={{ textTransform: 'none' }} disableElevation>
                                Select Course Image
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleSelectImage(e.target.files[0])}
                                    hidden
                                />
                            </Button>
                            <Typography variant="body2" color="textSecondary" style={{ marginLeft: '10px' }}>Selected file: {imageFilename ? imageFilename : 'No image selected'}</Typography>
                        </Box>
                        {errorImage && <Typography variant="caption" color="secondary">Image must be selected</Typography>}
                    </Box>
                    <Grid container>
                        <Grid item xs={8}>
                            <TextField
                                className={classes.textField}
                                label="Course Title"
                                variant="outlined"
                                size='small'
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                                error={errorTitle.length === 0 ? false : true}
                                helperText={errorTitle}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className={classes.textField}
                                label="Duration (weeks)"
                                variant="outlined"
                                size='small'
                                type='number'
                                value={duration}
                                onChange={(event) => setDuration(event.target.value)}
                                error={errorDuration.length === 0 ? false : true}
                                helperText={errorDuration}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField
                                className={classes.textField}
                                label="Fee ($)"
                                variant="outlined"
                                size='small'
                                type='number'
                                value={fee}
                                onChange={(event) => setFee(event.target.value)}
                                error={errorFee.length === 0 ? false : true}
                                helperText={errorFee}
                                fullWidth
                            />
                        </Grid> */}
                    </Grid>
                    <Typography variant="subtitle1" style={{ color: "gray" }}>
                        Details
                    </Typography>
                    <ReactQuill
                        className={classes.details}
                        theme="snow"
                        value={details}
                        modules={modules}
                        onChange={(details) => setDetails(details)}
                    />
                    <Box>
                        <FormControl
                            error={errorSelectedInst}
                            variant="outlined"
                            size='small'
                            className={classes.formControl}>
                            <InputLabel>Select Instructors</InputLabel>
                            <Select
                                multiple
                                value={selectedInstructors}
                                label="Select Instructors"
                                onChange={handleSelectInstructor}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value, index) => (
                                            <Chip key={index} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            >
                                {instructors.length > 0 ?
                                    instructors.map((instructor, index) => (
                                        <MenuItem key={instructor.id} value={instructor.name} id={instructor.id}>{instructor.name}</MenuItem>
                                    )) : <><MenuItem key={0}>No instructor available</MenuItem> </>
                                }
                            </Select>
                            {errorSelectedInst && <FormHelperText>Must not be kept blank</FormHelperText>}
                        </FormControl>
                    </Box>
                    <Box mt={3}>
                        <FormControl
                            error={errorSelectedSession}
                            variant="outlined"
                            size='small'
                            className={classes.formControl}>
                            <InputLabel>Select Session</InputLabel>
                            <Select
                                value={selectedSession}
                                label="Select Session"
                                onChange={handleSelectSession}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        <Chip key={selected} label={selected} className={classes.chip} />
                                    </div>
                                )}
                            >
                                {sessions.length > 0 ?
                                    sessions.map((session, index) => (
                                        <MenuItem key={session.id} value={session.title} id={session.id}>{session.title}</MenuItem>
                                    )) : <><MenuItem key={0}>No Session available</MenuItem> </>
                                }
                            </Select>
                            {errorSelectedSession && <FormHelperText>Must not be kept blank</FormHelperText>}
                        </FormControl>
                    </Box>

                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" className={classes.submitButton} onClick={submitCourse}>
                            Submit
                        </Button>
                    </div>
                </Paper>
            </Container>

            <Snackbar open={openSubmitSnackbar} autoHideDuration={4000} onClose={handleCloseSubmitSnackbar}>
                <Alert onClose={handleCloseSubmitSnackbar} severity="success">
                    Course created successfully!
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreateCourse
