import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Grid, Box, Typography, Tab, Tabs, Backdrop, Button, Snackbar } from '@material-ui/core';
import ReactQuill from 'react-quill';
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import qs from 'qs'
import 'react-quill/dist/quill.bubble.css';
import { IoHourglassOutline } from "react-icons/io5"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    courseContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    title: {
        color: '#0B6AB0',
    },
    subtitle: {
        color: 'grey',
    },
    details: {
        marginTop: theme.spacing(0),
        width: '50vw',
    },
    detailsTitle: {
        color: '#0B6AB0',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    instructorTitle: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        color: '#0B6AB0',
    },
    instructorDetails: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    enrollButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    },
    approvalPendingButton: {
        backgroundColor: '#ff9800',
        '&:hover': {
            backgroundColor: '#ff9800',
        },
        color: '#FFFFFF'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
}));

function CourseDetails() {
    const classes = useStyles();
    const [course, setCourse] = useState({});
    const { courseId } = useParams();
    const [showEnrollButton, setShowEnrollButton] = useState(false)
    const [showPendingButton, setShowPendingButton] = useState(false)
    const [showEnrolledButton, setShowEnrolledButton] = useState(false)

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    let button = <> </>

    useEffect(() => {
        const getCourse = async () => {
            setIsLoading(true)
            await axios
                .get(`courses/detail-unauth/${courseId}/`)
                .then((response) => {
                    if (response.status) {
                        setIsLoading(false)
                        setCourse(response.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        const getCourseEnrollStatus = async () => {
            await axios
                .get('students/course-enroll/', {
                    params: {
                        course_id: courseId
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        console.log('## ---- Enroll Status Success ---- ##', response.data);
                        if (response.data.course_status == 'Enroll') {
                            setShowEnrollButton(true)

                        } else if (response.data.course_status == 'Enrolled') {
                            setShowEnrolledButton(true)

                        } else if (response.data.course_status == 'Waiting for approval') {
                            setShowPendingButton(true)

                        }
                    } else {
                        console.log('## ---- Enroll Status Failed ---- ##', response.status);
                    }
                })
                .catch((error) => {
                    console.log('## --- Enroll Status Error ---- ##', error);
                    if (error.response.data.course_status == 'Not enrolled') {
                        setShowEnrollButton(true)
                    }
                })
        }
        getCourse();
        getCourseEnrollStatus();

    }, [])

    const enrollCourse = async () => {
        const body = qs.stringify({
            course_id: courseId
        })
        await axios
            .post('students/course-enroll/', body, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                }
            })
            .then((response) => {
                if (response.status === 201) {
                    console.log('## ---- Course Enroll Success ---- ##', response.status);
                    setShowPendingButton(true)
                    setShowEnrollButton(false)
                    setShowSnackbar(true)
                } else {
                    console.log('## ---- Course Enroll Failed ---- ##', response.status);
                    setShowPendingButton(false)
                    setShowEnrollButton(true)
                }
            })
            .catch((error) => {
                console.log('## ---- Course Enroll Error ---- ##', error);
            })
    }

    if (showEnrollButton) {
        button = <Button
            className={classes.enrollButton}
            variant='contained'
            onClick={() => enrollCourse()}>
            Enroll
        </Button>
    } else if (showPendingButton) {
        button = <Button
            className={classes.approvalPendingButton}
            variant='contained'
            disableElevation>
            Waiting for approval
        </Button>
    } else if (showEnrolledButton) {
        button = <Button
            disabled
            variant='contained'>
            Enrolled
        </Button>
    }

    return (
        <>
            {
                !isLoading &&
                <Container maxWidth={false} className={classes.root}>
                    <div className={classes.courseContainer}>
                        <Grid
                            container
                            direction='row'
                            justify='space-between'>
                            <Grid item xs={6}>
                                <Typography variant="h5" color="initial" className={classes.title}>{course.title}</Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                {button}
                            </Grid>
                        </Grid>

                        {/* <Typography variant="h6" color="initial" className={classes.subtitle}>Duration: {course.duration} weeks | Fee: ${course.fee}</Typography> */}
                        <Typography variant="h6" color="initial" className={classes.subtitle}>Duration: {course.duration} weeks</Typography>

                        <Typography variant="h6" color="initial" className={classes.detailsTitle}>About</Typography>
                        <Grid container>
                            <Grid item xs={12} className={classes.details}>
                                <Paper elevation={1} variant='outlined'>
                                    <ReactQuill
                                        //className={classes.details}
                                        value={course.details || ''}
                                        readOnly={true}
                                        theme={"bubble"}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" color="initial" className={classes.instructorTitle}>Instructors</Typography>
                        <Grid container>
                            {course.instructor && course.instructor.map((ins, index) => (
                                <Grid item xs={12} spacing={2}>
                                    <Paper elevation={1} className={classes.instructorDetails} variant='outlined'>
                                        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>{ins.name}</Typography>
                                        <Typography variant="body2" style={{ marginBottom: '15px' }}>{ins.occupation}</Typography>
                                        <Typography variant="body2" style={{ marginBottom: '15px' }}>{ins.details}</Typography>
                                        {/* <Typography variant="body2"><span>Email:</span> <i>{ins.contact_email}</i></Typography> */}
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <Snackbar open={showSnackbar} autoHideDuration={5000} onClose={handleClose} >
                        <Alert onClose={handleClose} severity="success">
                            Your enroll request successfully submitted!
                        </Alert>
                    </Snackbar>
                </Container>
            }
            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </>
    );
}

export default CourseDetails