import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Snackbar, Tabs, Tab, Paper, Backdrop } from '@material-ui/core';
//import { TabPanel } from '@material-ui/lab';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { IoHourglassOutline } from "react-icons/io5";

import WeekSubmissions from "./WeekSubmissions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0),
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '100vh',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        //backgroundColor: '#fcfdfe',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}));

function SubmittedHomeworks({ courseId }) {
    var tabIndex = -1;
    const classes = useStyles();
    const [courseContents, setCourseContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState(0);

    const incTabIndex = () => {
        tabIndex++;

        return tabIndex;
    }

    const resetTabIndex = () => {
        tabIndex = -1;
    }

    useEffect(() => {
        const getData = async () => {
        await axios({
            method: 'GET',
            url: `courses/content-min-list/${courseId}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                setCourseContents(res.data);
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
        }

        getData();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
        {!isLoading &&
            <Paper elevation={1} className={classes.container} variant='outlined' square>
                {!courseContents.every((courseContent, index) => courseContent.homework_submission_number===0) ?
                    <div className={classes.root}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="weeks"
                            indicatorColor="primary"
                            className={classes.tabs}
                            style={{ width: '12vw' }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#0B6AB0"
                                }
                            }}
                        >
                            {courseContents.map((courseContent, index) => (
                                (courseContent.homework_submission_number &&
                                    <Tab label={`Week ${courseContent.week}`} {...a11yProps(incTabIndex())} />
                                )
                            ))}
                        </Tabs>
                        {resetTabIndex()}
                        {courseContents.map((courseContent, index) => (
                            (courseContent.homework_submission_number ?
                                <TabPanel value={value} index={incTabIndex()} style={{ width: '88vw' }}>
                                    <WeekSubmissions courseContentId={courseContent.id} />
                                </TabPanel>
                                :
                                <></>
                            )
                        ))}
                    </div>
                    :
                    <Box height='60vh' display='flex' flexDirection="column" justifyContent='center'>
                        <Typography variant="h5" color="textSecondary" align='center' style={{ color: '#0B6AB0' }}>No Submission Found</Typography>
                    </Box>
                }
                
            </Paper>
        }

        <Backdrop className={classes.backdrop} open={isLoading}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
            {/* <Typography variant="h6" color="initial" style={{ marginLeft: '5px', fontWeight: 'normal' }}>Loading...</Typography> */}
        </Backdrop>
        </>
    )
}

export default SubmittedHomeworks;