import React, { useEffect } from 'react';
import { IoHourglassOutline } from "react-icons/io5";
import { makeStyles, Paper, Typography, withStyles, Backdrop, Box } from '@material-ui/core';
import { useState } from 'react';
import axios from 'axios';
import CumulativeResultListTable from './CumulativeResultListTable';


const useStyles = makeStyles((theme) => ({
  heading: {
      //fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#0B6AB0',
      '&.MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0,0.02)'
      }
  },
}))


const CumulativeProgress = ({ courseId }) => {
  const classes = useStyles();

  const [results, setResults] = useState([]);
  const [totalExam, setTotalExam] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const getResults = () => {
      setIsLoading(true);
      axios({
        method: 'GET',
        url: 'exams/cumulative-result-list/',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },
        params: {
          course_id: courseId
        }
      }).then((response) => {
        console.log("# ---- Cumulative result fetch success ---- #", response.status);
        setIsLoading(false);
        if (response.status) {
          setResults(response.data.students);
          setTotalExam(response.data.total_exam)
        }

      }).catch((error) => {
        console.log("# ---- Cumulative result fetch error ---- #", error);
        setIsLoading(false);
      })
    }
    getResults()
  }, [])
  
  return (
   <>
    {!isLoading && <CumulativeResultListTable results={results} totalExam={totalExam} />}
    <Backdrop className={classes.backdrop} open={isLoading}>
        <IoHourglassOutline style={{ fontSize: 35 }} />
    </Backdrop>
   </> 
  )
}

export default CumulativeProgress