import { useState, useEffect } from 'react'
import { Dialog, DialogContent, Button, Typography, Box, Paper, Backdrop, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { IoHourglassOutline } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    cancelButton: {
        textTransform: 'none',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
}))

const StorageUsageDlg = ({ courseId, open, setOpen }) => {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getStorageUsage = () => {
            setIsLoading(true)
            axios({
                method: 'GET',
                url: `courses/storage-usage/${courseId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                setIsLoading(false)
                if (res.status === 200) {
                    setData(res.data);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
        }
        if (open) {
            getStorageUsage();
        }
    }, [open])

    return (
        <div>
            {!isLoading &&
            <Dialog scroll='body' maxWidth='sm' open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogContent>
                    <Typography variant="h6" color="initial" className={classes.title}>Storage Usage of this Course</Typography>

                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Videos</TableCell>
                                <TableCell align="right">{data.videos_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Lecture Notes</TableCell>
                                <TableCell align="right">{data.lecture_notes_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Homeworks</TableCell>
                                <TableCell align="right">{data.homeworks_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Homework Submissions</TableCell>
                                <TableCell align="right">{data.homework_submissions_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Homework Submission Reviews</TableCell>
                                <TableCell align="right">{data.homework_sub_reviews_size}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Image</TableCell>
                                <TableCell align="right">{data.image_size}</TableCell>
                            </TableRow>
                            <TableRow style={{ backgroundColor: '#fcfdfe' }}>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Total Storage Usage</TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold', color: '#0B6AB0' }}>{data.total_files_size}</TableCell>
                            </TableRow>
                            <TableRow style={{ backgroundColor: '#fcfdfe' }}>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>Total Video Duration</TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold', color: '#00BC9E' }}>{data.videos_duration}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    
                    <Box display='flex' flexDirection='column' alignItems='flex-end' mt={3} mb={1}>
                        <Button className={classes.cancelButton} color="primary" onClick={() => setOpen(false)} disableElevation>Close</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            }

            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </div>
    )
}

export default StorageUsageDlg;
