import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import Dashboard from './Dashboard'

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        // backgroundColor: '#000000'
    }
}));

function UserApproval() {
    const classes = useStyles()

    return (
        <Container maxWidth={false} className={classes.container}>
            <Dashboard />
        </Container>
    )
}

export default UserApproval
