import { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, LinearProgress, Paper, Tooltip, Dialog, TextField, Button, Snackbar } from '@material-ui/core'
import { useConfirm } from "material-ui-confirm"
import PropTypes from 'prop-types'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

import PdfViewer from './../PdfViewerSingle'
import FViewer from '../FViewer'
import TextFileReader from "../TextFileReader"

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function getFileExt(filename) {
    return filename.toLowerCase().split('.').pop();
}

function isOpenFViewer(ext) {
    var supportedFormats = ['csv', 'xlsx', 'docx'];

    return supportedFormats.indexOf(ext) !== -1;
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
    rootM: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0),
    },
    noteSelector: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        //whiteSpace: 'nowrap',
        overflow: 'hidden',
        //textOverflow: 'ellipsis',
        //backgroundColor: '#fcfdfe',
    },
    noteSelectorM: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        overflow: 'hidden',
    },
    noteIcon: {
        color: '#0B6AB0',
    },
    noteButton: {
        color: '#0B6AB0',
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(3),
    },
}));

function ExamFeedback({ examFeedbacks, mobileView }) {
    const classes = useStyles()
    const [isOpenFeedbackPdfDlg, setIsOpenFeedbackPdfDlg] = useState(Array.from({length: examFeedbacks.length}, i => i = false))
    const [isOpenFeedbackDocDlg, setIsOpenFeedbackDocDlg] = useState(Array.from({length: examFeedbacks.length}, i => i = false))
    const [isOpenFeedbackTxtDlg, setIsOpenFeedbackTxtDlg] = useState(Array.from({length: examFeedbacks.length}, i => i = false))

    const handleClickOpenFeedback = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenFeedbackPdfDlg];
            values[index] = true;
            setIsOpenFeedbackPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenFeedbackDocDlg];
            values[index] = true;
            setIsOpenFeedbackDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenFeedbackTxtDlg];
            values[index] = true;
            setIsOpenFeedbackTxtDlg(values);
        }
    }

    const handleCloseFeedback = (index, file) => {
        if (file.toLowerCase().endsWith('.pdf')) {
            var values = [...isOpenFeedbackPdfDlg];
            values[index] = false;
            setIsOpenFeedbackPdfDlg(values);
        }
        else if (isOpenFViewer(getFileExt(file))) {
            var values = [...isOpenFeedbackDocDlg];
            values[index] = false;
            setIsOpenFeedbackDocDlg(values);
        }
        else if (getFileExt(file)==='txt') {
            var values = [...isOpenFeedbackTxtDlg];
            values[index] = false;
            setIsOpenFeedbackTxtDlg(values);
        }
    }

    return (
        <div className={mobileView ? classes.rootM : classes.root}>
            <Box mb={1}>
                <Typography variant="h6" color='textSecondary' style={{ color: '#0B6AB0' }}>Exam Feedback</Typography>
            </Box>
            {examFeedbacks.map((feedback, index) => (
                <div>
                    <Paper
                        className={mobileView ? classes.noteSelectorM : classes.noteSelector}
                        variant='outlined'
                        square
                        onClick={() => handleClickOpenFeedback(index, feedback.file)}
                    >
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                            <Box display='flex' flexDirection='row'>
                                <DescriptionOutlinedIcon className={classes.noteIcon} />
                                {mobileView ?
                                    <Typography noWrap variant='body1' display='inline' style={{ marginLeft: '10px', width: '50vw' }}>{feedback.title}</Typography>
                                    :
                                    <Typography variant='body1' display='inline' style={{ marginLeft: '10px' }}>{feedback.title}</Typography>
                                }
                            </Box>
                            <Button variant="text" color="default" className={classes.noteButton}>
                                View
                            </Button>
                        </Box>
                    </Paper>
                    <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenFeedbackPdfDlg[index]} onClose={() => handleCloseFeedback(index, feedback.file)}>
                        <PdfViewer file={`${contentBaseURL}${feedback.file}`} />
                    </Dialog>
                    <Dialog maxWidth={false} scroll='body' open={isOpenFeedbackDocDlg[index]} onClose={() => handleCloseFeedback(index, feedback.file)} fullWidth>
                        <FViewer file={`${contentBaseURL}${feedback.file}`} type={getFileExt(feedback.file)} />
                    </Dialog>
                    <Dialog maxWidth={false} scroll='body' open={isOpenFeedbackTxtDlg[index]} onClose={() => handleCloseFeedback(index, feedback.file)} fullWidth>
                        <TextFileReader txt={`${contentBaseURL}${feedback.file}`}/>
                    </Dialog>
                </div>
            ))}
        </div>
    )
}

export default ExamFeedback
