import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

// Define column headers
const columns = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 540 },
  { field: "email", headerName: "Email", width: 540 },
];

function rows(admins) {
  const rows = [];
  admins.map((admin, index) =>
    rows.push({
      key: admin.id,
      id: admin.id,
      name: admin.name,
      email: admin.email,
    })
  );
  return rows;
}

const AdminListTable = ({ admins, handleChecked, handleSelectedAdmins }) => {
  return (
    <div>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowHeight={35}
        rows={rows(admins)}
        columns={columns}
        onSelectionModelChange={(newSelection) => {
          handleChecked(newSelection.length);
          handleSelectedAdmins(newSelection);
        }}
        pageSize={10}
      />
    </div>
  );
};

export default AdminListTable;
