import { useState, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import {
    Slide, Dialog, Backdrop, AppBar, Toolbar, Paper, Container, Tooltip, TextareaAutosize, TextField,
    Typography, Box, Snackbar, Table, TableHead, TableBody, TableRow, TableCell, Grid, Button, DialogActions
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { BiCalendarEvent} from "react-icons/bi";
import { FiX, FiBookOpen, FiEdit, FiTrash, FiFileText, FiSquare, FiXSquare, FiCheckSquare } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import axios from 'axios';
import { useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import ReactQuill from 'react-quill';
import Schedule from "./Schedule";
import EnabledStudentList from "./EnabledStudentList";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#EDF0F5",
        color: "black",
    },
    title: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    xIcon: {
        cursor: 'pointer',
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    container: {
        margin: theme.spacing(2)
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ExamSchedule = ({ examId, examTitle, examEnableAll, open, setOpen }) => {
    const classes = useStyles();
  return (
    <div>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar variant='dense'>
                    <BiCalendarEvent />
                    <Typography variant="body1" className={classes.title}>{examTitle}</Typography>
                    <FiX className={classes.xIcon} onClick={() => setOpen(false)} />
                </Toolbar>
            </AppBar>
            <Box mt={2} ml={3} mr={3}>
                <Schedule examId={examId} examEnableAll={examEnableAll} open={open}/>
            </Box>
        </Dialog>
    </div>
  )
};

export default ExamSchedule;
