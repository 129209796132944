import { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, LinearProgress, Paper, Tooltip, Dialog, TextField, Button, Snackbar } from '@material-ui/core'
import { useConfirm } from "material-ui-confirm"
import PropTypes from 'prop-types'
import axios from 'axios'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import MuiAlert from '@material-ui/lab/Alert'

import PdfViewer from '../PdfViewerSingle'
import FViewer from '../FViewer'
import TextFileReader from "../TextFileReader"

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function getFileExt(filename) {
    return filename.toLowerCase().split('.').pop();
}

function isOpenFViewer(ext) {
    var supportedFormats = ['csv', 'xlsx', 'docx'];

    return supportedFormats.indexOf(ext) !== -1;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center" mb={2}>
            <Box minWidth={35} mr={2}>
                <Typography variant="body2" color="primary">Uploading</Typography>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
    reviewContainer: {
        //marginBottom: theme.spacing(4),
        padding: theme.spacing(4),
        backgroundColor: '#fcfdfe'
    },
    contentContainer: {
        padding: theme.spacing(2),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    uploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fileIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    dialog: {
        position: 'absolute',
        top: theme.spacing(3),
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
    deleteConfirmButton: {
        textTransform: 'none',
        backgroundColor: '#f44336',
        '&:hover': {
            backgroundColor: '#f44336',
        },
        color: '#FFFFFF'
    },
    cancelButton: {
        textTransform: 'none',
    },
    dialogTitle: {
        color: '#0B6AB0',
    },
}));

function UploadHomeworkSubReview({ submissions, setSubmissions, subIndex, homeworkSubReview, courseContentId, studentId }) {
    const classes = useStyles()
    const confirm = useConfirm()
    const [reviewTitle, setReviewTitle] = useState("")
    const [errorReviewTitle, setErrorReviewTitle] = useState("")
    const [uploadFile, setUploadFile] = useState()
    const [isUploadReview, setIsUploadReview] = useState(true)
    const [reviewFilename, setReviewFilename] = useState('No file selected')
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)
    const [isOpenReviewPdfDlg, setIsOpenReviewPdfDlg] = useState(Array.from({ length: homeworkSubReview.length }, i => i = false))
    const [isOpenReviewDocDlg, setIsOpenReviewDocDlg] = useState(Array.from({ length: homeworkSubReview.length }, i => i = false))
    const [isOpenReviewTxtDlg, setIsOpenReviewTxtDlg] = useState(Array.from({ length: homeworkSubReview.length }, i => i = false))
    const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false)
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false)
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false)

    const handleChangeReviewTitle = (newValue) => {
        setReviewTitle(newValue);
    }

    const handleClickOpenReviewPdf = (index) => {
        var values = [...isOpenReviewPdfDlg];
        values[index] = true;
        setIsOpenReviewPdfDlg(values);
    }

    const handleCloseReviewPdf = (index) => {
        var values = [...isOpenReviewPdfDlg];
        values[index] = false;
        setIsOpenReviewPdfDlg(values);
    }

    const handleClickOpenReviewDoc = (index) => {
        var values = [...isOpenReviewDocDlg];
        values[index] = true;
        setIsOpenReviewDocDlg(values);
    }

    const handleCloseReviewDoc = (index) => {
        var values = [...isOpenReviewDocDlg];
        values[index] = false;
        setIsOpenReviewDocDlg(values);
    }

    const handleClickOpenReviewTxt = (index) => {
        var values = [...isOpenReviewTxtDlg];
        values[index] = true;
        setIsOpenReviewTxtDlg(values);
    }

    const handleCloseReviewTxt = (index) => {
        var values = [...isOpenReviewTxtDlg];
        values[index] = false;
        setIsOpenReviewTxtDlg(values);
    }

    const handleUpload = () => {
        const uploadData = new FormData();

        if (reviewTitle) {
            // Show progressbar
            setShowProgress(true);
            uploadData.append('coursecontent_id', courseContentId)
            uploadData.append('student_id', studentId)
            uploadData.append('homeworksubreview', uploadFile, uploadFile.name)
            uploadData.append('title', reviewTitle)
            axios({
                method: "POST",
                url: `courses/student-homework-sub-review/`,
                data: uploadData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgress(percentCompleted);
                },
            }).then(function (res) {
                if (res.status === 201) {
                    console.log('Review uploaded.');

                    // Hide progressbar
                    setShowProgress(false);
                    // Disable upload button
                    setIsUploadReview(true);
                    // Clear title
                    setReviewTitle("");
                    // Clear error message
                    setErrorReviewTitle("");
                    // Add review field
                    var values = [...submissions]
                    values[subIndex].homeworksubreview = [...values[subIndex].homeworksubreview, { id: res.data.id, title: res.data.title ? res.data.title : 'No title', file: res.data.file }]
                    setSubmissions(values)

                    setIsOpenReviewPdfDlg([...isOpenReviewPdfDlg, false])
                    setIsOpenReviewDocDlg([...isOpenReviewDocDlg, false])
                    setIsOpenReviewTxtDlg([...isOpenReviewTxtDlg, false])

                    // Clear added file
                    setReviewFilename('No file selected')

                    setOpenUploadSnackbar(true);
                }
            }).catch((error) => {
                console.log(error);
                setOpenFailureSnackbar(true);

                // Hide progressbar
                setShowProgress(false);
                setIsUploadReview(true);
            });
        }
        else {
            setErrorReviewTitle('Title must not be kept blank');
        }

    }

    const handleSelect = (file) => {
        // Set file for upload
        setUploadFile(file);

        // Enable upload button
        setIsUploadReview(false);

        // Set filename
        setReviewFilename(file.name);
    }

    const deleteReviewConfirm = (index, id, title) => {
        confirm({
            title: <Typography variant='h6' className={classes.dialogTitle}>Delete File</Typography>,
            description: <Typography variant='body1'>Are you sure to delete <strong>{title}</strong>?</Typography>,
            confirmationText: 'Delete',
            confirmationButtonProps: { className: classes.deleteConfirmButton, disableElevation: true },
            cancellationButtonProps: { color: 'primary', className: classes.cancelButton, disableElevation: true },
        })
            .then(() => handleDelete(index, id))
            .catch(() => console.log('Cancelled!'));
    }

    const handleDelete = (index, id) => {
        axios({
            method: "delete",
            url: `courses/student-homework-sub-review/`,
            data: {
                id: id
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(function (res) {
            if (res.status === 202) {
                console.log('Review deleted!');

                var values = [...submissions]
                values[subIndex].homeworksubreview.splice(index, 1)
                setSubmissions(values)

                setOpenDeleteSnackbar(true);
            }
        }).catch((error) => {
            console.log(error);
            setOpenFailureSnackbar(true);
        });
    }

    const handleCloseUploadSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUploadSnackbar(false);
    };

    const handleCloseDeleteSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    return (
        <Paper variant='outlined' className={classes.reviewContainer} square>
            {showProgress && <LinearProgressWithLabel value={progress} />}
            <Typography variant="body1" color="initial" style={{ marginBottom: '5px', fontWeight: 'bold' }}>Submit Feedback</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={0} variant='outlined' square className={classes.contentContainer}>
                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>Feedback Files</Typography>
                        {homeworkSubReview.length ?
                            homeworkSubReview.map((review, i) => (
                                <div className={classes.content}>
                                    <Box display='flex' flexDirection='row' alignItems='center'>
                                        <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '20vw' }}>{review.title ? review.title : 'No title'}</Typography>
                                        <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{getFileExt(review.file)}</Typography>
                                    </Box>
                                    <div>
                                        {
                                            (() => {
                                                if (`${review.file}`.toLowerCase().endsWith('.pdf')) {
                                                    return <Tooltip title='View'>
                                                        <DescriptionOutlinedIcon onClick={() => handleClickOpenReviewPdf(i)} className={classes.fileIcon} />
                                                    </Tooltip>
                                                }
                                                else if (isOpenFViewer(getFileExt(review.file))) {
                                                    return <Tooltip title='View'>
                                                        <DescriptionOutlinedIcon onClick={() => handleClickOpenReviewDoc(i)} className={classes.fileIcon} />
                                                    </Tooltip>
                                                }
                                                else if (getFileExt(review.file) === 'txt') {
                                                    return <Tooltip title='View'>
                                                        <DescriptionOutlinedIcon onClick={() => handleClickOpenReviewTxt(i)} className={classes.fileIcon} />
                                                    </Tooltip>
                                                }
                                            })()
                                        }
                                        <Tooltip title='Download'>
                                            <a target="_blank" href={`${contentBaseURL}${review.file}`} download><GetAppOutlinedIcon className={classes.downloadIcon} /></a>
                                        </Tooltip>
                                        <Tooltip title='Delete'>
                                            <DeleteOutlineOutlinedIcon onClick={() => deleteReviewConfirm(i, review.id, review.title)} className={classes.deleteIcon} />
                                        </Tooltip>
                                        <Dialog maxWidth={false} scroll='paper' classes={{ paper: classes.dialog }} open={isOpenReviewPdfDlg[i]} onClose={() => handleCloseReviewPdf(i)}>
                                            <PdfViewer file={`${contentBaseURL}${review.file}`} />
                                        </Dialog>
                                        <Dialog maxWidth={false} scroll='body' open={isOpenReviewDocDlg[i]} onClose={() => handleCloseReviewDoc(i)} fullWidth>
                                            <FViewer file={`${contentBaseURL}${review.file}`} type={getFileExt(review.file)} />
                                        </Dialog>
                                        <Dialog maxWidth={false} scroll='body' open={isOpenReviewTxtDlg[i]} onClose={() => handleCloseReviewTxt(i)} fullWidth>
                                            <TextFileReader txt={`${contentBaseURL}${review.file}`} />
                                        </Dialog>
                                    </div>
                                </div>
                            ))
                            :
                            <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '30px' }}>Not uploaded yet</Typography>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={classes.uploadContainer}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div>
                            <TextField
                                label="Set Feedback Title"
                                value={reviewTitle}
                                onChange={(e) => handleChangeReviewTitle(e.target.value)}
                                style={{ marginBottom: '10px' }}
                                size="small"
                                error={errorReviewTitle ? errorReviewTitle.length === 0 ? false : true : false}
                                helperText={errorReviewTitle}
                                fullWidth
                                variant='outlined'
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button
                                    variant="contained"
                                    size='small'
                                    color="primary"
                                    onClick={() => handleUpload()}
                                    disabled={isUploadReview}
                                    startIcon={<CloudUploadOutlinedIcon />}
                                    style={{ marginBottom: '0px' }}
                                    disableElevation
                                    className={classes.uploadButton}
                                >
                                    Upload
                                </Button>
                                <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                    Add Feedback File
                                    <input
                                        type="file"
                                        accept='.pdf, .docx, .xlsx, .txt, .csv'
                                        onChange={(e) => handleSelect(e.target.files[0])}
                                        hidden
                                    />
                                </Button>
                            </div>
                            <Typography
                                variant="caption"
                                color='textSecondary'
                                style={{ marginTop: '5px' }}
                            >
                                Added file: {reviewFilename}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Snackbar open={openUploadSnackbar} autoHideDuration={4000} onClose={handleCloseUploadSnackbar}>
                <Alert onClose={handleCloseUploadSnackbar} severity="success">
                    File uploaded successfully.
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteSnackbar} autoHideDuration={4000} onClose={handleCloseDeleteSnackbar}>
                <Alert onClose={handleCloseDeleteSnackbar} severity="success">
                    Feedback deleted.
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={4000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    Operation failed!
                </Alert>
            </Snackbar>
        </Paper>
    )
}

export default UploadHomeworkSubReview
