import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Snackbar, Tabs, Tab, Paper, Grid, Backdrop, Divider } from '@material-ui/core';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import { IoHourglassOutline } from "react-icons/io5";

import UploadHomeworkSubReview from "./UploadHomeworkSubReview";
import SingleSubmission from "./SingleSubmission";

const contentBaseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
      //backgroundColor: '#fcfdfe',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 70,
        backgroundColor: "#fcfdfe",
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        //width: '100%',
        //padding: theme.spacing(3),
    },
    submission: {
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    heading: {
        //fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    downloadIcon: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    },
    showUploadFeedback: {
        cursor: "pointer",
        marginLeft: theme.spacing(3),
        color: "#0B6AB0",
        //fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    }
}));

function WeekSubmissions({ courseContentId }) {
    const classes = useStyles();
    const [submissions, setSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showUploadReview, setShowUploadReview] = useState(false);

    useEffect(() => {
        const getData = async () => {
        await axios({
            method: 'GET',
            url: `courses/student-homework-submission/${courseContentId}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then((res) => {
            if (res.status == 200) {
                setSubmissions(res.data);

                setIsLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
        }

        getData();
    }, []);

    return (
        <div className={classes.root}>
        {!isLoading &&
            <>
                {submissions.map((submission, index) => (
                    submission.homeworksubmission.length ?
                        <Accordion square>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography variant="body1" className={classes.heading}>
                                    {submission.first_name} {submission.last_name} <Typography variant="caption" color='textSecondary' display='inline'>#{submission.id}</Typography>
                                </Typography>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography variant="body2" color="textSecondary" style={{ marginRight: 8 }}>{submission.homeworksubmission.length > 1 ? `${submission.homeworksubmission.length} Files` : `${submission.homeworksubmission.length} File`}</Typography>
                                    <Typography variant="caption" color="textSecondary">(Last submission {submission.homeworksubmission[submission.homeworksubmission.length-1].submission_time})</Typography>
                                    {!submission.homeworksubmission.every((elem) => {return elem.is_comment_seen}) && <Typography variant="caption" style={{ color: "#0B6AB0", fontWeight: 'bold', marginLeft: 8 }}>(New comment)</Typography>}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Paper elevation={0} variant='elevation' square>
                                            <Box p={3}>
                                                <Grid container spacing={2}>  
                                                    {submission.homeworksubmission.map((sub, index) => (
                                                        <Grid item xs={12}>
                                                            <SingleSubmission submission={sub} />
                                                            <Divider />
                                                        </Grid>
                                                    ))}
                                                    
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                            onClick={() => setShowUploadReview(!showUploadReview)}
                                            className={classes.showUploadFeedback}
                                        >
                                            {!showUploadReview ? "Upload feedback file" : "Hide ..."}
                                        </Typography>
                                        {showUploadReview && <UploadHomeworkSubReview submissions={submissions} setSubmissions={setSubmissions} subIndex={index} homeworkSubReview={submission.homeworksubreview} courseContentId={courseContentId} studentId={submission.id} />}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        :
                        <></>
                    
                ))}
            </>
        }

        <Backdrop className={classes.backdrop} open={isLoading}>
            <IoHourglassOutline style={{ fontSize: 35 }} />
            {/* <Typography variant="h6" color="initial" style={{ marginLeft: '5px', fontWeight: 'normal' }}>Loading...</Typography> */}
        </Backdrop>
        </div>
    )
}

export default WeekSubmissions;