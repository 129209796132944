import { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {Box, Typography, Paper, Table,
        TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { FiCalendar, FiClock } from "react-icons/fi";
import {userTime, getLocalDateFormat} from '../../custom_util'
import Popover from '@material-ui/core/Popover';
import ExamGradeView from "../ManageExam/ExamGradeView";


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    iconStyle:{
        position: 'relative',
        top: 2,
        marginRight: 4
    },
    tableHeader:{
        fontWeight: 'bold'
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#0B6AB0',
    },
    customWidth: {
        maxWidth: 700,
    },
    paper: {
        padding: 0,
        boxShadow: 'none',
        border: '1px solid gray'
    },
    popover: {
        pointerEvents: 'none',
    },
}));

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function HistoryList({ history }) {
    const classes = useStyles();
    const [exams, setExams] = useState([
        {
            title: "Week 8 Jira Course",
            start_time: new Date(), status:"Examining"
        },
        {
            title: "Week 6 Jira Course",
            start_time: new Date(), status:"Graded"
        }
    ])

    const [openViewResultDialog, setOpenViewResultDialog] = useState(false);
    const [submissionId, setSubmissionId] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickViewResult = (id) => {
        setSubmissionId(id);
        setOpenViewResultDialog(true);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box mb={2}>
                <Typography variant="h6" className={classes.header} display="inline">Exam History</Typography>
            </Box>
            {history && history.length ?
                <TableContainer component={Paper} variant="outlined">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>Name</TableCell>
                                <TableCell className={classes.tableHeader}>Course</TableCell>
                                <TableCell className={classes.tableHeader}>Time</TableCell>
                                <TableCell className={classes.tableHeader}>Status</TableCell>
                                <TableCell className={classes.tableHeader}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history && history.map((exam, key) => (
                                <TableRow key={key}>
                                    <TableCell>
                            
                                        <Typography 
                                            variant="body1"
                                            noWrap
                                            style={{maxWidth:"20vw"}}
                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}>{exam.exam.title}</Typography>

                                        <Popover
                                            id="mouse-over-popover"
                                            className={classes.popover}
                                            classes={{
                                                paper: classes.paper,
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'start',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <Typography>{exam.exam.title}</Typography>
                                        </Popover>
                                    
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/access-course/${exam.exam.course}`} className={classes.link}>Link</Link>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex">
                                            <FiCalendar color='#0B6AB0' className={classes.iconStyle}/> 
                                            <Typography variant="body2">{userTime(exam.started_at, 'DD MMM YYYY')}</Typography>
                                        </Box>
                                        <Box display="flex">
                                            <FiClock color="red" className={classes.iconStyle}/> 
                                            <Typography variant="body2">{userTime(exam.started_at, 'hh:mm A')}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{color: exam.is_graded ? '#00BC9E' : '#0B6AB0'}}>{exam.is_graded ? "Graded" : "Examining"}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="caption" className={classes.link} style={{color: 'black'}}>Details</Typography><br/>
                                        {exam.is_graded ? <Typography variant="caption" className={classes.link} style={{color: 'red'}} onClick={() => handleClickViewResult(exam.id)}>View Result</Typography> : <></>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    
                </TableContainer>
                :
                <Box display='flex' flexDirection='column' justifyContent='center' style={{ height: '20vh' }}>
                    <Typography variant='h6' color='textSecondary' align='center'>You have not given any exam yet.</Typography>                    
                </Box>
            }
            <ExamGradeView submissionId={submissionId} open={openViewResultDialog} setOpen={setOpenViewResultDialog} viewMode="Result"/>
        </>
    )
}

export default HistoryList
