import { useState, useEffect } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Snackbar, Backdrop, Paper,
    Tooltip,TextField } from '@material-ui/core';
import { IoHourglassOutline } from "react-icons/io5";
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ReactQuill, {Quill} from 'react-quill';
import ImageUploader from "quill-image-uploader";
import ImageResize from 'quill-image-resize-module-react';
import axios from "axios";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

//import 'react-quill/dist/quill.snow.css';
import "./../index.css";
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageUploader", ImageUploader);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
    },
    input: {
        width: '100%',
        padding: 8,
        border: '0.5px solid #ccc',
        '&::placeholder': {
            color: "rgba(0,0,0,0.6)",
        },
        '&:focus': {
            outline: 'none !important',
        },
        resize: 'none',
    },
    choice: {
        width: '90%',
        padding: 8,
        border: '0.5px solid #ccc',
        '&::placeholder': {
            color: "rgba(0,0,0,0.6)",
        },
        '&:focus': {
            outline: 'none !important',
        },
        resize: 'none',
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    addAnotherBtn: {
        textTransform: 'none',
        backgroundColor: '#00BC9E',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#00BC9E',
        }
    },
    saveBtn: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: '#0B6AB0',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        color: '#0B6AB0',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        },
    },
    uploadButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF',
    },
    addContentButton: {
        textTransform: 'none',
        '&:hover': {
            background: '#edf0f5',
        },
        borderColor: '#0B6AB0',
        color: '#0B6AB0',
    },
    deleteIcon: {
        color: '#C0392B',
        cursor: 'pointer',
        marginLeft: theme.spacing(1),
        fontSize: "21px"
    },
    deleteButton:{
        margin: 0,
        "&:hover":{
            backgroundColor: 'transparent'
        }
    },
    contentContainer: {
        padding: theme.spacing(2),
        //backgroundColor: '#fcfdfe',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
}));

const modules = {
    toolbar: [
        //[{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote", "image"],
        [
            { list: "ordered" },
            { list: "bullet" },
            //{ indent: "-1" },
            //{ indent: "+1" }
        ],
        //["link", "image", "video"],
        // ["image"],
        ["clean"]
    ],
    imageUploader:{
        upload: file => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("file", file);
                axios({
                    method: 'POST',
                    url: `exams/exam-question-picture-upload/`,
                    data: formData,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    },
                }).then(response => {
                    console.log(response.data.file)
                    resolve(response.data.file)
                })
                .catch(error =>{ 
                    console.log(error)
                    reject("Upload Refected")
                });
            })
        }
    },
    imageResize: {
        parchment: Quill.import('parchment')
        // See optional "config" below
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    }
};

const CreateMCQDlg = ({ examId, open, setOpen, questions, setQuestions, update=false, questionId=null, updateQuestion }) => {  // questionId for update only
    const classes = useStyles();
    const [addAnotherEnabled, setAddAnotherEnabled] = useState(true);
    const [qText, setQText] = useState("");
    const [marks, setMarks] = useState("");
    const [choices, setChoices] = useState([
        {text: "", correct: false},
        {text: "", correct: false},
        {text: "", correct: false},
        {text: "", correct: false},
    ]);

    const [errorQText, setErrorQText] = useState("");
    const [errorMarks, setErrorMarks] = useState("");
    const [errorChoices, setErrorChoices] = useState("");

    const [openCreateSnack, setOpenCreateSnack] = useState(false);
    const [openUpdateSnack, setOpenUpdateSnack] = useState(false);
    const [openFailureSnack, setOpenFailureSnack] = useState(false);

    const [image, setImage] = useState({file:undefined, name:undefined});
    const [images, setImages] = useState([]);

    const [openFileCreateSnack, setOpenFileCreateSnack] = useState(false)
    const [openFileDeleteSnack, setOpenFileDeleteSnack] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        init();
        const getData = async () => {
            setIsLoading(true)
            await axios({
                method: 'GET',
                url: `exams/question-details/${questionId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((res) => {
                if (res.status == 200) {
                    if (res.data.type === 'MCQ') {
                        setQText(res.data.mcq.q_text);
                        setMarks(res.data.mcq.marks);
                        setChoices([]);
                        setChoices(res.data.mcq.choice);
                        setImages(res.data.question_picture)
                    }
                    setIsLoading(false)
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
        }
        if (open && update)
            getData();
    }, [open]);

    const init = () => {
        setQText('');
        setMarks('');
        setChoices([
            {text: "", correct: false},
            {text: "", correct: false},
            {text: "", correct: false},
            {text: "", correct: false},
        ]);

        setErrorQText("");
        setErrorMarks("");
        setErrorChoices("");
    }

    const handleSave = (addAnother) => {
        if (validate()) {
            if (addAnother) {
                setAddAnotherEnabled(false);
            }
            else {
                setOpen(false);
            }
            setIsLoading(true)
            axios({
                method: 'POST',
                url: `exams/question-list/${examId}/`,
                data: {
                    type: "MCQ",
                    q_text: qText,
                    marks: marks,
                    choices: choices,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                setAddAnotherEnabled(true);
                if (response.status === 201) {
                    console.log('MCQ create success!');
                    setQuestions([...questions, response.data]);

                    setQText("");
                    setMarks("");
                    setChoices([]);
                    setChoices([
                        {text: "", correct: false},
                        {text: "", correct: false},
                        {text: "", correct: false},
                        {text: "", correct: false},
                    ]);
                    setIsLoading(false)
                    setOpenCreateSnack(true);
                } else {
                    console.log('MCQ create failed!', response.status);
                    setIsLoading(false);
                    setOpenFailureSnack(true);
                }
            }).catch((error) => {
                setAddAnotherEnabled(true);
                console.log('MCQ create error!', error);
                setIsLoading(false)
                setOpenFailureSnack(true);
            })
        }
    }

    const handleUpdate = () => {
        if (validate()) {
            setIsLoading(true)
            axios({
                method: 'PUT',
                url: `exams/question-details/${questionId}/`,
                data: {
                    type: "MCQ",
                    q_text: qText,
                    marks: marks,
                    choices: choices,
                },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    console.log('MCQ update success!');
                    updateQuestion(response.data)
                    setIsLoading(false);
                    setOpenUpdateSnack(true);
                } else {
                    console.log('MCQ update failed!', response.status);
                    setIsLoading(false)
                    setOpenFailureSnack(true);
                }
            }).catch((error) => {
                console.log('MCQ create error!', error);
                setIsLoading(false)
                setOpenFailureSnack(true);
            })
        }
    }

    const validate = () => {
        var validated = true;

        if (qText.trim()) {
            setErrorQText("");
        }
        else {
            setErrorQText('This field cannot be kept blank.');
            validated = false;
        }

        var num = parseFloat(marks);
        if (isNaN(num)) {
            setErrorMarks("This field cannot be kept blank.");
            validated = false;
        }
        else if (num===0) {
            setErrorMarks("Marks must not be a zero.");
            validated = false;
        }
        else if (num < 0) {
            setErrorMarks("Marks must not be negative.");
            validated = false;
        }
        else {
            setErrorMarks("");
        }

        return validateChoices(validated);
    }

    const validateChoices = (validated) => {
        var choiceCount = 0;
        var correctCount = 0;

        for (var i=0; i<choices.length; i++) {
            if (choices[i].text.trim()) choiceCount++;
            if (choices[i].correct) correctCount++;
        }

        if (choiceCount < 2) {
            setErrorChoices("There must be at least two choices filled up.");
            validated = false;
        }
        else if (correctCount < 1) {
            setErrorChoices("There must be at least one correct choice.");
            validated = false;
        }
        else {
            setErrorChoices("");
        }

        return validated;
    }

    const handleChoiceTxtChange = (index, value) => {
        var values = [...choices];
        values[index].text = value;
        setChoices(values);
    }

    const handleCheck = (index) => {
        var values = [...choices];
        values[index].correct = !values[index].correct;
        setChoices(values);
    }

    const handleAddChoice = () => {
        setChoices([...choices, {text: "", correct: false}]);
    }

    const handleDeleteChoice = () => {
        if (choices.length > 2) {
            var values = [...choices];
            values.pop();
            setChoices(values);
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        
        setOpen(false);
    }

    const handleCloseCreateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenCreateSnack(false);
    }

    const handleCloseUpdateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUpdateSnack(false);
    }

    const handleCloseFailureSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnack(false);
    }

    const uploadImage = () => {
        const formData = new FormData();
        formData.append("file", image.file);
        formData.append("name", image.name);
        formData.append("question", questionId);
        axios({
            method: 'POST',
            url: `exams/exam-question-picture-upload/`,
            data: formData,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(response => {
            setOpenFileCreateSnack(true)
            setImage({file:undefined, name:undefined})
            setImages([...images, response.data])
        })
        .catch(error =>{ 
            console.log("Picture upload Failed", error.data)
        });
    }

    const deleteImage = (id) => {
        axios({
            method: 'DELETE',
            url: `exams/exam-question-picture-delete/${id}/`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
        }).then(response => {
            if(response.status === 204){
                let x = images.filter(file => file.id !== id)
                setImages(x)
                setOpenFileDeleteSnack(true)
                //showError(true, `${fileType === "img"? "Picture" : "File" } Deleted`, "success")
            }
        })
        .catch(err => {
            console.log("File Delete Failed", err.status);
            setIsLoading(false)
        })
    }

    return (
        <>
        <Dialog scroll='body' maxWidth='sm' open={open} onClose={handleClose} fullWidth>
            <DialogContent>
                <Typography variant="h6" color="initial" className={classes.title}>{update ? 'Edit MCQ' : 'Create MCQ'}</Typography>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <IoHourglassOutline style={{ fontSize: 35 }} />
                </Backdrop>
                <Box>
                    <Typography variant="body2" color="initial">Question</Typography>
                    <ReactQuill
                        theme="snow"
                        value={qText}
                        placeholder='Type your question here...'
                        modules={modules}
                        onChange={(value) => setQText(value)}
                    />
                    <Typography variant='caption' style={{ color: '#C0392B' }}>{errorQText}</Typography>
                </Box>

                
                <Box mt={2}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <TextField
                            variant='outlined'
                            label={`Set File Title`}
                            value={image.name || ""}
                            onChange={(e) => setImage({...image, name:e.target.value})}
                            style={{ marginBottom: '10px'}}
                            size="small"
                            error={false}
                        />
                    <div>
                        <div>
                            <Button
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={() => uploadImage()}
                                disabled={image.file===undefined || image.name===undefined}
                                startIcon={<CloudUploadOutlinedIcon />}
                                disableElevation
                                className={classes.uploadButton}
                            >
                                Upload
                            </Button>
                            <Button variant="outlined" className={classes.addContentButton} size='small' component="label" startIcon={<AddOutlinedIcon />} style={{ marginLeft: '10px' }} disableElevation>
                                {/* Add {fileType === "img"? "Image" : "Document" } */}
                                Add File
                                <input
                                    type="file"
                                    id="input_field"
                                    accept={"*"}
                                    onChange={(e) => {
                                        setImage({...image, file:e.target.files[0]})
                                        e.target.value = null
                                    }}
                                    
                                    hidden
                                />
                            </Button>
                        </div>
                        <Typography
                            variant="caption"
                            color='textSecondary'
                            style={{ marginTop: '5px' }}
                        >
                            Added file: {image.file && image.file.name || "No file selected"}
                        </Typography>
                        </div>
                </div>
                </Box>

                <Paper variant='outlined' square className={classes.contentContainer}>
                    {images.length ? <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                        {` (${images.length} Files Uploaded)`}</Typography> : ""}
                        
                    {images.length ?
                        images.map((img, i) => (
                        <div className={classes.content}>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <Typography noWrap variant="body1" color="initial" display="inline" style={{ maxWidth: '30vw' }}>{img.name}</Typography>
                                <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{img.file.split(".").pop()}</Typography>
                                {/* <Typography variant='caption' color='textSecondary' display='inine' style={{ marginLeft: '5px' }}>{img.size}KB</Typography> */}
                            </Box>
                            <div>  
                            <Tooltip title='Delete'>
                                <Button startIcon={<DeleteOutlineOutlinedIcon className={classes.deleteIcon} /> }
                                        onClick={() =>deleteImage(img.id)}
                                        className={classes.deleteButton}
                                        disabled={isLoading}></Button>
                            </Tooltip>   
                            </div>
                        </div>
                        )) :
                
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginBottom: '10px', marginTop: '10px', }}>Not uploaded yet</Typography> }
                    {/* {(uploadOrDownloadCount <= 100 && uploadOrDownloadCount > 0) && <LinearProgressWithLabel value={uploadOrDownloadCount} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>} */}
                            
                    </Paper>

                <Box mt={2}>
                    <Typography variant="body2" color="initial">Marks</Typography>
                    <input
                        className={classes.input}
                        type='number'
                        value={marks}
                        placeholder='Type question marks here...'
                        onChange={(e) => setMarks(e.target.value)}
                    />
                    <Typography variant='caption' style={{ color: '#C0392B' }}>{errorMarks}</Typography>
                </Box>

                <Box mt={2}>
                    <Typography variant="body2" color="initial">Choices</Typography>
                    <Typography variant="caption" style={{ color: '#00BC9E' }}>Multiple correct answer can be selected. Please check the choices which are right.</Typography>
                    <Box mt={1}>
                        {choices && choices.map((choice, index) => (
                            <Box display='flex' flexDirection='row' alignItems='center' mb={1}>
                                <input
                                    className={classes.choice}
                                    type='text'
                                    value={choice.text}
                                    placeholder={`Choice ${index+1}`}
                                    onChange={(e) => handleChoiceTxtChange(index, e.target.value)}
                                />
                                {choice.correct ?
                                    <CheckBoxIcon
                                        style={{ color: '#00BC9E', marginLeft: 8, cursor: 'pointer' }}
                                        onClick={() => handleCheck(index)}
                                    />
                                    :
                                    <CheckBoxOutlineBlankIcon
                                        style={{ color: 'grey', marginLeft: 8, cursor: 'pointer' }}
                                        onClick={() => handleCheck(index)}
                                    />
                                }
                            </Box>
                        ))}
                        <Typography variant='caption' style={{ color: '#C0392B' }}>{errorChoices}</Typography>
                    </Box>
                </Box>

                <Box display='flex' flexDirection='row'>
                    <Box display="flex" flexDirection="row" alignItems='center' style={{ cursor: 'pointer' }} onClick={handleAddChoice}>
                        <AddCircleOutlineIcon style={{ color: '#0B6AB0', fontSize: 16 }} />
                        <Typography variant='body2' style={{ color: '#0B6AB0', marginLeft: 2 }} >Add Choice</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center' ml={2} style={{ cursor: 'pointer' }} onClick={handleDeleteChoice}>
                        <RemoveCircleOutlineIcon style={{ color: '#C0392B', fontSize: 16 }} />
                        <Typography variant='body2' style={{ color: '#C0392B', marginLeft: 2 }}>Delete Choice</Typography>
                    </Box>
                </Box>

                {update ?
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={2}>
                        <Button
                            className={classes.cancelBtn}
                            color="primary"
                            size="small"
                            onClick={() => setOpen(false)}
                            disableElevation
                        >
                            Close
                        </Button>
                        <Button
                            className={classes.saveBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </Box>
                    :
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={4} mb={2}>
                        <Button
                            className={classes.cancelBtn}
                            color="primary"
                            size="small"
                            onClick={() => setOpen(false)}
                            disableElevation
                        >
                            Close
                        </Button>
                        <Button
                            className={classes.addAnotherBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disabled={!addAnotherEnabled}
                            disableElevation
                            onClick={() => handleSave(true)}
                        >
                            Save & Add Another
                        </Button>
                        <Button
                            className={classes.saveBtn}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={() => handleSave(false)}
                        >
                            Save
                        </Button>
                    </Box>
                }
            </DialogContent>
        </Dialog>
        
        <Snackbar open={openCreateSnack} autoHideDuration={4000} onClose={handleCloseCreateSnack}>
            <Alert onClose={handleCloseCreateSnack} severity="success">
                Question added.
            </Alert>
        </Snackbar>
        <Snackbar open={openUpdateSnack} autoHideDuration={4000} onClose={handleCloseUpdateSnack}>
            <Alert onClose={handleCloseUpdateSnack} severity="success">
                Question updated.
            </Alert>
        </Snackbar>
        <Snackbar open={openFileCreateSnack} autoHideDuration={4000} onClose={() => setOpenFileCreateSnack(false)}>
            <Alert onClose={() => setOpenFileCreateSnack(false)} severity="success">
                File added.
            </Alert>
        </Snackbar>
        <Snackbar open={openFileDeleteSnack} autoHideDuration={4000} onClose={() => setOpenFileDeleteSnack(false)}>
            <Alert onClose={() => setOpenFileDeleteSnack(false)} severity="error">
                File deleted.
            </Alert>
        </Snackbar>
        <Snackbar open={openFailureSnack} autoHideDuration={4000} onClose={handleCloseFailureSnack}>
            <Alert onClose={handleCloseFailureSnack} severity="error">
                Operation failed!
            </Alert>
        </Snackbar>
        </>
    )
}

export default CreateMCQDlg;
