import { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Typography, Button, Box, IconButton, Backdrop, Paper, Snackbar, useTheme } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IoHourglassOutline } from "react-icons/io5";
import { FiCalendar, FiClock, FiTrendingUp, FiHelpCircle, FiInfo } from "react-icons/fi";
import {FaInfoCircle} from "react-icons/fa";
import axios from 'axios';
import moment from "moment";
import { useConfirm } from "material-ui-confirm";
import CreateExam from './CreateExam';
import QuestionList from './QuestionList';
import ExamSubmissionList from './ExamSubmissionList';
import ExamSchedule from './ExamSchedule';
import ExamRunningView from './ExamRunningView';
import { userTime } from '../../custom_util';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(10),
        minHeight: '60vh',
    },
    header: {
        color: '#0B6AB0',
        borderBottom: '2px solid #00BC9E',
    },
    addButton: {
        backgroundColor: '#0B6AB0',
        '&:hover': {
            background: '#0B6AB0',
        },
        color: '#FFFFFF',
        textTransform: 'none',
        marginLeft: theme.spacing(1),
    },
    addExamBtn: {
        color: '#0B6AB0',
        cursor: 'pointer',
    },
    addExamIcon: {
        color: '#0B6AB0',
        fontSize: 16,
    },
    addExamText: {
        color: '#0B6AB0',
        fontWeight: 'bold',
        marginLeft: 2,
    },
    examPaper: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    examActionBtn: {
        color: '#0B6AB0',
        cursor: 'pointer',
        marginRight: 4,
    },
    examActionSep: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    calendarIcon: {
        color: '#0B6AB0',
        marginRight: 4,
    },
    clockIcon: {
        color: '#AF2323',
        marginRight: 4,
    },
    durationIcon: {
        color: '#00BC9E',
        marginLeft: 8,
        marginRight: 4,
    },
    confirmButton: {
        textTransform: 'none',
        backgroundColor: '#0B6AB0',
        '&:hover': {
            backgroundColor: '#0B6AB0',
        },
        color: '#FFFFFF'
    },
    cancelBtn: {
        textTransform: 'none',
        color: '#0B6AB0',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#0B6AB0',
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
    titleDetails: {
        marginTop: '8px',
        marginLeft: '.6rem',
        cursor: 'pointer'
    },
    customWidth: {
        maxWidth: 500,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: 0,
        boxShadow: 'none',
        border: '1px solid gray'
    },
}));

const examStatus = {
    'Unpublished': 'grey',
    'Published': '#0B6AB0',
    'Finished': '#00BC9E',
}



const ExamCard = ({ index, exams, setExams, handleEdit }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:850px)');
    const confirm = useConfirm();
    const [openQList, setOpenQList] = useState(false);
    const [openExamSubmissionList, setOpenExamSubmissionList] = useState(false);
    const [openExamSchedule, setOpenExamSchedule] = useState(false);
    const [openExamRunningView, setOpenExamRunningView] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const [submissionListLoading, setSubmissionListLoading] = useState(false);
    const [openUpdateSnack, setOpenUpdateSnack] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    var now = new Date();
    
    const getExamSubmissionList = async (examId) => {
        setSubmissionListLoading(true);
        await axios({
            method: 'GET',
            url: 'exams/exam-submission-list/',
            params: {
                exam_id:examId
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },

        }).then((response) => {
            setSubmissionListLoading(false);
            if (response.status === 200) {
                // setSubmissions(response.data)
                console.log('# ---- Exam submission list success ---- #', response.status);
                setSubmissions(response.data)
            } else {
                console.log('# ---- Exam submission list failed ---- #', response.status);
            }
        }).catch((error) => {
            setSubmissionListLoading(false);
            console.log('# ---- Exam submission list error ---- #', error);
        })
    }

    const confirmChangeExamStatus = (status) => {
        confirm({
            title: <Typography variant='h6' style={{ fontWeight: 'normal' }}>Change Status</Typography>,
            description: <Typography variant='body1'>{status} the exam?</Typography>,
            confirmationText: 'Confirm',
            confirmationButtonProps: { className: classes.confirmButton, disableElevation: true },
            cancellationButtonProps: { className: classes.cancelBtn, disableElevation: true },
        })
        .then(() => changeExamStatus(status))
        .catch(() => console.log('Cancelled!'));
    }

    const changeExamStatus = (status) => {
        axios({
            method: 'PUT',
            url: `exams/exam-status/${exams[index].id}/`,
            data: {
                status: status + 'ed'
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }).then((response) => {
            if (response.status === 200) {
                console.log("# ---- Exam status change success ---- #");
                var values = [...exams];
                values[index] = response.data;
                setExams(values);

                setOpenUpdateSnack(true);
            } else {
                console.log('# ---- Exam status change failed ---- #', response.status);
            }
        }).catch((error) => {
            console.log('# ---- Exam status change error ---- #', error);
        })
    }

    const handleCloseUpdateSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUpdateSnack(false);
    }

    

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Paper elevation={0} variant='outlined' className={classes.examPaper}>
            {/* <Box p={3} display='flex' flexDirection={!matches ? "column": "row"} justifyContent='space-between' alignItems={!matches ? 'flex-start' : 'center'}> */}
            <Box p={3} display='flex' flexDirection={"row"} justifyContent='space-between' alignItems={!matches ? 'flex-start' : 'center'} flexWrap="wrap">
                <Box mb={!matches ? 1.5 : 0} style={!matches ? {flexBasis: '100%'} : {flexBasis: 'auto'}}>
                    <Box display='flex' flexDirection='row' alignItems='center' id="test">
                        <Typography 
                            variant="h6" 
                            noWrap 
                            style={{ fontWeight: 'normal', maxWidth:"20vw", cursor: 'pointer'}}
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}>{exams[index].title}
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.paper,
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <Box px={1} py={0} display='flex' flexDirection='row' alignItems='center' style={{width:'auto'}}>
                                <Typography variant='body2'>{exams[index].title}</Typography>
                            </Box>
                        </Popover>
                        

                        <PopupState variant="popover" popupId="question title details popup">
                            {(popupState) => (
                                <div>
                                {/* <Typography variant='caption' color='textSecondary' display='inine' className={classes.titleDetails}>
                                    <FiHelpCircle color={"blue"} {...bindTrigger(popupState)}/>
                                </Typography> */}
                                <FaInfoCircle color={"#0B6AB0"} className={classes.titleDetails} {...bindTrigger(popupState)}/>
                                <Popover
                                    {...bindPopover(popupState)}
                                    
                                    classes={{
                                        paper: classes.paper,
                                    }}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                    }}
                                >
                                    <Box px={1} py={0} display='flex' flexDirection='row' alignItems='center' style={{width:'auto'}}>
                                        <Typography variant="body2" style={{ fontWeight: 'normal'}}>
                                            Created by - {exams[index].added_by.first_name ? exams[index].added_by.first_name + " " + exams[index].added_by.last_name : exams[index].added_by.username}
                                        </Typography>
                                    </Box>
                                </Popover>
                                </div>
                            )}
                        </PopupState>
                        
                    </Box>
                    
                    <Box display='flex' flexDirection='row' mt={0}>
                        {(() => {
                            if (exams[index].status === 'Unpublished') {
                                return (
                                    <>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => handleEdit(exams[index].id, index)}>Edit</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenQList(true)}>Manage Questions</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenExamSchedule(true)}>Schedule</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => confirmChangeExamStatus("Publish")}>Publish</Typography>
                                    </>
                                )
                            }
                            else if (exams[index].status === 'Published') {
                                return (
                                    <>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => handleEdit(exams[index].id, index)}>Edit</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenQList(true)}>Manage Questions</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenExamSchedule(true)}>Schedule</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenExamRunningView(true)}>View</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => confirmChangeExamStatus("Unpublish")}>Unpublish</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => confirmChangeExamStatus("Finish")}>Finish</Typography>
                                    </>
                                )
                            }
                            else if (exams[index].status === 'Finished') {
                                return (
                                    <>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => {setOpenExamSubmissionList(true); getExamSubmissionList(exams[index].id)}}>Grade</Typography>
                                        <Typography variant="caption" className={classes.examActionSep}> | </Typography>
                                        <Typography variant="caption" className={classes.examActionBtn} onClick={() => setOpenQList(true)}>Questions</Typography>
                                    </>
                                )
                            }

                            return null;
                        })()}
                    </Box>
                </Box>
            
                    <Box mb={!matches ? 1.5 : 0}>
                        <Box display='flex' flexDirection='row' alignItems='center' mb={1}>
                            <FiCalendar className={classes.calendarIcon} />
                            <Typography variant='body2'>{userTime(exams[index].start_time, 'DD MMM YYYY')}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <FiClock className={classes.clockIcon} />
                            <Typography variant='body2'>{userTime(exams[index].start_time, 'hh:mm A')}</Typography>
                            <FiTrendingUp className={classes.durationIcon} />
                            <Typography variant='body2'>{exams[index].duration} min</Typography>
                        </Box>
                    </Box>
                    <Box mb={!matches ? 1.5 : 0}>
                        <Typography variant="body2">Participants: {exams[index].participants !== 0 ? exams[index].participants : "N/A"}</Typography>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Typography variant="body2">Status: </Typography>
                            <Typography variant='caption' style={{ marginLeft: 4, marginTop:4, fontWeight: 'bold', color: examStatus[exams[index].status] }}>{exams[index].status}</Typography>
                        </Box>
                    </Box>
        
            </Box>

            <QuestionList examId={exams[index].id} examTitle={exams[index].title} open={openQList} setOpen={setOpenQList} />
            <ExamSubmissionList
                examId={exams[index].id}
                examTitle={exams[index].title}
                open={openExamSubmissionList}
                setOpen={setOpenExamSubmissionList}
                submissions={submissions}
                setSubmissions={setSubmissions}
                loading={submissionListLoading}
            />
            <ExamSchedule 
                examId={exams[index].id} 
                examTitle={exams[index].title}
                examEnableAll={exams[index].is_enabled_all} 
                open={openExamSchedule} 
                setOpen={setOpenExamSchedule} 
            />

            <ExamRunningView
                examId={exams[index].id}
                examTitle={exams[index].title}
                open={openExamRunningView}
                setOpen={setOpenExamRunningView}
            />
            
            <Snackbar open={openUpdateSnack} autoHideDuration={4000} onClose={handleCloseUpdateSnack}>
                <Alert onClose={handleCloseUpdateSnack} severity="success">
                    Exam status updated.
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={submissionListLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </Paper>
    )
}

const ManageExam = ({ courseId }) => {
    const classes = useStyles();
    const [exams, setExams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [openCreateExam, setOpenCreateExam] = useState(false);
    const [openEditExam, setOpenEditExam] = useState(false);
    const [examId, setExamId] = useState(null);  // For exam edit
    const [examIndex, setExamIndex] = useState(null);  // For exam edit

    useEffect(() => {
        const getExams = () => {
            axios({
                method: 'GET',
                url: `exams/exam-list/${courseId}/`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
            }).then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setExams(response.data);
                } else {
                    console.log('Exams fetch failed!', response.status);
                }
            }).catch((error) => {
                setIsLoading(false);
                console.log('Exams fetch error!', error);
            })
        }
        getExams();
    }, []);

    const handleEditExam = (id, index) => {
        setExamId(id);
        setExamIndex(index);
        setOpenEditExam(true);
    }

    return (
        <Paper elevation={0} variant="outlined" square className={classes.root}>
            <Box display='flex' justifyContent='space-between' mb={2} ml={3} mr={3}>
                <Typography variant='h6' className={classes.header} display='inline'>Exams</Typography>
                <Box display="flex" flexDirection="row" alignItems='center' className={classes.addExamBtn} onClick={() => setOpenCreateExam(true)}>
                    <AddCircleOutlineIcon className={classes.addExamIcon} />
                    <Typography variant='body2' className={classes.addExamText}>Add Exam</Typography>
                </Box>
            </Box>
            <Box>
                {exams.length ?
                    <Grid container spacing={2}>
                        {exams.map((exam, index) => (
                            <Grid item spacing={2} xs={12} key={index}>
                                <ExamCard index={index} exams={exams} setExams={setExams} handleEdit={handleEditExam}/>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    !isLoading && <Box height='50vh' display="flex" flexDirection='row' justifyContent='center'>
                        <Box width='100vw' display='flex' flexDirection='column' justifyContent='center'>
                            <Typography variant="h5" color="initial" align="center" style={{ color: '#0B6AB0' }}>No Exam Found</Typography>
                            <Typography variant="body1" color="textSecondary" align='center'>Please click "+ Add Exam" button to proceed.</Typography>
                        </Box>
                    </Box>
                }
            </Box>

            {/* Create exam */}
            <CreateExam courseId={courseId} open={openCreateExam} setOpen={setOpenCreateExam} exams={exams} setExams={setExams} />
            {/* Edit exam */}
            <CreateExam courseId={courseId} open={openEditExam} setOpen={setOpenEditExam} exams={exams} setExams={setExams} update={true} id={examId} index={examIndex} />

            <Backdrop className={classes.backdrop} open={isLoading}>
                <IoHourglassOutline style={{ fontSize: 35 }} />
            </Backdrop>
        </Paper>
    )
}

export default ManageExam
