import moment from 'moment'
import 'moment-timezone'

const baseURL = process.env.REACT_APP_SERVER_URL.substring(0, process.env.REACT_APP_SERVER_URL.length - 1);  // Using substring to remove last '/'

export const userTime = (time, format) => {
    return moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
}

export const utcTime = (time, format) => {
    return moment(time, format).utc().format(format)
}

export const getLocalDateFormat = (time, format) => {
    return moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate()
}

export const fromNowMoment = (time) => (
    moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).fromNow()
)

function removeHttp(url) {
    return url.replace(/^https?:\/\//, '');
}

export const webSocketBase = () => {
    var protocol = window.location.protocol;
    var host = `${removeHttp(baseURL)}`;
    
    if (protocol == 'https:') {
        var wsStart = 'wss://';
    }
    else {
        var wsStart = 'ws://';
    }

    return wsStart+host;
}